@charset "UTF-8";
/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

h1 {
  margin: 0.67em 0;
  font-size: 2em; }

mark {
  color: #000;
  background: #ff0; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  line-height: normal; }

input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  padding: 0.35em 0.625em 0.75em;
  margin: 0 2px;
  border: 1px solid silver; }

legend {
  padding: 0;
  border: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: 700; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

td,
th {
  padding: 0; }

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  :after,
  :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img,
  tr {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important; }
  .label {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
  .table td,
  .table th {
    background-color: #fff !important; }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ddd !important; } }

@font-face {
  font-family: 'Glyphicons Halflings';
  /* src: url(fonts/glyphicons-halflings-regular.eot);
    src: url(fonts/glyphicons-halflings-regular.eot?#iefix)
        format("embedded-opentype"),
      url(fonts/glyphicons-halflings-regular.woff2) format("woff2"),
      url(fonts/glyphicons-halflings-regular.woff) format("woff"),
      url(fonts/glyphicons-halflings-regular.ttf) format("truetype"),
      url(fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular)
        format("svg"); */ }

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.glyphicon-asterisk:before {
  content: '*'; }

.glyphicon-plus:before {
  content: '+'; }

.glyphicon-eur:before,
.glyphicon-euro:before {
  content: '\20AC'; }

.glyphicon-minus:before {
  content: '\2212'; }

.glyphicon-cloud:before {
  content: '\2601'; }

.glyphicon-envelope:before {
  content: '\2709'; }

.glyphicon-pencil:before {
  content: '\270F'; }

.glyphicon-glass:before {
  content: '\E001'; }

.glyphicon-music:before {
  content: '\E002'; }

.glyphicon-search:before {
  content: '\E003'; }

.glyphicon-heart:before {
  content: '\E005'; }

.glyphicon-star:before {
  content: '\E006'; }

.glyphicon-star-empty:before {
  content: '\E007'; }

.glyphicon-user:before {
  content: '\E008'; }

.glyphicon-film:before {
  content: '\E009'; }

.glyphicon-th-large:before {
  content: '\E010'; }

.glyphicon-th:before {
  content: '\E011'; }

.glyphicon-th-list:before {
  content: '\E012'; }

.glyphicon-ok:before {
  content: '\E013'; }

.glyphicon-remove:before {
  content: '\E014'; }

.glyphicon-zoom-in:before {
  content: '\E015'; }

.glyphicon-zoom-out:before {
  content: '\E016'; }

.glyphicon-off:before {
  content: '\E017'; }

.glyphicon-signal:before {
  content: '\E018'; }

.glyphicon-cog:before {
  content: '\E019'; }

.glyphicon-trash:before {
  content: '\E020'; }

.glyphicon-home:before {
  content: '\E021'; }

.glyphicon-file:before {
  content: '\E022'; }

.glyphicon-time:before {
  content: '\E023'; }

.glyphicon-road:before {
  content: '\E024'; }

.glyphicon-download-alt:before {
  content: '\E025'; }

.glyphicon-download:before {
  content: '\E026'; }

.glyphicon-upload:before {
  content: '\E027'; }

.glyphicon-inbox:before {
  content: '\E028'; }

.glyphicon-play-circle:before {
  content: '\E029'; }

.glyphicon-repeat:before {
  content: '\E030'; }

.glyphicon-refresh:before {
  content: '\E031'; }

.glyphicon-list-alt:before {
  content: '\E032'; }

.glyphicon-lock:before {
  content: '\E033'; }

.glyphicon-flag:before {
  content: '\E034'; }

.glyphicon-headphones:before {
  content: '\E035'; }

.glyphicon-volume-off:before {
  content: '\E036'; }

.glyphicon-volume-down:before {
  content: '\E037'; }

.glyphicon-volume-up:before {
  content: '\E038'; }

.glyphicon-qrcode:before {
  content: '\E039'; }

.glyphicon-barcode:before {
  content: '\E040'; }

.glyphicon-tag:before {
  content: '\E041'; }

.glyphicon-tags:before {
  content: '\E042'; }

.glyphicon-book:before {
  content: '\E043'; }

.glyphicon-bookmark:before {
  content: '\E044'; }

.glyphicon-print:before {
  content: '\E045'; }

.glyphicon-camera:before {
  content: '\E046'; }

.glyphicon-font:before {
  content: '\E047'; }

.glyphicon-bold:before {
  content: '\E048'; }

.glyphicon-italic:before {
  content: '\E049'; }

.glyphicon-text-height:before {
  content: '\E050'; }

.glyphicon-text-width:before {
  content: '\E051'; }

.glyphicon-align-left:before {
  content: '\E052'; }

.glyphicon-align-center:before {
  content: '\E053'; }

.glyphicon-align-right:before {
  content: '\E054'; }

.glyphicon-align-justify:before {
  content: '\E055'; }

.glyphicon-list:before {
  content: '\E056'; }

.glyphicon-indent-left:before {
  content: '\E057'; }

.glyphicon-indent-right:before {
  content: '\E058'; }

.glyphicon-facetime-video:before {
  content: '\E059'; }

.glyphicon-picture:before {
  content: '\E060'; }

.glyphicon-map-marker:before {
  content: '\E062'; }

.glyphicon-adjust:before {
  content: '\E063'; }

.glyphicon-tint:before {
  content: '\E064'; }

.glyphicon-edit:before {
  content: '\E065'; }

.glyphicon-share:before {
  content: '\E066'; }

.glyphicon-check:before {
  content: '\E067'; }

.glyphicon-move:before {
  content: '\E068'; }

.glyphicon-step-backward:before {
  content: '\E069'; }

.glyphicon-fast-backward:before {
  content: '\E070'; }

.glyphicon-backward:before {
  content: '\E071'; }

.glyphicon-play:before {
  content: '\E072'; }

.glyphicon-pause:before {
  content: '\E073'; }

.glyphicon-stop:before {
  content: '\E074'; }

.glyphicon-forward:before {
  content: '\E075'; }

.glyphicon-fast-forward:before {
  content: '\E076'; }

.glyphicon-step-forward:before {
  content: '\E077'; }

.glyphicon-eject:before {
  content: '\E078'; }

.glyphicon-chevron-left:before {
  content: '\E079'; }

.glyphicon-chevron-right:before {
  content: '\E080'; }

.glyphicon-plus-sign:before {
  content: '\E081'; }

.glyphicon-minus-sign:before {
  content: '\E082'; }

.glyphicon-remove-sign:before {
  content: '\E083'; }

.glyphicon-ok-sign:before {
  content: '\E084'; }

.glyphicon-question-sign:before {
  content: '\E085'; }

.glyphicon-info-sign:before {
  content: '\E086'; }

.glyphicon-screenshot:before {
  content: '\E087'; }

.glyphicon-remove-circle:before {
  content: '\E088'; }

.glyphicon-ok-circle:before {
  content: '\E089'; }

.glyphicon-ban-circle:before {
  content: '\E090'; }

.glyphicon-arrow-left:before {
  content: '\E091'; }

.glyphicon-arrow-right:before {
  content: '\E092'; }

.glyphicon-arrow-up:before {
  content: '\E093'; }

.glyphicon-arrow-down:before {
  content: '\E094'; }

.glyphicon-share-alt:before {
  content: '\E095'; }

.glyphicon-resize-full:before {
  content: '\E096'; }

.glyphicon-resize-small:before {
  content: '\E097'; }

.glyphicon-exclamation-sign:before {
  content: '\E101'; }

.glyphicon-gift:before {
  content: '\E102'; }

.glyphicon-leaf:before {
  content: '\E103'; }

.glyphicon-fire:before {
  content: '\E104'; }

.glyphicon-eye-open:before {
  content: '\E105'; }

.glyphicon-eye-close:before {
  content: '\E106'; }

.glyphicon-warning-sign:before {
  content: '\E107'; }

.glyphicon-plane:before {
  content: '\E108'; }

.glyphicon-calendar:before {
  content: '\E109'; }

.glyphicon-random:before {
  content: '\E110'; }

.glyphicon-comment:before {
  content: '\E111'; }

.glyphicon-magnet:before {
  content: '\E112'; }

.glyphicon-chevron-up:before {
  content: '\E113'; }

.glyphicon-chevron-down:before {
  content: '\E114'; }

.glyphicon-retweet:before {
  content: '\E115'; }

.glyphicon-shopping-cart:before {
  content: '\E116'; }

.glyphicon-folder-close:before {
  content: '\E117'; }

.glyphicon-folder-open:before {
  content: '\E118'; }

.glyphicon-resize-vertical:before {
  content: '\E119'; }

.glyphicon-resize-horizontal:before {
  content: '\E120'; }

.glyphicon-hdd:before {
  content: '\E121'; }

.glyphicon-bullhorn:before {
  content: '\E122'; }

.glyphicon-bell:before {
  content: '\E123'; }

.glyphicon-certificate:before {
  content: '\E124'; }

.glyphicon-thumbs-up:before {
  content: '\E125'; }

.glyphicon-thumbs-down:before {
  content: '\E126'; }

.glyphicon-hand-right:before {
  content: '\E127'; }

.glyphicon-hand-left:before {
  content: '\E128'; }

.glyphicon-hand-up:before {
  content: '\E129'; }

.glyphicon-hand-down:before {
  content: '\E130'; }

.glyphicon-circle-arrow-right:before {
  content: '\E131'; }

.glyphicon-circle-arrow-left:before {
  content: '\E132'; }

.glyphicon-circle-arrow-up:before {
  content: '\E133'; }

.glyphicon-circle-arrow-down:before {
  content: '\E134'; }

.glyphicon-globe:before {
  content: '\E135'; }

.glyphicon-wrench:before {
  content: '\E136'; }

.glyphicon-tasks:before {
  content: '\E137'; }

.glyphicon-filter:before {
  content: '\E138'; }

.glyphicon-briefcase:before {
  content: '\E139'; }

.glyphicon-fullscreen:before {
  content: '\E140'; }

.glyphicon-dashboard:before {
  content: '\E141'; }

.glyphicon-paperclip:before {
  content: '\E142'; }

.glyphicon-heart-empty:before {
  content: '\E143'; }

.glyphicon-link:before {
  content: '\E144'; }

.glyphicon-phone:before {
  content: '\E145'; }

.glyphicon-pushpin:before {
  content: '\E146'; }

.glyphicon-usd:before {
  content: '\E148'; }

.glyphicon-gbp:before {
  content: '\E149'; }

.glyphicon-sort:before {
  content: '\E150'; }

.glyphicon-sort-by-alphabet:before {
  content: '\E151'; }

.glyphicon-sort-by-alphabet-alt:before {
  content: '\E152'; }

.glyphicon-sort-by-order:before {
  content: '\E153'; }

.glyphicon-sort-by-order-alt:before {
  content: '\E154'; }

.glyphicon-sort-by-attributes:before {
  content: '\E155'; }

.glyphicon-sort-by-attributes-alt:before {
  content: '\E156'; }

.glyphicon-unchecked:before {
  content: '\E157'; }

.glyphicon-expand:before {
  content: '\E158'; }

.glyphicon-collapse-down:before {
  content: '\E159'; }

.glyphicon-collapse-up:before {
  content: '\E160'; }

.glyphicon-log-in:before {
  content: '\E161'; }

.glyphicon-flash:before {
  content: '\E162'; }

.glyphicon-log-out:before {
  content: '\E163'; }

.glyphicon-new-window:before {
  content: '\E164'; }

.glyphicon-record:before {
  content: '\E165'; }

.glyphicon-save:before {
  content: '\E166'; }

.glyphicon-open:before {
  content: '\E167'; }

.glyphicon-saved:before {
  content: '\E168'; }

.glyphicon-import:before {
  content: '\E169'; }

.glyphicon-export:before {
  content: '\E170'; }

.glyphicon-send:before {
  content: '\E171'; }

.glyphicon-floppy-disk:before {
  content: '\E172'; }

.glyphicon-floppy-saved:before {
  content: '\E173'; }

.glyphicon-floppy-remove:before {
  content: '\E174'; }

.glyphicon-floppy-save:before {
  content: '\E175'; }

.glyphicon-floppy-open:before {
  content: '\E176'; }

.glyphicon-credit-card:before {
  content: '\E177'; }

.glyphicon-transfer:before {
  content: '\E178'; }

.glyphicon-cutlery:before {
  content: '\E179'; }

.glyphicon-header:before {
  content: '\E180'; }

.glyphicon-compressed:before {
  content: '\E181'; }

.glyphicon-earphone:before {
  content: '\E182'; }

.glyphicon-phone-alt:before {
  content: '\E183'; }

.glyphicon-tower:before {
  content: '\E184'; }

.glyphicon-stats:before {
  content: '\E185'; }

.glyphicon-sd-video:before {
  content: '\E186'; }

.glyphicon-hd-video:before {
  content: '\E187'; }

.glyphicon-subtitles:before {
  content: '\E188'; }

.glyphicon-sound-stereo:before {
  content: '\E189'; }

.glyphicon-sound-dolby:before {
  content: '\E190'; }

.glyphicon-sound-5-1:before {
  content: '\E191'; }

.glyphicon-sound-6-1:before {
  content: '\E192'; }

.glyphicon-sound-7-1:before {
  content: '\E193'; }

.glyphicon-copyright-mark:before {
  content: '\E194'; }

.glyphicon-registration-mark:before {
  content: '\E195'; }

.glyphicon-cloud-download:before {
  content: '\E197'; }

.glyphicon-cloud-upload:before {
  content: '\E198'; }

.glyphicon-tree-conifer:before {
  content: '\E199'; }

.glyphicon-tree-deciduous:before {
  content: '\E200'; }

.glyphicon-cd:before {
  content: '\E201'; }

.glyphicon-save-file:before {
  content: '\E202'; }

.glyphicon-open-file:before {
  content: '\E203'; }

.glyphicon-level-up:before {
  content: '\E204'; }

.glyphicon-copy:before {
  content: '\E205'; }

.glyphicon-paste:before {
  content: '\E206'; }

.glyphicon-alert:before {
  content: '\E209'; }

.glyphicon-equalizer:before {
  content: '\E210'; }

.glyphicon-king:before {
  content: '\E211'; }

.glyphicon-queen:before {
  content: '\E212'; }

.glyphicon-pawn:before {
  content: '\E213'; }

.glyphicon-bishop:before {
  content: '\E214'; }

.glyphicon-knight:before {
  content: '\E215'; }

.glyphicon-baby-formula:before {
  content: '\E216'; }

.glyphicon-tent:before {
  content: '\26FA'; }

.glyphicon-blackboard:before {
  content: '\E218'; }

.glyphicon-bed:before {
  content: '\E219'; }

.glyphicon-apple:before {
  content: '\F8FF'; }

.glyphicon-erase:before {
  content: '\E221'; }

.glyphicon-hourglass:before {
  content: '\231B'; }

.glyphicon-lamp:before {
  content: '\E223'; }

.glyphicon-duplicate:before {
  content: '\E224'; }

.glyphicon-piggy-bank:before {
  content: '\E225'; }

.glyphicon-scissors:before {
  content: '\E226'; }

.glyphicon-bitcoin:before {
  content: '\E227'; }

.glyphicon-btc:before {
  content: '\E227'; }

.glyphicon-xbt:before {
  content: '\E227'; }

.glyphicon-yen:before {
  content: '\A5'; }

.glyphicon-jpy:before {
  content: '\A5'; }

.glyphicon-ruble:before {
  content: '\20BD'; }

.glyphicon-rub:before {
  content: '\20BD'; }

.glyphicon-scale:before {
  content: '\E230'; }

.glyphicon-ice-lolly:before {
  content: '\E231'; }

.glyphicon-ice-lolly-tasted:before {
  content: '\E232'; }

.glyphicon-education:before {
  content: '\E233'; }

.glyphicon-option-horizontal:before {
  content: '\E234'; }

.glyphicon-option-vertical:before {
  content: '\E235'; }

.glyphicon-menu-hamburger:before {
  content: '\E236'; }

.glyphicon-modal-window:before {
  content: '\E237'; }

.glyphicon-oil:before {
  content: '\E238'; }

.glyphicon-grain:before {
  content: '\E239'; }

.glyphicon-sunglasses:before {
  content: '\E240'; }

.glyphicon-text-size:before {
  content: '\E241'; }

.glyphicon-text-color:before {
  content: '\E242'; }

.glyphicon-text-background:before {
  content: '\E243'; }

.glyphicon-object-align-top:before {
  content: '\E244'; }

.glyphicon-object-align-bottom:before {
  content: '\E245'; }

.glyphicon-object-align-horizontal:before {
  content: '\E246'; }

.glyphicon-object-align-left:before {
  content: '\E247'; }

.glyphicon-object-align-vertical:before {
  content: '\E248'; }

.glyphicon-object-align-right:before {
  content: '\E249'; }

.glyphicon-triangle-right:before {
  content: '\E250'; }

.glyphicon-triangle-left:before {
  content: '\E251'; }

.glyphicon-triangle-bottom:before {
  content: '\E252'; }

.glyphicon-triangle-top:before {
  content: '\E253'; }

.glyphicon-console:before {
  content: '\E254'; }

.glyphicon-superscript:before {
  content: '\E255'; }

.glyphicon-subscript:before {
  content: '\E256'; }

.glyphicon-menu-left:before {
  content: '\E257'; }

.glyphicon-menu-right:before {
  content: '\E258'; }

.glyphicon-menu-down:before {
  content: '\E259'; }

.glyphicon-menu-up:before {
  content: '\E260'; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  color: #337ab7;
  text-decoration: none; }

a:focus,
a:hover {
  color: #23527c;
  text-decoration: underline; }

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

figure {
  margin: 0; }

img {
  vertical-align: middle; }

.carousel-inner > .item > a > img,
.carousel-inner > .item > img,
.img-responsive,
.thumbnail a > img,
.thumbnail > img {
  display: block;
  max-width: 100%;
  height: auto; }

.img-rounded {
  border-radius: 6px; }

.img-thumbnail {
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.img-circle {
  border-radius: 50%; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

[role="button"] {
  cursor: pointer; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777; }

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px; }

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
  font-size: 65%; }

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px; }

.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-size: 75%; }

.h1,
h1 {
  font-size: 36px; }

.h2,
h2 {
  font-size: 30px; }

.h3,
h3 {
  font-size: 24px; }

.h4,
h4 {
  font-size: 18px; }

.h5,
h5 {
  font-size: 14px; }

.h6,
h6 {
  font-size: 12px; }

p {
  margin: 0 0 10px; }

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4; }

@media (min-width: 768px) {
  .lead {
    font-size: 21px; } }

.small,
small {
  font-size: 85%; }

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-muted {
  color: #777; }

.text-primary {
  color: #337ab7; }

a.text-primary:focus,
a.text-primary:hover {
  color: #286090; }

.text-success {
  color: #3c763d; }

a.text-success:focus,
a.text-success:hover {
  color: #2b542c; }

.text-info {
  color: #31708f; }

a.text-info:focus,
a.text-info:hover {
  color: #245269; }

.text-warning {
  color: #8a6d3b; }

a.text-warning:focus,
a.text-warning:hover {
  color: #66512c; }

.text-danger {
  color: #a94442; }

a.text-danger:focus,
a.text-danger:hover {
  color: #843534; }

.bg-primary {
  color: #fff;
  background-color: #337ab7; }

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #286090; }

.bg-success {
  background-color: #dff0d8; }

a.bg-success:focus,
a.bg-success:hover {
  background-color: #c1e2b3; }

.bg-info {
  background-color: #d9edf7; }

a.bg-info:focus,
a.bg-info:hover {
  background-color: #afd9ee; }

.bg-warning {
  background-color: #fcf8e3; }

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #f7ecb5; }

.bg-danger {
  background-color: #f2dede; }

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #e4b9b9; }

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee; }

ol,
ul {
  margin-top: 0;
  margin-bottom: 10px; }

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none; }

.list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px; }

dl {
  margin-top: 0;
  margin-bottom: 20px; }

dd,
dt {
  line-height: 1.42857143; }

dt {
  font-weight: 700; }

dd {
  margin-left: 0; }

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .dl-horizontal dd {
    margin-left: 180px; } }

abbr[data-original-title],
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #777; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee; }

blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child {
  margin-bottom: 0; }

blockquote .small,
blockquote footer,
blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777; }

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
  content: '\2014   \A0'; }

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0; }

.blockquote-reverse .small:before,
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
blockquote.pull-right .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before {
  content: ''; }

.blockquote-reverse .small:after,
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
blockquote.pull-right .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after {
  content: '\A0   \2014'; }

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace; }

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px; }

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  -webkit-box-shadow: none;
  box-shadow: none; }

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px; }

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 768px) {
  .container {
    width: 750px; } }

@media (min-width: 992px) {
  .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px; } }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  margin-right: -15px;
  margin-left: -15px; }

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left; }

.col-xs-12 {
  width: 100%; }

.col-xs-11 {
  width: 91.66666667%; }

.col-xs-10 {
  width: 83.33333333%; }

.col-xs-9 {
  width: 75%; }

.col-xs-8 {
  width: 66.66666667%; }

.col-xs-7 {
  width: 58.33333333%; }

.col-xs-6 {
  width: 50%; }

.col-xs-5 {
  width: 41.66666667%; }

.col-xs-4 {
  width: 33.3%; }

.col-xs-3 {
  width: 25%; }

.col-xs-2 {
  width: 16.66666667%; }

.col-xs-1 {
  width: 8.33333333%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-pull-11 {
  right: 91.66666667%; }

.col-xs-pull-10 {
  right: 83.33333333%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-8 {
  right: 66.66666667%; }

.col-xs-pull-7 {
  right: 58.33333333%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-5 {
  right: 41.66666667%; }

.col-xs-pull-4 {
  right: 33.3%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-2 {
  right: 16.66666667%; }

.col-xs-pull-1 {
  right: 8.33333333%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-push-11 {
  left: 91.66666667%; }

.col-xs-push-10 {
  left: 83.33333333%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-8 {
  left: 66.66666667%; }

.col-xs-push-7 {
  left: 58.33333333%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-5 {
  left: 41.66666667%; }

.col-xs-push-4 {
  left: 33.3%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-2 {
  left: 16.66666667%; }

.col-xs-push-1 {
  left: 8.33333333%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-offset-12 {
  margin-left: 100%; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-offset-4 {
  margin-left: 33.3%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-offset-0 {
  margin-left: 0; }

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.3%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-pull-11 {
    right: 91.66666667%; }
  .col-sm-pull-10 {
    right: 83.33333333%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-8 {
    right: 66.66666667%; }
  .col-sm-pull-7 {
    right: 58.33333333%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-5 {
    right: 41.66666667%; }
  .col-sm-pull-4 {
    right: 33.3%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-2 {
    right: 16.66666667%; }
  .col-sm-pull-1 {
    right: 8.33333333%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-push-11 {
    left: 91.66666667%; }
  .col-sm-push-10 {
    left: 83.33333333%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-8 {
    left: 66.66666667%; }
  .col-sm-push-7 {
    left: 58.33333333%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-5 {
    left: 41.66666667%; }
  .col-sm-push-4 {
    left: 33.3%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-2 {
    left: 16.66666667%; }
  .col-sm-push-1 {
    left: 8.33333333%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-offset-12 {
    margin-left: 100%; }
  .col-sm-offset-11 {
    margin-left: 91.66666667%; }
  .col-sm-offset-10 {
    margin-left: 83.33333333%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-8 {
    margin-left: 66.66666667%; }
  .col-sm-offset-7 {
    margin-left: 58.33333333%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-5 {
    margin-left: 41.66666667%; }
  .col-sm-offset-4 {
    margin-left: 33.3%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-2 {
    margin-left: 16.66666667%; }
  .col-sm-offset-1 {
    margin-left: 8.33333333%; }
  .col-sm-offset-0 {
    margin-left: 0; } }

@media (min-width: 992px) {
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.3%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-pull-11 {
    right: 91.66666667%; }
  .col-md-pull-10 {
    right: 83.33333333%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-8 {
    right: 66.66666667%; }
  .col-md-pull-7 {
    right: 58.33333333%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-5 {
    right: 41.66666667%; }
  .col-md-pull-4 {
    right: 33.3%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-2 {
    right: 16.66666667%; }
  .col-md-pull-1 {
    right: 8.33333333%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-push-11 {
    left: 91.66666667%; }
  .col-md-push-10 {
    left: 83.33333333%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-8 {
    left: 66.66666667%; }
  .col-md-push-7 {
    left: 58.33333333%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-5 {
    left: 41.66666667%; }
  .col-md-push-4 {
    left: 33.3%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-2 {
    left: 16.66666667%; }
  .col-md-push-1 {
    left: 8.33333333%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-offset-12 {
    margin-left: 100%; }
  .col-md-offset-11 {
    margin-left: 91.66666667%; }
  .col-md-offset-10 {
    margin-left: 83.33333333%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-8 {
    margin-left: 66.66666667%; }
  .col-md-offset-7 {
    margin-left: 58.33333333%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-5 {
    margin-left: 41.66666667%; }
  .col-md-offset-4 {
    margin-left: 33.3%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-2 {
    margin-left: 16.66666667%; }
  .col-md-offset-1 {
    margin-left: 8.33333333%; }
  .col-md-offset-0 {
    margin-left: 0; } }

@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66666667%; }
  .col-lg-10 {
    width: 83.33333333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66666667%; }
  .col-lg-7 {
    width: 58.33333333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66666667%; }
  .col-lg-4 {
    width: 33.3%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66666667%; }
  .col-lg-1 {
    width: 8.33333333%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-pull-11 {
    right: 91.66666667%; }
  .col-lg-pull-10 {
    right: 83.33333333%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-8 {
    right: 66.66666667%; }
  .col-lg-pull-7 {
    right: 58.33333333%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-5 {
    right: 41.66666667%; }
  .col-lg-pull-4 {
    right: 33.3%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-2 {
    right: 16.66666667%; }
  .col-lg-pull-1 {
    right: 8.33333333%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-push-11 {
    left: 91.66666667%; }
  .col-lg-push-10 {
    left: 83.33333333%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-8 {
    left: 66.66666667%; }
  .col-lg-push-7 {
    left: 58.33333333%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-5 {
    left: 41.66666667%; }
  .col-lg-push-4 {
    left: 33.3%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-2 {
    left: 16.66666667%; }
  .col-lg-push-1 {
    left: 8.33333333%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-offset-12 {
    margin-left: 100%; }
  .col-lg-offset-11 {
    margin-left: 91.66666667%; }
  .col-lg-offset-10 {
    margin-left: 83.33333333%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-8 {
    margin-left: 66.66666667%; }
  .col-lg-offset-7 {
    margin-left: 58.33333333%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-5 {
    margin-left: 41.66666667%; }
  .col-lg-offset-4 {
    margin-left: 33.3%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-2 {
    margin-left: 16.66666667%; }
  .col-lg-offset-1 {
    margin-left: 8.33333333%; }
  .col-lg-offset-0 {
    margin-left: 0; } }

table {
  background-color: transparent; }

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left; }

th {
  text-align: left; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px; }

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd; }

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd; }

.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
  border-top: 0; }

.table > tbody + tbody {
  border-top: 2px solid #ddd; }

.table .table {
  background-color: #fff; }

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  padding: 5px; }

.table-bordered {
  border: 1px solid #ddd; }

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #ddd; }

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-bottom-width: 2px; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9; }

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5; }

table col[class*='col-'] {
  position: static;
  display: table-column;
  float: none; }

table td[class*='col-'],
table th[class*='col-'] {
  position: static;
  display: table-cell;
  float: none; }

.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > thead > tr > td.active,
.table > thead > tr > th.active {
  background-color: #f5f5f5; }

.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr.active:hover > th,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover {
  background-color: #e8e8e8; }

.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > thead > tr > td.success,
.table > thead > tr > th.success {
  background-color: #dff0d8; }

.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr.success:hover > th,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover {
  background-color: #d0e9c6; }

.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > thead > tr > td.info,
.table > thead > tr > th.info {
  background-color: #d9edf7; }

.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr.info:hover > th,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover {
  background-color: #c4e3f3; }

.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > thead > tr > td.warning,
.table > thead > tr > th.warning {
  background-color: #fcf8e3; }

.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr.warning:hover > th,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover {
  background-color: #faf2cc; }

.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > thead > tr > td.danger,
.table > thead > tr > th.danger {
  background-color: #f2dede; }

.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr.danger:hover > th,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover {
  background-color: #ebcccc; }

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto; }

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd; }
  .table-responsive > .table {
    margin-bottom: 0; }
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > thead > tr > th {
    white-space: nowrap; }
  .table-responsive > .table-bordered {
    border: 0; }
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > thead > tr > th:first-child {
    border-left: 0; }
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > thead > tr > th:last-child {
    border-right: 0; }
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0; } }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700; }

input[type='search'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type='checkbox'],
input[type='radio'] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal; }

input[type='file'] {
  display: block; }

input[type='range'] {
  display: block;
  width: 100%; }

select[multiple],
select[size] {
  height: auto; }

input[type='file']:focus,
input[type='checkbox']:focus,
input[type='radio']:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #999; }

.form-control::-webkit-input-placeholder {
  color: #999; }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1; }

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed; }

textarea.form-control {
  height: auto; }

input[type='search'] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px; }
  .input-group-sm input[type='date'],
  .input-group-sm input[type='time'],
  .input-group-sm input[type='datetime-local'],
  .input-group-sm input[type='month'],
  input[type='date'].input-sm,
  input[type='time'].input-sm,
  input[type='datetime-local'].input-sm,
  input[type='month'].input-sm {
    line-height: 30px; }
  .input-group-lg input[type='date'],
  .input-group-lg input[type='time'],
  .input-group-lg input[type='datetime-local'],
  .input-group-lg input[type='month'],
  input[type='date'].input-lg,
  input[type='time'].input-lg,
  input[type='datetime-local'].input-lg,
  input[type='month'].input-lg {
    line-height: 46px; } }

.form-group {
  margin-bottom: 15px; }

.checkbox,
.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }

.checkbox label,
.radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer; }

.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'],
.radio input[type='radio'],
.radio-inline input[type='radio'] {
  position: absolute;
  margin-top: 4px\9;
  margin-left: -20px; }

.checkbox + .checkbox,
.radio + .radio {
  margin-top: -5px; }

.checkbox-inline,
.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer; }

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 10px; }

fieldset[disabled] input[type='checkbox'],
fieldset[disabled] input[type='radio'],
input[type='checkbox'].disabled,
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='radio'][disabled] {
  cursor: not-allowed; }

.checkbox-inline.disabled,
.radio-inline.disabled,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .radio-inline {
  cursor: not-allowed; }

.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label {
  cursor: not-allowed; }

.form-control-static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0; }

.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-right: 0;
  padding-left: 0; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

select.input-sm {
  height: 30px;
  line-height: 30px; }

select[multiple].input-sm,
textarea.input-sm {
  height: auto; }

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5; }

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

select.input-lg {
  height: 46px;
  line-height: 46px; }

select[multiple].input-lg,
textarea.input-lg {
  height: auto; }

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

.has-feedback {
  position: relative; }

.has-feedback .form-control {
  padding-right: 42.5px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

.form-group-lg .form-control + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.input-lg + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.form-group-sm .form-control + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.input-sm + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
  color: #3c763d; }

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
  color: #8a6d3b; }

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b; }

.has-warning .form-control-feedback {
  color: #8a6d3b; }

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442; }

.has-error .form-control-feedback {
  color: #a94442; }

.has-feedback label ~ .form-control-feedback {
  top: 25px; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-static {
    display: inline-block; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
  .form-inline .input-group .form-control,
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn {
    width: auto; }
  .form-inline .input-group > .form-control {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .checkbox,
  .form-inline .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .checkbox label,
  .form-inline .radio label {
    padding-left: 0; }
  .form-inline .checkbox input[type='checkbox'],
  .form-inline .radio input[type='radio'] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0; }

.form-horizontal .checkbox,
.form-horizontal .radio {
  min-height: 27px; }

.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px; }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right; } }

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px; }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px; } }

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px; }

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none; }

.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }

.btn-default.focus,
.btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c; }

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad; }

.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad; }

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c; }

.btn-default.active,
.btn-default:active,
.open > .dropdown-toggle.btn-default {
  background-image: none; }

.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc; }

.btn-default .badge {
  color: #fff;
  background-color: #333; }

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4; }

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40; }

.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74; }

.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74; }

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40; }

.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-image: none; }

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: #337ab7;
  border-color: #2e6da4; }

.btn-primary .badge {
  color: #337ab7;
  background-color: #fff; }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c; }

.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625; }

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439; }

.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439; }

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #398439;
  border-color: #255625; }

.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
  background-image: none; }

.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
  background-color: #5cb85c;
  border-color: #4cae4c; }

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff; }

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da; }

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85; }

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc; }

.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc; }

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85; }

.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
  background-image: none; }

.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled].focus,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
  background-color: #5bc0de;
  border-color: #46b8da; }

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff; }

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236; }

.btn-warning.focus,
.btn-warning:focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d; }

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512; }

.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512; }

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d; }

.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
  background-image: none; }

.btn-warning.disabled.focus,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled].focus,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
  background-color: #f0ad4e;
  border-color: #eea236; }

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff; }

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; }

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19; }

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925; }

.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925; }

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19; }

.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
  background-image: none; }

.btn-danger.disabled.focus,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled].focus,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
  background-color: #d9534f;
  border-color: #d43f3a; }

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff; }

.btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0; }

.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  border-color: transparent; }

.btn-link:focus,
.btn-link:hover {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent; }

.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
  color: #777;
  text-decoration: none; }

.btn-group-lg > .btn, .btn-lg, .btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

.btn-group-sm > .btn, .btn-sm, .btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 5px; }

input[type='button'].btn-block,
input[type='reset'].btn-block,
input[type='submit'].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }

.fade.in {
  opacity: 1; }

.collapse {
  display: none; }

.collapse.in {
  display: block; }

tr.collapse.in {
  display: table-row; }

tbody.collapse.in {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.dropdown,
.dropup {
  position: relative; }

.dropdown-toggle:focus {
  outline: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

.dropdown-menu.pull-right {
  right: 0;
  left: auto; }

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5; }

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap; }

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5; }

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0; }

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:focus,
.dropdown-menu > .disabled > a:hover {
  color: #777; }

.dropdown-menu > .disabled > a:focus,
.dropdown-menu > .disabled > a:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false); }

.open > .dropdown-menu {
  display: block; }

.open > a {
  outline: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap; }

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990; }

.pull-right > .dropdown-menu {
  right: 0;
  left: auto; }

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: '';
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9; }

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px; }

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto; }
  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0; } }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  float: left; }

.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 2; }

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px; }

.btn-toolbar {
  margin-left: -5px; }

.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left; }

.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 5px; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0; }

.btn-group > .btn + .dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px; }

.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-right: 12px;
  padding-left: 12px; }

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn .caret {
  margin-left: 0; }

.btn-lg .caret, .btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0; }

.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px; }

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%; }

.btn-group-vertical > .btn-group > .btn {
  float: none; }

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; }

.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
  display: table-cell;
  float: none;
  width: 1%; }

.btn-group-justified > .btn-group .btn {
  width: 100%; }

.btn-group-justified > .btn-group .dropdown-menu {
  left: auto; }

[data-toggle='buttons'] > .btn input[type='checkbox'],
[data-toggle='buttons'] > .btn input[type='radio'],
[data-toggle='buttons'] > .btn-group > .btn input[type='checkbox'],
[data-toggle='buttons'] > .btn-group > .btn input[type='radio'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: table;
  border-collapse: separate; }

.input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0; }

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0; }

.input-group .form-control:focus {
  z-index: 3; }

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn {
  height: 46px;
  line-height: 46px; }

select[multiple].input-group-lg > .form-control,
select[multiple].input-group-lg > .input-group-addon,
select[multiple].input-group-lg > .input-group-btn > .btn,
textarea.input-group-lg > .form-control,
textarea.input-group-lg > .input-group-addon,
textarea.input-group-lg > .input-group-btn > .btn {
  height: auto; }

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn {
  height: 30px;
  line-height: 30px; }

select[multiple].input-group-sm > .form-control,
select[multiple].input-group-sm > .input-group-addon,
select[multiple].input-group-sm > .input-group-btn > .btn,
textarea.input-group-sm > .form-control,
textarea.input-group-sm > .input-group-addon,
textarea.input-group-sm > .input-group-btn > .btn {
  height: auto; }

.input-group .form-control,
.input-group-addon,
.input-group-btn {
  display: table-cell; }

.input-group .form-control:not(:first-child):not(:last-child),
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; }

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px; }

.input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px; }

.input-group-addon.input-lg {
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 6px; }

.input-group-addon input[type='checkbox'],
.input-group-addon input[type='radio'] {
  margin-top: 0; }

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:first-child {
  border-right: 0; }

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-addon:last-child {
  border-left: 0; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }

.input-group-btn > .btn {
  position: relative; }

.input-group-btn > .btn + .btn {
  margin-left: -1px; }

.input-group-btn > .btn:active,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:hover {
  z-index: 2; }

.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px; }

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px; }

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav > li {
  position: relative;
  display: block; }

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px; }

.nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
  background-color: #eee; }

.nav > li.disabled > a {
  color: #777; }

.nav > li.disabled > a:focus,
.nav > li.disabled > a:hover {
  color: #777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent; }

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: #eee;
  border-color: #337ab7; }

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5; }

.nav > li > a > img {
  max-width: none; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }

.nav-tabs > li {
  float: left;
  margin-bottom: -1px; }

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0; }

.nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd; }

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent; }

.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0; }

.nav-tabs.nav-justified > li {
  float: none; }

.nav-tabs.nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center; }

.nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto; }

@media (min-width: 768px) {
  .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%; }
  .nav-tabs.nav-justified > li > a {
    margin-bottom: 0; } }

.nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 4px; }

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:focus,
.nav-tabs.nav-justified > .active > a:hover {
  border: 1px solid #ddd; }

@media (min-width: 768px) {
  .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0; }
  .nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:focus,
  .nav-tabs.nav-justified > .active > a:hover {
    border-bottom-color: #fff; } }

.nav-pills > li {
  float: left; }

.nav-pills > li > a {
  border-radius: 4px; }

.nav-pills > li + li {
  margin-left: 2px; }

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: #fff;
  background-color: #337ab7; }

.nav-stacked > li {
  float: none; }

.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0; }

.nav-justified {
  width: 100%; }

.nav-justified > li {
  float: none; }

.nav-justified > li > a {
  margin-bottom: 5px;
  text-align: center; }

.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto; }

@media (min-width: 768px) {
  .nav-justified > li {
    display: table-cell;
    width: 1%; }
  .nav-justified > li > a {
    margin-bottom: 0; } }

.nav-tabs-justified {
  border-bottom: 0; }

.nav-tabs-justified > li > a {
  margin-right: 0;
  border-radius: 4px; }

.nav-tabs-justified > .active > a,
.nav-tabs-justified > .active > a:focus,
.nav-tabs-justified > .active > a:hover {
  border: 1px solid #ddd; }

@media (min-width: 768px) {
  .nav-tabs-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0; }
  .nav-tabs-justified > .active > a,
  .nav-tabs-justified > .active > a:focus,
  .nav-tabs-justified > .active > a:hover {
    border-bottom-color: #fff; } }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent; }

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px; } }

@media (min-width: 768px) {
  .navbar-header {
    float: left; } }

.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1); }

.navbar-collapse.in {
  overflow-y: auto; }

@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important; }
  .navbar-collapse.in {
    overflow-y: visible; }
  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse {
    padding-right: 0;
    padding-left: 0; } }

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
  max-height: 340px; }

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse {
    max-height: 200px; } }

.container-fluid > .navbar-collapse, .container-sm > .navbar-collapse, .container-md > .navbar-collapse, .container-lg > .navbar-collapse, .container-xl > .navbar-collapse,
.container-fluid > .navbar-header,
.container-sm > .navbar-header,
.container-md > .navbar-header,
.container-lg > .navbar-header,
.container-xl > .navbar-header,
.container > .navbar-collapse,
.container > .navbar-header {
  margin-right: -15px;
  margin-left: -15px; }

@media (min-width: 768px) {
  .container-fluid > .navbar-collapse, .container-sm > .navbar-collapse, .container-md > .navbar-collapse, .container-lg > .navbar-collapse, .container-xl > .navbar-collapse,
  .container-fluid > .navbar-header,
  .container-sm > .navbar-header,
  .container-md > .navbar-header,
  .container-lg > .navbar-header,
  .container-xl > .navbar-header,
  .container > .navbar-collapse,
  .container > .navbar-header {
    margin-right: 0;
    margin-left: 0; } }

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px; }

@media (min-width: 768px) {
  .navbar-static-top {
    border-radius: 0; } }

.navbar-fixed-bottom,
.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030; }

@media (min-width: 768px) {
  .navbar-fixed-bottom,
  .navbar-fixed-top {
    border-radius: 0; } }

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px; }

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0; }

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px; }

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none; }

.navbar-brand > img {
  display: block; }

@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand,
  .navbar > .container-sm .navbar-brand,
  .navbar > .container-md .navbar-brand,
  .navbar > .container-lg .navbar-brand,
  .navbar > .container-xl .navbar-brand {
    margin-left: -15px; } }

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px; }

.navbar-toggle:focus {
  outline: 0; }

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px; }

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px; }

@media (min-width: 768px) {
  .navbar-toggle {
    display: none; } }

.navbar-nav {
  margin: 7.5px -15px; }

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px; }

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .navbar-nav .open .dropdown-menu .dropdown-header,
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 15px 5px 25px; }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px; }
  .navbar-nav .open .dropdown-menu > li > a:focus,
  .navbar-nav .open .dropdown-menu > li > a:hover {
    background-image: none; } }

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0; }
  .navbar-nav > li {
    float: left; }
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px; } }

.navbar-form {
  padding: 10px 15px;
  margin-top: 8px;
  margin-right: -15px;
  margin-bottom: 8px;
  margin-left: -15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1); }

@media (min-width: 768px) {
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .navbar-form .form-control-static {
    display: inline-block; }
  .navbar-form .input-group {
    display: inline-table;
    vertical-align: middle; }
  .navbar-form .input-group .form-control,
  .navbar-form .input-group .input-group-addon,
  .navbar-form .input-group .input-group-btn {
    width: auto; }
  .navbar-form .input-group > .form-control {
    width: 100%; }
  .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .navbar-form .checkbox,
  .navbar-form .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
  .navbar-form .checkbox label,
  .navbar-form .radio label {
    padding-left: 0; }
  .navbar-form .checkbox input[type='checkbox'],
  .navbar-form .radio input[type='radio'] {
    position: relative;
    margin-left: 0; }
  .navbar-form .has-feedback .form-control-feedback {
    top: 0; } }

@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px; }
  .navbar-form .form-group:last-child {
    margin-bottom: 0; } }

@media (min-width: 768px) {
  .navbar-form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none; } }

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px; }

.navbar-btn.btn-sm, .btn-group-sm > .navbar-btn.btn {
  margin-top: 10px;
  margin-bottom: 10px; }

.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px; }

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px; }

@media (min-width: 768px) {
  .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px; } }

@media (min-width: 768px) {
  .navbar-left {
    float: left !important; }
  .navbar-right {
    float: right !important;
    margin-right: -15px; }
  .navbar-right ~ .navbar-right {
    margin-right: 0; } }

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7; }

.navbar-default .navbar-brand {
  color: #777; }

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
  color: #5e5e5e;
  background-color: transparent; }

.navbar-default .navbar-text {
  color: #777; }

.navbar-default .navbar-nav > li > a {
  color: #777; }

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #333;
  background-color: transparent; }

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
  color: #555;
  background-color: #e7e7e7; }

.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:focus,
.navbar-default .navbar-nav > .disabled > a:hover {
  color: #ccc;
  background-color: transparent; }

.navbar-default .navbar-toggle {
  border-color: #ddd; }

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: #ddd; }

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888; }

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e7e7e7; }

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
  color: #555;
  background-color: #e7e7e7; }

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #777; }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #333;
    background-color: transparent; }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #555;
    background-color: #e7e7e7; }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #ccc;
    background-color: transparent; } }

.navbar-default .navbar-link {
  color: #777; }

.navbar-default .navbar-link:hover {
  color: #333; }

.navbar-default .btn-link {
  color: #777; }

.navbar-default .btn-link:focus,
.navbar-default .btn-link:hover {
  color: #333; }

.navbar-default .btn-link[disabled]:focus,
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:focus,
fieldset[disabled] .navbar-default .btn-link:hover {
  color: #ccc; }

.navbar-inverse {
  background-color: #222;
  border-color: #080808; }

.navbar-inverse .navbar-brand {
  color: #9d9d9d; }

.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover {
  color: #fff;
  background-color: transparent; }

.navbar-inverse .navbar-text {
  color: #9d9d9d; }

.navbar-inverse .navbar-nav > li > a {
  color: #9d9d9d; }

.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > li > a:hover {
  color: #fff;
  background-color: transparent; }

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff;
  background-color: #080808; }

.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:focus,
.navbar-inverse .navbar-nav > .disabled > a:hover {
  color: #444;
  background-color: transparent; }

.navbar-inverse .navbar-toggle {
  border-color: #333; }

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
  background-color: #333; }

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff; }

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #101010; }

.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > .open > a:hover {
  color: #fff;
  background-color: #080808; }

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #080808; }
  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #080808; }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #9d9d9d; }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #fff;
    background-color: transparent; }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #fff;
    background-color: #080808; }
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #444;
    background-color: transparent; } }

.navbar-inverse .navbar-link {
  color: #9d9d9d; }

.navbar-inverse .navbar-link:hover {
  color: #fff; }

.navbar-inverse .btn-link {
  color: #9d9d9d; }

.navbar-inverse .btn-link:focus,
.navbar-inverse .btn-link:hover {
  color: #fff; }

.navbar-inverse .btn-link[disabled]:focus,
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:focus,
fieldset[disabled] .navbar-inverse .btn-link:hover {
  color: #444; }

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px; }

.breadcrumb > li {
  display: inline-block; }

.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: '/\A0'; }

.breadcrumb > .active {
  color: #777; }

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px; }

.pagination > li {
  display: inline; }

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd; }

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd; }

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7; }

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd; }

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333; }

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; }

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5; }

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none; }

.pager li {
  display: inline; }

.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px; }

.pager li > a:focus,
.pager li > a:hover {
  text-decoration: none;
  background-color: #eee; }

.pager .next > a,
.pager .next > span {
  float: right; }

.pager .previous > a,
.pager .previous > span {
  float: left; }

.pager .disabled > a,
.pager .disabled > a:focus,
.pager .disabled > a:hover,
.pager .disabled > span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff; }

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em; }

a.label:focus,
a.label:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

.label:empty {
  display: none; }

.btn .label {
  position: relative;
  top: -1px; }

.label-default {
  background-color: #777; }

.label-default[href]:focus,
.label-default[href]:hover {
  background-color: #5e5e5e; }

.label-primary {
  background-color: #337ab7; }

.label-primary[href]:focus,
.label-primary[href]:hover {
  background-color: #286090; }

.label-success {
  background-color: #5cb85c; }

.label-success[href]:focus,
.label-success[href]:hover {
  background-color: #449d44; }

.label-info {
  background-color: #5bc0de; }

.label-info[href]:focus,
.label-info[href]:hover {
  background-color: #31b0d5; }

.label-warning {
  background-color: #f0ad4e; }

.label-warning[href]:focus,
.label-warning[href]:hover {
  background-color: #ec971f; }

.label-danger {
  background-color: #d9534f; }

.label-danger[href]:focus,
.label-danger[href]:hover {
  background-color: #c9302c; }

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.btn-group-xs > .btn .badge,
.btn-xs .badge {
  top: 0;
  padding: 1px 5px; }

a.badge:focus,
a.badge:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: #337ab7;
  background-color: #fff; }

.list-group-item > .badge {
  float: right; }

.list-group-item > .badge + .badge {
  margin-right: 5px; }

.nav-pills > li > a > .badge {
  margin-left: 3px; }

.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eee; }

.jumbotron .h1,
.jumbotron h1 {
  color: inherit; }

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200; }

.jumbotron > hr {
  border-top-color: #d5d5d5; }

.container .jumbotron,
.container-fluid .jumbotron,
.container-sm .jumbotron,
.container-md .jumbotron,
.container-lg .jumbotron,
.container-xl .jumbotron {
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 6px; }

.jumbotron .container {
  max-width: 100%; }

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px; }
  .container .jumbotron,
  .container-fluid .jumbotron,
  .container-sm .jumbotron,
  .container-md .jumbotron,
  .container-lg .jumbotron,
  .container-xl .jumbotron {
    padding-right: 60px;
    padding-left: 60px; }
  .jumbotron .h1,
  .jumbotron h1 {
    font-size: 63px; } }

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out; }

.thumbnail a > img,
.thumbnail > img {
  margin-right: auto;
  margin-left: auto; }

a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
  border-color: #337ab7; }

.thumbnail .caption {
  padding: 9px;
  color: #333; }

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px; }

.alert h4 {
  margin-top: 0;
  color: inherit; }

.alert .alert-link {
  font-weight: 700; }

.alert > p,
.alert > ul {
  margin-bottom: 0; }

.alert > p + p {
  margin-top: 5px; }

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px; }

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit; }

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6; }

.alert-success hr {
  border-top-color: #c9e2b3; }

.alert-success .alert-link {
  color: #2b542c; }

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }

.alert-info hr {
  border-top-color: #a6e1ec; }

.alert-info .alert-link {
  color: #245269; }

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; }

.alert-warning hr {
  border-top-color: #f7e1b5; }

.alert-warning .alert-link {
  color: #66512c; }

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1; }

.alert-danger hr {
  border-top-color: #e4b9c0; }

.alert-danger .alert-link {
  color: #843534; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0; }
  to {
    background-position: 0 0; } }

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease; }

.progress-bar-striped,
.progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px; }

.progress-bar.active,
.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite; }

.progress-bar-success {
  background-color: #5cb85c; }

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-info {
  background-color: #5bc0de; }

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-warning {
  background-color: #f0ad4e; }

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.progress-bar-danger {
  background-color: #d9534f; }

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }

.media {
  margin-top: 15px; }

.media:first-child {
  margin-top: 0; }

.media,
.media-body {
  overflow: hidden;
  zoom: 1; }

.media-body {
  width: 10000px; }

.media-object {
  display: block; }

.media-object.img-thumbnail {
  max-width: none; }

.media-right,
.media > .pull-right {
  padding-left: 10px; }

.media-left,
.media > .pull-left {
  padding-right: 10px; }

.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top; }

.media-middle {
  vertical-align: middle; }

.media-bottom {
  vertical-align: bottom; }

.media-heading {
  margin-top: 0;
  margin-bottom: 5px; }

.media-list {
  padding-left: 0;
  list-style: none; }

.list-group {
  padding-left: 0;
  margin-bottom: 20px; }

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd; }

.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

a.list-group-item,
button.list-group-item {
  color: #555; }

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
  color: #333; }

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5; }

button.list-group-item {
  width: 100%;
  text-align: left; }

.list-group-item.disabled,
.list-group-item.disabled:focus,
.list-group-item.disabled:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #eee; }

.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading {
  color: inherit; }

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text {
  color: #777; }

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7; }

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading > .small,
.list-group-item.active .list-group-item-heading > small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading > .small,
.list-group-item.active:focus .list-group-item-heading > small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading > .small,
.list-group-item.active:hover .list-group-item-heading > small {
  color: inherit; }

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
  color: #c7ddef; }

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8; }

a.list-group-item-success,
button.list-group-item-success {
  color: #3c763d; }

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
  color: inherit; }

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #3c763d;
  background-color: #d0e9c6; }

a.list-group-item-success.active,
a.list-group-item-success.active:focus,
a.list-group-item-success.active:hover,
button.list-group-item-success.active,
button.list-group-item-success.active:focus,
button.list-group-item-success.active:hover {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d; }

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7; }

a.list-group-item-info,
button.list-group-item-info {
  color: #31708f; }

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
  color: inherit; }

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #31708f;
  background-color: #c4e3f3; }

a.list-group-item-info.active,
a.list-group-item-info.active:focus,
a.list-group-item-info.active:hover,
button.list-group-item-info.active,
button.list-group-item-info.active:focus,
button.list-group-item-info.active:hover {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f; }

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #8a6d3b; }

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
  color: inherit; }

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #8a6d3b;
  background-color: #faf2cc; }

a.list-group-item-warning.active,
a.list-group-item-warning.active:focus,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active,
button.list-group-item-warning.active:focus,
button.list-group-item-warning.active:hover {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b; }

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #a94442; }

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
  color: inherit; }

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #a94442;
  background-color: #ebcccc; }

a.list-group-item-danger.active,
a.list-group-item-danger.active:focus,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active,
button.list-group-item-danger.active:focus,
button.list-group-item-danger.active:hover {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442; }

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px; }

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3; }

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.panel-body {
  padding: 15px; }

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.panel-heading > .dropdown .dropdown-toggle {
  color: inherit; }

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit; }

.panel-title > .small,
.panel-title > .small > a,
.panel-title > a,
.panel-title > small,
.panel-title > small > a {
  color: inherit; }

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0; }

.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0; }

.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0; }

.list-group + .panel-footer {
  border-top-width: 0; }

.panel > .panel-collapse > .table,
.panel > .table,
.panel > .table-responsive > .table {
  margin-bottom: 0; }

.panel > .panel-collapse > .table caption,
.panel > .table caption,
.panel > .table-responsive > .table caption {
  padding-right: 15px;
  padding-left: 15px; }

.panel > .table-responsive:first-child > .table:first-child,
.panel > .table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child {
  border-top-left-radius: 3px; }

.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child {
  border-top-right-radius: 3px; }

.panel > .table-responsive:last-child > .table:last-child,
.panel > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px; }

.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px; }

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd; }

.panel > .table > tbody:first-child > tr:first-child td,
.panel > .table > tbody:first-child > tr:first-child th {
  border-top: 0; }

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0; }

.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child {
  border-left: 0; }

.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child {
  border-right: 0; }

.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th {
  border-bottom: 0; }

.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0; }

.panel > .table-responsive {
  margin-bottom: 0;
  border: 0; }

.panel-group {
  margin-bottom: 20px; }

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px; }

.panel-group .panel + .panel {
  margin-top: 5px; }

.panel-group .panel-heading {
  border-bottom: 0; }

.panel-group .panel-heading + .panel-collapse > .list-group,
.panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 1px solid #ddd; }

.panel-group .panel-footer {
  border-top: 0; }

.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd; }

.panel-default {
  border-color: #ddd; }

.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd; }

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd; }

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333; }

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd; }

.panel-primary {
  border-color: #337ab7; }

.panel-primary > .panel-heading {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7; }

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #337ab7; }

.panel-primary > .panel-heading .badge {
  color: #337ab7;
  background-color: #fff; }

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #337ab7; }

.panel-success {
  border-color: #d6e9c6; }

.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6; }

.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6; }

.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d; }

.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6; }

.panel-info {
  border-color: #bce8f1; }

.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1; }

.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1; }

.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f; }

.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1; }

.panel-warning {
  border-color: #faebcc; }

.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc; }

.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc; }

.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b; }

.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc; }

.panel-danger {
  border-color: #ebccd1; }

.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1; }

.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1; }

.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #a94442; }

.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-16by9 {
  padding-bottom: 56.25%; }

.embed-responsive-4by3 {
  padding-bottom: 75%; }

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }

.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15); }

.well-lg {
  padding: 24px;
  border-radius: 6px; }

.well-sm {
  padding: 9px;
  border-radius: 3px; }

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2; }

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5; }

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0; }

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%); }

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0; }

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5; }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5; }

.modal-header .close {
  margin-top: -2px; }

.modal-title {
  margin: 0;
  line-height: 1.42857143; }

.modal-body {
  position: relative;
  padding: 15px; }

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5; }

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px; }

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px; }

.modal-footer .btn-block + .btn-block {
  margin-left: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto; }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 900px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity=0);
  opacity: 0;
  line-break: auto; }

.tooltip.in {
  filter: alpha(opacity=90);
  opacity: 0.9; }

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px; }

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px; }

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px; }

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px; }

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000; }

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000; }

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto; }

.popover.top {
  margin-top: -10px; }

.popover.right {
  margin-left: 10px; }

.popover.bottom {
  margin-top: 10px; }

.popover.left {
  margin-left: -10px; }

.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0; }

.popover-content {
  padding: 9px 14px; }

.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.popover > .arrow {
  border-width: 11px; }

.popover > .arrow:after {
  content: '';
  border-width: 10px; }

.popover.top > .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0; }

.popover.top > .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: ' ';
  border-top-color: #fff;
  border-bottom-width: 0; }

.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0; }

.popover.right > .arrow:after {
  bottom: -10px;
  left: 1px;
  content: ' ';
  border-right-color: #fff;
  border-left-width: 0; }

.popover.bottom > .arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.popover.bottom > .arrow:after {
  top: 1px;
  margin-left: -10px;
  content: ' ';
  border-top-width: 0;
  border-bottom-color: #fff; }

.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25); }

.popover.left > .arrow:after {
  right: 1px;
  bottom: -10px;
  content: ' ';
  border-right-width: 0;
  border-left-color: #fff; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left; }

.carousel-inner > .item > a > img,
.carousel-inner > .item > img {
  line-height: 1; }

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px; }
  .carousel-inner > .item.active.right,
  .carousel-inner > .item.next {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .carousel-inner > .item.active.left,
  .carousel-inner > .item.prev {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .carousel-inner > .item.active,
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block; }

.carousel-inner > .active {
  left: 0; }

.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%; }

.carousel-inner > .next {
  left: 100%; }

.carousel-inner > .prev {
  left: -100%; }

.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0; }

.carousel-inner > .active.left {
  left: -100%; }

.carousel-inner > .active.right {
  left: 100%; }

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
  filter: alpha(opacity=50);
  opacity: 0.5; }

.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x; }

.carousel-control.right {
  right: 0;
  left: auto;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x; }

.carousel-control:focus,
.carousel-control:hover {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: 0.9; }

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px; }

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
  left: 50%;
  margin-left: -10px; }

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
  right: 50%;
  margin-right: -10px; }

.carousel-control .icon-next,
.carousel-control .icon-prev {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1; }

.carousel-control .icon-prev:before {
  content: '\2039'; }

.carousel-control .icon-next:before {
  content: '\203A'; }

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none; }

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #0009;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px; }

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6); }

.carousel-caption .btn {
  text-shadow: none; }

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next,
  .carousel-control .icon-prev {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px; }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px; }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px; }
  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px; }
  .carousel-indicators {
    bottom: 20px; } }

.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-sm:after,
.container-md:after,
.container-lg:after,
.container-xl:after,
.container-fluid:before,
.container-sm:before,
.container-md:before,
.container-lg:before,
.container-xl:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: ' '; }

.btn-group-vertical > .btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container-sm:after,
.container-md:after,
.container-lg:after,
.container-xl:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
  clear: both; }

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.visible-lg,
.visible-md,
.visible-sm,
.visible-xs {
  display: none !important; }

.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  td.visible-xs,
  th.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  td.visible-sm,
  th.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  td.visible-md,
  th.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  td.visible-lg,
  th.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  td.visible-print,
  th.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }

@media print {
  .visible-print-block {
    display: block !important; } }

.visible-print-inline {
  display: none !important; }

@media print {
  .visible-print-inline {
    display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }

@media print {
  .visible-print-inline-block {
    display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

@font-face {
  font-family: "icomoon";
  src: url(../fonts/icomoon.eot);
  src: url(../fonts/icomoon.eot#iefix) format("embedded-opentype"), url(../fonts/icomoon.ttf) format("truetype"), url(../fonts/icomoon.woff) format("woff"), url(../fonts/icomoon.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

h1,
.h1, .jada-banner .content-slider .swiper-container .swiper-wrapper .swiper-slide .banner-content h1, .event-detail-wrapper .jada-main-banner h1, .publications-and-resources-wrapper .jada-main-banner h1 {
  margin: 1em 0 0.5em; }

/*  headings */
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: "Cairo", sans-serif;
  margin: 0; }

h1,
.h1 {
  font-size: 5rem;
  line-height: 1.075;
  letter-spacing: 1px;
  font-weight: 600;
  max-width: 903; }
  @media only screen and (max-width: 767px) {
    h1,
    .h1 {
      font-size: 50px;
      line-height: 57.5px; } }

.h2 {
  font-size: 2.5rem;
  font-family: "Cairo", sans-serif;
  font-weight: 200;
  line-height: 1.15; }

h2 {
  font-family: "Cairo", sans-serif;
  font-size: 5rem;
  font-weight: 400;
  line-height: 1.075; }
  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 3.75rem; } }
  @media only screen and (max-width: 767px) {
    h2 {
      font-size: 40px;
      line-height: 46px; } }

.p-40 {
  font-family: "Cairo", sans-serif;
  font-size: 2.5rem;
  line-height: 1.15;
  font-weight: 300; }

h3 {
  font-family: "Cairo", sans-serif;
  font-size: 3.25rem;
  line-height: 1;
  letter-spacing: 0.1px;
  font-weight: 600;
  color: #373433; }

.p {
  font-family: "Cairo", sans-serif;
  font-size: 1.5rem;
  line-height: 1.45; }

.f-2026 {
  font-family: "Cairo", sans-serif;
  font-size: 1.25rem;
  line-height: 1.3; }

/* site Loader */
div[data-scroll-section] {
  will-change: transform;
  transform: translateZ(0); }

.bg-grey {
  background-color: #373433; }

.bg-light-grey {
  background-color: #E5E5E5; }

.bg-green {
  background-color: #f2f8f7; }

.site-loader {
  display: none;
  background: #007473;
  height: 100vh;
  width: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  -webkit-transition: width 0.6s cubic-bezier(0.6, 0.04, 0.12, 0.96);
  -moz-transition: width 0.6s cubic-bezier(0.6, 0.04, 0.12, 0.96);
  -ms-transition: width 0.6s cubic-bezier(0.6, 0.04, 0.12, 0.96);
  -o-transition: width 0.6s cubic-bezier(0.6, 0.04, 0.12, 0.96);
  transition: width 0.6s cubic-bezier(0.6, 0.04, 0.12, 0.96); }
  .site-loader .logo-middle {
    height: 50vh;
    margin: 0 auto; }
    .site-loader .logo-middle img {
      width: 100%;
      height: 100%;
      display: block; }

.init-overlay {
  background: #007473;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 99999999; }


.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 40px;
  padding-right: 40px; }
  @media only screen and (max-width: 767px) {
    
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      padding-left: 20px;
      padding-right: 20px; } }

.heading_line {
  overflow: hidden; }
  .heading_line .split-lines {
    display: inline-block;
    opacity: 0;
    width: 100%;
    height: 100%; }

.slide-timer {
  display: inline-block;
  vertical-align: middle;
  width: 127px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.4);
  position: relative; }
  .slide-timer:before {
    content: " ";
    position: absolute;
    display: block;
    height: 4px;
    background-color: #FFFFFF;
    left: 0;
    z-index: 10; }
  .slide-timer.active:before {
    animation: slide_duration;
    animation-timing-function: linear;
    animation-duration: 7s; }

@keyframes slide_duration {
  from {
    width: 0; }
  to {
    width: 100%; } }

.jada-banner {
  height: 80vh;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-height: 700px) {
    .jada-banner {
      height: 100vh; } }
  .jada-banner .content-slider {
    height: 100%;
    display: flex;
    align-items: flex-end;
    z-index: 3;
    margin-left: 74px; }
    @media only screen and (max-width: 1090px) {
      .jada-banner .content-slider {
        margin-left: 0px; } }
    .jada-banner .content-slider .swiper-container {
      height: 100%; }
      .jada-banner .content-slider .swiper-container .swiper-wrapper {
        overflow: hidden; }
        .jada-banner .content-slider .swiper-container .swiper-wrapper .swiper-slide {
          position: relative; }
          .jada-banner .content-slider .swiper-container .swiper-wrapper .swiper-slide .banner-content {
            padding: 0 40px 60px;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end; }
            @media only screen and (max-width: 767px) {
              .jada-banner .content-slider .swiper-container .swiper-wrapper .swiper-slide .banner-content {
                padding-right: 20px;
                padding-left: 20px;
                padding-bottom: 30px; } }
            .jada-banner .content-slider .swiper-container .swiper-wrapper .swiper-slide .banner-content h1 {
              font-size: 5rem;
              line-height: 1.075;
              letter-spacing: 1px;
              font-weight: 600; }
              @media only screen and (max-width: 991px) {
                .jada-banner .content-slider .swiper-container .swiper-wrapper .swiper-slide .banner-content h1 {
                  font-size: 5.3125rem !important;
                  line-height: 1 !important;
                  max-width: 700px !important; } }
              @media only screen and (max-width: 767px) {
                .jada-banner .content-slider .swiper-container .swiper-wrapper .swiper-slide .banner-content h1 {
                  font-size: 50px !important;
                  line-height: 57.5px !important; } }
            .jada-banner .content-slider .swiper-container .swiper-wrapper .swiper-slide .banner-content h1 {
              color: #FFFFFF;
              font-weight: normal;
              margin: 0;
              opacity: 0;
              max-width: 950px; }
              @media only screen and (max-width: 767px) {
                .jada-banner .content-slider .swiper-container .swiper-wrapper .swiper-slide .banner-content h1 {
                  max-width: 100%;
                  width: 100%; } }
        .jada-banner .content-slider .swiper-container .swiper-wrapper .swiper-slide-active h1 .heading_line .split-lines {
          opacity: 0; }
      .jada-banner .content-slider .swiper-container .swiper-pagination {
        bottom: 80px;
        left: auto;
        right: 0px;
        padding-right: 60px;
        display: flex;
        flex-direction: column;
        width: auto; }
        @media only screen and (max-width: 1090px) {
          .jada-banner .content-slider .swiper-container .swiper-pagination {
            margin-right: 0px; } }
        @media only screen and (max-width: 991px) {
          .jada-banner .content-slider .swiper-container .swiper-pagination {
            bottom: 0;
            top: 110px;
            flex-direction: row;
            right: auto;
            left: 40px;
            margin-right: 0px; } }
        @media only screen and (max-width: 767px) {
          .jada-banner .content-slider .swiper-container .swiper-pagination {
            left: 20px; } }
        .jada-banner .content-slider .swiper-container .swiper-pagination .swiper-pagination-bullet {
          height: 20px;
          width: 20px;
          margin-bottom: 10px;
          background-color: #FFFFFF;
          opacity: 0.2;
          border-radius: 0px;
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
          -ms-border-radius: 0px;
          -o-border-radius: 0px;
          transition: opacity 0.3s ease-in;
          -webkit-transition: opacity 0.3s ease-in;
          -moz-transition: opacity 0.3s ease-in;
          -ms-transition: opacity 0.3s ease-in;
          -o-transition: opacity 0.3s ease-in; }
          .jada-banner .content-slider .swiper-container .swiper-pagination .swiper-pagination-bullet:last-child {
            margin-bottom: 0px; }
          .jada-banner .content-slider .swiper-container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
            opacity: 1; }
  .jada-banner .image-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%; }
    .jada-banner .image-slider .swiper-container {
      height: 100%;
      width: 100%; }
      .jada-banner .image-slider .swiper-container .swiper-wrapper .swiper-slide .bg-img {
        background-size: cover !important;
        background-repeat: no-repeat !important;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 105%;
        background-position: 50% !important;
        background-position: center !important; }

.custom-tabs {
  border: 0px !important; }
  .custom-tabs li {
    margin-right: 40px;
    list-style: none; }
    @media only screen and (max-width: 767px) {
      .custom-tabs li {
        margin-right: 20px; }
        .custom-tabs li:last-child {
          margin-right: 0; } }
    .custom-tabs li a {
      font-family: "Cairo", sans-serif;
      font-size: 2.5rem;
      line-height: 0.7;
      font-weight: 400;
      color: #373433 !important;
      opacity: 0.3;
      border: 0px !important;
      padding: 0 !important;
      background-color: transparent !important;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in; }
      @media only screen and (max-width: 767px) {
        .custom-tabs li a {
          font-size: 24px;
          line-height: 16.8px; } }
      .custom-tabs li a.active {
        opacity: 1; }
      .custom-tabs li a:hover {
        text-decoration: none;
        opacity: 1; }

.list .swiper-container .navigation {
  position: absolute;
  height: 92px;
  width: 92px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  background-color: #FFFFFF;
  border: 1px solid #e6e6e6;
  top: 40%;
  right: auto;
  z-index: 4;
  cursor: pointer !important; }
  @media only screen and (min-width: 1600px) {
    .list .swiper-container .navigation {
      top: 31%; } }
  .list .swiper-container .navigation .swiper-button-prev:after {
    content: "\E907";
    font-size: 0.875rem;
    font-family: icomoon !important;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    color: #373433; }
  .list .swiper-container .navigation .swiper-button-next:after {
    content: "\E908";
    font-size: 0.875rem;
    font-family: icomoon !important;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    color: #373433; }

@keyframes Fade {
  0% {
    opacity: 0%; }
  100% {
    opacity: 100%; } }

.breadcrumb-container {
  z-index: 4;
  position: absolute;
  left: 40px;
  top: 121px; }
  .breadcrumb-container.fade {
    opacity: 0; }
  @media only screen and (max-width: 767px) {
    .breadcrumb-container {
      left: 20px; } }
  .breadcrumb-container .breadcrumb {
    background: transparent;
    padding: 0px;
    margin-bottom: 0;
    opacity: 0; }
    .breadcrumb-container .breadcrumb li {
      font-family: "Cairo", sans-serif;
      font-size: 1.125rem;
      line-height: 1.11111;
      color: #FFFFFF;
      font-weight: 400; }
      .breadcrumb-container .breadcrumb li a {
        font-family: "Cairo", sans-serif;
        font-size: 1.125rem;
        line-height: 1.11111;
        color: #FFFFFF;
        font-weight: 400; }
        .breadcrumb-container .breadcrumb li a:hover {
          opacity: 0.7;
          transition: 0.3s opacity ease;
          -webkit-transition: 0.3s opacity ease;
          -moz-transition: 0.3s opacity ease;
          -ms-transition: 0.3s opacity ease;
          -o-transition: 0.3s opacity ease; }
      .breadcrumb-container .breadcrumb li.active {
        font-family: "Cairo", sans-serif;
        font-size: 1.125rem;
        line-height: 1.11111;
        color: #FFFFFF;
        font-weight: 400; }

.rangeslide .slider .thumb {
  box-shadow: none;
  background-color: #007473;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  border: none; }

.rangeslide .value-indicator {
  background-color: transparent !important;
  margin-top: 0 !important;
  top: 50% !important;
  left: 50% !important;
  width: 100% !important;
  transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
  font-family: "Cairo", sans-serif;
  font-size: 1.25rem;
  line-height: 1.3;
  font-weight: 400;
  color: #FFFFFF; }

.rangeslide .value-indicator.above:after {
  display: none; }

.rangeslide .track {
  height: 1px !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: none !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important; }

.rangeslide .track-progress {
  background-color: transparent !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  border: none !important; }

.bootstrap-select .dropdown-menu {
  bottom: auto;
  margin-top: 0px;
  background-color: #FFFFFF;
  padding: 0px;
  border-left: 1px solid #373433;
  border-right: 1px solid #373433;
  border-bottom: 1px solid #373433; }
  .bootstrap-select .dropdown-menu li {
    position: relative; }
    .bootstrap-select .dropdown-menu li:focus-visible {
      border: none;
      outline: none;
      border-radius: none; }
    .bootstrap-select .dropdown-menu li:after {
      position: absolute;
      content: "";
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 80%;
      background-color: rgba(55, 52, 51, 0.1); }
    .bootstrap-select .dropdown-menu li:last-child::after {
      display: none; }
  .bootstrap-select .dropdown-menu .dropdown-item {
    color: #373433;
    text-align: center;
    font-size: 1.75rem;
    line-height: 1.2;
    padding: 26px 0px;
    border: none !important;
    outline: none !important; }
    .bootstrap-select .dropdown-menu .dropdown-item span {
      color: #373433; }
    @media only screen and (max-width: 767px) {
      .bootstrap-select .dropdown-menu .dropdown-item {
        font-size: 16px;
        line-height: 21.6px; } }
    .bootstrap-select .dropdown-menu .dropdown-item:hover {
      color: #373433; }
  .bootstrap-select .dropdown-menu .show {
    overflow-y: hidden; }

.filter {
  width: 50%;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .filter {
      width: 100%;
      margin-bottom: 15px; }
      .filter:last-child {
        margin-bottom: 0px; } }
  .filter:focus-visible {
    outline: none; }
  .filter:after {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 0px;
    width: 100%;
    content: "";
    background-color: #007473;
    transition: 0.2s all ease; }
  @media only screen and (min-width: 1200px) {
    .filter:hover:after {
      height: 6px; } }
  .filter .dropdown {
    width: 100% !important; }
    .filter .dropdown:focus-visible {
      outline: none; }
    .filter .dropdown .btn {
      background-color: #373433;
      color: #FFFFFF;
      border-radius: 0px;
      border: none;
      padding: 35px 0px;
      font-size: 1.75rem;
      line-height: 1.2;
      font-weight: 600;
      display: flex;
      justify-content: center;
      position: relative; }
      @media only screen and (max-width: 767px) {
        .filter .dropdown .btn {
          padding: 25px 0px;
          font-size: 18px;
          line-height: 18px; } }
      .filter .dropdown .btn:nth-child(2) {
        border-left: 1px solid rgba(255, 255, 255, 0.15); }
      .filter .dropdown .btn:hover, .filter .dropdown .btn:active {
        color: #FFFFFF; }
      .filter .dropdown .btn:active {
        background-color: #007473; }
      .filter .dropdown .btn:focus {
        box-shadow: none !important;
        color: #FFFFFF;
        border: 0px !important;
        outline: 0px !important;
        border-radius: 0px !important;
        background-color: #007473; }
      .filter .dropdown .btn:focus-visible {
        outline: none !important;
        background-color: #007473; }
      .filter .dropdown .btn:visited {
        background-color: #373433; }
    .filter .dropdown .filter-option {
      flex: none !important;
      width: auto; }
      .filter .dropdown .filter-option .filter-option-inner {
        text-align: center; }

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #f1f1f1; }

.three-col-block {
  padding: 0 40px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  z-index: 2;
  margin-bottom: -80px;
  overflow: visible; }
  @media only screen and (max-width: 1400px) {
    .three-col-block {
      margin-bottom: -100px; } }
  @media only screen and (max-width: 1300px) {
    .three-col-block {
      padding: 0; } }
  @media only screen and (max-width: 1090px) {
    .three-col-block {
      margin-bottom: -60px; } }
  @media only screen and (max-width: 1300px) {
    .three-col-block .swiper-container {
      padding-left: 40px; } }
  @media only screen and (max-width: 767px) {
    .three-col-block .swiper-container {
      padding-left: 20px;
      padding-right: 20px; } }
  .three-col-block .swiper-wrapper {
    overflow: visible; }
  .three-col-block .col-block {
    width: auto;
    display: flex;
    z-index: 2;
    cursor: pointer; }
    @media only screen and (min-width: 1200px) {
      .three-col-block .col-block:hover .img-bg {
        transform: scale(1.1); }
      .three-col-block .col-block:hover .content-section {
        background-color: #373433; } }
    .three-col-block .col-block .video {
      width: 100%;
      display: flex; }
      .three-col-block .col-block .video .img-section {
        display: flex;
        justify-content: center;
        align-items: center; }
        @media only screen and (max-width: 767px) {
          .three-col-block .col-block .video .img-section {
            display: none; } }
        .three-col-block .col-block .video .img-section .play_white {
          height: 54px;
          width: 54px;
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
          position: relative;
          background-color: #FFFFFF;
          z-index: 3; }
          .three-col-block .col-block .video .img-section .play_white:before {
            content: "\E914";
            position: absolute;
            top: 50%;
            left: 47%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            font-family: "icomoon" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            font-size: 1rem;
            color: #007473; }
    .three-col-block .col-block .img-section {
      height: 100%;
      width: 37.7%;
      position: relative;
      overflow: hidden; }
      @media only screen and (max-width: 767px) {
        .three-col-block .col-block .img-section {
          width: 40%;
          display: none; } }
      .three-col-block .col-block .img-section .img-bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 1;
        transition: 0.3s all ease;
        background-position: center !important; }
    .three-col-block .col-block .content-section {
      background-color: #007473;
      padding: 8.5% 16px 8% 19px;
      width: 62.3%;
      position: relative;
      cursor: pointer;
      transition: 0.3s all ease;
      overflow: hidden; }
      @media only screen and (max-width: 991px) {
        .three-col-block .col-block .content-section {
          padding: 40px; } }
      @media only screen and (max-width: 767px) {
        .three-col-block .col-block .content-section {
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%; } }
      .three-col-block .col-block .content-section h4 {
        font-size: 1.875rem;
        line-height: 1.2;
        font-weight: 400;
        font-family: "Cairo", sans-serif;
        color: #FFFFFF;
        margin-bottom: 8px;
        max-width: 176px; }
        @media only screen and (max-width: 991px) {
          .three-col-block .col-block .content-section h4 {
            max-width: 100%; } }
        @media only screen and (max-width: 767px) {
          .three-col-block .col-block .content-section h4 {
            font-size: 26px; } }
      .three-col-block .col-block .content-section span {
        font-family: "Cairo", sans-serif;
        font-weight: 200;
        font-size: 0.875rem;
        line-height: 1.85714;
        color: #FFFFFF;
        opacity: 0.6; }
        @media only screen and (max-width: 991px) {
          .three-col-block .col-block .content-section span {
            font-size: 15px; } }

.detail-page-banner {
  height: auto;
  background-color: #f2f8f7;
  display: flex;
  align-items: flex-end; }
  @media screen and (min-width: 1440px) and (max-height: 800px) {
    .detail-page-banner {
      height: auto; } }
  @media only screen and (max-height: 800px) {
    .detail-page-banner {
      height: auto; } }
  @media only screen and (max-width: 991px) {
    .detail-page-banner {
      height: auto; } }
  .detail-page-banner .breadcrumb-container .breadcrumb li {
    color: #373433; }
    .detail-page-banner .breadcrumb-container .breadcrumb li:before {
      color: #373433; }
    .detail-page-banner .breadcrumb-container .breadcrumb li a {
      color: #373433; }
  .detail-page-banner .content {
    padding: 0 40px;
    padding-bottom: 80px;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    position: relative;
    width: 100%;
    margin-top: 220px; }
    @media only screen and (max-width: 1090px) {
      .detail-page-banner .content {
        padding-bottom: 60px; } }
    @media only screen and (max-width: 767px) {
      .detail-page-banner .content {
        padding-bottom: 40px !important;
        padding: 0 20px; } }
    .detail-page-banner .content h1 {
      color: #373433;
      margin: 0;
      opacity: 0;
      z-index: 2 !important;
      font-size: 5rem !important;
      line-height: 1.075 !important;
      font-weight: 600;
      max-width: 1017px !important; }
      @media only screen and (max-width: 1090px) {
        .detail-page-banner .content h1 {
          font-size: 5.3125rem !important;
          line-height: 1 !important;
          max-width: 700px !important; } }
      @media only screen and (max-width: 767px) {
        .detail-page-banner .content h1 {
          font-size: 50px !important;
          line-height: 57.5px !important; } }

.bottom-footer-banner .social-share-box {
  margin: 100px auto; }
  @media only screen and (max-width: 767px) {
    .bottom-footer-banner .social-share-box {
      margin: 80px auto;
      padding: 0 15px; } }
  .bottom-footer-banner .social-share-box .emp-stats {
    padding: 25px 25px; }
    @media only screen and (max-width: 767px) {
      .bottom-footer-banner .social-share-box .emp-stats {
        padding: 15px 15px; } }
    @media only screen and (max-width: 992px) {
      .bottom-footer-banner .social-share-box .emp-stats .btns .btn-wrap {
        padding: 0; } }
    @media only screen and (max-width: 767px) {
      .bottom-footer-banner .social-share-box .emp-stats .btns .btn-wrap {
        justify-content: space-between; } }
    @media only screen and (max-width: 767px) {
      .bottom-footer-banner .social-share-box .emp-stats .btns span {
        margin-left: 0; } }
    .bottom-footer-banner .social-share-box .emp-stats .btns .social-links {
      margin-right: 0; }
      @media only screen and (min-width: 768px) {
        .bottom-footer-banner .social-share-box .emp-stats .btns .social-links {
          margin-right: 25px; } }

.bottom-footer-banner .img-fluid {
  width: 100%; }

.testimonial {
  padding-bottom: 6.6%; }
  @media only screen and (min-width: 992px) {
    .testimonial {
      margin-left: 0.6%; } }
  @media only screen and (min-width: 1200px) {
    .testimonial {
      margin-left: 7.6%; } }
  .testimonial h3 {
    padding-top: 15px;
    padding-bottom: 15px;
    border-left: 3px solid #007473;
    padding-left: 54px; }
    @media only screen and (max-width: 992px) {
      .testimonial h3 {
        padding-left: 25px; } }
    @media only screen and (max-width: 767px) {
      .testimonial h3 {
        font-size: 2.25rem; } }
  .testimonial .author {
    padding-top: 10px;
    text-indent: -40px;
    line-height: 26.4px;
    padding-left: 100px; }
    @media only screen and (max-width: 992px) {
      .testimonial .author {
        padding-left: 70px; } }
    .testimonial .author span {
      margin-left: 15px; }

[data-scroll-section] {
  will-change: transform, opacity; }

:root {
  font-size: 16px; }
  @media only screen and (max-width: 767px) {
    :root {
      font-size: 12px; } }

* {
  margin: 0;
  padding: 0; }

input[type=button], input[type=reset], input[type=submit], button {
  min-width: auto !important; }

html,
body {
  height: 100%;
  margin: 0; }

body {
  margin: 0;
  font-family: "Cairo", sans-serif;
  color: #373433;
  font-weight: normal;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  text-decoration: none;
  outline: 0; }
  a:hover {
    text-decoration: none; }

img {
  border: none 0; }

input:focus,
textarea:focus, textarea:focus-visible {
  outline: none;
  box-shadow: none; }

select:focus {
  outline: none; }

section {
  display: block; }

input,
textarea {
  /* hide placeholder on focus of input */
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */ }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #fff; }
  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: #fff; }
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #fff; }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #fff; }
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    color: transparent !important; }
  input:focus:-moz-placeholder,
  textarea:focus:-moz-placeholder {
    color: transparent !important; }
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    color: transparent !important; }
  input:focus:-ms-input-placeholder,
  textarea:focus:-ms-input-placeholder {
    color: transparent !important; }

input[type='submit'] {
  -webkit-appearance: none; }

.ios-device * {
  background-attachment: scroll; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-add_black:before {
  content: "\E900"; }

.icon-add_green:before {
  content: "\E901";
  color: #007473; }

.icon-calender:before {
  content: "\E902";
  color: #007473; }

.icon-camcoder:before {
  content: "\E903";
  color: #fff; }

.icon-campaign:before {
  content: "\E904"; }

.icon-cancel:before {
  content: "\E905"; }

.icon-Checked_green:before {
  content: "\E906"; }

.icon-coolicon_left:before {
  content: "\E907"; }

.icon-coolicon_right:before {
  content: "\E908"; }

.icon-curverd-down:before {
  content: "\E909"; }

.icon-delete_black:before {
  content: "\E90A"; }

.icon-download_white:before {
  content: "\E90B";
  color: #fff; }

.icon-drop_down_arrow:before {
  content: "\E90C"; }

.icon-drop_down_circle:before {
  content: "\E90D"; }

.icon-edit_black:before {
  content: "\E90E"; }

.icon-email:before {
  content: "\E90F"; }

.icon-format_minus:before {
  content: "\E910"; }

.icon-format_plus:before {
  content: "\E911"; }

.icon-instagram:before {
  content: "\E912";
  color: #fff; }

.icon-linkedin:before {
  content: "\E913";
  color: #fff; }

.icon-new-play:before {
  content: "\E914";
  color: #007473; }

.icon-password:before {
  content: "\E915"; }

.icon-pause:before {
  content: "\E916";
  color: #fff; }

.icon-Phone:before {
  content: "\E917"; }

.icon-play_green .path1:before {
  content: "\E918";
  color: white; }

.icon-play_green .path2:before {
  content: "\E919";
  margin-left: -1em;
  color: #007473; }

.icon-profile:before {
  content: "\E91A"; }

.icon-record_voice:before {
  content: "\E91B"; }

.icon-search:before {
  content: "\E91C"; }

.icon-sound:before {
  content: "\E91D";
  color: #fff; }

.icon-speak:before {
  content: "\E91E"; }

.icon-square:before {
  content: "\E91F";
  color: #fff; }

.icon-time:before {
  content: "\E920";
  color: #007473; }

.icon-twitter:before {
  content: "\E921";
  color: #fff; }

.icon-Union-right:before {
  content: "\E922";
  color: #007473; }

.icon-youtube:before {
  content: "\E923";
  color: #fff; }

.jada-btn {
  display: inline-block;
  font-family: "Cairo", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.1px;
  position: relative;
  box-sizing: border-box;
  text-transform: uppercase;
  padding: 18px 32px 18px 64px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  cursor: pointer;
  max-height: 64px;
  overflow: hidden;
  z-index: 1; }
  @media only screen and (max-width: 767px) {
    .jada-btn {
      padding: 14px 28px 14px 61px; } }
  .jada-btn.arrowed:after {
    position: absolute;
    top: 19px;
    left: 22px;
    content: "\E922";
    font-family: icomoon !important;
    font-style: normal;
    font-weight: 700;
    color: #007473;
    font-variant: normal;
    text-transform: none;
    font-size: 0.9375rem;
    display: inline;
    vertical-align: middle;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in; }
  .jada-btn.bordered_green {
    border: 1px solid #007473;
    color: #007473; }
    .jada-btn.bordered_green:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      z-index: -1 !important;
      width: 100%;
      height: 0%;
      background-color: #007473;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in; }
  .jada-btn.b-green-effects {
    color: #FFFFFF; }
    .jada-btn.b-green-effects:before {
      height: 100%;
      z-index: -1; }
    .jada-btn.b-green-effects:after {
      color: #FFFFFF; }
  .jada-btn.b-green-effects-reverse:before {
    height: 0%;
    top: auto;
    bottom: 0px; }
  .jada-btn.filled_green {
    background-color: #007473;
    padding: 18px 28px 18px 28px !important;
    color: #FFFFFF;
    position: relative;
    min-width: 187px;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .jada-btn.filled_green {
        min-width: auto;
        padding: 14px 28px 14px 28px !important; } }
    .jada-btn.filled_green:after {
      position: absolute;
      z-index: -1;
      content: '';
      height: 0%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #FFFFFF;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in; }
  .jada-btn.f-green-effects {
    color: #007473; }
    .jada-btn.f-green-effects:after {
      height: 100%; }
  .jada-btn.f-green-effects-reverse {
    color: #FFFFFF; }
    .jada-btn.f-green-effects-reverse:after {
      top: auto;
      bottom: 0;
      height: 0%; }
  .jada-btn.bordered-black {
    border: 1px solid #373433;
    color: #373433;
    padding: 18px 28px 18px 28px !important;
    min-width: 187px;
    border-radius: 8px; }
    @media only screen and (max-width: 767px) {
      .jada-btn.bordered-black {
        padding: 14px 28px 14px 28px !important;
        min-width: auto; } }
    .jada-btn.bordered-black:after {
      position: absolute;
      z-index: -1;
      content: '';
      height: 0%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #373433;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in; }
    .jada-btn.bordered-black:hover {
      color: #FFFFFF; }
      .jada-btn.bordered-black:hover:after {
        height: 90%; }
  .jada-btn.filled_green-arrowed {
    color: #FFFFFF;
    background-color: #007473; }
    .jada-btn.filled_green-arrowed:after {
      position: absolute;
      top: 19px;
      left: 22px;
      content: "\E922";
      font-family: icomoon !important;
      font-style: normal;
      font-weight: 700;
      color: #FFFFFF;
      font-variant: normal;
      text-transform: none;
      font-size: 0.9375rem;
      display: inline;
      vertical-align: middle;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in; }
    .jada-btn.filled_green-arrowed:before {
      position: absolute;
      z-index: -1;
      content: '';
      height: 0%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #FFFFFF;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in; }
  .jada-btn.fa-green-effects {
    background-color: #FFFFFF;
    color: #007473;
    border: 1px solid #007473; }
    .jada-btn.fa-green-effects:after {
      color: #007473; }
    .jada-btn.fa-green-effects:before {
      height: 100%; }
  .jada-btn.fa-green-effects-reverse {
    color: #FFFFFF; }
    .jada-btn.fa-green-effects-reverse:before {
      top: auto;
      bottom: 0;
      height: 0%; }

.jada-header.mega-menu-active.sticky .custom-navbar .logo-wrap .logo-icon {
  display: none !important; }

.jada-header.mega-menu-active.sticky .custom-navbar .logo-wrap .logo-green {
  display: block !important; }

.jada-header {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  z-index: 999;
  width: calc(100% - 85px);
  height: 85px;
  box-sizing: border-box;
  padding-left: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  @media only screen and (max-width: 1090px) {
    .jada-header {
      width: calc(100% - 85px); } }
  @media only screen and (max-width: 767px) {
    .jada-header {
      padding-left: 0px; } }
  .jada-header.homepage-header {
    width: calc(100% - 74px); }
  .jada-header.top {
    top: -85px;
    width: calc(100% - 85px) !important;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1); }
  .jada-header.sticky {
    position: fixed;
    top: 0px !important;
    width: calc(100% - 85px) !important;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1); }
  .jada-header.sticky, .jada-header.dark-header {
    width: calc(100% - 85px) !important;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .jada-header.sticky .custom-navbar .logo-wrap, .jada-header.dark-header .custom-navbar .logo-wrap {
      border-right: 0px; }
      .jada-header.sticky .custom-navbar .logo-wrap .logo-icon, .jada-header.dark-header .custom-navbar .logo-wrap .logo-icon {
        display: none; }
      .jada-header.sticky .custom-navbar .logo-wrap .logo-green, .jada-header.dark-header .custom-navbar .logo-wrap .logo-green {
        display: block; }
    .jada-header.sticky .custom-navbar .navigation-container .social-links li, .jada-header.dark-header .custom-navbar .navigation-container .social-links li {
      background-color: #007473; }
      .jada-header.sticky .custom-navbar .navigation-container .social-links li:hover, .jada-header.dark-header .custom-navbar .navigation-container .social-links li:hover {
        background-color: #FFFFFF;
        border: 1px solid #007473; }
        .jada-header.sticky .custom-navbar .navigation-container .social-links li:hover a .icomoon:before, .jada-header.dark-header .custom-navbar .navigation-container .social-links li:hover a .icomoon:before {
          color: #007473; }
    .jada-header.sticky .custom-navbar .navigation-container .language-switcher li, .jada-header.dark-header .custom-navbar .navigation-container .language-switcher li {
      color: #007473; }
      .jada-header.sticky .custom-navbar .navigation-container .language-switcher li.active:after, .jada-header.dark-header .custom-navbar .navigation-container .language-switcher li.active:after {
        background-color: #007473; }
      .jada-header.sticky .custom-navbar .navigation-container .language-switcher li:hover, .jada-header.dark-header .custom-navbar .navigation-container .language-switcher li:hover {
        color: #007473;
        opacity: 0.5; }
        .jada-header.sticky .custom-navbar .navigation-container .language-switcher li:hover.active:after, .jada-header.dark-header .custom-navbar .navigation-container .language-switcher li:hover.active:after {
          background-color: #007473; }
    .jada-header.sticky .custom-navbar .navigation-container .language-switcher span, .jada-header.dark-header .custom-navbar .navigation-container .language-switcher span {
      color: #007473;
      opacity: 0.5; }
  .jada-header.mega-menu-active {
    width: calc(100vw - 104px) !important; }
    @media only screen and (max-width: 1090px) {
      .jada-header.mega-menu-active {
        background-color: #373433;
        width: calc(100vw - 85px) !important; } }
    .jada-header.mega-menu-active .custom-navbar .logo-wrap .logo-icon {
      display: block !important; }
    .jada-header.mega-menu-active .custom-navbar .logo-wrap .logo-green {
      display: none !important; }
    .jada-header.mega-menu-active .navigation-container .social-links {
      display: none !important; }
  .jada-header.homepage-page-header {
    width: calc(100% - 74px); }
    @media only screen and (max-width: 1090px) {
      .jada-header.homepage-page-header {
        width: calc(100% - 85px); } }
  .jada-header.login-header {
    display: none; }
  .jada-header.dark {
    border-bottom: 1px solid rgba(55, 52, 51, 0.15);
    width: calc(100% - 85px); }
    .jada-header.dark .custom-navbar .logo-wrap .logo-icon {
      display: none; }
    .jada-header.dark .custom-navbar .logo-wrap .logo-green {
      display: block; }
    .jada-header.dark .custom-navbar .navigation-container .social-links li {
      background-color: rgba(55, 52, 51, 0.05); }
      .jada-header.dark .custom-navbar .navigation-container .social-links li a .icomoon:before {
        color: #373433; }
    .jada-header.dark .custom-navbar .navigation-container .language-switcher li {
      color: #373433; }
      .jada-header.dark .custom-navbar .navigation-container .language-switcher li.active:after {
        background-color: #373433; }
    .jada-header.dark .custom-navbar .navigation-container .language-switcher span {
      color: #373433; }
    .jada-header.dark.mega-menu-active {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
      .jada-header.dark.mega-menu-active .custom-navbar .logo-wrap {
        border-right: none !important;
        border-left: none !important; }
        .jada-header.dark.mega-menu-active .custom-navbar .logo-wrap .logo-icon {
          display: block; }
        .jada-header.dark.mega-menu-active .custom-navbar .logo-wrap .logo-green {
          display: none; }
  .jada-header.dashboard-header {
    background-color: #fff;
    border-bottom: 0px; }
    .jada-header.dashboard-header .custom-navbar .logo-wrap .logo-icon {
      display: none; }
    .jada-header.dashboard-header .custom-navbar .logo-wrap .logo-green {
      display: block; }
    .jada-header.dashboard-header .custom-navbar .navigation-container .social-links li {
      background-color: rgba(55, 52, 51, 0.05); }
      .jada-header.dashboard-header .custom-navbar .navigation-container .social-links li a .icomoon:before {
        color: #373433; }
    .jada-header.dashboard-header .custom-navbar .navigation-container .language-switcher li {
      color: #373433; }
      .jada-header.dashboard-header .custom-navbar .navigation-container .language-switcher li.active:after {
        background-color: #373433; }
    .jada-header.dashboard-header .custom-navbar .navigation-container .language-switcher span {
      color: #373433; }
  .jada-header .custom-navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent; }
    @media only screen and (max-width: 767px) {
      .jada-header .custom-navbar {
        justify-content: flex-end; } }
    .jada-header .custom-navbar .logo-wrap {
      height: 85px;
      padding-right: 20px; }
      @media only screen and (max-width: 991px) {
        .jada-header .custom-navbar .logo-wrap {
          border-right: 0px; } }
      .jada-header .custom-navbar .logo-wrap .logo-icon {
        width: auto;
        height: auto;
        display: block; }
      .jada-header .custom-navbar .logo-wrap .logo-green {
        display: none;
        width: auto;
        height: auto; }
    .jada-header .custom-navbar .navigation-container {
      display: flex;
      align-items: center; }
      .jada-header .custom-navbar .navigation-container .social-links {
        display: flex;
        justify-content: center;
        align-items: center; }
        @media only screen and (max-width: 1090px) {
          .jada-header .custom-navbar .navigation-container .social-links {
            display: none; } }
        .jada-header .custom-navbar .navigation-container .social-links li {
          position: relative;
          list-style: none;
          height: 40px;
          width: 40px;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 13px;
          overflow: hidden;
          transition: background-color 0.3s ease-in;
          -webkit-transition: background-color 0.3s ease-in;
          -moz-transition: background-color 0.3s ease-in;
          -ms-transition: background-color 0.3s ease-in;
          -o-transition: background-color 0.3s ease-in; }
          .jada-header .custom-navbar .navigation-container .social-links li:last-child {
            margin-right: 0px; }
          .jada-header .custom-navbar .navigation-container .social-links li a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%; }
            .jada-header .custom-navbar .navigation-container .social-links li a .icomoon {
              font-size: 18px;
              text-align: center;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%); }
          .jada-header .custom-navbar .navigation-container .social-links li:hover {
            background-color: rgba(255, 255, 255, 0.25); }
      .jada-header .custom-navbar .navigation-container .language-switcher {
        margin-left: 28px;
        margin-right: 40px;
        font-family: "Cairo", sans-serif;
        display: flex;
        align-items: center; }
        @media only screen and (max-width: 1090px) {
          .jada-header .custom-navbar .navigation-container .language-switcher {
            display: none; } }
        .jada-header .custom-navbar .navigation-container .language-switcher li {
          list-style: none;
          font-family: inherit;
          font-size: 1rem;
          line-height: 1.3;
          letter-spacing: 1px;
          color: #FFFFFF;
          font-weight: 700;
          position: relative;
          transition: 0.3s all ease-in;
          -webkit-transition: 0.3s all ease-in;
          -moz-transition: 0.3s all ease-in;
          -ms-transition: 0.3s all ease-in;
          -o-transition: 0.3s all ease-in; }
          .jada-header .custom-navbar .navigation-container .language-switcher li a {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            color: inherit;
            opacity: 0.5; }
            .jada-header .custom-navbar .navigation-container .language-switcher li a.active {
              opacity: 1; }
              .jada-header .custom-navbar .navigation-container .language-switcher li a.active:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                width: 100%;
                background-color: #FFFFFF;
                margin-top: 1px; }
          .jada-header .custom-navbar .navigation-container .language-switcher li:hover {
            opacity: 0.5; }
            .jada-header .custom-navbar .navigation-container .language-switcher li:hover a {
              text-decoration: none; }
            .jada-header .custom-navbar .navigation-container .language-switcher li:hover.active:after {
              opacity: 0.5; }
        .jada-header .custom-navbar .navigation-container .language-switcher span {
          margin: 0 5px;
          font-family: inherit;
          font-size: 1rem;
          line-height: 1.3;
          letter-spacing: 1px;
          color: #FFFFFF;
          font-weight: 700;
          opacity: 0.5; }
      .jada-header .custom-navbar .navigation-container .dashboard-menu {
        display: flex;
        height: 85px; }
        @media only screen and (max-width: 767px) {
          .jada-header .custom-navbar .navigation-container .dashboard-menu {
            display: none; } }
        .jada-header .custom-navbar .navigation-container .dashboard-menu ul {
          display: flex;
          margin: 0; }
        .jada-header .custom-navbar .navigation-container .dashboard-menu .icons-wrap {
          height: 36px;
          width: 36px;
          border-radius: 46px;
          -webkit-border-radius: 46px;
          -moz-border-radius: 46px;
          -ms-border-radius: 46px;
          -o-border-radius: 46px;
          border: 0.9px solid rgba(55, 52, 51, 0.15);
          text-align: center;
          list-style: none; }
        .jada-header .custom-navbar .navigation-container .dashboard-menu .icomoon {
          font-size: 1.25rem;
          line-height: 36px;
          color: #373433; }
        .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links {
          cursor: pointer;
          position: relative;
          padding: 24px;
          box-sizing: border-box;
          list-style: none;
          background-color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 0.8px solid rgba(55, 52, 51, 0.1);
          border-bottom: 0.8px solid rgba(55, 52, 51, 0.1);
          transition: 300ms all ease-in-out; }
          .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links .profile-image {
            height: 35px;
            width: 35px;
            border-radius: 100%; }
          .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links ul {
            position: absolute;
            min-width: 165px;
            top: 105px;
            left: 50%;
            transform: translateX(-50%);
            padding: 15px 0px;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            transition: 300ms all ease-in-out 0.5s; }
            .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links ul li {
              list-style: none;
              text-align: left;
              padding: 10px 22px 11px 22px; }
              .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links ul li a {
                font-size: 1rem;
                line-height: 1.4;
                letter-spacing: 0.1px;
                font-weight: 700;
                color: #373433;
                text-transform: uppercase; }
              .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links ul li button {
                background: transparent;
                font-size: 1rem;
                letter-spacing: 0.1px;
                font-weight: 700;
                color: #373433;
                text-transform: uppercase;
                border: none;
                width: 100%;
                min-width: auto;
                text-align: left;
                padding: 0px;
                display: flex;
                align-items: center;
                height: 22.4px; }
              .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links ul li:hover {
                background-color: rgba(55, 52, 51, 0.07); }
            .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links ul:after {
              position: absolute;
              content: '';
              top: -10px;
              left: 50%;
              transform: translateX(-50%);
              width: 32px;
              height: 14px;
              border-bottom: solid 14px #FFFFFF;
              border-left: solid 15px transparent;
              border-right: solid 15px transparent; }
          .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links:last-child .icomoon, .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links:first-child .icomoon, .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links:nth-child(2) .icomoon {
            font-size: 1.5rem; }
          .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links span {
            opacity: 0;
            width: 0px;
            overflow: hidden;
            white-space: nowrap;
            text-transform: uppercase;
            font-family: "Cairo", sans-serif;
            font-size: 1rem;
            line-height: 1.4;
            font-weight: bold;
            color: #373433;
            transition: 300ms all ease-in-out; }
            .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links span a {
              color: #373433; }
          .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links:after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            height: 0px;
            width: 100%;
            background-color: #007473;
            transition: 0.2s all ease; }
          @media only screen and (min-width: 767px) {
            .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links:hover {
              border-bottom: 0px;
              width: auto; }
              .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links:hover ul {
                visibility: visible;
                opacity: 1; }
              .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links:hover:after {
                height: 6px; }
              .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links:hover span {
                opacity: 1;
                overflow: visible;
                padding-left: 15px;
                width: auto; } }
          .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links.access::before, .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links.login::before {
            position: absolute;
            content: '';
            height: 100%;
            width: 100%;
            z-index: -1;
            top: 20px;
            left: 0;
            background-color: transparent;
            display: none; }
          .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links.access:hover::before, .jada-header .custom-navbar .navigation-container .dashboard-menu .d-links.login:hover::before {
            display: block; }
        .jada-header .custom-navbar .navigation-container .dashboard-menu .access ul {
          min-width: 245px; }
          .jada-header .custom-navbar .navigation-container .dashboard-menu .access ul li {
            text-align: left; }
            .jada-header .custom-navbar .navigation-container .dashboard-menu .access ul li button .icomoon {
              font-size: 1.375rem;
              padding-right: 15px; }

.side-bar-hamburger {
  z-index: 11;
  position: absolute;
  top: -1px;
  left: 0;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85px;
  background: #373433;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  .side-bar-hamburger .nav-wrapper {
    position: relative;
    height: 85px;
    width: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .side-bar-hamburger .nav-wrapper:after {
      content: '';
      height: 0;
      width: 100%;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #007473;
      transition: 0.3s height ease; }
    .side-bar-hamburger .nav-wrapper:hover:after {
      height: 100%; }
    .side-bar-hamburger .nav-wrapper .title {
      display: none; }
  .side-bar-hamburger.homepage-page-menu {
    height: 80vh;
    width: 74px; }
    @media only screen and (max-width: 1090px) {
      .side-bar-hamburger.homepage-page-menu {
        height: 85px;
        width: 85px; } }
    @media only screen and (max-height: 700px) {
      .side-bar-hamburger.homepage-page-menu {
        height: 100vh; } }
    @media only screen and (max-width: 767px) {
      .side-bar-hamburger.homepage-page-menu {
        height: 85px;
        width: 85px; } }
    .side-bar-hamburger.homepage-page-menu .nav-wrapper:after {
      display: none; }
    .side-bar-hamburger.homepage-page-menu .nav-wrapper .title {
      display: block; }
      @media only screen and (max-width: 1090px) {
        .side-bar-hamburger.homepage-page-menu .nav-wrapper .title {
          display: none; } }
    .side-bar-hamburger.homepage-page-menu .nav-wrapper .hamburger span:first-child {
      margin-top: 0px; }
  .side-bar-hamburger.top {
    top: -85px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
    height: 85px;
    width: 85px !important;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    @media only screen and (max-width: 991px) {
      .side-bar-hamburger.top {
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease; } }
    .side-bar-hamburger.top .title {
      display: none !important; }
  .side-bar-hamburger.sticky {
    position: fixed !important;
    top: 0px !important;
    width: 85px !important;
    height: 85px;
    background-color: #007473;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .side-bar-hamburger.sticky .title {
      display: none !important; }
    .side-bar-hamburger.sticky .nav-wrapper {
      position: relative;
      height: 85px;
      width: 85px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      .side-bar-hamburger.sticky .nav-wrapper:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: #007473;
        transition: all 0.3s ease-in;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        z-index: -1; }
      @media only screen and (max-width: 1090px) {
        .side-bar-hamburger.sticky .nav-wrapper:hover:after {
          height: 100%; } }
  .side-bar-hamburger.search-active {
    height: 85px;
    width: 85px; }
    .side-bar-hamburger.search-active .title {
      display: none; }
  .side-bar-hamburger.mega-menu-active {
    z-index: 0; }
  @media only screen and (max-width: 1090px) {
    .side-bar-hamburger {
      height: 85px;
      width: 85px;
      max-height: none; } }
  .side-bar-hamburger.login-header {
    display: none; }
  .side-bar-hamburger .title {
    color: #FFFFFF;
    font-family: "Cairo", sans-serif;
    text-transform: uppercase;
    line-height: 1.4;
    font-size: 0.8125rem;
    letter-spacing: 0.1px;
    text-align: center;
    display: block;
    margin-bottom: 10px; }
    @media only screen and (max-width: 1090px) {
      .side-bar-hamburger .title {
        display: none; } }
  .side-bar-hamburger span {
    height: 2px;
    width: 35px;
    background-color: #FFFFFF;
    margin-bottom: 5px; }
    .side-bar-hamburger span:last-child {
      margin-bottom: 0px; }
  .side-bar-hamburger .hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer; }

.mega-menu {
  transition: all 0.3s cubic-bezier(0.405, 0, 0.025, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.405, 0, 0.025, 1);
  -moz-transition: all 0.3s cubic-bezier(0.405, 0, 0.025, 1);
  -ms-transition: all 0.3s cubic-bezier(0.405, 0, 0.025, 1);
  -o-transition: all 0.3s cubic-bezier(0.405, 0, 0.025, 1);
  height: 100vh;
  overflow: hidden;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 0;
  z-index: 6; }
  .mega-menu.active {
    opacity: 1;
    visibility: visible; }
    .mega-menu.active .nav-section {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0); }
    .mega-menu.active .links-section {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0); }
    .mega-menu.active .info-section .nav-bg-img {
      opacity: 1;
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1); }
    .mega-menu.active .info-section .info-slider {
      transform: translateY(0);
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0); }
  .mega-menu .row {
    height: 100%;
    margin: 0;
    flex-wrap: nowrap; }
    @media only screen and (max-width: 991px) {
      .mega-menu .row {
        display: flex; } }
  .mega-menu .nav-section {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    height: 100%;
    padding: 11% 40px 4%;
    background-color: #373433;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 55%;
    max-width: 55%;
    width: 55%; }
    @media only screen and (max-width: 1600px) {
      .mega-menu .nav-section {
        padding: 12% 40px 6%; } }
    @media screen and (min-width: 1280px) and (max-height: 850px) {
      .mega-menu .nav-section {
        padding: 8% 40px 3%; } }
    @media only screen and (max-width: 1090px) {
      .mega-menu .nav-section {
        width: calc(100vw);
        flex: none;
        max-width: none;
        justify-content: flex-start;
        overflow: auto; } }
    @media only screen and (max-width: 991px) {
      .mega-menu .nav-section {
        padding-top: 20%; } }
    @media only screen and (max-width: 767px) {
      .mega-menu .nav-section {
        padding-top: 120px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 150px; } }
    @media only screen and (max-width: 500px) {
      .mega-menu .nav-section {
        width: calc(100vw); } }
    .mega-menu .nav-section .nav-center-links nav ul {
      list-style: none;
      margin-bottom: 0; }
      .mega-menu .nav-section .nav-center-links nav ul li {
        list-style: none;
        padding: 2.8% 0 3.2% 0;
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
        @media only screen and (max-width: 1090px) {
          .mega-menu .nav-section .nav-center-links nav ul li {
            padding: 20px 0; } }
        .mega-menu .nav-section .nav-center-links nav ul li a {
          font-family: "Cairo", sans-serif;
          font-weight: 400;
          font-size: 2.5rem;
          line-height: 1.15;
          color: #FFFFFF;
          transition: 0.3s color ease-in;
          -webkit-transition: 0.3s color ease-in;
          -moz-transition: 0.3s color ease-in;
          -ms-transition: 0.3s color ease-in;
          -o-transition: 0.3s color ease-in; }
          .mega-menu .nav-section .nav-center-links nav ul li a.active, .mega-menu .nav-section .nav-center-links nav ul li a:hover {
            color: #95CBC8; }
          .mega-menu .nav-section .nav-center-links nav ul li a:focus {
            text-decoration: none;
            border: 0px;
            outline: 0px; }
    .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile {
      display: none; }
      @media only screen and (max-width: 767px) {
        .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile {
          display: block; } }
      .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile ul li {
        padding: 20px 0; }
        .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile ul li a,
        .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile ul li div {
          font-size: 20px;
          line-height: 22px;
          display: flex;
          align-items: center;
          color: #FFFFFF;
          text-transform: uppercase; }
          .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile ul li a i,
          .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile ul li div i {
            font-size: 12px;
            height: 30px;
            width: 30px;
            border: 1px solid #ffffff80;
            border-radius: 50%;
            display: inline-block;
            padding: 8px;
            margin-right: 12px; }
      .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile .accessibility ul {
        border: 1px solid rgba(255, 255, 255, 0.15);
        padding: 20px;
        margin-top: 18px;
        display: none; }
        .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile .accessibility ul li {
          list-style: none;
          margin-bottom: 20px;
          padding: 0px;
          border: 0px; }
          .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile .accessibility ul li:last-child {
            margin-bottom: 0px; }
          .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile .accessibility ul li button {
            background: transparent;
            font-size: 16px;
            line-height: 22.4px;
            letter-spacing: 0.1px;
            font-weight: 700;
            color: #FFFFFF;
            text-transform: uppercase;
            border: none;
            width: 100%;
            min-width: auto;
            text-align: left;
            padding: 0px; }
          .mega-menu .nav-section .nav-center-links .dashboard-menu.mobile .accessibility ul li .icomoon {
            font-size: 1.25rem;
            padding-right: 16px; }
    .mega-menu .nav-section .nav-bottom-links ul {
      display: flex;
      list-style: none; }
      @media only screen and (max-width: 1090px) {
        .mega-menu .nav-section .nav-bottom-links ul {
          flex-direction: column;
          margin-top: 60px; } }
      .mega-menu .nav-section .nav-bottom-links ul li {
        list-style: none;
        margin-right: 44px; }
        .mega-menu .nav-section .nav-bottom-links ul li:last-child {
          margin-right: 0; }
        @media only screen and (max-width: 500px) {
          .mega-menu .nav-section .nav-bottom-links ul li {
            margin-right: 10px; }
            .mega-menu .nav-section .nav-bottom-links ul li:last-child {
              margin-right: 0; } }
        .mega-menu .nav-section .nav-bottom-links ul li a {
          color: #FFFFFF;
          font-size: 1.25rem;
          line-height: 1;
          font-weight: 400;
          transition: 0.3s color ease-in;
          -webkit-transition: 0.3s color ease-in;
          -moz-transition: 0.3s color ease-in;
          -ms-transition: 0.3s color ease-in;
          -o-transition: 0.3s color ease-in; }
          @media only screen and (max-width: 1090px) {
            .mega-menu .nav-section .nav-bottom-links ul li a {
              font-size: 30px;
              display: block;
              margin-bottom: 20px; } }
          @media only screen and (max-width: 767px) {
            .mega-menu .nav-section .nav-bottom-links ul li a {
              font-size: 20px;
              margin-bottom: 16px; } }
          .mega-menu .nav-section .nav-bottom-links ul li a.active, .mega-menu .nav-section .nav-bottom-links ul li a:hover {
            color: #95CBC8; }
    .mega-menu .nav-section .navigation-container {
      display: none; }
      @media only screen and (max-width: 1090px) {
        .mega-menu .nav-section .navigation-container {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-top: 25px; }
          .mega-menu .nav-section .navigation-container .language-switcher {
            display: flex;
            align-items: center;
            list-style-type: none;
            color: #FFFFFF; }
            .mega-menu .nav-section .navigation-container .language-switcher span {
              padding: 0 6px;
              font-size: 20px;
              line-height: 22px;
              color: #FFFFFF;
              text-decoration: none;
              opacity: 0.5;
              letter-spacing: 1px; } }
      @media only screen and (max-width: 1090px) and (max-width: 767px) {
        .mega-menu .nav-section .navigation-container .language-switcher span {
          line-height: 21px;
          font-size: 16px; } }
      @media only screen and (max-width: 1090px) {
            .mega-menu .nav-section .navigation-container .language-switcher li a {
              font-size: 20px;
              line-height: 22px;
              color: #FFFFFF;
              text-decoration: none;
              opacity: 0.5;
              letter-spacing: 1px; } }
      @media only screen and (max-width: 1090px) and (max-width: 767px) {
        .mega-menu .nav-section .navigation-container .language-switcher li a {
          line-height: 21px;
          font-size: 16px; } }
      @media only screen and (max-width: 1090px) {
            .mega-menu .nav-section .navigation-container .language-switcher li.active a {
              opacity: 1;
              text-decoration: underline; }
          .mega-menu .nav-section .navigation-container .social-links {
            display: flex;
            flex-direction: column; } }
    @media only screen and (max-width: 1090px) and (max-width: 500px) {
      .mega-menu .nav-section .navigation-container .social-links {
        width: 50%; } }
      @media only screen and (max-width: 1090px) {
            .mega-menu .nav-section .navigation-container .social-links ul {
              list-style: none;
              margin-bottom: 0;
              display: flex;
              justify-content: flex-end; }
              .mega-menu .nav-section .navigation-container .social-links ul li {
                position: relative;
                list-style: none;
                height: 42px;
                width: 42px;
                border-radius: 3px;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                -ms-border-radius: 3px;
                -o-border-radius: 3px;
                background-color: #FFFFFF;
                margin-right: 14px;
                -webkit-transition: 0.3s all ease-in;
                -moz-transition: 0.3s all ease-in;
                -ms-transition: 0.3s all ease-in;
                -o-transition: 0.3s all ease-in;
                transition: 0.3s all ease-in;
                transition: 0.3s background-color ease-in; } }
        @media only screen and (max-width: 1090px) and (max-width: 767px) {
          .mega-menu .nav-section .navigation-container .social-links ul li {
            height: 36px;
            width: 36px; } }
      @media only screen and (max-width: 1090px) {
                .mega-menu .nav-section .navigation-container .social-links ul li:hover {
                  background-color: transparent;
                  border: 1px solid #FFFFFF; }
                  .mega-menu .nav-section .navigation-container .social-links ul li:hover .icomoon:before {
                    color: #FFFFFF; }
                .mega-menu .nav-section .navigation-container .social-links ul li:last-child {
                  margin-right: 0; }
                .mega-menu .nav-section .navigation-container .social-links ul li a {
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  top: 0;
                  left: 0; }
                  .mega-menu .nav-section .navigation-container .social-links ul li a .icomoon {
                    position: absolute;
                    font-size: 1.25rem;
                    line-height: 1.25rem;
                    text-align: center;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%); }
                    .mega-menu .nav-section .navigation-container .social-links ul li a .icomoon:before {
                      color: #373433;
                      -webkit-transition: 0.3s all ease-in;
                      -moz-transition: 0.3s all ease-in;
                      -ms-transition: 0.3s all ease-in;
                      -o-transition: 0.3s all ease-in;
                      transition: 0.3s all ease-in; } }
  .mega-menu .info-section {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    max-width: none;
    width: calc(45% - 104px);
    height: 100%;
    padding: 0; }
    @media only screen and (max-width: 1090px) {
      .mega-menu .info-section {
        display: none; } }
    .mega-menu .info-section .nav-bg-img {
      position: relative;
      height: 48%;
      width: 100%;
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0;
      transform: scale(0);
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -o-transform: scale(0);
      transition: all 1s ease;
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -ms-transition: all 1s ease;
      -o-transition: all 1s ease; }
      .mega-menu .info-section .nav-bg-img:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3); }
    .mega-menu .info-section .info-slider {
      background-color: #007473;
      height: 52%;
      padding: 57px 40px 88px;
      transform: translateY(100%);
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -o-transform: translateY(100%);
      transition: all 0.8s ease;
      -webkit-transition: all 0.8s ease;
      -moz-transition: all 0.8s ease;
      -ms-transition: all 0.8s ease;
      -o-transition: all 0.8s ease; }
      @media only screen and (max-width: 1090px) {
        .mega-menu .info-section .info-slider {
          padding-bottom: 20px; } }
      .mega-menu .info-section .info-slider .swiper-container .swiper-wrapper .swiper-slide {
        margin-bottom: 60px; }
        @media only screen and (max-width: 1090px) {
          .mega-menu .info-section .info-slider .swiper-container .swiper-wrapper .swiper-slide {
            margin-bottom: 30px; } }
        @media screen and (min-width: 1280px) and (max-height: 850px) {
          .mega-menu .info-section .info-slider .swiper-container .swiper-wrapper .swiper-slide {
            margin-bottom: 40px; } }
        .mega-menu .info-section .info-slider .swiper-container .swiper-wrapper .swiper-slide p.number {
          font-family: "Cairo", sans-serif;
          font-weight: 200;
          font-size: 7.5rem;
          line-height: 1.05;
          margin-bottom: 20px;
          color: #FFFFFF; }
          @media screen and (min-width: 1280px) and (max-height: 850px) {
            .mega-menu .info-section .info-slider .swiper-container .swiper-wrapper .swiper-slide p.number {
              font-size: 65px; } }
          @media only screen and (max-width: 1090px) {
            .mega-menu .info-section .info-slider .swiper-container .swiper-wrapper .swiper-slide p.number {
              font-size: 5.3125rem;
              line-height: 1; } }
        .mega-menu .info-section .info-slider .swiper-container .swiper-wrapper .swiper-slide h2 {
          max-width: 380px;
          color: #FFFFFF; }
          @media screen and (min-width: 1280px) and (max-height: 850px) {
            .mega-menu .info-section .info-slider .swiper-container .swiper-wrapper .swiper-slide h2 {
              font-size: 30px; } }
          @media only screen and (max-width: 1090px) {
            .mega-menu .info-section .info-slider .swiper-container .swiper-wrapper .swiper-slide h2 {
              font-size: 1.875rem;
              line-height: 1.2; } }
  .mega-menu .links-section {
    position: relative;
    height: inherit;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 0 0 auto;
    max-width: none;
    width: 104px;
    transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%); }
    @media only screen and (max-width: 1090px) {
      .mega-menu .links-section {
        height: 85px;
        position: absolute;
        left: 0;
        right: auto;
        width: 85px;
        z-index: 10;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transition: all 0.4s ease; }
        .mega-menu .links-section .menu-close {
          transform: translate(-50%, -50%) !important; }
        .mega-menu .links-section .bottom-social {
          display: none !important; } }
    @media only screen and (max-width: 767px) {
      .mega-menu .links-section .menu-close {
        transform: translate(-50%, 50%) !important; } }
    @media only screen and (max-width: 500px) {
      .mega-menu .links-section {
        width: 85px;
        height: 85px; } }
    .mega-menu .links-section .menu-close {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      height: 38px;
      width: 38px;
      cursor: pointer; }
      .mega-menu .links-section .menu-close:hover {
        opacity: 0.5; }
      .mega-menu .links-section .menu-close:before, .mega-menu .links-section .menu-close:after {
        position: absolute;
        content: "";
        height: 38px;
        width: 3px;
        background-color: #000000;
        right: 18px; }
      .mega-menu .links-section .menu-close:before {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg); }
      .mega-menu .links-section .menu-close:after {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg); }
      @media only screen and (max-width: 767px) {
        .mega-menu .links-section .menu-close {
          top: 0px; } }
    .mega-menu .links-section .bottom-social {
      padding-bottom: 40px;
      display: flex;
      justify-content: center; }
      @media only screen and (max-width: 767px) {
        .mega-menu .links-section .bottom-social {
          padding-bottom: 60px; } }
      .mega-menu .links-section .bottom-social ul {
        list-style: none;
        margin-bottom: 0; }
        .mega-menu .links-section .bottom-social ul li {
          position: relative;
          list-style: none;
          height: 42px;
          width: 42px;
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          background-color: #373433;
          margin-bottom: 14px;
          transition: 0.3s background-color ease-in; }
          .mega-menu .links-section .bottom-social ul li:hover {
            background-color: transparent;
            border: 1px solid #373433; }
            .mega-menu .links-section .bottom-social ul li:hover .icomoon:before {
              color: #373433; }
            .mega-menu .links-section .bottom-social ul li:hover:nth-child(even) {
              border: 1px solid #007473;
              background-color: transparent; }
              .mega-menu .links-section .bottom-social ul li:hover:nth-child(even) .icomoon:before {
                color: #007473; }
          .mega-menu .links-section .bottom-social ul li:last-child {
            margin-bottom: 0; }
          .mega-menu .links-section .bottom-social ul li:nth-child(even) {
            background-color: #007473; }
          .mega-menu .links-section .bottom-social ul li a {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0; }
            .mega-menu .links-section .bottom-social ul li a .icomoon {
              position: absolute;
              color: #FFFFFF;
              font-size: 1.25rem;
              line-height: 1.25rem;
              text-align: center;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%); }

#jadaFooter {
  background-color: #007473;
  padding-top: 60px;
  padding-bottom: 40px;
  position: relative; }
  #jadaFooter .logo-wrapper {
    display: flex;
    justify-content: space-between; }
    #jadaFooter .logo-wrapper .footer-logo {
      width: 83%; }
      @media only screen and (min-width: 1600px) {
        #jadaFooter .logo-wrapper .footer-logo {
          width: 81%; } }
      @media only screen and (max-width: 767px) {
        #jadaFooter .logo-wrapper .footer-logo {
          width: 60%; } }
      @media only screen and (max-width: 500px) {
        #jadaFooter .logo-wrapper .footer-logo {
          width: 50%; } }
      #jadaFooter .logo-wrapper .footer-logo img {
        width: 144px; }
        @media only screen and (max-width: 991px) {
          #jadaFooter .logo-wrapper .footer-logo img {
            width: 220px; } }
        @media only screen and (max-width: 767px) {
          #jadaFooter .logo-wrapper .footer-logo img {
            width: 144px; } }
    #jadaFooter .logo-wrapper .social-links {
      display: flex;
      flex-direction: column; }
      @media only screen and (max-width: 500px) {
        #jadaFooter .logo-wrapper .social-links {
          width: 50%; } }
      #jadaFooter .logo-wrapper .social-links ul {
        list-style: none;
        margin-bottom: 0;
        display: flex; }
        #jadaFooter .logo-wrapper .social-links ul li {
          position: relative;
          list-style: none;
          height: 42px;
          width: 42px;
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          background-color: #FFFFFF;
          margin-right: 14px;
          -webkit-transition: 0.3s all ease-in;
          -moz-transition: 0.3s all ease-in;
          -ms-transition: 0.3s all ease-in;
          -o-transition: 0.3s all ease-in;
          transition: 0.3s all ease-in;
          transition: 0.3s background-color ease-in; }
          @media only screen and (max-width: 767px) {
            #jadaFooter .logo-wrapper .social-links ul li {
              height: 36px;
              width: 36px; } }
          #jadaFooter .logo-wrapper .social-links ul li:hover {
            background-color: transparent;
            border: 1px solid #FFFFFF; }
            #jadaFooter .logo-wrapper .social-links ul li:hover .icomoon:before {
              color: #FFFFFF; }
          #jadaFooter .logo-wrapper .social-links ul li:last-child {
            margin-right: 0; }
          #jadaFooter .logo-wrapper .social-links ul li a {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0; }
            #jadaFooter .logo-wrapper .social-links ul li a .icomoon {
              position: absolute;
              font-size: 1.25rem;
              line-height: 1.25rem;
              text-align: center;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%); }
              #jadaFooter .logo-wrapper .social-links ul li a .icomoon:before {
                color: #007473;
                -webkit-transition: 0.3s all ease-in;
                -moz-transition: 0.3s all ease-in;
                -ms-transition: 0.3s all ease-in;
                -o-transition: 0.3s all ease-in;
                transition: 0.3s all ease-in; }
      #jadaFooter .logo-wrapper .social-links label {
        text-transform: uppercase;
        font-family: "Cairo", sans-serif;
        font-size: 1rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        opacity: 0.5;
        margin-bottom: 16px;
        font-weight: 700; }
  #jadaFooter .central-links {
    margin-top: 60px; }
    @media only screen and (max-width: 991px) {
      #jadaFooter .central-links {
        display: none; } }
    #jadaFooter .central-links ul {
      list-style: none;
      display: flex;
      flex-direction: column; }
      #jadaFooter .central-links ul li {
        list-style: none;
        margin-bottom: 17px;
        max-width: 245px;
        cursor: pointer; }
        #jadaFooter .central-links ul li:last-child {
          margin-bottom: 0; }
        #jadaFooter .central-links ul li a {
          font-size: 1.125rem;
          line-height: 1.3;
          font-weight: 700;
          letter-spacing: 0.5px;
          color: #FFFFFF;
          text-transform: uppercase;
          text-decoration: none;
          transition: opacity 0.3s ease-in;
          -webkit-transition: opacity 0.3s ease-in;
          -moz-transition: opacity 0.3s ease-in;
          -ms-transition: opacity 0.3s ease-in;
          -o-transition: opacity 0.3s ease-in; }
          #jadaFooter .central-links ul li a:hover {
            opacity: 0.7; }
        #jadaFooter .central-links ul li #speak-up {
          cursor: pointer; }
        #jadaFooter .central-links ul li .spanner {
          font-size: 1.125rem;
          line-height: 1.3;
          font-weight: 700;
          letter-spacing: 0.5px;
          color: #FFFFFF;
          text-transform: uppercase;
          text-decoration: none;
          transition: opacity 0.3s ease-in;
          -webkit-transition: opacity 0.3s ease-in;
          -moz-transition: opacity 0.3s ease-in;
          -ms-transition: opacity 0.3s ease-in;
          -o-transition: opacity 0.3s ease-in; }
          #jadaFooter .central-links ul li .spanner:hover {
            opacity: 0.7; }
    #jadaFooter .central-links .info ul {
      list-style: none;
      margin-bottom: 0; }
      #jadaFooter .central-links .info ul label {
        text-transform: uppercase;
        font-family: "Cairo", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        opacity: 0.5;
        margin-bottom: 7px;
        display: block; }
      #jadaFooter .central-links .info ul li {
        margin-bottom: 38px; }
        #jadaFooter .central-links .info ul li:last-child {
          margin-bottom: 0; }
        #jadaFooter .central-links .info ul li a {
          position: relative;
          font-size: 1.25rem;
          line-height: 1.7;
          text-transform: none; }
          #jadaFooter .central-links .info ul li a:after {
            position: absolute;
            content: "";
            bottom: 0px;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: #FFFFFF; }
      #jadaFooter .central-links .info ul span {
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.7;
        font-weight: 600;
        color: #FFFFFF;
        max-width: 330px; }
  #jadaFooter .central-links-mobile {
    margin-top: 60px;
    display: none; }
    @media only screen and (max-width: 991px) {
      #jadaFooter .central-links-mobile {
        display: block; } }
    @media only screen and (max-width: 767px) {
      #jadaFooter .central-links-mobile .li {
        display: flex; } }
    #jadaFooter .central-links-mobile ul {
      list-style: none;
      display: flex;
      flex-direction: column; }
      @media only screen and (max-width: 767px) {
        #jadaFooter .central-links-mobile ul {
          width: 50%; } }
      #jadaFooter .central-links-mobile ul li {
        list-style: none;
        margin-bottom: 17px;
        max-width: 245px; }
        @media only screen and (max-width: 600px) {
          #jadaFooter .central-links-mobile ul li {
            max-width: 147px; } }
        #jadaFooter .central-links-mobile ul li:last-child {
          margin-bottom: 0; }
        #jadaFooter .central-links-mobile ul li a {
          font-size: 1.125rem;
          line-height: 1.3;
          font-weight: 700;
          letter-spacing: 0.5px;
          color: #FFFFFF;
          text-transform: uppercase;
          text-decoration: none;
          transition: opacity 0.3s ease-in;
          -webkit-transition: opacity 0.3s ease-in;
          -moz-transition: opacity 0.3s ease-in;
          -ms-transition: opacity 0.3s ease-in;
          -o-transition: opacity 0.3s ease-in; }
          #jadaFooter .central-links-mobile ul li a:hover {
            opacity: 0.7; }
    @media only screen and (max-width: 767px) {
      #jadaFooter .central-links-mobile .info {
        margin-top: 40px; } }
    #jadaFooter .central-links-mobile .info ul {
      list-style: none;
      margin-bottom: 0;
      width: 100%; }
      #jadaFooter .central-links-mobile .info ul label {
        text-transform: uppercase;
        font-family: "Cairo", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        opacity: 0.5;
        margin-bottom: 7px;
        display: block; }
      #jadaFooter .central-links-mobile .info ul li {
        margin-bottom: 38px; }
        #jadaFooter .central-links-mobile .info ul li:last-child {
          margin-bottom: 0; }
        #jadaFooter .central-links-mobile .info ul li a {
          position: relative;
          font-size: 1.25rem;
          line-height: 1.7;
          text-transform: none; }
          #jadaFooter .central-links-mobile .info ul li a:after {
            position: absolute;
            content: "";
            bottom: 0px;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: #FFFFFF; }
      #jadaFooter .central-links-mobile .info ul span {
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.7;
        font-weight: 600;
        color: #FFFFFF;
        max-width: 330px; }
        #jadaFooter .central-links-mobile .info ul span br {
          display: none; }
          @media only screen and (max-width: 767px) {
            #jadaFooter .central-links-mobile .info ul span br {
              display: block; } }
    #jadaFooter .central-links-mobile .address {
      margin-top: 40px; }
  #jadaFooter .powered-by {
    margin-top: 50px; }
    @media only screen and (max-width: 991px) {
      #jadaFooter .powered-by.desktop {
        display: none; } }
    #jadaFooter .powered-by.mobile .logo {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end; }
      #jadaFooter .powered-by.mobile .logo span {
        margin-left: 20px;
        margin-bottom: 0; }
    @media only screen and (max-width: 991px) {
      #jadaFooter .powered-by.mobile {
        margin-top: 29px; } }
    #jadaFooter .powered-by span {
      text-transform: uppercase;
      display: block;
      font-family: "Cairo", sans-serif;
      font-size: 0.875rem;
      line-height: 1.3;
      letter-spacing: 0.5px;
      font-weight: 400;
      color: #FFFFFF;
      margin-bottom: 12px; }
  #jadaFooter .copy-right {
    margin-top: 50px; }
    @media only screen and (max-width: 991px) {
      #jadaFooter .copy-right {
        margin-top: 94px; } }
    @media only screen and (max-width: 767px) {
      #jadaFooter .copy-right {
        margin-top: 35px; } }
    #jadaFooter .copy-right .flex {
      display: flex;
      justify-content: space-between; }
      @media only screen and (max-width: 991px) {
        #jadaFooter .copy-right .flex {
          flex-direction: column;
          align-items: center;
          justify-content: center; } }
      @media only screen and (max-width: 767px) {
        #jadaFooter .copy-right .flex {
          flex-direction: column-reverse;
          align-items: flex-start; } }
      #jadaFooter .copy-right .flex span {
        font-family: "Cairo", sans-serif;
        font-size: 0.875rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        font-weight: 400;
        text-transform: uppercase; }
        @media only screen and (max-width: 991px) {
          #jadaFooter .copy-right .flex span {
            margin-bottom: 17px; } }
        @media only screen and (max-width: 767px) {
          #jadaFooter .copy-right .flex span {
            margin-bottom: 0;
            margin-top: 20px; } }
      #jadaFooter .copy-right .flex ul {
        margin-bottom: 0;
        list-style: none;
        display: flex;
        flex-direction: row; }
        #jadaFooter .copy-right .flex ul li {
          list-style: none;
          margin-bottom: 0;
          margin-right: 30px; }
          #jadaFooter .copy-right .flex ul li:last-child {
            margin-right: 0px; }
          #jadaFooter .copy-right .flex ul li a {
            text-transform: uppercase;
            font-family: "Cairo", sans-serif;
            font-size: 0.875rem;
            line-height: 1.3;
            letter-spacing: 0.5px;
            color: #FFFFFF;
            font-weight: 700;
            transition: opacity 0.3s ease-in;
            -webkit-transition: opacity 0.3s ease-in;
            -moz-transition: opacity 0.3s ease-in;
            -ms-transition: opacity 0.3s ease-in;
            -o-transition: opacity 0.3s ease-in; }
            #jadaFooter .copy-right .flex ul li a:hover {
              opacity: 0.7; }
  #jadaFooter .canvas {
    position: absolute;
    width: 50%;
    bottom: -48%;
    right: -21%;
    z-index: -1; }
    @media only screen and (min-width: 1600px) {
      #jadaFooter .canvas {
        bottom: -65%; } }
    @media only screen and (max-width: 1090px) {
      #jadaFooter .canvas {
        bottom: -38%;
        width: 60%; } }
    @media only screen and (max-width: 991px) {
      #jadaFooter .canvas {
        bottom: -51%;
        width: 100%;
        right: auto;
        left: -2%; } }
    @media only screen and (max-width: 767px) {
      #jadaFooter .canvas {
        bottom: -29%;
        width: 183%;
        right: -138%;
        left: auto; } }

.glightbox-team-popup {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden; }
  .glightbox-team-popup .gslider {
    justify-content: flex-start;
    align-items: flex-start; }
    .glightbox-team-popup .gslider .gslide-inline {
      height: 100vh !important;
      width: 100vw !important;
      max-height: none;
      overflow: hidden;
      background-color: #FFFFFF; }
      .glightbox-team-popup .gslider .gslide-inline .ginlined-content {
        height: 100% !important;
        width: 100% !important;
        max-width: none !important;
        padding-left: 0 !important;
        padding-right: 0% !important;
        padding-top: 96px !important;
        padding-bottom: 37px !important;
        opacity: 1 !important; }
        @media only screen and (max-width: 991px) {
          .glightbox-team-popup .gslider .gslide-inline .ginlined-content {
            padding-right: 40px !important;
            padding-left: 40px !important;
            overflow-y: scroll; } }
        .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%; }
          @media only screen and (max-width: 1090px) {
            .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
              height: auto; } }
          .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .row {
            display: flex;
            flex-wrap: wrap;
            height: 90%;
            overflow-x: hidden;
            overflow-y: scroll;
            margin: 0px; }
            @media only screen and (max-width: 991px) {
              .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .row {
                flex-direction: column;
                height: auto;
                flex-wrap: nowrap;
                overflow-y: visible; } }
          .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
            width: 47.1%;
            height: 100%; }
            @media only screen and (max-width: 991px) {
              .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
                width: 80%;
                margin: auto; } }
            @media only screen and (max-width: 767px) {
              .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
                width: 100%; } }
            .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
              height: 0;
              width: 100%;
              background-size: cover !important;
              background-repeat: no-repeat !important;
              padding-top: 117%; }
              @media only screen and (min-width: 1700px) {
                .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
                  padding-top: 80%; } }
              @media only screen and (max-width: 991px) {
                .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
                  padding-top: 100%; } }
          .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            width: 52.1%;
            padding: 0 0% 0 4%;
            padding-right: 10% !important; }
            @media only screen and (max-width: 991px) {
              .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                width: 80%;
                padding: 0  !important;
                padding-top: 20px !important;
                margin: auto; } }
            @media only screen and (max-height: 800px) {
              .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                justify-content: flex-start; } }
            @media only screen and (max-width: 767px) {
              .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                width: 100%;
                padding-right: 0px !important; } }
            .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper h4 {
              font-family: "Cairo", sans-serif;
              font-size: 1.25rem;
              line-height: 1.5;
              color: #373433;
              font-weight: 700;
              margin-bottom: 4px;
              margin-top: 0; }
              @media only screen and (max-width: 767px) {
                .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper h4 {
                  font-size: 20px;
                  line-height: 30px; } }
            .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .designation {
              font-family: "Cairo", sans-serif;
              font-size: 0.875rem;
              line-height: 1.3;
              letter-spacing: 0.5px;
              color: #373433;
              font-weight: 400;
              text-transform: uppercase;
              margin-bottom: 30px; }
              @media only screen and (max-width: 767px) {
                .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .designation {
                  font-size: 14px;
                  line-height: 18.2px; } }
            .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .content {
              font-family: "Cairo", sans-serif;
              font-size: 1.375rem;
              line-height: 1.5;
              color: #373433;
              font-weight: 300; }
              @media only screen and (max-width: 767px) {
                .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .content {
                  font-size: 22px;
                  line-height: 33px; } }
          .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
            padding: 0 40px;
            display: flex;
            height: 10%;
            margin-top: 61px; }
            @media only screen and (max-width: 991px) {
              .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
                padding: 0;
                margin-top: 40px !important;
                width: 80%;
                margin: auto; } }
            @media only screen and (max-width: 767px) {
              .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
                width: 100%;
                padding: 0 !important; } }
            .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number {
              font-display: "Cairo", sans-serif;
              font-size: 1.125rem;
              line-height: 1.2;
              font-weight: 400;
              color: #373433;
              margin-right: 66px; }
              .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number span {
                font-weight: 700; }
            .glightbox-team-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer h4 {
              font-family: "Cairo", sans-serif;
              font-size: 1.625rem;
              line-height: 1.2;
              font-weight: 400;
              color: #373433; }
  .glightbox-team-popup button {
    min-width: none !important; }
  .glightbox-team-popup .gclose {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none; }
    @media only screen and (max-width: 500px) {
      .glightbox-team-popup .gclose {
        top: 20px;
        right: 5px;
        height: 30px;
        width: 30px; } }
    .glightbox-team-popup .gclose svg {
      width: 15px; }
  .glightbox-team-popup .gprev,
  .glightbox-team-popup .gnext {
    position: absolute;
    bottom: 50px;
    background-color: transparent;
    min-width: none; }
    @media only screen and (max-width: 991px) {
      .glightbox-team-popup .gprev,
      .glightbox-team-popup .gnext {
        top: 50%;
        bottom: auto; } }
    .glightbox-team-popup .gprev svg,
    .glightbox-team-popup .gnext svg {
      display: none; }
  .glightbox-team-popup .gprev {
    right: 122px; }
    @media only screen and (max-width: 1090px) {
      .glightbox-team-popup .gprev {
        right: 65px; } }
    @media only screen and (max-width: 991px) {
      .glightbox-team-popup .gprev {
        left: 20px;
        right: auto; } }
    @media only screen and (max-width: 767px) {
      .glightbox-team-popup .gprev {
        left: 5px; } }
    .glightbox-team-popup .gprev:after {
      content: "\E907";
      font-size: 0.875rem;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433; }
      @media only screen and (max-width: 991px) {
        .glightbox-team-popup .gprev:after {
          font-size: 14px; } }
      @media only screen and (max-width: 500px) {
        .glightbox-team-popup .gprev:after {
          font-size: 12px; } }
  .glightbox-team-popup .gnext {
    right: 46px; }
    @media only screen and (max-width: 1090px) {
      .glightbox-team-popup .gnext {
        right: 15px; } }
    @media only screen and (max-width: 991px) {
      .glightbox-team-popup .gnext {
        right: 20px; } }
    @media only screen and (max-width: 767px) {
      .glightbox-team-popup .gnext {
        right: 5px; } }
    .glightbox-team-popup .gnext:after {
      content: "\E908";
      font-size: 0.875rem;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433; }
      @media only screen and (max-width: 991px) {
        .glightbox-team-popup .gnext:after {
          font-size: 14px; } }
      @media only screen and (max-width: 991px) {
        .glightbox-team-popup .gnext:after {
          font-size: 12px; } }

.glightbox-executive-popup {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden; }
  .glightbox-executive-popup .gslider {
    justify-content: flex-start;
    align-items: flex-start; }
    .glightbox-executive-popup .gslider .gslide-inline {
      height: 100vh !important;
      width: 100vw !important;
      max-height: none;
      overflow: hidden;
      background-color: #FFFFFF; }
      .glightbox-executive-popup .gslider .gslide-inline .ginlined-content {
        height: 100% !important;
        width: 100% !important;
        max-width: none !important;
        padding-left: 0 !important;
        padding-right: 0% !important;
        padding-top: 96px !important;
        padding-bottom: 37px !important;
        opacity: 1 !important; }
        @media only screen and (max-width: 991px) {
          .glightbox-executive-popup .gslider .gslide-inline .ginlined-content {
            padding-right: 40px !important;
            padding-left: 40px !important;
            overflow-y: scroll; } }
        .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%; }
          @media only screen and (max-width: 1090px) {
            .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
              height: auto; } }
          .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .row {
            display: flex;
            flex-wrap: wrap;
            height: 90%;
            overflow-x: hidden;
            overflow-y: scroll;
            margin: 0px; }
            @media only screen and (max-width: 991px) {
              .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .row {
                flex-direction: column;
                height: auto;
                flex-wrap: nowrap;
                overflow-y: visible; } }
          .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
            width: 47.1%;
            height: 100%;
            overflow: hidden; }
            @media only screen and (max-width: 991px) {
              .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
                width: 80%;
                margin: auto; } }
            @media only screen and (max-width: 767px) {
              .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
                width: 100%; } }
            .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
              height: 0;
              width: 100%;
              background-size: cover !important;
              background-repeat: no-repeat !important;
              padding-top: 117%; }
              @media only screen and (min-width: 1700px) {
                .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
                  padding-top: 80%; } }
              @media only screen and (max-width: 991px) {
                .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
                  padding-top: 80%; } }
          .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            width: 52.1%;
            padding: 0 0% 0 4%;
            padding-right: 10% !important; }
            @media only screen and (max-width: 991px) {
              .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                width: 80%;
                padding: 0;
                padding-top: 20px !important;
                margin: auto; } }
            @media only screen and (max-height: 800px) {
              .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                justify-content: flex-start; } }
            @media only screen and (max-width: 767px) {
              .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                width: 100%;
                padding-right: 0px !important; } }
            .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper h4 {
              font-family: "Cairo", sans-serif;
              font-size: 1.25rem;
              line-height: 1.5;
              color: #373433;
              font-weight: 700;
              margin-bottom: 4px;
              margin-top: 0; }
            .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .designation {
              font-family: "Cairo", sans-serif;
              font-size: 0.875rem;
              line-height: 1.3;
              letter-spacing: 0.5px;
              color: #373433;
              font-weight: 400;
              text-transform: uppercase;
              margin-bottom: 30px; }
            .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .content {
              font-family: "Cairo", sans-serif;
              font-size: 1.375rem;
              line-height: 1.5;
              color: #373433;
              font-weight: 300; }
          .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
            padding: 0 40px;
            display: flex;
            height: 10%;
            margin-top: 61px; }
            @media only screen and (max-width: 991px) {
              .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
                padding: 0;
                margin-top: 40px !important;
                width: 80%;
                margin: auto; } }
            @media only screen and (max-width: 767px) {
              .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
                width: 100%;
                padding: 0 !important; } }
            .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number {
              font-display: "Cairo", sans-serif;
              font-size: 1.125rem;
              line-height: 1.2;
              font-weight: 400;
              color: #373433;
              margin-right: 66px; }
              .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number span {
                font-weight: 700; }
            .glightbox-executive-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer h4 {
              font-family: "Cairo", sans-serif;
              font-size: 1.625rem;
              line-height: 1.2;
              font-weight: 400;
              color: #373433; }
  .glightbox-executive-popup button {
    min-width: none !important; }
  .glightbox-executive-popup .gclose {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none; }
    @media only screen and (max-width: 500px) {
      .glightbox-executive-popup .gclose {
        top: 20px;
        right: 5px;
        height: 30px;
        width: 30px; } }
    .glightbox-executive-popup .gclose svg {
      width: 15px; }
  .glightbox-executive-popup .gprev,
  .glightbox-executive-popup .gnext {
    position: absolute;
    bottom: 50px;
    background-color: transparent;
    min-width: none; }
    @media only screen and (max-width: 991px) {
      .glightbox-executive-popup .gprev,
      .glightbox-executive-popup .gnext {
        top: 50%;
        bottom: auto; } }
    .glightbox-executive-popup .gprev svg,
    .glightbox-executive-popup .gnext svg {
      display: none; }
  .glightbox-executive-popup .gprev {
    right: 122px; }
    @media only screen and (max-width: 1090px) {
      .glightbox-executive-popup .gprev {
        right: 65px; } }
    @media only screen and (max-width: 991px) {
      .glightbox-executive-popup .gprev {
        left: 20px;
        right: auto; } }
    @media only screen and (max-width: 767px) {
      .glightbox-executive-popup .gprev {
        left: 5px; } }
    .glightbox-executive-popup .gprev:after {
      content: "\E907";
      font-size: 0.875rem;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433; }
      @media only screen and (max-width: 991px) {
        .glightbox-executive-popup .gprev:after {
          font-size: 14px; } }
      @media only screen and (max-width: 500px) {
        .glightbox-executive-popup .gprev:after {
          font-size: 12px; } }
  .glightbox-executive-popup .gnext {
    right: 46px; }
    @media only screen and (max-width: 1090px) {
      .glightbox-executive-popup .gnext {
        right: 15px; } }
    @media only screen and (max-width: 991px) {
      .glightbox-executive-popup .gnext {
        right: 20px; } }
    @media only screen and (max-width: 767px) {
      .glightbox-executive-popup .gnext {
        right: 5px; } }
    .glightbox-executive-popup .gnext:after {
      content: "\E908";
      font-size: 0.875rem;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433; }
      @media only screen and (max-width: 991px) {
        .glightbox-executive-popup .gnext:after {
          font-size: 14px; } }
      @media only screen and (max-width: 991px) {
        .glightbox-executive-popup .gnext:after {
          font-size: 12px; } }

.glightbox-speaker-popup {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden; }
  .glightbox-speaker-popup .gslider {
    justify-content: flex-start;
    align-items: flex-start; }
    .glightbox-speaker-popup .gslider .gslide-inline {
      height: 100vh !important;
      width: 100vw !important;
      max-height: none;
      overflow: hidden;
      background-color: #FFFFFF; }
      .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content {
        height: 100% !important;
        width: 100% !important;
        max-width: none !important;
        padding-left: 0 !important;
        padding-right: 10% !important;
        padding-top: 96px !important;
        padding-bottom: 37px !important;
        overflow-x: hidden;
        overflow-y: scroll;
        opacity: 1 !important; }
        @media only screen and (max-width: 1090px) {
          .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content {
            padding-right: 16% !important; } }
        @media only screen and (max-width: 991px) {
          .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content {
            padding-right: 40px !important;
            padding-left: 40px !important; } }
        .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: auto; }
          .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .row {
            display: flex;
            flex-wrap: wrap;
            height: 90%;
            overflow: hidden;
            margin: 0px; }
            @media only screen and (max-width: 991px) {
              .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .row {
                flex-direction: column; } }
          .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
            width: 47.1%;
            height: 100%;
            overflow: hidden; }
            @media only screen and (max-width: 991px) {
              .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
                width: 80%;
                margin: auto; } }
            @media only screen and (max-width: 767px) {
              .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
                width: 100%; } }
            .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
              height: 0;
              width: 100%;
              background-size: cover !important;
              background-repeat: no-repeat !important;
              padding-top: 117%; }
              @media only screen and (min-width: 1700px) {
                .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
                  padding-top: 80%; } }
              @media only screen and (max-width: 991px) {
                .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
                  padding-top: 80%; } }
          .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            width: 52.1%;
            padding: 0 0% 0 4%; }
            @media only screen and (max-width: 991px) {
              .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                width: 80%;
                padding: 0;
                padding-top: 20px !important;
                margin: auto; } }
            @media only screen and (max-height: 800px) {
              .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                justify-content: flex-start; } }
            @media only screen and (max-width: 767px) {
              .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                width: 100%; } }
            .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper h4 {
              font-family: "Cairo", sans-serif;
              font-size: 1.25rem;
              line-height: 1.5;
              color: #373433;
              font-weight: 700;
              margin-bottom: 4px;
              margin-top: 0; }
            .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .designation {
              font-family: "Cairo", sans-serif;
              font-size: 0.875rem;
              line-height: 1.3;
              letter-spacing: 0.5px;
              color: #373433;
              font-weight: 400;
              text-transform: uppercase;
              margin-bottom: 30px; }
            .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .content {
              font-family: "Cairo", sans-serif;
              font-size: 1.375rem;
              line-height: 1.5;
              color: #373433;
              font-weight: 300; }
          .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
            padding: 0 40px;
            display: flex;
            height: 10%;
            margin-top: 61px; }
            @media only screen and (max-width: 991px) {
              .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
                padding: 0;
                margin-top: 40px !important;
                width: 80%;
                margin: auto; } }
            @media only screen and (max-width: 767px) {
              .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
                width: 100%;
                padding: 0 !important; } }
            .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number {
              font-display: "Cairo", sans-serif;
              font-size: 1.125rem;
              line-height: 1.2;
              font-weight: 400;
              color: #373433;
              margin-right: 66px; }
              .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number span {
                font-weight: 700; }
            .glightbox-speaker-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer h4 {
              font-family: "Cairo", sans-serif;
              font-size: 1.625rem;
              line-height: 1.2;
              font-weight: 400;
              color: #373433; }
  .glightbox-speaker-popup button {
    min-width: none !important; }
  .glightbox-speaker-popup .gclose {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none; }
    @media only screen and (max-width: 500px) {
      .glightbox-speaker-popup .gclose {
        top: 20px;
        right: 5px;
        height: 30px;
        width: 30px; } }
    .glightbox-speaker-popup .gclose svg {
      width: 15px; }
  .glightbox-speaker-popup .gprev,
  .glightbox-speaker-popup .gnext {
    position: absolute;
    bottom: 50px;
    background-color: transparent;
    min-width: none; }
    @media only screen and (max-width: 991px) {
      .glightbox-speaker-popup .gprev,
      .glightbox-speaker-popup .gnext {
        top: 50%;
        bottom: auto; } }
    .glightbox-speaker-popup .gprev svg,
    .glightbox-speaker-popup .gnext svg {
      display: none; }
  .glightbox-speaker-popup .gprev {
    right: 122px; }
    @media only screen and (max-width: 1090px) {
      .glightbox-speaker-popup .gprev {
        right: 65px; } }
    @media only screen and (max-width: 991px) {
      .glightbox-speaker-popup .gprev {
        left: 20px;
        right: auto; } }
    @media only screen and (max-width: 767px) {
      .glightbox-speaker-popup .gprev {
        left: 5px; } }
    .glightbox-speaker-popup .gprev:after {
      content: "\E907";
      font-size: 0.875rem;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433; }
      @media only screen and (max-width: 991px) {
        .glightbox-speaker-popup .gprev:after {
          font-size: 14px; } }
      @media only screen and (max-width: 500px) {
        .glightbox-speaker-popup .gprev:after {
          font-size: 12px; } }
  .glightbox-speaker-popup .gnext {
    right: 46px; }
    @media only screen and (max-width: 1090px) {
      .glightbox-speaker-popup .gnext {
        right: 15px; } }
    @media only screen and (max-width: 991px) {
      .glightbox-speaker-popup .gnext {
        right: 20px; } }
    @media only screen and (max-width: 767px) {
      .glightbox-speaker-popup .gnext {
        right: 5px; } }
    .glightbox-speaker-popup .gnext:after {
      content: "\E908";
      font-size: 0.875rem;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433; }
      @media only screen and (max-width: 991px) {
        .glightbox-speaker-popup .gnext:after {
          font-size: 14px; } }
      @media only screen and (max-width: 991px) {
        .glightbox-speaker-popup .gnext:after {
          font-size: 12px; } }

.glightbox-image-popup {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden; }
  .glightbox-image-popup .gslider {
    justify-content: flex-start;
    align-items: flex-start; }
    .glightbox-image-popup .gslider .gslide-inline {
      height: 100vh !important;
      width: 100vw !important;
      max-height: none;
      overflow: hidden;
      background-color: #FFFFFF; }
      .glightbox-image-popup .gslider .gslide-inline .ginlined-content {
        height: 100% !important;
        width: 100% !important;
        max-width: none !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        overflow-y: scroll; }
        .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: auto;
          padding-top: 9.3% !important;
          padding-bottom: 30px !important; }
          @media only screen and (max-width: 1090px) {
            .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
              height: 100%; } }
          @media only screen and (max-width: 500px) {
            .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
              padding-top: 50px !important; } }
          @media only screen and (max-width: 1090px) {
            .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
              justify-content: center; } }
          .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content {
            display: flex;
            justify-content: center;
            padding: 0 10.8%; }
            @media only screen and (max-width: 991px) {
              .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content {
                padding: 0 40px; } }
            @media only screen and (max-width: 767px) {
              .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content {
                padding: 0 20px; } }
            .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content .img-bg {
              padding-top: 50%;
              background-size: cover !important;
              background-position: center !important;
              background-repeat: no-repeat !important;
              width: 100%;
              height: 0; }
              @media only screen and (max-width: 767px) {
                .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content .img-bg {
                  padding-top: 80%; } }
              @media only screen and (max-width: 500px) {
                .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content .img-bg {
                  padding-top: 80%; } }
          .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
            padding: 0 23px 0px 40px;
            display: flex;
            width: 100%;
            height: 10%;
            margin-top: 61px;
            justify-content: space-between; }
            @media only screen and (max-width: 767px) {
              .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
                margin-top: 30px;
                flex-direction: column;
                padding: 0 20px;
                width: 100%;
                align-items: center; } }
            @media only screen and (max-width: 767px) {
              .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .btn-wrapper {
                margin-top: 30px; } }
            .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .footer-wrapper {
              display: flex;
              width: 53%; }
              @media only screen and (max-width: 767px) {
                .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .footer-wrapper {
                  justify-content: center; } }
              @media only screen and (max-width: 500px) {
                .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .footer-wrapper {
                  width: 100%; } }
            .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number {
              font-display: "Cairo", sans-serif;
              font-size: 1.125rem;
              line-height: 1.2;
              font-weight: 400;
              color: #373433;
              margin-right: 12%; }
              .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number span {
                font-weight: 700; }
            .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer h4 {
              font-family: "Cairo", sans-serif;
              font-size: 1.625rem;
              line-height: 1.2;
              font-weight: 400;
              color: #373433; }
              @media only screen and (max-width: 767px) {
                .glightbox-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer h4 {
                  text-align: center; } }
  .glightbox-image-popup button {
    min-width: none !important; }
  .glightbox-image-popup .gclose {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none; }
    @media only screen and (max-width: 767px) {
      .glightbox-image-popup .gclose {
        height: 30px;
        width: 30px;
        top: 20px;
        right: 20px; }
        .glightbox-image-popup .gclose svg {
          width: 14px; } }
  .glightbox-image-popup .gprev,
  .glightbox-image-popup .gnext {
    display: none;
    position: absolute;
    bottom: 50px;
    background-color: transparent;
    min-width: none; }
    @media only screen and (max-width: 1090px) {
      .glightbox-image-popup .gprev,
      .glightbox-image-popup .gnext {
        bottom: 10px; } }
    .glightbox-image-popup .gprev svg,
    .glightbox-image-popup .gnext svg {
      display: none; }
  .glightbox-image-popup .gprev {
    right: 122px; }
    .glightbox-image-popup .gprev:after {
      content: "\E907";
      font-size: 0.875rem;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433; }
    @media only screen and (max-width: 767px) {
      .glightbox-image-popup .gprev {
        left: 20px !important;
        right: auto !important; } }
  .glightbox-image-popup .gnext {
    right: 46px; }
    @media only screen and (max-width: 767px) {
      .glightbox-image-popup .gnext {
        right: 20px !important; } }
    .glightbox-image-popup .gnext:after {
      content: "\E908";
      font-size: 0.875rem;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433; }

.glightbox-video-popup .goverlay::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid #007473;
  border-right: 2px solid transparent;
  animation: spinner .6s linear infinite; }

.glightbox-video-popup .gcontainer {
  height: 100vh !important;
  width: 100vw !important;
  padding-top: 135px !important;
  overflow: hidden;
  background-color: #FFFFFF; }
  @media only screen and (min-width: 1600px) {
    .glightbox-video-popup .gcontainer {
      padding-top: 130px !important; } }
  @media only screen and (max-width: 1400px) {
    .glightbox-video-popup .gcontainer {
      padding-top: 100px !important; } }
  @media screen and (min-width: 1440px) and (max-height: 800px) {
    .glightbox-video-popup .gcontainer {
      padding-top: 70px !important; } }
  .glightbox-video-popup .gcontainer .gslider {
    justify-content: flex-start;
    align-items: flex-start;
    height: 100% !important;
    width: 100vw !important;
    max-height: none;
    overflow: hidden;
    padding-bottom: 37px !important; }
    @media only screen and (max-width: 1090px) {
      .glightbox-video-popup .gcontainer .gslider .gslide {
        height: 100%; } }
  .glightbox-video-popup .gcontainer .gslide-media {
    width: 78.5% !important;
    max-width: none !important;
    box-shadow: none !important; }
    @media only screen and (max-width: 1400px) {
      .glightbox-video-popup .gcontainer .gslide-media {
        width: 76% !important; } }
    @media only screen and (max-height: 769px) {
      .glightbox-video-popup .gcontainer .gslide-media {
        width: 70% !important; } }
    @media only screen and (min-width: 1600px) {
      .glightbox-video-popup .gcontainer .gslide-media {
        width: 60% !important; } }
    @media screen and (min-width: 1440px) and (max-height: 800px) {
      .glightbox-video-popup .gcontainer .gslide-media {
        width: 60% !important; } }
    @media only screen and (max-width: 991px) {
      .glightbox-video-popup .gcontainer .gslide-media {
        width: 90% !important; } }
  .glightbox-video-popup .gcontainer .gslide-description {
    max-width: none !important;
    width: 100%;
    margin-top: 61px; }
    @media only screen and (max-width: 991px) {
      .glightbox-video-popup .gcontainer .gslide-description {
        position: relative !important;
        height: auto !important;
        bottom: auto !important;
        background: none !important;
        padding: 0 40px !important;
        flex: none !important; } }
    @media only screen and (max-width: 767px) {
      .glightbox-video-popup .gcontainer .gslide-description {
        padding: 0 20px !important; } }
    .glightbox-video-popup .gcontainer .gslide-description .gdesc-inner {
      padding: 0 40px;
      display: flex; }
      @media only screen and (min-width: 1600px) {
        .glightbox-video-popup .gcontainer .gslide-description .gdesc-inner {
          padding: 0 6%; } }
      @media only screen and (min-width: 1700px) {
        .glightbox-video-popup .gcontainer .gslide-description .gdesc-inner {
          padding: 0 14%; } }
      @media only screen and (max-width: 991px) {
        .glightbox-video-popup .gcontainer .gslide-description .gdesc-inner {
          padding: 0 !important; } }
      .glightbox-video-popup .gcontainer .gslide-description .gdesc-inner .gslide-title {
        margin-bottom: 0px;
        display: inline-block; }
      .glightbox-video-popup .gcontainer .gslide-description .gdesc-inner .gslide-desc {
        display: inline-block; }
        @media only screen and (max-width: 991px) {
          .glightbox-video-popup .gcontainer .gslide-description .gdesc-inner .gslide-desc {
            padding: 0 !important;
            width: 100%;
            display: block; } }
  .glightbox-video-popup .gcontainer .popup-footer {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .glightbox-video-popup .gcontainer .popup-footer {
        justify-content: center; } }
    .glightbox-video-popup .gcontainer .popup-footer .number {
      font-display: "Cairo", sans-serif;
      font-size: 1.125rem;
      line-height: 1.2;
      font-weight: 400;
      color: #373433;
      margin-right: 66px;
      margin-bottom: 0px; }
      @media only screen and (max-width: 991px) {
        .glightbox-video-popup .gcontainer .popup-footer .number {
          margin-right: 5% !important; }
          .glightbox-video-popup .gcontainer .popup-footer .number br {
            display: none; } }
      .glightbox-video-popup .gcontainer .popup-footer .number span {
        font-weight: 700; }
    .glightbox-video-popup .gcontainer .popup-footer h4 {
      font-family: "Cairo", sans-serif;
      font-size: 1.625rem;
      line-height: 1.2;
      font-weight: 400;
      color: #373433; }
      @media only screen and (max-width: 767px) {
        .glightbox-video-popup .gcontainer .popup-footer h4 {
          text-align: center; } }
  .glightbox-video-popup .gcontainer .gclose {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none; }
    .glightbox-video-popup .gcontainer .gclose svg {
      width: 16px; }
    @media only screen and (max-width: 767px) {
      .glightbox-video-popup .gcontainer .gclose {
        height: 30px;
        width: 30px;
        top: 20px;
        right: 20px; }
        .glightbox-video-popup .gcontainer .gclose svg {
          width: 14px; } }
  .glightbox-video-popup .gcontainer .gprev,
  .glightbox-video-popup .gcontainer .gnext {
    position: absolute !important;
    bottom: 50px !important;
    top: auto;
    background-color: transparent !important;
    min-width: none;
    display: none; }
    .glightbox-video-popup .gcontainer .gprev svg,
    .glightbox-video-popup .gcontainer .gnext svg {
      display: none !important; }
    .glightbox-video-popup .gcontainer .gprev.disabled,
    .glightbox-video-popup .gcontainer .gnext.disabled {
      opacity: 0.5 !important; }
  .glightbox-video-popup .gcontainer .gprev {
    right: 122px !important;
    left: auto !important; }
    @media only screen and (max-width: 767px) {
      .glightbox-video-popup .gcontainer .gprev {
        left: 20px !important;
        right: auto !important; } }
    .glightbox-video-popup .gcontainer .gprev:after {
      content: "\E907" !important;
      font-size: 0.875rem !important;
      font-family: icomoon !important;
      font-style: normal !important;
      font-variant: normal;
      text-transform: none;
      color: #373433 !important; }
  .glightbox-video-popup .gcontainer .gnext {
    right: 46px !important;
    left: auto !important; }
    @media only screen and (max-width: 767px) {
      .glightbox-video-popup .gcontainer .gnext {
        right: 20px !important; } }
    .glightbox-video-popup .gcontainer .gnext:after {
      content: "\E908" !important;
      font-size: 0.875rem !important;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433 !important; }

.plyr--video .plyr__control.plyr__control--overlaid {
  height: 159px;
  width: 159px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  min-width: none; }
  @media only screen and (max-width: 1090px) {
    .plyr--video .plyr__control.plyr__control--overlaid {
      height: 75px;
      width: 75px; } }
  @media only screen and (max-width: 500px) {
    .plyr--video .plyr__control.plyr__control--overlaid {
      height: 50px;
      width: 50px; } }
  .plyr--video .plyr__control.plyr__control--overlaid svg {
    height: 33px;
    width: 33px;
    fill: #007473; }
    @media only screen and (max-width: 1090px) {
      .plyr--video .plyr__control.plyr__control--overlaid svg {
        height: 20px;
        width: 20px; } }
    @media only screen and (max-width: 500px) {
      .plyr--video .plyr__control.plyr__control--overlaid svg {
        height: 15px;
        width: 15px; } }

.plyr--video .plyr__controls {
  background: rgba(0, 0, 0, 0.5) !important;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 15px;
  width: 98%;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%) !important; }
  @media only screen and (max-width: 767px) {
    .plyr--video .plyr__controls {
      padding-top: 10px;
      padding-bottom: 10px; } }

.plyr--video .plyr__controls__item.plyr__control[data-plyr="play"] {
  background-color: transparent; }

.plyr--video .plyr__control[data-plyr="settings"] {
  display: none; }

.plyr--video .plyr__progress__container input {
  color: #fff; }

.plyr--video .plyr__volume {
  margin-left: 20px !important;
  margin-right: 20px !important;
  width: auto;
  max-width: none;
  min-width: auto; }
  @media only screen and (max-width: 767px) {
    .plyr--video .plyr__volume {
      margin-left: 10px !important;
      margin-right: 10px !important; } }
  .plyr--video .plyr__volume input {
    display: none; }

.glightbox-testimonial-image-popup {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden; }
  .glightbox-testimonial-image-popup .gslider {
    justify-content: flex-start;
    align-items: flex-start; }
    .glightbox-testimonial-image-popup .gslider .gslide-inline {
      height: 100vh !important;
      width: 100vw !important;
      max-height: none;
      overflow: hidden;
      background-color: #FFFFFF; }
      .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content {
        height: 100% !important;
        width: 100% !important;
        max-width: none !important;
        padding-left: 0 !important;
        padding-top: 96px !important;
        padding-bottom: 37px !important;
        overflow: hidden; }
        @media only screen and (max-width: 500px) {
          .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content {
            padding-left: 20px !important;
            padding-right: 20px !important;
            margin-top: 50px !important;
            padding-top: 0px !important;
            margin-bottom: 50px !important;
            padding-bottom: 0px !important;
            overflow-y: scroll;
            height: auto !important; } }
        .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%; }
          @media only screen and (max-width: 500px) {
            .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper {
              height: auto; } }
          .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .row1 {
            display: flex;
            flex-wrap: wrap;
            height: 90%;
            overflow: hidden; }
            @media only screen and (max-width: 500px) {
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .row1 {
                flex-direction: column;
                height: auto; } }
          .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
            width: 47.1%;
            height: 100%;
            overflow: hidden; }
            @media only screen and (max-width: 500px) {
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper {
                width: 100%;
                height: auto; } }
            .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
              height: 0;
              width: 100%;
              background-size: cover !important;
              background-repeat: no-repeat !important;
              padding-top: 117%; }
              @media only screen and (min-width: 1700px) {
                .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
                  padding-top: 80%; } }
              @media only screen and (max-width: 500px) {
                .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .image-wrapper .bg-img {
                  padding-top: 90%; } }
          .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex: 1;
            width: 52.1%;
            padding: 0 10% 0 4%;
            height: 100%;
            overflow-y: scroll; }
            @media only screen and (max-width: 1090px) {
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                padding: 0 5% 0 4%; } }
            @media only screen and (max-height: 800px) {
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                justify-content: flex-start; } }
            @media only screen and (max-width: 500px) {
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper {
                width: 100%;
                padding: 20px 0px 0px 0px;
                height: auto;
                overflow-y: auto; } }
            .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .emp-title-wrapper {
              display: flex;
              margin-bottom: 47px; }
              @media only screen and (max-width: 1090px) {
                .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .emp-title-wrapper {
                  margin-bottom: 20px; } }
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .emp-title-wrapper .title {
                margin-right: 18px; }
                @media only screen and (max-width: 500px) {
                  .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .emp-title-wrapper .title {
                    width: 55%; } }
              @media only screen and (max-width: 500px) {
                .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .emp-title-wrapper .date {
                  width: 45%; } }
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .emp-title-wrapper p {
                margin-bottom: 4px;
                font-family: "Cairo", sans-serif;
                font-size: 14px;
                line-height: 18.2px;
                letter-spacing: 0.5px;
                color: #373433;
                font-weight: 400;
                text-transform: uppercase; }
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .emp-title-wrapper h6 {
                font-family: "Cairo", sans-serif;
                font-size: 1.25rem;
                line-height: 1.5;
                letter-spacing: 0.5px;
                color: #373433;
                font-weight: 700;
                max-width: 257px; }
            .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper h3 {
              font-family: "Cairo", sans-serif;
              font-size: 3.25rem;
              line-height: 1.2;
              font-weight: 600;
              color: #373433;
              margin-bottom: 28px; }
              @media only screen and (max-width: 1090px) {
                .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper h3 {
                  font-size: 2.375rem;
                  margin-bottom: 20px; } }
            .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .content {
              font-family: "Cairo", sans-serif;
              font-size: 1.375rem;
              line-height: 1.5;
              font-weight: 300;
              color: #373433;
              margin-bottom: 30px; }
            .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .speaker-wrapper {
              display: flex;
              flex-wrap: wrap; }
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .speaker-wrapper .speaker-card {
                width: 50%;
                padding-right: 15px; }
                .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .speaker-wrapper .speaker-card p {
                  margin-bottom: 16px;
                  font-family: "Cairo", sans-serif;
                  font-size: 0.875rem;
                  line-height: 1.3;
                  letter-spacing: 00.5px;
                  color: #373433;
                  font-weight: 400;
                  text-transform: uppercase; }
                .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .speaker-wrapper .speaker-card img {
                  height: 55px;
                  width: 55px;
                  object-fit: cover; }
                .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .speaker-wrapper .speaker-card .content-wrap {
                  margin-top: 9px; }
                  .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .speaker-wrapper .speaker-card .content-wrap h5 {
                    font-family: "Cairo", sans-serif;
                    font-size: 1rem;
                    line-height: 1.5;
                    font-weight: 700; }
                  .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .content-wrapper .speaker-wrapper .speaker-card .content-wrap .designation {
                    font-family: "Cairo", sans-serif;
                    font-size: 1rem;
                    line-height: 1.5;
                    font-weight: 400;
                    text-transform: none;
                    max-width: 247px; }
          .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
            padding: 0 40px;
            display: flex;
            height: 10%;
            margin-top: 61px;
            width: 80%; }
            @media only screen and (max-width: 500px) {
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer {
                margin-top: 30px;
                padding: 0px;
                width: 100%;
                height: auto; } }
            .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number {
              font-display: "Cairo", sans-serif;
              font-size: 1.125rem;
              line-height: 1.2;
              font-weight: 400;
              color: #373433;
              margin-right: 66px; }
              .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number span {
                font-weight: 700; }
              @media only screen and (max-width: 500px) {
                .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer .number {
                  margin-right: 15px; } }
            .glightbox-testimonial-image-popup .gslider .gslide-inline .ginlined-content .g--about-wrapper .popup-footer h4 {
              font-family: "Cairo", sans-serif;
              font-size: 1.625rem;
              line-height: 1.2;
              font-weight: 400;
              color: #373433; }
  .glightbox-testimonial-image-popup button {
    min-width: none !important; }
  .glightbox-testimonial-image-popup .gclose {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none; }
    @media only screen and (max-width: 500px) {
      .glightbox-testimonial-image-popup .gclose {
        height: 30px;
        width: 30px;
        top: 10px;
        right: 20px; }
        .glightbox-testimonial-image-popup .gclose svg {
          width: 14px; } }
  .glightbox-testimonial-image-popup .gprev,
  .glightbox-testimonial-image-popup .gnext {
    position: absolute;
    bottom: 50px;
    background-color: transparent;
    min-width: none; }
    .glightbox-testimonial-image-popup .gprev svg,
    .glightbox-testimonial-image-popup .gnext svg {
      display: none; }
    @media only screen and (max-width: 500px) {
      .glightbox-testimonial-image-popup .gprev,
      .glightbox-testimonial-image-popup .gnext {
        bottom: 0px; } }
  .glightbox-testimonial-image-popup .gprev {
    right: 122px; }
    @media only screen and (max-width: 500px) {
      .glightbox-testimonial-image-popup .gprev {
        right: 100px; } }
    .glightbox-testimonial-image-popup .gprev:after {
      content: "\E907";
      font-size: 0.875rem;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433; }
  .glightbox-testimonial-image-popup .gnext {
    right: 46px; }
    @media only screen and (max-width: 500px) {
      .glightbox-testimonial-image-popup .gnext {
        right: 20px; } }
    .glightbox-testimonial-image-popup .gnext:after {
      content: "\E908";
      font-size: 0.875rem;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433; }

.glightbox-testimonial-video-popup .gcontainer {
  height: 100vh !important;
  width: 100vw !important;
  padding-top: 95px !important;
  background-color: #FFFFFF;
  padding-bottom: 37px !important; }
  @media only screen and (max-width: 1090px) {
    .glightbox-testimonial-video-popup .gcontainer {
      padding-bottom: 70px !important; } }
  @media only screen and (max-width: 991px) {
    .glightbox-testimonial-video-popup .gcontainer {
      padding-bottom: 50px !important; } }
  @media only screen and (max-width: 500px) {
    .glightbox-testimonial-video-popup .gcontainer {
      padding-top: 50px !important; } }
  .glightbox-testimonial-video-popup .gcontainer .gslider {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100vw !important;
    max-height: none;
    padding-bottom: 0px !important;
    overflow-y: scroll; }
    @media only screen and (max-width: 1090px) {
      .glightbox-testimonial-video-popup .gcontainer .gslider .gslide {
        height: 100%;
        align-items: flex-start; } }
    @media only screen and (max-width: 991px) {
      .glightbox-testimonial-video-popup .gcontainer .gslider .gslide .gslide-inner-content {
        height: 100%; } }
    @media only screen and (max-width: 991px) {
      .glightbox-testimonial-video-popup .gcontainer .gslider .gslide .gslide-inner-content .ginner-container {
        height: auto; } }
  .glightbox-testimonial-video-popup .gcontainer .gslide-media {
    width: 78.5% !important;
    max-width: none !important;
    box-shadow: none !important; }
    @media only screen and (max-width: 1400px) {
      .glightbox-testimonial-video-popup .gcontainer .gslide-media {
        width: 76% !important; } }
    @media only screen and (max-height: 769px) {
      .glightbox-testimonial-video-popup .gcontainer .gslide-media {
        width: 70% !important; } }
    @media only screen and (min-width: 1600px) {
      .glightbox-testimonial-video-popup .gcontainer .gslide-media {
        width: 60% !important; } }
    @media screen and (min-width: 1440px) and (max-height: 800px) {
      .glightbox-testimonial-video-popup .gcontainer .gslide-media {
        width: 60% !important; } }
    @media only screen and (max-width: 991px) {
      .glightbox-testimonial-video-popup .gcontainer .gslide-media {
        width: 90% !important; } }
  .glightbox-testimonial-video-popup .gcontainer .gslide-description {
    max-width: none !important;
    width: 100%;
    margin-top: 30px; }
    @media only screen and (max-width: 991px) {
      .glightbox-testimonial-video-popup .gcontainer .gslide-description {
        position: relative !important;
        height: auto !important;
        bottom: auto !important;
        background: none !important;
        padding: 0 40px !important;
        flex: none !important;
        overflow: hidden !important; } }
    @media only screen and (max-width: 767px) {
      .glightbox-testimonial-video-popup .gcontainer .gslide-description {
        padding: 0 20px !important; } }
    .glightbox-testimonial-video-popup .gcontainer .gslide-description .gdesc-inner {
      display: flex; }
      .glightbox-testimonial-video-popup .gcontainer .gslide-description .gdesc-inner .gslide-title {
        margin-bottom: 0px;
        display: inline-block; }
      .glightbox-testimonial-video-popup .gcontainer .gslide-description .gdesc-inner .gslide-desc {
        display: inline-block;
        width: 100%; }
        @media only screen and (max-width: 991px) {
          .glightbox-testimonial-video-popup .gcontainer .gslide-description .gdesc-inner .gslide-desc {
            padding: 0 !important;
            width: 100%;
            display: block; } }
  .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper {
    display: flex;
    width: 78.5% !important;
    max-width: none !important;
    box-shadow: none !important;
    margin: auto; }
    @media only screen and (max-width: 1400px) {
      .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper {
        width: 76% !important; } }
    @media only screen and (min-width: 1600px) {
      .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper {
        width: 60% !important; } }
    @media screen and (min-width: 1440px) and (max-height: 800px) {
      .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper {
        width: 60% !important; } }
    @media only screen and (max-width: 991px) {
      .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper {
        width: 100% !important;
        flex-direction: column; } }
    .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .emp-title-wrapper {
      display: flex;
      margin-bottom: 47px; }
      @media only screen and (max-width: 1090px) {
        .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .emp-title-wrapper {
          margin-bottom: 20px; } }
      .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .emp-title-wrapper .title {
        margin-right: 37px;
        max-width: 343px; }
        @media only screen and (max-width: 500px) {
          .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .emp-title-wrapper .title {
            width: 55%; } }
      .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .emp-title-wrapper .date {
        max-width: 257px;
        margin-right: 37px; }
        @media only screen and (max-width: 500px) {
          .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .emp-title-wrapper .date {
            width: 45%; } }
      .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .emp-title-wrapper p {
        margin-bottom: 4px;
        font-family: "Cairo", sans-serif;
        font-size: 14px;
        line-height: 18.2px;
        letter-spacing: 0.5px;
        color: #373433;
        font-weight: 400;
        text-transform: uppercase; }
      .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .emp-title-wrapper h6 {
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.5;
        letter-spacing: 0.5px;
        color: #373433;
        font-weight: 700; }
    .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .speaker-wrapper {
      display: flex;
      flex-direction: column; }
      .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .speaker-wrapper p {
        margin-bottom: 16px;
        font-family: "Cairo", sans-serif;
        font-size: 0.875rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #373433;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 16px; }
      .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .speaker-wrapper .speaker-card {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 16px; }
        .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .speaker-wrapper .speaker-card img {
          height: 55px;
          width: 55px;
          object-fit: cover; }
        .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .speaker-wrapper .speaker-card .content-wrap {
          margin-left: 27px; }
          .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .speaker-wrapper .speaker-card .content-wrap h5 {
            font-family: "Cairo", sans-serif;
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 700;
            color: #373433; }
          .glightbox-testimonial-video-popup .gcontainer .event-description-wrapper .speaker-wrapper .speaker-card .content-wrap .designation {
            font-family: "Cairo", sans-serif;
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 400;
            text-transform: none;
            color: #373433; }
  .glightbox-testimonial-video-popup .gcontainer .popup-footer {
    padding: 0 40px;
    display: flex;
    height: 10%;
    margin-top: 30px;
    width: 80%; }
    @media only screen and (max-width: 991px) {
      .glightbox-testimonial-video-popup .gcontainer .popup-footer {
        padding: 0px; } }
    @media only screen and (max-width: 500px) {
      .glightbox-testimonial-video-popup .gcontainer .popup-footer {
        margin-top: 30px;
        width: 100%;
        height: auto; } }
    .glightbox-testimonial-video-popup .gcontainer .popup-footer .number {
      font-display: "Cairo", sans-serif;
      font-size: 1.125rem;
      line-height: 1.2;
      font-weight: 400;
      color: #373433;
      margin-right: 66px; }
      .glightbox-testimonial-video-popup .gcontainer .popup-footer .number span {
        font-weight: 700; }
      @media only screen and (max-width: 500px) {
        .glightbox-testimonial-video-popup .gcontainer .popup-footer .number {
          margin-right: 15px; } }
    .glightbox-testimonial-video-popup .gcontainer .popup-footer h4 {
      font-family: "Cairo", sans-serif;
      font-size: 1.625rem;
      line-height: 1.2;
      font-weight: 400;
      color: #373433; }
  .glightbox-testimonial-video-popup .gcontainer .gclose {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none; }
    @media only screen and (max-width: 500px) {
      .glightbox-testimonial-video-popup .gcontainer .gclose {
        height: 30px;
        width: 30px;
        top: 10px;
        right: 20px; }
        .glightbox-testimonial-video-popup .gcontainer .gclose svg {
          width: 14px; } }
  .glightbox-testimonial-video-popup .gcontainer .gprev,
  .glightbox-testimonial-video-popup .gcontainer .gnext {
    position: absolute !important;
    bottom: 50px !important;
    top: auto;
    background-color: transparent !important;
    min-width: none; }
    @media only screen and (max-width: 1090px) {
      .glightbox-testimonial-video-popup .gcontainer .gprev,
      .glightbox-testimonial-video-popup .gcontainer .gnext {
        bottom: 20px !important; } }
    @media only screen and (max-width: 991px) {
      .glightbox-testimonial-video-popup .gcontainer .gprev,
      .glightbox-testimonial-video-popup .gcontainer .gnext {
        bottom: 0px !important; } }
    .glightbox-testimonial-video-popup .gcontainer .gprev svg,
    .glightbox-testimonial-video-popup .gcontainer .gnext svg {
      display: none !important; }
    .glightbox-testimonial-video-popup .gcontainer .gprev.disabled,
    .glightbox-testimonial-video-popup .gcontainer .gnext.disabled {
      opacity: 0.5 !important; }
  .glightbox-testimonial-video-popup .gcontainer .gprev {
    right: 122px !important;
    left: auto !important; }
    @media only screen and (max-width: 767px) {
      .glightbox-testimonial-video-popup .gcontainer .gprev {
        left: 20px !important;
        right: auto !important; } }
    .glightbox-testimonial-video-popup .gcontainer .gprev:after {
      content: "\E907" !important;
      font-size: 0.875rem !important;
      font-family: icomoon !important;
      font-style: normal !important;
      font-variant: normal;
      text-transform: none;
      color: #373433 !important; }
  .glightbox-testimonial-video-popup .gcontainer .gnext {
    right: 46px !important;
    left: auto !important; }
    @media only screen and (max-width: 767px) {
      .glightbox-testimonial-video-popup .gcontainer .gnext {
        right: 20px !important; } }
    .glightbox-testimonial-video-popup .gcontainer .gnext:after {
      content: "\E908" !important;
      font-size: 0.875rem !important;
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      color: #373433 !important; }

#whistle-blowing {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;
  position: fixed;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100vw);
  transition: 0.6s all ease;
  overflow: hidden; }
  #whistle-blowing.in-view {
    transform: translateX(0);
    z-index: 1000 !important; }
  #whistle-blowing .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 !important; }
    #whistle-blowing .wrapper .row {
      margin-left: 0px;
      margin-right: 0px;
      height: 100vh; }
    #whistle-blowing .wrapper .image-wrapper {
      width: 30%;
      height: 100vh;
      overflow: hidden;
      position: relative; }
      @media only screen and (max-width: 767px) {
        #whistle-blowing .wrapper .image-wrapper {
          display: none; } }
      #whistle-blowing .wrapper .image-wrapper .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; }
    #whistle-blowing .wrapper .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      width: 70%;
      padding: 0 10% 0 4%;
      height: 100%;
      justify-content: flex-start;
      padding-top: 100px;
      padding-bottom: 100px;
      overflow-y: scroll; }
      @media only screen and (max-width: 1090px) {
        #whistle-blowing .wrapper .content-wrapper {
          padding-right: 100px;
          padding-left: 40px; } }
      @media only screen and (max-width: 991px) {
        #whistle-blowing .wrapper .content-wrapper {
          padding-left: 40px;
          padding-right: 40px; } }
      @media only screen and (max-width: 767px) {
        #whistle-blowing .wrapper .content-wrapper {
          width: 100%;
          padding: 50px 20px; } }
      #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info h3 {
        font-size: 4.0625rem;
        line-height: 1;
        font-weight: 600;
        color: #373433;
        margin-bottom: 20px; }
      #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph {
        margin-bottom: 50px; }
        @media only screen and (max-width: 700px) {
          #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph {
            width: 100%; } }
        @media screen and (min-width: 1440px) and (max-height: 800px) {
          #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph {
            margin-bottom: 20px; } }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph p {
          font-size: 1rem;
          line-height: 1.5;
          font-family: "Cairo", sans-serif;
          color: #373433;
          max-width: 670px;
          font-weight: 300; }
          #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph p a {
            text-decoration: underline;
            font-weight: 600;
            color: #373433; }
          @media only screen and (max-width: 767px) {
            #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph p {
              font-size: 16px;
              line-height: 24px; } }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph .input {
          padding: 20px; }
      #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .input-box.first_name,
      #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .input-box.msg {
        width: 100%;
        padding-right: 0px; }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .input-box.first_name .abs_label,
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .input-box.msg .abs_label {
          color: rgba(55, 52, 51, 0.5); }
      #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .msg {
        height: 124px; }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .msg .input {
          height: 100%; }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .msg textarea {
          font-size: 1.25rem;
          line-height: 1.3;
          font-weight: 400; }
          #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .msg textarea::placeholder {
            color: rgba(55, 52, 51, 0.5); }
      #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form {
        width: 100%;
        display: flex;
        justify-content: space-between; }
        @media only screen and (max-width: 1090px) {
          #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form {
            flex-direction: column; } }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.phone,
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.email {
          width: 48%;
          padding-right: 0px; }
          @media only screen and (max-width: 1090px) {
            #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.phone,
            #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.email {
              width: 100%; } }
          #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.phone input::placeholder,
          #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.email input::placeholder {
            color: rgba(55, 52, 51, 0.5); }
      #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 {
        display: flex;
        width: 100%;
        margin-top: 20px;
        align-items: center; }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 .icon-cancel:before {
          padding: 10px;
          background: #da3d3d;
          border-radius: 50%;
          color: white; }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 p {
          margin: 0px;
          margin-left: 25px;
          margin-right: 5px; }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 button {
          border: 1px solid #373433;
          width: 165px;
          height: 60px;
          border-radius: 15px; }
          #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 button a {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 700; }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 button:hover {
          background-color: transparent !important;
          border-color: #373433 !important;
          box-shadow: none;
          outline: none; }
        #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 button:focus-visible {
          outline: none; }
      #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form2 {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px; }
        @media only screen and (max-width: 700px) {
          #whistle-blowing .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form2 {
            justify-content: center; } }
      #whistle-blowing .wrapper .content-wrapper h4 {
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.5;
        color: #373433;
        font-weight: 700;
        margin-bottom: 4px;
        margin-top: 0; }
      #whistle-blowing .wrapper .content-wrapper .designation {
        font-family: "Cairo", sans-serif;
        font-size: 0.875rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #373433;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 30px; }
      #whistle-blowing .wrapper .content-wrapper .content {
        font-family: "Cairo", sans-serif;
        font-size: 1.375rem;
        line-height: 1.5;
        color: #373433;
        font-weight: 300; }
      #whistle-blowing .wrapper .content-wrapper .declaration .flex {
        display: flex;
        flex-wrap: nowrap; }
        #whistle-blowing .wrapper .content-wrapper .declaration .flex .in {
          position: relative; }
        #whistle-blowing .wrapper .content-wrapper .declaration .flex input[type="checkbox"] {
          opacity: 0;
          position: absolute;
          height: 24px;
          width: 24px;
          z-index: 1; }
        #whistle-blowing .wrapper .content-wrapper .declaration .flex label {
          height: 24px;
          width: 24px;
          border-radius: 8px;
          border: 1px solid rgba(55, 52, 51, 0.2);
          position: relative;
          cursor: pointer; }
          #whistle-blowing .wrapper .content-wrapper .declaration .flex label:after {
            position: absolute;
            top: -6px;
            right: -4px;
            content: "\E906";
            font-family: "icomoon";
            font-size: 24px;
            color: #007473;
            opacity: 0; }
        #whistle-blowing .wrapper .content-wrapper .declaration .flex input[type="checkbox"]:checked ~ label:after {
          opacity: 1; }
        #whistle-blowing .wrapper .content-wrapper .declaration .flex p {
          margin: 0;
          padding-left: 19px;
          max-width: 495px; }
  #whistle-blowing .p-close {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      #whistle-blowing .p-close {
        right: 20px;
        top: 20px; } }
    #whistle-blowing .p-close:after {
      position: absolute;
      font-family: "icomoon" !important;
      speak: never;
      content: "\E905";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

#apply_now {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;
  position: fixed;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100vw);
  transition: 0.6s all ease;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    #apply_now {
      overflow: scroll; } }
  #apply_now.in-view {
    transform: translateX(0);
    z-index: 1000 !important; }
  #apply_now .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0 !important; }
    @media only screen and (max-width: 767px) {
      #apply_now .wrapper {
        height: auto;
        overflow: scroll; } }
    #apply_now .wrapper .row {
      margin-left: 0px;
      margin-right: 0px;
      height: 100%; }
    #apply_now .wrapper .image-wrapper {
      width: 30%;
      height: 100%;
      overflow: hidden;
      position: relative; }
      @media only screen and (max-width: 767px) {
        #apply_now .wrapper .image-wrapper {
          display: none; } }
      #apply_now .wrapper .image-wrapper .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; }
    #apply_now .wrapper .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      width: 70%;
      padding: 0 10% 0 4%;
      height: 100%;
      justify-content: flex-start;
      padding-top: 100px;
      padding-bottom: 100px;
      overflow-y: scroll; }
      @media only screen and (max-width: 1090px) {
        #apply_now .wrapper .content-wrapper {
          padding-right: 100px;
          padding-left: 40px; } }
      @media only screen and (max-width: 991px) {
        #apply_now .wrapper .content-wrapper {
          padding-left: 40px;
          padding-right: 40px; } }
      @media only screen and (max-width: 767px) {
        #apply_now .wrapper .content-wrapper {
          width: 100%;
          padding: 50px 20px 50px 20px;
          height: auto; } }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info h3 {
        font-size: 4.0625rem;
        line-height: 1;
        font-weight: 600;
        color: #373433;
        margin-bottom: 20px; }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph {
        margin-bottom: 50px;
        max-width: 600px; }
        @media only screen and (max-width: 700px) {
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph {
            width: 100%; } }
        @media screen and (min-width: 1440px) and (max-height: 800px) {
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph {
            margin-bottom: 20px; } }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph p {
          font-size: 1rem;
          line-height: 1.5;
          font-family: "Cairo", sans-serif;
          color: #373433;
          max-width: 670px;
          font-weight: 300; }
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph p a {
            text-decoration: underline;
            font-weight: 600;
            color: #373433; }
          @media only screen and (max-width: 767px) {
            #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .paragraph p {
              font-size: 16px;
              line-height: 24px; } }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .input-box.first_name,
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .input-box.msg {
        width: 100%;
        padding-right: 0px; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .input-box.first_name .abs_label,
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .input-box.msg .abs_label {
          color: rgba(55, 52, 51, 0.5); }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .msg {
        height: auto; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .msg .input {
          height: 100%;
          padding: 20px !important; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .msg textarea {
          font-size: 1.25rem;
          line-height: 1.3;
          font-weight: 400;
          height: 124px !important; }
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .msg textarea::placeholder {
            color: rgba(55, 52, 51, 0.5); }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .msg .input:hover,
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .msg textarea:hover {
          background-color: transparent !important;
          box-shadow: none;
          border: 1px solid #007473;
          outline: none;
          border-color: #007473 !important; }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 3; }
        @media only screen and (max-width: 1090px) {
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form {
            flex-direction: column; } }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.phone,
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.email {
          width: 48%;
          padding-right: 0px; }
          @media only screen and (max-width: 1090px) {
            #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.phone,
            #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.email {
              width: 100%; } }
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.phone .abs_label,
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form .input-box.email .abs_label {
            color: rgba(55, 52, 51, 0.5); }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 {
        display: flex;
        width: 100%;
        margin-top: 20px;
        align-items: center; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 .disable {
          display: none; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 .enable {
          display: block; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 .file-upload-name {
          margin-left: 20px;
          border: none;
          pointer-events: none; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 .icon-cancel:before {
          padding: 10px;
          background: #da3d3d;
          border-radius: 50%;
          color: white;
          margin-left: 10px; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 p {
          margin: 0px;
          margin-left: 25px;
          margin-right: 5px; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 button {
          border: 1px solid #373433;
          width: 165px;
          height: 60px;
          border-radius: 15px;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 700; }
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 button a {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 700; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 button:hover {
          background-color: transparent !important;
          border-color: #373433 !important;
          box-shadow: none;
          outline: none; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form1 button:focus-visible {
          outline: none; }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form2 {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px; }
        @media only screen and (max-width: 700px) {
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form2 {
            justify-content: center; } }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info .inline-form2 .submit-btn {
          text-transform: uppercase; }
      #apply_now .wrapper .content-wrapper h4 {
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.5;
        color: #373433;
        font-weight: 700;
        margin-bottom: 4px;
        margin-top: 0; }
      #apply_now .wrapper .content-wrapper .designation {
        font-family: "Cairo", sans-serif;
        font-size: 0.875rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #373433;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 30px; }
      #apply_now .wrapper .content-wrapper .content {
        font-family: "Cairo", sans-serif;
        font-size: 1.375rem;
        line-height: 1.5;
        color: #373433;
        font-weight: 300; }
      #apply_now .wrapper .content-wrapper .declaration .flex {
        display: flex;
        flex-wrap: nowrap; }
        #apply_now .wrapper .content-wrapper .declaration .flex .in {
          position: relative; }
        #apply_now .wrapper .content-wrapper .declaration .flex input[type="checkbox"] {
          opacity: 0;
          position: absolute;
          height: 24px;
          width: 24px;
          z-index: 1; }
        #apply_now .wrapper .content-wrapper .declaration .flex label {
          height: 24px;
          width: 24px;
          border-radius: 8px;
          border: 1px solid rgba(55, 52, 51, 0.2);
          position: relative;
          cursor: pointer; }
          #apply_now .wrapper .content-wrapper .declaration .flex label:after {
            position: absolute;
            top: -6px;
            right: -4px;
            content: "\E906";
            font-family: "icomoon";
            font-size: 20px;
            color: #007473;
            opacity: 0; }
        #apply_now .wrapper .content-wrapper .declaration .flex input[type="checkbox"]:checked ~ label:after {
          opacity: 1; }
        #apply_now .wrapper .content-wrapper .declaration .flex p {
          margin: 0;
          padding-left: 19px;
          max-width: 495px; }
  #apply_now .a-close {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      #apply_now .a-close {
        right: 20px; } }
    #apply_now .a-close:after {
      position: absolute;
      font-family: "icomoon" !important;
      speak: never;
      content: "\E905";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

.search_pop-up-white {
  height: 100vh;
  background-color: #FFFFFF;
  z-index: -4;
  transform: scale(0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.2s ease;
  opacity: 0; }
  .search_pop-up-white .search-container {
    height: 100%;
    align-items: center; }
    .search_pop-up-white .search-container .search {
      position: relative; }
      .search_pop-up-white .search-container .search input {
        border-bottom: 1px solid rgba(55, 52, 51, 0.3) !important;
        border: 0px;
        color: rgba(55, 52, 51, 0.5);
        width: 100%; }
        .search_pop-up-white .search-container .search input::placeholder {
          color: rgba(55, 52, 51, 0.5); }
      .search_pop-up-white .search-container .search .cancel-search {
        position: absolute;
        bottom: 20px;
        right: 0px;
        height: 93px;
        width: 93px;
        border-radius: 100%;
        z-index: 99999;
        background-color: #007473; }
        @media only screen and (max-width: 991px) {
          .search_pop-up-white .search-container .search .cancel-search {
            height: 70px;
            width: 70px; } }
        @media only screen and (max-width: 767px) {
          .search_pop-up-white .search-container .search .cancel-search {
            position: fixed;
            top: 120px;
            right: 20px !important; } }
        @media only screen and (max-width: 539px) {
          .search_pop-up-white .search-container .search .cancel-search {
            height: 60px;
            width: 60px; } }
        .search_pop-up-white .search-container .search .cancel-search:after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: icomoon !important;
          color: #FFFFFF;
          content: "\E905";
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          font-variant: normal;
          text-transform: none;
          display: inline;
          vertical-align: middle; }
          @media only screen and (max-width: 539px) {
            .search_pop-up-white .search-container .search .cancel-search:after {
              font-size: 16px; } }
  .search_pop-up-white .cancel-search {
    position: absolute;
    bottom: -20px;
    right: 40px;
    height: 111px;
    width: 111px;
    border-radius: 100%;
    z-index: 99999;
    background-color: #007473; }
    @media only screen and (max-width: 991px) {
      .search_pop-up-white .cancel-search {
        bottom: 0px; } }
    @media only screen and (max-width: 767px) {
      .search_pop-up-white .cancel-search {
        height: 70px !important;
        width: 70px !important;
        bottom: 90px !important;
        right: 0px !important; } }
    .search_pop-up-white .cancel-search:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: icomoon !important;
      color: #FFFFFF;
      content: "\E905";
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      font-variant: normal;
      text-transform: none;
      display: inline;
      vertical-align: middle; }
  .search_pop-up-white .search-container {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative; }
    .search_pop-up-white .search-container .search {
      width: 100%;
      display: flex; }
      .search_pop-up-white .search-container .search input {
        font-size: 2.625rem;
        line-height: 1.10095;
        padding-bottom: 18px;
        background-color: transparent; }
        @media only screen and (max-width: 767px) {
          .search_pop-up-white .search-container .search input {
            font-size: 24px; } }
      .search_pop-up-white .search-container .search button {
        background-color: transparent;
        border: none;
        transform: translateX(-20px);
        transform: translateX(0px);
        margin-left: 25px; }
        .search_pop-up-white .search-container .search button .icomoon:before {
          color: #FFFFFF;
          font-size: 1.25rem;
          font-weight: 300; }
          @media only screen and (max-width: 767px) {
            .search_pop-up-white .search-container .search button .icomoon:before {
              font-size: 20px; } }
  .search_pop-up-white.enable-pop-up {
    z-index: 10;
    opacity: 1;
    transform: scale(1); }

#change-email-popup {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(55, 52, 51, 0.3);
  backdrop-filter: blur(40px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100vw);
  transition: 0.6s all ease;
  overflow: hidden; }
  #change-email-popup.in-view {
    transform: translateX(0);
    z-index: 1000 !important; }
  #change-email-popup .wrapper {
    padding: 53px 60px 53px 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    width: auto;
    height: auto;
    background-color: #fff;
    position: relative; }
    @media only screen and (max-width: 767px) {
      #change-email-popup .wrapper {
        padding: 40px;
        margin-left: 20px;
        margin-right: 20px; } }
    @media only screen and (max-width: 500px) {
      #change-email-popup .wrapper {
        padding: 20px; } }
    #change-email-popup .wrapper .inner-box {
      max-width: 395px; }
    #change-email-popup .wrapper h4 {
      font-family: "Cairo", sans-serif;
      font-size: 2.25rem;
      line-height: 1.10111;
      font-weight: 700;
      color: #000000;
      margin-bottom: 14px; }
      @media only screen and (max-width: 767px) {
        #change-email-popup .wrapper h4 {
          padding-top: 40px; } }
    #change-email-popup .wrapper p {
      font-family: "Cairo", sans-serif;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 50px;
      max-width: 355px;
      margin-left: auto;
      margin-right: auto; }
      @media only screen and (max-width: 767px) {
        #change-email-popup .wrapper p {
          font-size: 16px; } }
    #change-email-popup .wrapper .input-box {
      width: 100%;
      padding-right: 0px; }
      #change-email-popup .wrapper .input-box:last-child {
        margin-bottom: 0px; }
    #change-email-popup .wrapper .buttonwrap {
      margin-top: 35px;
      display: flex; }
      #change-email-popup .wrapper .buttonwrap button {
        width: 50%;
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: 0.1px;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        min-width: auto !important;
        border-radius: 15px; }
      #change-email-popup .wrapper .buttonwrap .green {
        background-color: #007473;
        color: #FFFFFF;
        border: none;
        margin-right: 6px; }
      #change-email-popup .wrapper .buttonwrap .white {
        border: 1px solid #007473;
        background-color: transparent;
        color: #007473;
        margin-left: 6px; }
    #change-email-popup .wrapper .a-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 35px;
      height: 35px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      background-color: #FFFFFF;
      min-width: none;
      cursor: pointer; }
      @media only screen and (max-width: 767px) {
        #change-email-popup .wrapper .a-close {
          right: 20px; } }
      #change-email-popup .wrapper .a-close:after {
        position: absolute;
        font-family: "icomoon" !important;
        speak: never;
        content: "\E905";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  #change-email-popup .optbox {
    display: none; }
    #change-email-popup .optbox .input-container {
      margin-bottom: 30px; }
      #change-email-popup .optbox .input-container input {
        border: 1px solid rgba(127, 129, 132, 0.1);
        background-color: rgba(127, 129, 132, 0.04);
        font-size: 1.25rem;
        line-height: 1.25;
        font-family: "Cairo", sans-serif;
        font-weight: 400;
        color: #373433;
        margin-bottom: 13px;
        max-width: 395px;
        pointer-events: none; }
        #change-email-popup .optbox .input-container input::placeholder {
          color: #373433; }
      #change-email-popup .optbox .input-container span {
        font-size: 0.875rem;
        line-height: 1.4;
        letter-spacing: 0.1px;
        font-weight: 700;
        color: #007473;
        text-transform: uppercase; }
    #change-email-popup .optbox .otp-input .span {
      font-size: 0.75rem;
      line-height: 1.66667;
      letter-spacing: 0.1px;
      font-weight: 400;
      color: #7F8184;
      margin-bottom: 10px; }
    #change-email-popup .optbox .otp-input .number-inputs {
      border: 1px solid rgba(55, 52, 51, 0.1);
      border-radius: 6px;
      background-color: transparent;
      padding: 18px;
      display: flex; }
    #change-email-popup .optbox .otp-input input {
      width: 25%;
      margin-right: 9px;
      border: 1.14px solid #B2B4CC;
      font-size: 2.25rem;
      line-height: 1;
      font-weight: 700;
      color: #373433;
      border-radius: 6px;
      text-align: center; }
      #change-email-popup .optbox .otp-input input:focus, #change-email-popup .optbox .otp-input input:hover {
        border-color: #007473;
        outline: none;
        background-color: transparent; }
      #change-email-popup .optbox .otp-input input:last-child {
        margin-right: 0px; }
    #change-email-popup .optbox .otp-input input::-webkit-outer-spin-button,
    #change-email-popup .optbox .otp-input input::-webkit-inner-spin-button {
      -webkit-appearance: none; }
    #change-email-popup .optbox .otp-input input[type=number] {
      -moz-appearance: textfield; }
    #change-email-popup .optbox .buttonwrap button {
      width: auto;
      min-width: 125px;
      max-width: 253px;
      padding: 18px 33px 18px 33px; }

#change-phone-popup {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(55, 52, 51, 0.3);
  backdrop-filter: blur(40px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100vw);
  transition: 0.6s all ease;
  overflow: hidden; }
  #change-phone-popup.in-view {
    transform: translateX(0);
    z-index: 1000 !important; }
  #change-phone-popup .wrapper {
    padding: 53px 60px 53px 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    position: relative; }
    #change-phone-popup .wrapper .inner-box {
      max-width: 395px; }
    @media only screen and (max-width: 767px) {
      #change-phone-popup .wrapper {
        padding: 40px;
        margin-left: 20px;
        margin-right: 20px; } }
    @media only screen and (max-width: 500px) {
      #change-phone-popup .wrapper {
        padding: 20px;
        padding-top: 60px !important; } }
    #change-phone-popup .wrapper h4 {
      font-family: "Cairo", sans-serif;
      font-size: 2.25rem;
      line-height: 1.10111;
      font-weight: 700;
      color: #000000;
      margin-bottom: 14px; }
    #change-phone-popup .wrapper p {
      font-family: "Cairo", sans-serif;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 50px;
      max-width: 369px;
      margin-left: auto;
      margin-right: auto; }
      @media only screen and (max-width: 767px) {
        #change-phone-popup .wrapper p {
          font-size: 16px; } }
    #change-phone-popup .wrapper .input-box {
      width: 100%;
      padding-right: 0px; }
      #change-phone-popup .wrapper .input-box:last-child {
        margin-bottom: 0px; }
    #change-phone-popup .wrapper .buttonwrap {
      margin-top: 35px;
      display: flex; }
      #change-phone-popup .wrapper .buttonwrap button {
        width: 50%;
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: 0.1px;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        min-width: auto !important;
        border-radius: 15px; }
        @media only screen and (max-width: 500px) {
          #change-phone-popup .wrapper .buttonwrap button {
            padding: 20px !important; } }
      #change-phone-popup .wrapper .buttonwrap .green {
        background-color: #007473;
        color: #FFFFFF;
        border: none;
        margin-right: 6px;
        opacity: 1 !important; }
        #change-phone-popup .wrapper .buttonwrap .green.disabled {
          opacity: 0.5 !important;
          cursor: not-allowed; }
      #change-phone-popup .wrapper .buttonwrap .white {
        border: 1px solid #007473;
        background-color: transparent;
        color: #007473;
        margin-left: 6px; }
    #change-phone-popup .wrapper .a-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 35px;
      height: 35px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      background-color: #FFFFFF;
      min-width: none;
      cursor: pointer; }
      @media only screen and (max-width: 767px) {
        #change-phone-popup .wrapper .a-close {
          right: 20px; } }
      #change-phone-popup .wrapper .a-close:after {
        position: absolute;
        font-family: "icomoon" !important;
        speak: never;
        content: "\E905";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  #change-phone-popup .optbox {
    display: none; }
    #change-phone-popup .optbox .input-container {
      margin-bottom: 30px; }
      #change-phone-popup .optbox .input-container input {
        border: 1px solid rgba(127, 129, 132, 0.1);
        background-color: rgba(127, 129, 132, 0.04);
        font-size: 1.25rem;
        line-height: 1.25;
        font-family: "Cairo", sans-serif;
        font-weight: 400;
        color: #373433;
        margin-bottom: 13px;
        max-width: 395px;
        pointer-events: none; }
        #change-phone-popup .optbox .input-container input::placeholder {
          color: #373433; }
      #change-phone-popup .optbox .input-container span {
        font-size: 0.875rem;
        line-height: 1.4;
        letter-spacing: 0.1px;
        font-weight: 700;
        color: #007473;
        text-transform: uppercase; }
    #change-phone-popup .optbox .otp-input .span {
      font-size: 0.75rem;
      line-height: 1.66667;
      letter-spacing: 0.1px;
      font-weight: 400;
      color: #7F8184;
      margin-bottom: 10px; }
    #change-phone-popup .optbox .otp-input .number-inputs {
      border: 1px solid rgba(55, 52, 51, 0.1);
      border-radius: 6px;
      background-color: transparent;
      padding: 18px;
      display: flex; }
    #change-phone-popup .optbox .otp-input input {
      width: 25%;
      margin-right: 9px;
      border: 1.14px solid #B2B4CC;
      font-size: 2.25rem;
      line-height: 1;
      font-weight: 700;
      color: #373433;
      border-radius: 6px;
      text-align: center; }
      #change-phone-popup .optbox .otp-input input:focus, #change-phone-popup .optbox .otp-input input:hover {
        border-color: #007473;
        outline: none;
        background-color: transparent; }
      #change-phone-popup .optbox .otp-input input:last-child {
        margin-right: 0px; }
    #change-phone-popup .optbox .otp-input input::-webkit-outer-spin-button,
    #change-phone-popup .optbox .otp-input input::-webkit-inner-spin-button {
      -webkit-appearance: none; }
    #change-phone-popup .optbox .otp-input input[type=number] {
      -moz-appearance: textfield; }
    #change-phone-popup .optbox .buttonwrap button {
      width: auto;
      min-width: 125px;
      max-width: 253px;
      padding: 18px 33px 18px 33px; }
  #change-phone-popup .a-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 35px;
    height: 35px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      #change-phone-popup .a-close {
        right: 20px; } }
    #change-phone-popup .a-close:after {
      position: absolute;
      font-family: "icomoon" !important;
      speak: never;
      content: "\E905";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

#delete-acc {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(55, 52, 51, 0.3);
  backdrop-filter: blur(40px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100vw);
  transition: 0.6s all ease;
  overflow: hidden; }
  #delete-acc.in-view {
    transform: translateX(0);
    z-index: 1000 !important; }
  #delete-acc .inner-box {
    background-color: #fff;
    padding: 50px 36px;
    position: relative; }
    @media only screen and (max-width: 500px) {
      #delete-acc .inner-box {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 10px;
        margin-right: 10px; } }
    #delete-acc .inner-box h4 {
      font-family: "Cairo", sans-serif;
      font-size: 2.25rem;
      line-height: 1.10111;
      font-weight: 700;
      color: #000000;
      margin-bottom: 14px;
      text-align: center; }
    #delete-acc .inner-box p {
      font-family: "Cairo", sans-serif;
      text-align: center;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 50px;
      max-width: 355px;
      margin-left: auto;
      margin-right: auto; }
      @media only screen and (max-width: 767px) {
        #delete-acc .inner-box p {
          font-size: 16px; } }
      @media only screen and (max-width: 500px) {
        #delete-acc .inner-box p {
          max-width: 100%; } }
    #delete-acc .inner-box .buttonwrap {
      margin-top: 35px;
      display: flex; }
      #delete-acc .inner-box .buttonwrap button {
        width: 180px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: 0.1px;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        min-width: auto !important;
        border-radius: 15px; }
        @media only screen and (max-width: 500px) {
          #delete-acc .inner-box .buttonwrap button {
            padding: 18px !important;
            width: 50%; } }
      #delete-acc .inner-box .buttonwrap .green {
        background-color: #007473;
        color: #FFFFFF;
        border: none;
        margin-right: 6px;
        opacity: 1 !important; }
        #delete-acc .inner-box .buttonwrap .green.disabled {
          opacity: 0.5 !important;
          cursor: not-allowed; }
      #delete-acc .inner-box .buttonwrap .white {
        border: 1px solid #007473;
        background-color: transparent;
        color: #007473;
        margin-left: 6px; }
    #delete-acc .inner-box .d-close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 35px;
      height: 35px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      background-color: #FFFFFF;
      min-width: none;
      cursor: pointer; }
      @media only screen and (max-width: 767px) {
        #delete-acc .inner-box .d-close {
          right: 20px; } }
      #delete-acc .inner-box .d-close:after {
        position: absolute;
        font-family: "icomoon" !important;
        speak: never;
        content: "\E905";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }

.dashboard-footer {
  padding: 20px 56px;
  background-color: #007473;
  width: 100vw;
  position: relative;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .dashboard-footer {
      padding-left: 20px;
      padding-right: 20px; } }
  .dashboard-footer .canvas {
    position: absolute;
    right: 0;
    top: -100px;
    width: 500px; }
    @media only screen and (max-width: 767px) {
      .dashboard-footer .canvas {
        right: -50%;
        top: 30px; } }
  .dashboard-footer .flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 1; }
    .dashboard-footer .flex span {
      font-family: "Cairo", sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.3;
      letter-spacing: 0.5px;
      color: #FFFFFF; }
      @media only screen and (max-width: 767px) {
        .dashboard-footer .flex span {
          font-size: 14px; } }
    .dashboard-footer .flex .box-1 {
      display: flex;
      align-items: center;
      z-index: 1; }
      @media only screen and (max-width: 991px) {
        .dashboard-footer .flex .box-1 {
          width: 50%; } }
      @media only screen and (max-width: 767px) {
        .dashboard-footer .flex .box-1 {
          width: 100%; } }
    .dashboard-footer .flex .box-2 {
      display: flex;
      align-items: center;
      z-index: 1; }
      @media only screen and (max-width: 991px) {
        .dashboard-footer .flex .box-2 {
          width: 50%; } }
      @media only screen and (max-width: 767px) {
        .dashboard-footer .flex .box-2 {
          width: 100%;
          margin-top: 20px; } }
      .dashboard-footer .flex .box-2 a {
        font-family: "Cairo", sans-serif;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        text-transform: uppercase;
        margin-right: 30px;
        transition: 0.2s all ease; }
        @media only screen and (max-width: 767px) {
          .dashboard-footer .flex .box-2 a {
            font-size: 14px; } }
        .dashboard-footer .flex .box-2 a:last-child {
          margin-right: 0; }
        .dashboard-footer .flex .box-2 a:hover {
          opacity: 0.5; }
    .dashboard-footer .flex .box-3 {
      display: flex;
      align-items: center;
      z-index: 1; }
      @media only screen and (max-width: 991px) {
        .dashboard-footer .flex .box-3 {
          margin-top: 30px; } }
      @media only screen and (max-width: 767px) {
        .dashboard-footer .flex .box-3 {
          width: 100%;
          margin-top: 20px; } }
      .dashboard-footer .flex .box-3 span {
        margin-right: 10px; }

.main-homepage .container-fluid, .main-homepage .container-sm, .main-homepage .container-md, .main-homepage .container-lg, .main-homepage .container-xl {
  padding: 0 9% 0 3%; }
  @media only screen and (max-width: 991px) {
    .main-homepage .container-fluid, .main-homepage .container-sm, .main-homepage .container-md, .main-homepage .container-lg, .main-homepage .container-xl {
      padding-left: 40px;
      padding-right: 40px; } }
  @media only screen and (max-width: 767px) {
    .main-homepage .container-fluid, .main-homepage .container-sm, .main-homepage .container-md, .main-homepage .container-lg, .main-homepage .container-xl {
      padding-left: 20px;
      padding-right: 20px; } }

.about-section {
  padding-top: 9% !important;
  margin: -1px 0px;
  position: relative;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 991px) {
    .about-section {
      padding-right: 0 !important; } }
  .about-section .green-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 80%;
    width: 100%;
    background-color: #f2f8f7; }
    @media only screen and (max-width: 991px) {
      .about-section .green-bg {
        height: 75%; } }
  @media only screen and (max-width: 991px) {
    .about-section .content-section.row {
      margin-right: 0px; } }
  .about-section .content-section.row .title {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 991px) {
      .about-section .content-section.row .title .jada-btn {
        display: none; } }
  .about-section .content-section.row .content {
    margin-top: 85px; }
    @media only screen and (max-width: 991px) {
      .about-section .content-section.row .content {
        padding-right: 44px; } }
    @media only screen and (max-width: 767px) {
      .about-section .content-section.row .content {
        margin-top: 35px;
        padding-right: 20px; } }
    .about-section .content-section.row .content p {
      color: #000000;
      font-size: 3.125rem;
      line-height: 1.3;
      opacity: 0.7;
      font-weight: 300;
      margin: 0; }
      @media only screen and (max-width: 767px) {
        .about-section .content-section.row .content p {
          font-size: 25px;
          line-height: 32.5px; } }
      .about-section .content-section.row .content p span {
        font-size: 1.25rem;
        line-height: 1.2;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        color: #007473;
        padding-right: 30px;
        opacity: 1 !important; }
    .about-section .content-section.row .content .jada-btn {
      display: none;
      margin-top: 30px; }
      @media only screen and (max-width: 991px) {
        .about-section .content-section.row .content .jada-btn {
          display: inline-block; } }
  .about-section .partners-slider {
    padding-top: 116px;
    margin-right: -10%;
    margin-left: -4%;
    position: relative; }
    @media only screen and (max-width: 991px) {
      .about-section .partners-slider {
        margin-right: -40px;
        margin-left: -40px; } }
    @media only screen and (max-width: 767px) {
      .about-section .partners-slider {
        margin-right: -20px;
        margin-left: -20px;
        padding-top: 60px; } }
    .about-section .partners-slider h2 {
      margin-bottom: 85px; }
      @media only screen and (max-width: 767px) {
        .about-section .partners-slider h2 {
          margin-bottom: 35px; } }
    .about-section .partners-slider .swiper-container {
      position: relative; }
    .about-section .partners-slider .canvas {
      position: absolute;
      top: 200px;
      left: 50%;
      transform: translateX(-50%);
      width: 40%;
      height: auto; }
      @media only screen and (max-width: 991px) {
        .about-section .partners-slider .canvas {
          transform: translateX(0);
          left: auto;
          right: -10px;
          top: -12%;
          width: 60%;
          height: 100%;
          display: none; } }
      @media only screen and (max-width: 767px) {
        .about-section .partners-slider .canvas {
          transform: translateX(-50%);
          right: auto;
          left: 50%;
          top: 0px;
          width: 80%; } }
    @media only screen and (max-width: 1090px) {
      .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .partner-card {
        background-color: #007473; }
        .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .partner-card a {
          z-index: 2; }
        .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .partner-card .explore {
          opacity: 1; } }
    .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card {
      width: 100%;
      height: 521px;
      background-color: #3e3b3a;
      position: relative;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease; }
      @media only screen and (max-width: 1090px) {
        .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card {
          height: 630px; } }
      @media only screen and (max-width: 767px) {
        .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card {
          height: 436px; } }
      @media only screen and (min-width: 1200px) {
        .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card:hover {
          background-color: #007473; }
          .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card:hover .explore {
            opacity: 1; } }
      .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card img {
        max-width: 260px;
        height: auto; }
        @media only screen and (max-width: 500px) {
          .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card img {
            max-width: 200px; } }
      .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore {
        opacity: 0;
        height: 111px;
        width: 111px;
        border-radius: 100%;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 30px;
        left: 46px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        @media only screen and (max-width: 767px) {
          .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore {
            height: 80px;
            width: 80px;
            top: 20px;
            left: 20px; } }
        .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore a {
          z-index: 2; }
        .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore:after {
          position: absolute;
          content: "";
          top: 50%;
          left: 50%;
          height: 89px;
          width: 89px;
          background-color: #007473;
          transform: translate(-50%, -50%) scale(0);
          opacity: 0;
          transition: 0.3s all ease;
          border-radius: 100%; }
          @media only screen and (max-width: 1090px) {
            .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore:after {
              display: none; } }
        .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore .span {
          color: #007473;
          text-transform: uppercase;
          font-family: "Cairo", sans-serif;
          font-size: 1rem;
          line-height: 1.4;
          letter-spacing: 0.1px;
          font-weight: 700;
          margin-bottom: 10px;
          z-index: 2;
          transition: 0.2s all ease; }
        .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore .icomoon {
          font-size: 0.875rem;
          font-weight: 700;
          z-index: 2; }
          .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore .icomoon:before {
            color: #007473;
            transition: 0.2s all ease; }
        @media only screen and (min-width: 1200px) {
          .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore:hover {
            height: 127px;
            width: 127px; }
            .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore:hover:after {
              transform: translate(-50%, -50%) scale(1);
              opacity: 1; }
            .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore:hover .span {
              color: #FFFFFF; }
            .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .explore:hover .icomoon:before {
              color: #FFFFFF; } }
      .about-section .partners-slider .swiper-container .swiper-wrapper .swiper-slide .partner-card .partner-title {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.25rem;
        line-height: 1.4;
        text-align: center;
        font-weight: 600;
        color: #FFFFFF; }

.stats-section {
  padding-top: 13% !important;
  background-color: #FFFFFF;
  margin: -2px 0px;
  padding-left: 40px !important;
  padding-right: 40px !important; }
  @media only screen and (max-width: 767px) {
    .stats-section {
      padding-right: 20px !important;
      padding-left: 20px !important; } }
  .stats-section .span {
    font-family: "Cairo", sans-serif;
    font-size: 1.125rem;
    line-height: 1.33333;
    font-weight: 300;
    color: #373433;
    text-transform: uppercase; }
    @media only screen and (max-width: 767px) {
      .stats-section .span {
        font-size: 16px;
        line-height: 21.6px; } }
  .stats-section .stat-block__main {
    display: flex;
    align-items: flex-end;
    justify-content: center; }
    @media only screen and (max-width: 991px) {
      .stats-section .stat-block__main {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: fit-content;
        margin: auto;
        position: relative; } }
    @media only screen and (max-width: 767px) {
      .stats-section .stat-block__main {
        width: 100%; } }
    .stats-section .stat-block__main .stat-logo {
      height: 100px;
      width: 100px; }
    .stats-section .stat-block__main img {
      height: auto;
      width: auto;
      margin-bottom: 1%; }
    .stats-section .stat-block__main .title {
      margin: 0 110px 0 54px; }
      @media only screen and (max-width: 991px) {
        .stats-section .stat-block__main .title {
          margin: 0;
          display: flex;
          flex-direction: column-reverse; } }
      .stats-section .stat-block__main .title span {
        display: block; }
        @media only screen and (max-width: 991px) {
          .stats-section .stat-block__main .title span {
            margin-top: 10px;
            max-width: 176px; } }
      .stats-section .stat-block__main .title h1 {
        font-family: "Cairo", sans-serif;
        font-size: 5rem;
        line-height: 1.575;
        font-weight: 400;
        color: #000000; }
    .stats-section .stat-block__main .button {
      font-family: "Cairo", sans-serif;
      font-size: 1rem;
      line-height: 1.4;
      letter-spacing: 0.1px;
      font-weight: 700;
      color: #007473;
      padding-left: 40px;
      position: relative;
      text-transform: uppercase;
      display: flex;
      align-items: flex-end;
      margin-bottom: 1%; }
      @media only screen and (max-width: 991px) {
        .stats-section .stat-block__main .button {
          position: absolute;
          right: 10px;
          top: 60px; } }
      @media only screen and (max-width: 767px) {
        .stats-section .stat-block__main .button {
          right: 20%; } }
      .stats-section .stat-block__main .button:after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "\E922";
        font-family: icomoon !important;
        font-style: normal;
        font-weight: 700;
        color: #007473;
        font-variant: normal;
        text-transform: none;
        font-size: 0.9375rem;
        display: inline;
        vertical-align: middle; }
  .stats-section .stat-grid {
    padding-top: 70px;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center; }
    .stats-section .stat-grid .stat-block-container {
      margin-bottom: 58px;
      width: 40%; }
      @media only screen and (min-width: 1800px) {
        .stats-section .stat-grid .stat-block-container {
          width: 35%; } }
      @media only screen and (max-width: 1300px) {
        .stats-section .stat-grid .stat-block-container {
          width: 42%; }
          .stats-section .stat-grid .stat-block-container:nth-child(odd) {
            padding-right: 15px; } }
      @media only screen and (max-width: 1090px) {
        .stats-section .stat-grid .stat-block-container {
          width: 50%; } }
      @media only screen and (max-width: 767px) {
        .stats-section .stat-grid .stat-block-container {
          width: 100%; }
          .stats-section .stat-grid .stat-block-container:nth-child(odd) {
            padding-right: 0px; } }
      @media only screen and (max-width: 767px) {
        .stats-section .stat-grid .stat-block-container:last-child {
          margin-bottom: 0px; } }
      @media only screen and (max-width: 991px) {
        .stats-section .stat-grid .stat-block-container:nth-child(4) .stat_block .title span {
          max-width: 176px; } }
      .stats-section .stat-grid .stat-block-container .stat_block {
        display: flex;
        align-items: flex-end; }
        @media only screen and (max-width: 991px) {
          .stats-section .stat-grid .stat-block-container .stat_block {
            flex-direction: column;
            align-items: flex-start; } }
        .stats-section .stat-grid .stat-block-container .stat_block img {
          height: auto;
          width: auto;
          margin-bottom: 1%; }
        .stats-section .stat-grid .stat-block-container .stat_block .title {
          margin: 0 0 0 35px; }
          @media only screen and (max-width: 1090px) {
            .stats-section .stat-grid .stat-block-container .stat_block .title {
              margin: 0 0 7px; } }
          @media only screen and (max-width: 991px) {
            .stats-section .stat-grid .stat-block-container .stat_block .title {
              margin: 0;
              display: flex;
              flex-direction: column-reverse; } }
          .stats-section .stat-grid .stat-block-container .stat_block .title span {
            display: block; }
          .stats-section .stat-grid .stat-block-container .stat_block .title h1 {
            font-family: "Cairo", sans-serif;
            font-size: 5rem;
            line-height: 1.575;
            font-weight: 400;
            color: #000000; }
  .stats-section .row-4 {
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 991px) {
      .stats-section .row-4 {
        justify-content: flex-start; } }
  .stats-section .content-box {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media only screen and (max-width: 991px) {
      .stats-section .content-box {
        align-items: flex-start; } }
    @media only screen and (max-width: 767px) {
      .stats-section .content-box {
        margin-top: 67px; } }
    .stats-section .content-box .jada-btn {
      margin-bottom: 2px; }
    .stats-section .content-box span {
      font-family: "Cairo", sans-serif;
      font-size: 1rem;
      line-height: 1.4;
      letter-spacing: 1px;
      font-weight: 700;
      color: #373433;
      margin-bottom: 13px; }
    .stats-section .content-box p {
      opacity: 1;
      font-family: "Cairo", sans-serif;
      font-size: 1.5rem;
      line-height: 1.45;
      text-align: center;
      font-weight: 400;
      color: #7F8184;
      max-width: 662px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 30px; }
      @media only screen and (max-width: 991px) {
        .stats-section .content-box p {
          text-align: left; } }
      @media only screen and (max-width: 767px) {
        .stats-section .content-box p {
          font-size: 24px;
          line-height: 34.8px; } }

body.arabic .events-section {
  padding-left: 0px !important;
  padding-right: 3% !important; }
  @media only screen and (max-width: 991px) {
    body.arabic .events-section {
      padding-right: 40px !important; } }
  @media only screen and (max-width: 767px) {
    body.arabic .events-section {
      padding-right: 20px !important; } }
  @media only screen and (max-width: 500px) {
    body.arabic .events-section {
      padding-right: 0px !important; } }
  body.arabic .events-section .events-title-section {
    padding: 0px 60px 0px 135px; }
    @media only screen and (max-width: 1090px) {
      body.arabic .events-section .events-title-section {
        padding: 0px 40px 0px 40px; } }
    @media only screen and (max-width: 991px) {
      body.arabic .events-section .events-title-section {
        padding: 0px 20px 0px 40px; } }
  body.arabic .events-section .tabs-content {
    padding-left: 0px;
    padding-right: 60px; }
    @media only screen and (max-width: 1090px) {
      body.arabic .events-section .tabs-content {
        padding-right: 40px; } }
    @media only screen and (max-width: 991px) {
      body.arabic .events-section .tabs-content {
        padding-right: 20px; } }

@media only screen and (max-width: 991px) {
  body.arabic .stats-section .stat-grid .stat-block-container:nth-child(odd) {
    padding-right: 0px;
    padding-left: 15px; } }

body.arabic .stats-section .stat-grid .stat-block-container:nth-child(odd) {
  padding-left: 0px; }

.events-section {
  padding-top: 40px !important;
  padding-right: 0px !important;
  position: relative;
  background-color: #FFFFFF;
  margin: -1px 0px;
  padding-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .events-section {
      padding-bottom: 60px; } }
  @media only screen and (max-width: 500px) {
    .events-section {
      padding-left: 0px !important; } }
  .events-section .jada-black-bg {
    z-index: -1;
    background-color: #373433;
    position: absolute;
    height: 45%;
    width: 100%;
    bottom: 0%;
    left: 0; }
  .events-section .events-title-section {
    background-color: #007473;
    padding-top: 60px !important;
    padding: 0px 135px 0px 60px; }
    @media only screen and (max-width: 1090px) {
      .events-section .events-title-section {
        padding: 0px 40px 0px 40px; } }
    @media only screen and (max-width: 991px) {
      .events-section .events-title-section {
        padding: 0px 40px 0px 20px; } }
    @media only screen and (max-width: 500px) {
      .events-section .events-title-section {
        padding-top: 30px !important; } }
    .events-section .events-title-section .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 37px; }
      @media only screen and (max-width: 767px) {
        .events-section .events-title-section .title {
          flex-direction: column;
          align-items: flex-start; } }
      .events-section .events-title-section .title h2 {
        display: inline-block;
        margin-bottom: 17px;
        color: #FFFFFF; }
      .events-section .events-title-section .title .jada-btn {
        border: 1px solid #FFFFFF; }
    .events-section .events-title-section .tab-section .custom-tabs li a {
      color: #FFFFFF !important; }
  .events-section .tabs-content {
    padding-top: 40px;
    padding-bottom: 90px !important;
    padding-left: 60px;
    margin-left: 0px;
    overflow: visible;
    background-color: #007473; }
    @media only screen and (max-width: 1090px) {
      .events-section .tabs-content {
        padding-left: 40px; } }
    @media only screen and (max-width: 991px) {
      .events-section .tabs-content {
        padding-left: 20px; } }
    @media only screen and (max-width: 500px) {
      .events-section .tabs-content {
        padding-bottom: 40px !important; } }
    .events-section .tabs-content .list {
      overflow: visible;
      cursor: none; }
      .events-section .tabs-content .list .swiper-container {
        cursor: none !important;
        overflow: visible; }
        .events-section .tabs-content .list .swiper-container .swiper-wrapper {
          overflow: visible; }
          .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide {
            position: relative;
            overflow: hidden;
            background-color: #FFFFFF;
            padding-left: 30px;
            padding-top: 36px;
            padding-bottom: 57px;
            padding-right: 50px; }
            @media only screen and (max-width: 767px) {
              .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide {
                padding: 20px; } }
            .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide a {
              cursor: none; }
            .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide p {
              font-family: "Cairo", sans-serif;
              font-size: 2.375rem;
              line-height: 1.3;
              font-weight: 600;
              color: #373433;
              margin-bottom: 59px;
              max-width: 468px;
              transition: 0.3s color ease;
              -webkit-transition: 0.3s color ease;
              -moz-transition: 0.3s color ease;
              -ms-transition: 0.3s color ease;
              -o-transition: 0.3s color ease;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden; }
              @media only screen and (max-width: 991px) {
                .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide p {
                  font-size: 2.375rem;
                  line-height: 1.3;
                  margin-bottom: 20px; } }
              @media only screen and (max-width: 500px) {
                .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide p {
                  font-size: 25px;
                  line-height: 32.5px; } }
            .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide .calender-section {
              padding-top: 25px;
              position: relative; }
              .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide .calender-section:after {
                position: absolute;
                top: 0;
                left: -60px;
                content: "";
                height: 1px;
                width: 130%;
                background-color: rgba(0, 0, 0, 0.07); }
              .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide .calender-section .icomoon {
                font-size: 1.25rem;
                margin-right: 20px; }
              .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide .calender-section span {
                font-family: "Cairo", sans-serif;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 1.4;
                color: #171717; }
              .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide .calender-section .date {
                margin-bottom: 16px; }
            @media only screen and (min-width: 1200px) {
              .events-section .tabs-content .list .swiper-container .swiper-wrapper .swiper-slide:hover p {
                color: #007473; } }
        .events-section .tabs-content .list .swiper-container .swiper-pagination {
          display: none; }

.events-section .tabs-content.container-fluid, .events-section .tabs-content.container-sm, .events-section .tabs-content.container-md, .events-section .tabs-content.container-lg, .events-section .tabs-content.container-xl {
  padding-right: 0px; }

.social-section {
  background-color: #FFFFFF;
  position: relative;
  margin: -1px 0px; }
  .social-section .jada-black-bg {
    z-index: -1;
    background-color: #373433;
    position: absolute;
    height: 70%;
    width: 100%;
    top: 0%;
    left: 0;
    display: none; }
    @media only screen and (max-width: 991px) {
      .social-section .jada-black-bg {
        height: 60%; } }
    @media only screen and (max-width: 500px) {
      .social-section .jada-black-bg {
        height: 50%; } }
  .social-section h2 {
    color: #FFFFFF !important;
    max-width: 423px; }
    @media only screen and (max-width: 991px) {
      .social-section h2 {
        max-width: 100%; } }
  .social-section .social-gallery {
    padding-top: 0px; }
    .social-section .social-gallery .swiper-container {
      cursor: none !important; }
      .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide {
        margin: 0 50px;
        overflow: hidden;
        position: relative; }
        @media only screen and (min-width: 1090px) {
          .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide {
            margin-left: 30px !important;
            margin-right: 30px !important; } }
        @media only screen and (max-width: 1024px) {
          .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide {
            margin-left: 0px;
            margin-right: 0px; } }
        .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide img {
          display: block;
          height: 35vw;
          object-fit: cover;
          width: 100%;
          transition: all 0.4s ease;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          -ms-transition: all 0.4s ease;
          -o-transition: all 0.4s ease; }
          @media screen and (min-width: 1440px) and (max-height: 800px) {
            .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide img {
              height: 540px; } }
          @media only screen and (min-width: 1600px) {
            .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide img {
              height: 37.5vw; } }
          @media only screen and (max-width: 991px) {
            .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide img {
              height: 66vw; } }
          @media only screen and (max-width: 767px) {
            .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide img {
              height: 375px; } }
        .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide:nth-child(even) {
          margin-top: 60px; }
        .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide a:hover .slide-icon {
          opacity: 1 !important; }
        .social-section .social-gallery .swiper-container .swiper-wrapper .swiper-slide a:hover img {
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1); }

.get-in-touch {
  width: 100%;
  padding: 0 40px;
  padding-bottom: 37px;
  padding-top: 75px; }
  @media only screen and (max-width: 991px) {
    .get-in-touch {
      padding: 0; } }
  .get-in-touch .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f8f7;
    padding: 31px 40px 40px 40px; }
    @media only screen and (max-width: 767px) {
      .get-in-touch .content {
        padding: 26px 0;
        flex-direction: column; } }
    .get-in-touch .content p {
      font-family: "Cairo", sans-serif;
      font-size: 2.5rem;
      line-height: 1.15;
      font-weight: 400;
      color: #000000;
      margin-bottom: 0; }
      @media only screen and (max-width: 991px) {
        .get-in-touch .content p {
          font-size: 2.1875rem;
          line-height: 1.31429; } }
      @media only screen and (max-width: 767px) {
        .get-in-touch .content p {
          margin-bottom: 29px; } }

.jada-cursor {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  opacity: 0;
  transform: scale(0.8);
  transition: 0.3s opacity, transform  ease; }
  @media only screen and (max-width: 1200px) {
    .jada-cursor {
      display: none !important; } }
  .jada-cursor.homepage-events-active {
    opacity: 1 !important;
    transform: scale(1); }
    .jada-cursor.homepage-events-active .batch {
      background-color: #007473;
      border: 1px solid #fff; }
      .jada-cursor.homepage-events-active .batch .icomoon {
        color: #FFFFFF; }

.batch {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 1px solid #e6e6e6;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  background-color: #FFFFFF;
  height: 92px;
  width: 92px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .batch .icon-coolicon_left {
    margin-right: 10px;
    opacity: 0.2; }

.mouse-active {
  display: block; }

.slide-icon {
  z-index: 2;
  position: absolute;
  top: 30px;
  left: 30px;
  height: 70px;
  width: 70px;
  background-color: #FFFFFF;
  border-radius: 100%;
  opacity: 0;
  transition: all 0.2s ease-in;
  border: 1px solid #e6e6e6; }
  .slide-icon:after {
    font-family: icomoon;
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    opacity: 1;
    content: "\E922";
    position: absolute;
    color: #007473;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 3;
    transition: 0.2s all ease; }
  .slide-icon:before {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 52px;
    width: 52px;
    z-index: 2;
    transform: translate(-50%, -50%) scale(0);
    content: "";
    background-color: #007473;
    transition: 0.2s all ease;
    border-radius: 100%; }
  .slide-icon:hover {
    height: 80px;
    width: 80px; }
    .slide-icon:hover:after {
      color: #FFFFFF !important; }
    .slide-icon:hover:before {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1; }

.stats-section .anim-funds {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px; }

.banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important; }

.jada-main-banner {
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 2; }
  @media only screen and (max-width: 700px) {
    .jada-main-banner {
      height: 50vh;
      min-height: 500px; } }
  .jada-main-banner .content {
    padding: 0 40px;
    margin-bottom: 50px;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    position: relative; }
    @media only screen and (max-width: 1400px) {
      .jada-main-banner .content {
        margin-bottom: 10px; } }
    @media only screen and (max-width: 1090px) {
      .jada-main-banner .content {
        margin-bottom: 60px; } }
    @media only screen and (max-width: 767px) {
      .jada-main-banner .content {
        padding: 0 20px;
        margin-bottom: 30px; } }
    .jada-main-banner .content h1 {
      color: #FFFFFF;
      max-width: 950px;
      font-weight: normal;
      margin: 0;
      z-index: 2;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto; }
    .jada-main-banner .content .scroll-down {
      position: absolute;
      right: 70px;
      bottom: 40px; }
      .jada-main-banner .content .scroll-down .icon-curverd-down {
        position: relative;
        margin-right: 37px; }
        .jada-main-banner .content .scroll-down .icon-curverd-down:before {
          color: #000000;
          font-size: 1.25rem;
          transition: 0.2s all ease; }
        .jada-main-banner .content .scroll-down .icon-curverd-down:after {
          content: "";
          height: 60px;
          width: 60px;
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
          position: absolute;
          background-color: #FFFFFF;
          z-index: -1;
          top: -25px;
          left: -22px; }
        @media only screen and (min-width: 1200px) {
          .jada-main-banner .content .scroll-down .icon-curverd-down:hover:before {
            color: #007473; } }
      .jada-main-banner .content .scroll-down span {
        font-size: 1.25rem;
        line-height: 1.3;
        color: #FFFFFF;
        font-family: "Cairo", sans-serif;
        font-weight: 400; }
      @media only screen and (max-width: 991px) {
        .jada-main-banner .content .scroll-down {
          display: none; } }
  .jada-main-banner .three-col-block {
    max-width: 70%; }
    @media only screen and (max-width: 1090px) {
      .jada-main-banner .three-col-block {
        max-width: 100%; } }

.about-wrapper .jada-main-banner {
  height: 80vh; }
  @media only screen and (max-width: 991px) {
    .about-wrapper .jada-main-banner {
      min-height: 700px; } }

.about-tabs {
  padding-top: 186px;
  background-color: #FFFFFF;
  margin: -1px 0px;
  position: relative;
  overflow: hidden; }
  .about-tabs .jada-pattern {
    position: absolute;
    top: -27%;
    left: 5%;
    opacity: 0.4;
    width: 630px; }
    @media only screen and (max-width: 991px) {
      .about-tabs .jada-pattern {
        display: none; } }
  @media only screen and (max-width: 991px) {
    .about-tabs {
      padding-top: 100px; } }
  .about-tabs .mission-vision-block .title-container {
    height: 300px;
    overflow: hidden;
    margin-bottom: 10px; }
    .about-tabs .mission-vision-block .title-container .tab-slider {
      width: 400px;
      overflow: visible;
      margin-left: 0px; }
    @media only screen and (max-width: 767px) {
      .about-tabs .mission-vision-block .title-container {
        height: 250px; } }
  @media only screen and (max-width: 991px) {
    .about-tabs .mission-vision-block .tab-slider {
      margin-bottom: 30px; } }
  .about-tabs .mission-vision-block .tab-slider .swiper-container {
    cursor: none; }
  .about-tabs .mission-vision-block .tab-slider .swiper-slide {
    cursor: pointer;
    z-index: 2; }
    .about-tabs .mission-vision-block .tab-slider .swiper-slide h2 {
      font-family: "Cairo", sans-serif;
      font-size: 5rem;
      line-height: 1.075;
      font-weight: 400;
      color: #000000;
      position: relative;
      opacity: 0.2;
      margin: 0;
      z-index: 1;
      transition: 0.2s all ease; }
      .about-tabs .mission-vision-block .tab-slider .swiper-slide h2:after {
        position: absolute;
        top: 30%;
        left: 100%;
        height: 1px;
        width: 0%;
        content: "";
        background-color: #7f7184;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transition: 0.3s width ease;
        -webkit-transition: 0.3s width ease;
        -moz-transition: 0.3s width ease;
        -ms-transition: 0.3s width ease;
        -o-transition: 0.3s width ease; }
        @media only screen and (max-width: 991px) {
          .about-tabs .mission-vision-block .tab-slider .swiper-slide h2:after {
            display: none; } }
    @media only screen and (min-width: 1200px) {
      .about-tabs .mission-vision-block .tab-slider .swiper-slide:hover h2 {
        color: #000000;
        opacity: 1; } }
  .about-tabs .mission-vision-block .tab-slider .swiper-slide-active h2 {
    color: #007473;
    opacity: 1; }
    .about-tabs .mission-vision-block .tab-slider .swiper-slide-active h2:after {
      width: 215px; }
      @media only screen and (max-width: 1090px) {
        .about-tabs .mission-vision-block .tab-slider .swiper-slide-active h2:after {
          width: 150px;
          left: 78%; } }
  .about-tabs .mission-vision-block .content-slider .swiper-wrapper .swiper-slide .p-40 {
    color: #7f8184; }

.mission-vision-block .tab-slider {
  height: 100%; }

.our-story {
  padding-top: 100px;
  background-color: #FFFFFF;
  margin: -2px 0px; }
  @media only screen and (max-width: 991px) {
    .our-story {
      padding-top: 100px; } }
  @media only screen and (max-width: 767px) {
    .our-story {
      padding-top: 60px; } }
  .our-story span {
    font-family: "Cairo", sans-serif;
    font-size: 1.125rem;
    line-height: 1.33333;
    font-weight: 300;
    color: #000000;
    text-transform: capitalize; }
  .our-story h3 {
    margin-top: 19px;
    display: block;
    max-width: 373px;
    text-transform: uppercase; }
  .our-story .p-40 {
    color: #7f8184; }
    @media only screen and (max-width: 991px) {
      .our-story .p-40 {
        margin-top: 30px; } }

.range-slider {
  background-color: #FFFFFF;
  margin: -1px 0px;
  padding-bottom: 100px;
  padding-top: 100px; }
  @media only screen and (max-width: 991px) {
    .range-slider {
      padding-bottom: 60px;
      padding-top: 60px; } }
  @media only screen and (max-width: 500px) {
    .range-slider {
      padding-bottom: 30px;
      padding-top: 20px; } }
  .range-slider .swiper-container .swiper-slide {
    background-color: #F2F8F7;
    opacity: 0.5;
    padding: 60px;
    margin-top: 30px; }
    @media only screen and (max-width: 767px) {
      .range-slider .swiper-container .swiper-slide {
        padding: 40px; } }
    @media only screen and (max-width: 500px) {
      .range-slider .swiper-container .swiper-slide {
        padding: 20px; } }
    .range-slider .swiper-container .swiper-slide h2 {
      color: #007473;
      font-weight: 400;
      text-transform: capitalize;
      margin-bottom: 35px;
      font-size: 2.375rem;
      line-height: 1.21053; }
    .range-slider .swiper-container .swiper-slide p {
      color: #000000;
      max-width: 677px;
      font-size: 1.75rem;
      line-height: 1.4;
      font-weight: 300; }
    .range-slider .swiper-container .swiper-slide.swiper-slide-active {
      opacity: 1 !important; }
  .range-slider .swiper-container .swiper-button-next,
  .range-slider .swiper-container .swiper-button-prev {
    top: 40px;
    height: 92px;
    width: 92px;
    border-radius: 100%;
    border: 1px solid #e6e6e6; }
    @media only screen and (max-width: 500px) {
      .range-slider .swiper-container .swiper-button-next,
      .range-slider .swiper-container .swiper-button-prev {
        height: 62px;
        width: 62px; } }
    .range-slider .swiper-container .swiper-button-next:hover:after,
    .range-slider .swiper-container .swiper-button-prev:hover:after {
      color: #007473; }
  .range-slider .swiper-container .swiper-button-prev:after {
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    content: "\E907";
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.25rem;
    color: #373433;
    font-weight: 300; }
    @media only screen and (max-width: 500px) {
      .range-slider .swiper-container .swiper-button-prev:after {
        font-size: 16px; } }
  .range-slider .swiper-container .swiper-button-next {
    left: 113px; }
    @media only screen and (max-width: 500px) {
      .range-slider .swiper-container .swiper-button-next {
        left: 83px; } }
    .range-slider .swiper-container .swiper-button-next:after {
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      content: "\E908";
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 1.25rem;
      color: #373433;
      font-weight: 300;
      transition: 0.3s all ease; }
      @media only screen and (max-width: 500px) {
        .range-slider .swiper-container .swiper-button-next:after {
          font-size: 16px; } }
  .range-slider .swiper-container .swiper-wrapper {
    padding-top: 120px; }
    @media only screen and (max-width: 500px) {
      .range-slider .swiper-container .swiper-wrapper {
        padding-top: 80px; } }
  .range-slider .rangeslide {
    width: calc(100% - 40px); }
    @media only screen and (max-width: 767px) {
      .range-slider .rangeslide {
        width: calc(100% - 20px); } }
  .range-slider .range-wrap {
    padding-bottom: 100px;
    padding-top: 100px; }
    @media only screen and (max-width: 991px) {
      .range-slider .range-wrap {
        display: none; } }

.range-slider {
  padding-right: 0 !important; }

.all-about-jada {
  position: relative;
  background-color: #FFFFFF;
  padding-bottom: 50px;
  margin: -2px 0px; }
  @media only screen and (max-width: 500px) {
    .all-about-jada {
      padding-bottom: 30px; } }
  .all-about-jada .meaning-of-jada {
    padding: 0px 9.3%; }
    @media only screen and (max-width: 1090px) {
      .all-about-jada .meaning-of-jada {
        padding: 0 40px; } }
  .all-about-jada .jada-green-bg {
    background-color: #f2f8f7;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%; }
    @media only screen and (max-width: 991px) {
      .all-about-jada .jada-green-bg {
        height: 70%; } }
    @media only screen and (max-width: 500px) {
      .all-about-jada .jada-green-bg {
        height: 70%; } }
  .all-about-jada .jada-black-bg {
    background-color: #373433;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%; }
    @media only screen and (max-width: 991px) {
      .all-about-jada .jada-black-bg {
        height: 30%; } }
    @media only screen and (max-width: 500px) {
      .all-about-jada .jada-black-bg {
        height: 30%; } }
  .all-about-jada .title-block {
    padding-top: 116px; }
    @media only screen and (max-width: 991px) {
      .all-about-jada .title-block {
        padding-top: 80px; } }
    @media only screen and (max-width: 767px) {
      .all-about-jada .title-block {
        padding-top: 60px; } }
    @media only screen and (max-width: 500px) {
      .all-about-jada .title-block {
        padding-top: 30px; } }
    .all-about-jada .title-block h2 {
      color: #373433;
      max-width: 453px; }
    .all-about-jada .title-block .p {
      font-weight: 300;
      color: #373433;
      opacity: 0.5 !important;
      max-width: 690px;
      margin-bottom: 0px; }
      @media only screen and (max-width: 767px) {
        .all-about-jada .title-block .p {
          margin-top: 30px; } }
  .all-about-jada .video-block-container {
    margin-top: 60px;
    margin-bottom: 0%;
    padding: 0px 9.3%; }
    @media only screen and (max-width: 1090px) {
      .all-about-jada .video-block-container {
        padding: 0 40px; } }
    @media only screen and (max-width: 500px) {
      .all-about-jada .video-block-container {
        margin-top: 30px; } }
    .all-about-jada .video-block-container .video-block {
      cursor: pointer; }
      .all-about-jada .video-block-container .video-block:hover .play_white {
        background-color: #007473; }
        .all-about-jada .video-block-container .video-block:hover .play_white:before {
          color: #FFFFFF; }
      .all-about-jada .video-block-container .video-block:hover .bg-img {
        transform: scale(1.05); }
      .all-about-jada .video-block-container .video-block .img-container {
        position: relative;
        padding-top: calc(600 / 1334 * 100%);
        overflow: hidden;
        height: 0; }
        @media only screen and (max-width: 991px) {
          .all-about-jada .video-block-container .video-block .img-container {
            padding-top: 67%; } }
      .all-about-jada .video-block-container .video-block .bg-img {
        height: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.3s ease;
        background-position: center !important; }
      .all-about-jada .video-block-container .video-block .play_white {
        height: 120px;
        width: 120px;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
        position: absolute;
        background-color: #FFFFFF;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transition: background-color 0.2s ease; }
        .all-about-jada .video-block-container .video-block .play_white:before {
          content: "\E914";
          position: absolute;
          top: 50%;
          left: 47%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          font-family: "icomoon" !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          font-size: 1.625rem;
          color: #007473; }
        @media only screen and (max-width: 767px) {
          .all-about-jada .video-block-container .video-block .play_white {
            height: 60px;
            width: 60px; }
            .all-about-jada .video-block-container .video-block .play_white:before {
              font-size: 20px; } }

.jada-values-section {
  position: relative;
  background-color: #373433; }
  .jada-values-section .container-fluid, .jada-values-section .container-sm, .jada-values-section .container-md, .jada-values-section .container-lg, .jada-values-section .container-xl {
    margin: -1px 0px;
    padding-top: 100px;
    padding-bottom: 100px; }
    @media only screen and (max-width: 991px) {
      .jada-values-section .container-fluid, .jada-values-section .container-sm, .jada-values-section .container-md, .jada-values-section .container-lg, .jada-values-section .container-xl {
        padding-top: 60px;
        padding-bottom: 60px; } }
    @media only screen and (max-width: 767px) {
      .jada-values-section .container-fluid, .jada-values-section .container-sm, .jada-values-section .container-md, .jada-values-section .container-lg, .jada-values-section .container-xl {
        padding-top: 60px;
        padding-bottom: 20px; } }
    .jada-values-section .container-fluid .title-box h2, .jada-values-section .container-sm .title-box h2, .jada-values-section .container-md .title-box h2, .jada-values-section .container-lg .title-box h2, .jada-values-section .container-xl .title-box h2 {
      font-weight: 400;
      color: #FFFFFF; }
      @media only screen and (max-width: 767px) {
        .jada-values-section .container-fluid .title-box h2, .jada-values-section .container-sm .title-box h2, .jada-values-section .container-md .title-box h2, .jada-values-section .container-lg .title-box h2, .jada-values-section .container-xl .title-box h2 {
          margin-bottom: 30px; } }
    .jada-values-section .container-fluid .content-box .content, .jada-values-section .container-sm .content-box .content, .jada-values-section .container-md .content-box .content, .jada-values-section .container-lg .content-box .content, .jada-values-section .container-xl .content-box .content {
      margin-bottom: 40px;
      height: 7.875rem;
      overflow: hidden;
      transition: height 0.8s ease;
      -webkit-transition: height 0.8s ease;
      -moz-transition: height 0.8s ease;
      -ms-transition: height 0.8s ease;
      -o-transition: height 0.8s ease;
      cursor: pointer; }
      @media only screen and (max-width: 1090px) {
        .jada-values-section .container-fluid .content-box .content, .jada-values-section .container-sm .content-box .content, .jada-values-section .container-md .content-box .content, .jada-values-section .container-lg .content-box .content, .jada-values-section .container-xl .content-box .content {
          height: 5.0625rem; } }
      @media only screen and (max-width: 991px) {
        .jada-values-section .container-fluid .content-box .content, .jada-values-section .container-sm .content-box .content, .jada-values-section .container-md .content-box .content, .jada-values-section .container-lg .content-box .content, .jada-values-section .container-xl .content-box .content {
          height: 4.4375rem; } }
      .jada-values-section .container-fluid .content-box .content.active, .jada-values-section .container-sm .content-box .content.active, .jada-values-section .container-md .content-box .content.active, .jada-values-section .container-lg .content-box .content.active, .jada-values-section .container-xl .content-box .content.active {
        height: auto; }
        .jada-values-section .container-fluid .content-box .content.active h4, .jada-values-section .container-sm .content-box .content.active h4, .jada-values-section .container-md .content-box .content.active h4, .jada-values-section .container-lg .content-box .content.active h4, .jada-values-section .container-xl .content-box .content.active h4 {
          opacity: 1 !important; }
      @media only screen and (min-width: 1200px) {
        .jada-values-section .container-fluid .content-box .content:hover h4, .jada-values-section .container-sm .content-box .content:hover h4, .jada-values-section .container-md .content-box .content:hover h4, .jada-values-section .container-lg .content-box .content:hover h4, .jada-values-section .container-xl .content-box .content:hover h4 {
          opacity: 0.7 !important; } }
      .jada-values-section .container-fluid .content-box .content h4, .jada-values-section .container-sm .content-box .content h4, .jada-values-section .container-md .content-box .content h4, .jada-values-section .container-lg .content-box .content h4, .jada-values-section .container-xl .content-box .content h4 {
        font-family: "Cairo", sans-serif;
        font-weight: 400;
        font-size: 5.9375rem;
        line-height: 1.32632;
        color: #FFFFFF;
        opacity: 0.2 !important;
        transition: opacity 0.3s ease;
        -webkit-transition: opacity 0.3s ease;
        -moz-transition: opacity 0.3s ease;
        -ms-transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease; }
        @media only screen and (max-width: 1090px) {
          .jada-values-section .container-fluid .content-box .content h4, .jada-values-section .container-sm .content-box .content h4, .jada-values-section .container-md .content-box .content h4, .jada-values-section .container-lg .content-box .content h4, .jada-values-section .container-xl .content-box .content h4 {
            font-size: 3.75rem;
            line-height: 1.35; } }
        @media only screen and (max-width: 991px) {
          .jada-values-section .container-fluid .content-box .content h4, .jada-values-section .container-sm .content-box .content h4, .jada-values-section .container-md .content-box .content h4, .jada-values-section .container-lg .content-box .content h4, .jada-values-section .container-xl .content-box .content h4 {
            font-size: 3.125rem;
            line-height: 1.42; } }
      .jada-values-section .container-fluid .content-box .content p, .jada-values-section .container-sm .content-box .content p, .jada-values-section .container-md .content-box .content p, .jada-values-section .container-lg .content-box .content p, .jada-values-section .container-xl .content-box .content p {
        padding-top: 22px;
        margin-bottom: 0;
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.3;
        font-weight: 300;
        max-width: 480px;
        color: #FFFFFF; }
        @media only screen and (max-width: 991px) {
          .jada-values-section .container-fluid .content-box .content p, .jada-values-section .container-sm .content-box .content p, .jada-values-section .container-md .content-box .content p, .jada-values-section .container-lg .content-box .content p, .jada-values-section .container-xl .content-box .content p {
            opacity: 0.7 !important; } }

.our-leaders {
  background-color: #007473;
  margin: -1px 0px;
  position: relative; }
  .our-leaders .jada-pattern {
    position: absolute;
    right: -18%;
    width: 60%;
    top: 30px;
    max-width: 900px; }
    @media only screen and (min-width: 1600px) {
      .our-leaders .jada-pattern {
        width: 55%; } }
    @media only screen and (max-width: 767px) {
      .our-leaders .jada-pattern {
        display: none; } }
  .our-leaders .title-section {
    padding-top: 40px; }
    @media only screen and (max-width: 500px) {
      .our-leaders .title-section {
        padding-top: 30px; } }
    .our-leaders .title-section h1 {
      font-weight: 300;
      color: #FFFFFF; }
  .our-leaders .team-tab-section {
    margin-top: 70px; }
    @media only screen and (max-width: 767px) {
      .our-leaders .team-tab-section {
        margin-top: 40px; } }
    @media only screen and (max-width: 991px) {
      .our-leaders .team-tab-section .custom-tabs {
        flex-direction: column; } }
    .our-leaders .team-tab-section .custom-tabs li {
      margin-right: 20px; }
      @media only screen and (max-width: 991px) {
        .our-leaders .team-tab-section .custom-tabs li {
          margin-bottom: 15px;
          margin-right: 0px; }
          .our-leaders .team-tab-section .custom-tabs li:last-child {
            margin-bottom: 0px; } }
    .our-leaders .team-tab-section li a {
      color: #FFFFFF !important;
      opacity: 0.2; }
      .our-leaders .team-tab-section li a.active, .our-leaders .team-tab-section li a:hover {
        opacity: 1; }
  .our-leaders .team-list {
    display: flex;
    flex-wrap: wrap;
    color: #FFFFFF; }
  .our-leaders #board-members .team-featured,
  .our-leaders #executive-team .team-featured {
    width: 100%;
    margin-top: 60px;
    padding-bottom: 90px !important; }
    @media only screen and (max-width: 767px) {
      .our-leaders #board-members .team-featured,
      .our-leaders #executive-team .team-featured {
        padding-bottom: 60px !important; } }
    @media only screen and (max-width: 500px) {
      .our-leaders #board-members .team-featured,
      .our-leaders #executive-team .team-featured {
        margin-top: 30px !important;
        padding-bottom: 30px !important; } }
    .our-leaders #board-members .team-featured .row,
    .our-leaders #executive-team .team-featured .row {
      display: flex; }
      @media only screen and (max-width: 991px) {
        .our-leaders #board-members .team-featured .row,
        .our-leaders #executive-team .team-featured .row {
          flex-direction: column-reverse; } }
    .our-leaders #board-members .team-featured .img-container,
    .our-leaders #executive-team .team-featured .img-container {
      width: 35.9%;
      z-index: 2;
      padding-right: 15px; }
      @media only screen and (max-width: 1090px) {
        .our-leaders #board-members .team-featured .img-container,
        .our-leaders #executive-team .team-featured .img-container {
          width: 40%; } }
      @media only screen and (max-width: 991px) {
        .our-leaders #board-members .team-featured .img-container,
        .our-leaders #executive-team .team-featured .img-container {
          width: 100%;
          padding-left: 15px; } }
    .our-leaders #board-members .team-featured .bg-img,
    .our-leaders #executive-team .team-featured .bg-img {
      height: 0;
      background-size: cover;
      background-repeat: no-repeat;
      padding-top: 105%; }
      @media only screen and (max-width: 991px) {
        .our-leaders #board-members .team-featured .bg-img,
        .our-leaders #executive-team .team-featured .bg-img {
          padding-top: 90%; } }
    .our-leaders #board-members .team-featured .bio-container,
    .our-leaders #executive-team .team-featured .bio-container {
      width: 63.1%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding-right: 5%;
      padding-left: 15px; }
      @media only screen and (max-width: 1090px) {
        .our-leaders #board-members .team-featured .bio-container,
        .our-leaders #executive-team .team-featured .bio-container {
          width: 60%; } }
      @media only screen and (max-width: 991px) {
        .our-leaders #board-members .team-featured .bio-container,
        .our-leaders #executive-team .team-featured .bio-container {
          margin-top: 30px;
          width: 100%;
          padding-right: 15px; } }
      .our-leaders #board-members .team-featured .bio-container h2,
      .our-leaders #executive-team .team-featured .bio-container h2 {
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 21px; }
      .our-leaders #board-members .team-featured .bio-container p,
      .our-leaders #executive-team .team-featured .bio-container p {
        font-weight: 300;
        color: #FFFFFF;
        opacity: 0.5 !important; }
        @media only screen and (min-width: 1600px) {
          .our-leaders #board-members .team-featured .bio-container p,
          .our-leaders #executive-team .team-featured .bio-container p {
            max-width: none; } }
      .our-leaders #board-members .team-featured .bio-container .designation,
      .our-leaders #executive-team .team-featured .bio-container .designation {
        max-width: none;
        font-family: "Cairo", sans-serif;
        text-transform: uppercase;
        color: #FFFFFF;
        font-size: 1.25rem;
        line-height: 1.4;
        font-weight: 400;
        opacity: 1 !important;
        margin-bottom: 0; }
        @media only screen and (max-width: 991px) {
          .our-leaders #board-members .team-featured .bio-container .designation,
          .our-leaders #executive-team .team-featured .bio-container .designation {
            margin-bottom: 20px; } }
  .our-leaders #board-members .team-grid,
  .our-leaders #executive-team .team-grid {
    background-color: #288e8a;
    padding-top: 76px; }
    @media only screen and (max-width: 767px) {
      .our-leaders #board-members .team-grid,
      .our-leaders #executive-team .team-grid {
        padding-top: 40px; } }
    .our-leaders #board-members .team-grid .container-fluid, .our-leaders #board-members .team-grid .container-sm, .our-leaders #board-members .team-grid .container-md, .our-leaders #board-members .team-grid .container-lg, .our-leaders #board-members .team-grid .container-xl,
    .our-leaders #executive-team .team-grid .container-fluid,
    .our-leaders #executive-team .team-grid .container-sm,
    .our-leaders #executive-team .team-grid .container-md,
    .our-leaders #executive-team .team-grid .container-lg,
    .our-leaders #executive-team .team-grid .container-xl {
      max-width: 80%; }
      @media only screen and (max-width: 1090px) {
        .our-leaders #board-members .team-grid .container-fluid, .our-leaders #board-members .team-grid .container-sm, .our-leaders #board-members .team-grid .container-md, .our-leaders #board-members .team-grid .container-lg, .our-leaders #board-members .team-grid .container-xl,
        .our-leaders #executive-team .team-grid .container-fluid,
        .our-leaders #executive-team .team-grid .container-sm,
        .our-leaders #executive-team .team-grid .container-md,
        .our-leaders #executive-team .team-grid .container-lg,
        .our-leaders #executive-team .team-grid .container-xl {
          max-width: 100%; } }
    .our-leaders #board-members .team-grid .row,
    .our-leaders #executive-team .team-grid .row {
      margin-left: -75px;
      margin-right: -75px;
      display: flex;
      flex-wrap: wrap; }
      @media only screen and (max-width: 1090px) {
        .our-leaders #board-members .team-grid .row,
        .our-leaders #executive-team .team-grid .row {
          margin-left: -25px;
          margin-right: -25px; } }
      @media only screen and (max-width: 767px) {
        .our-leaders #board-members .team-grid .row,
        .our-leaders #executive-team .team-grid .row {
          margin-right: -10px;
          margin-left: -10px; } }
    .our-leaders #board-members .team-grid .team-member,
    .our-leaders #executive-team .team-grid .team-member {
      padding-left: 75px;
      padding-right: 75px;
      margin-bottom: 52px;
      width: 33.33%;
      overflow: hidden; }
      @media only screen and (max-width: 1090px) {
        .our-leaders #board-members .team-grid .team-member,
        .our-leaders #executive-team .team-grid .team-member {
          padding-left: 25px;
          padding-right: 25px; } }
      @media only screen and (max-width: 991px) {
        .our-leaders #board-members .team-grid .team-member,
        .our-leaders #executive-team .team-grid .team-member {
          width: 50%; } }
      @media only screen and (max-width: 767px) {
        .our-leaders #board-members .team-grid .team-member,
        .our-leaders #executive-team .team-grid .team-member {
          padding-left: 10px;
          padding-right: 10px;
          margin-bottom: 40px; } }
      .our-leaders #board-members .team-grid .team-member .bg-img,
      .our-leaders #executive-team .team-grid .team-member .bg-img {
        height: 0;
        width: 100%;
        padding-top: 90%;
        background-size: cover;
        background-repeat: no-repeat; }
        @media only screen and (max-width: 1090px) {
          .our-leaders #board-members .team-grid .team-member .bg-img,
          .our-leaders #executive-team .team-grid .team-member .bg-img {
            padding-top: 100%; } }
      .our-leaders #board-members .team-grid .team-member h5,
      .our-leaders #executive-team .team-grid .team-member h5 {
        margin-top: 10px;
        margin-bottom: 20px;
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.4;
        color: #FFFFFF;
        font-weight: 600; }
        @media only screen and (max-width: 767px) {
          .our-leaders #board-members .team-grid .team-member h5,
          .our-leaders #executive-team .team-grid .team-member h5 {
            margin-top: 10px;
            margin-bottom: 10px; } }
      .our-leaders #board-members .team-grid .team-member h4,
      .our-leaders #executive-team .team-grid .team-member h4 {
        margin-bottom: 0;
        font-family: "Cairo", sans-serif;
        font-size: 0.875rem;
        line-height: 1.42857;
        color: #FFFFFF;
        opacity: 0.6;
        text-transform: uppercase;
        font-weight: 300; }
      .our-leaders #board-members .team-grid .team-member a:hover,
      .our-leaders #executive-team .team-grid .team-member a:hover {
        transition: all 0.3s ease-in-out;
        cursor: url(../images/team-grid-cursor.svg), auto;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out; }

.Our-mandate {
  background-color: #FFFFFF;
  margin: -1px 0px; }
  .Our-mandate .container-fluid, .Our-mandate .container-sm, .Our-mandate .container-md, .Our-mandate .container-lg, .Our-mandate .container-xl {
    padding-top: 130px; }
    @media only screen and (max-width: 767px) {
      .Our-mandate .container-fluid, .Our-mandate .container-sm, .Our-mandate .container-md, .Our-mandate .container-lg, .Our-mandate .container-xl {
        padding-top: 60px; } }
    @media only screen and (max-width: 500px) {
      .Our-mandate .container-fluid, .Our-mandate .container-sm, .Our-mandate .container-md, .Our-mandate .container-lg, .Our-mandate .container-xl {
        padding-top: 30px; } }
  .Our-mandate .row {
    display: flex;
    flex-wrap: wrap; }
  .Our-mandate .img-box {
    width: 52%; }
    @media only screen and (max-width: 991px) {
      .Our-mandate .img-box {
        width: 100%; } }
  .Our-mandate .img-container {
    position: relative;
    padding-top: 122%; }
    @media only screen and (min-width: 1600px) {
      .Our-mandate .img-container {
        padding-top: 100%; } }
    @media only screen and (max-width: 1300px) {
      .Our-mandate .img-container {
        padding-top: 105%; } }
    .Our-mandate .img-container .bg-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-position: center !important; }
    .Our-mandate .img-container .play_white {
      height: 82px;
      width: 82px;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      position: absolute;
      background-color: #FFFFFF;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
      .Our-mandate .img-container .play_white:before {
        content: "\E914";
        position: absolute;
        top: 50%;
        left: 47%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-size: 1.625rem;
        color: #007473; }
        @media only screen and (max-width: 500px) {
          .Our-mandate .img-container .play_white:before {
            font-size: 20px; } }
      @media only screen and (max-width: 500px) {
        .Our-mandate .img-container .play_white {
          height: 60px;
          width: 60px; } }
  .Our-mandate .content-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 48%;
    padding-left: 40px; }
    @media only screen and (max-width: 991px) {
      .Our-mandate .content-container {
        width: 100%;
        padding-left: 15px;
        margin-top: 30px; } }
    .Our-mandate .content-container h2 {
      color: #007473;
      font-weight: 300;
      margin-bottom: 50px; }
      @media only screen and (max-width: 767px) {
        .Our-mandate .content-container h2 {
          margin-bottom: 30px; } }
    .Our-mandate .content-container p {
      font-family: "Cairo", sans-serif;
      font-weight: 300;
      font-size: 1.25rem;
      line-height: 1.3;
      max-width: 491px;
      color: #373433;
      margin: 0; }
      @media only screen and (min-width: 1600px) {
        .Our-mandate .content-container p {
          max-width: 90%; } }
      @media only screen and (max-width: 991px) {
        .Our-mandate .content-container p {
          max-width: 80%;
          font-size: 20px; } }
      @media only screen and (max-width: 600px) {
        .Our-mandate .content-container p {
          max-width: 100%; } }
    .Our-mandate .content-container .bottom-title {
      margin-top: 30px; }
      @media only screen and (max-width: 991px) {
        .Our-mandate .content-container .bottom-title {
          margin-top: 0px; } }
      .Our-mandate .content-container .bottom-title h4 {
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.4;
        color: #373433;
        text-transform: uppercase;
        max-width: 491px;
        margin-top: 0;
        margin-bottom: 14px; }
        @media only screen and (max-width: 991px) {
          .Our-mandate .content-container .bottom-title h4 {
            max-width: 100%;
            margin-top: 20px; } }
      .Our-mandate .content-container .bottom-title p {
        font-family: "Cairo", sans-serif;
        font-size: 0.875rem;
        line-height: 1.57143;
        color: #373433;
        opacity: 0.5 !important;
        max-width: 491px; }
        @media only screen and (max-width: 991px) {
          .Our-mandate .content-container .bottom-title p {
            max-width: 70%;
            font-size: 1.25rem;
            line-height: 1.3; } }
        @media only screen and (max-width: 600px) {
          .Our-mandate .content-container .bottom-title p {
            max-width: 100%; } }

.economic-impact {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #FFFFFF;
  margin: -2px 0px; }
  @media only screen and (max-width: 767px) {
    .economic-impact {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media only screen and (max-width: 500px) {
    .economic-impact {
      padding-top: 20px;
      padding-bottom: 20px; } }
  .economic-impact h2 {
    font-weight: 400;
    color: #373433;
    max-width: 796px; }

.eco-block-background-container {
  display: flex;
  justify-content: space-between;
  height: auto;
  overflow: hidden;
  height: auto;
  width: 100%;
  position: relative; }
  .eco-block-background-container:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)); }
  @media only screen and (max-width: 991px) {
    .eco-block-background-container {
      flex-direction: column;
      height: auto; } }
  .eco-block-background-container .inner-content {
    padding-left: 40px;
    padding-top: 40px;
    width: 55%;
    position: relative;
    z-index: 1; }
    @media only screen and (max-width: 1090px) {
      .eco-block-background-container .inner-content {
        width: 50%; } }
    @media only screen and (max-width: 991px) {
      .eco-block-background-container .inner-content {
        width: 100%; } }
    @media only screen and (max-width: 767px) {
      .eco-block-background-container .inner-content {
        padding-right: 20px;
        padding-left: 20px; } }
    .eco-block-background-container .inner-content .title-wrap.posFix {
      position: fixed;
      top: 45px; }
    .eco-block-background-container .inner-content .title-wrap.posAbs {
      position: absolute;
      bottom: 45px; }
    .eco-block-background-container .inner-content h4 {
      font-family: "Cairo", sans-serif;
      font-size: 3.25rem;
      line-height: 1.2;
      color: #FFFFFF;
      font-weight: 400;
      max-width: 604px;
      text-transform: uppercase;
      padding-bottom: 50px;
      z-index: 1; }
      @media only screen and (max-width: 1090px) {
        .eco-block-background-container .inner-content h4 {
          padding-bottom: 0px; } }
      @media only screen and (max-width: 991px) {
        .eco-block-background-container .inner-content h4 {
          max-width: 100%; } }
      @media only screen and (max-width: 500px) {
        .eco-block-background-container .inner-content h4 {
          font-size: 30px;
          line-height: 42px; } }
    .eco-block-background-container .inner-content .jada-pattern {
      position: absolute;
      left: 0;
      bottom: -100px;
      width: 120%;
      z-index: 1; }
      @media only screen and (max-width: 991px) {
        .eco-block-background-container .inner-content .jada-pattern {
          display: none; } }
  .eco-block-background-container .eco-cards-section {
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    z-index: 1; }
    @media only screen and (max-width: 1090px) {
      .eco-block-background-container .eco-cards-section {
        width: 50%; } }
    @media only screen and (max-width: 991px) {
      .eco-block-background-container .eco-cards-section {
        width: 100%;
        margin-top: 50px;
        border-top: 0.5px solid rgba(218, 218, 218, 0.6); } }
    .eco-block-background-container .eco-cards-section .eco-card {
      width: 50%;
      border: 1px solid rgba(218, 218, 218, 0.6);
      box-sizing: border-box;
      height: 302px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      z-index: 1; }
      @media only screen and (max-width: 991px) {
        .eco-block-background-container .eco-cards-section .eco-card {
          height: 330px; } }
      @media only screen and (max-width: 500px) {
        .eco-block-background-container .eco-cards-section .eco-card {
          height: 240px; } }
      .eco-block-background-container .eco-cards-section .eco-card:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        content: "";
        background-color: #31948f;
        height: 0;
        transition: height 0.6s ease;
        -webkit-transition: height 0.6s ease;
        -moz-transition: height 0.6s ease;
        -ms-transition: height 0.6s ease;
        -o-transition: height 0.6s ease; }
      .eco-block-background-container .eco-cards-section .eco-card .card-box {
        padding: 0 38px;
        text-align: center;
        z-index: 1; }
        @media only screen and (max-width: 500px) {
          .eco-block-background-container .eco-cards-section .eco-card .card-box {
            padding: 0 20px; } }
        @media only screen and (max-width: 500px) {
          .eco-block-background-container .eco-cards-section .eco-card .card-box img {
            height: 80px;
            width: 80px; } }
        .eco-block-background-container .eco-cards-section .eco-card .card-box p {
          font-family: "Cairo", sans-serif;
          font-size: 1.5rem;
          line-height: 1.2;
          font-weight: 400;
          margin-bottom: 0;
          margin-top: 25px;
          text-transform: uppercase;
          color: #FFFFFF;
          opacity: 0;
          height: 0;
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -o-transition: all 0.3s ease; }
          @media only screen and (max-width: 1090px) {
            .eco-block-background-container .eco-cards-section .eco-card .card-box p {
              display: block;
              opacity: 1;
              height: auto; } }
      @media only screen and (min-width: 1200px) {
        .eco-block-background-container .eco-cards-section .eco-card.slide-up:after {
          height: 100% !important;
          top: 0 !important;
          bottom: auto;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in; }
        .eco-block-background-container .eco-cards-section .eco-card.slide-up .card-box p {
          height: auto;
          padding-top: 25px;
          opacity: 1; }
        .eco-block-background-container .eco-cards-section .eco-card.slide-down:after {
          bottom: 0px;
          height: 0;
          top: auto;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in; }
        .eco-block-background-container .eco-cards-section .eco-card.slide-down .card-box p {
          height: 0;
          opacity: 0;
          padding: 0; } }

.jada-magazine-block {
  padding-top: 67px;
  padding-bottom: 60px;
  background-color: #FFFFFF;
  margin: -1px 0px; }
  @media only screen and (max-width: 991px) {
    .jada-magazine-block {
      padding: 0px !important; } }
  .jada-magazine-block .green-container {
    background-color: #f2f8f7;
    padding: 30px 67px;
    margin-left: 0px;
    margin-right: 0px; }
    @media only screen and (max-width: 1090px) {
      .jada-magazine-block .green-container {
        padding-right: 40px;
        padding-left: 40px; } }
    @media only screen and (max-width: 991px) {
      .jada-magazine-block .green-container {
        flex-direction: column-reverse;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 60px;
        padding-bottom: 60px;
        align-items: center; } }
    @media only screen and (max-width: 767px) {
      .jada-magazine-block .green-container {
        padding-left: 20px;
        padding-right: 20px; } }
    .jada-magazine-block .green-container h4 {
      font-family: "Cairo", sans-serif;
      font-size: 3.25rem;
      line-height: 1.2;
      font-weight: 400;
      max-width: 442px;
      color: #000000;
      text-transform: uppercase; }
      @media only screen and (min-width: 1600px) {
        .jada-magazine-block .green-container h4 {
          font-size: 3.5rem;
          line-height: 1.18571;
          max-width: 465px; } }
      @media only screen and (max-width: 991px) {
        .jada-magazine-block .green-container h4 {
          max-width: 100%;
          text-align: center; } }
    .jada-magazine-block .green-container p {
      font-family: "Cairo", sans-serif;
      font-size: 1.25rem;
      line-height: 1.3;
      font-weight: 600;
      max-width: 194px;
      color: #000000;
      margin-top: 2%;
      margin-bottom: 4%; }
      @media only screen and (min-width: 1600px) {
        .jada-magazine-block .green-container p {
          font-size: 1.5rem;
          line-height: 1.25;
          max-width: 225px; } }
      @media only screen and (max-width: 991px) {
        .jada-magazine-block .green-container p {
          max-width: 100%;
          margin-top: 14px;
          margin-bottom: 25px; } }
      @media only screen and (max-width: 500px) {
        .jada-magazine-block .green-container p {
          font-size: 20px;
          line-height: 26px; } }
    .jada-magazine-block .green-container .my-buttons {
      display: flex; }
      @media only screen and (max-width: 500px) {
        .jada-magazine-block .green-container .my-buttons {
          flex-direction: column;
          align-items: center;
          margin-top: 20px; } }
      .jada-magazine-block .green-container .my-buttons .jada-btn {
        min-width: auto !important;
        max-width: none !important; }
        .jada-magazine-block .green-container .my-buttons .jada-btn:before {
          display: none; }
        @media only screen and (max-width: 500px) {
          .jada-magazine-block .green-container .my-buttons .jada-btn {
            width: 180px !important; } }
      @media only screen and (min-width: 1200px) {
        .jada-magazine-block .green-container .my-buttons .jada-btn.bordered_green:hover {
          color: #007473 !important; }
          .jada-magazine-block .green-container .my-buttons .jada-btn.bordered_green:hover::after {
            color: #007473 !important; } }
      .jada-magazine-block .green-container .my-buttons .jada-btn.filled_green-video.arrowed {
        background-color: #007473;
        color: #FFFFFF;
        position: relative;
        margin-right: 20px;
        padding: 18px 32px 18px 64px !important; }
        .jada-magazine-block .green-container .my-buttons .jada-btn.filled_green-video.arrowed:after {
          color: #FFFFFF; }
        @media only screen and (max-width: 767px) {
          .jada-magazine-block .green-container .my-buttons .jada-btn.filled_green-video.arrowed {
            padding: 14px 28px 14px 61px !important; } }
        @media only screen and (max-width: 500px) {
          .jada-magazine-block .green-container .my-buttons .jada-btn.filled_green-video.arrowed {
            margin-right: 0px;
            margin-bottom: 20px; } }
    .jada-magazine-block .green-container .img-bg {
      background-size: cover;
      background-repeat: no-repeat;
      height: 0;
      padding-top: 100%;
      background-position: center !important;
      background-position: center; }
    @media only screen and (max-width: 991px) {
      .jada-magazine-block .green-container .image-col {
        width: 55%; } }
    @media only screen and (max-width: 500px) {
      .jada-magazine-block .green-container .image-col {
        width: 80%; } }
    .jada-magazine-block .green-container .content-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 0px; }
      @media only screen and (max-width: 991px) {
        .jada-magazine-block .green-container .content-col {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 30px;
          padding-right: 0px; } }
      .jada-magazine-block .green-container .content-col .jada-btn {
        max-width: 183px;
        width: fit-content; }

body.arabic .jada-magazine-block .green-container .my-buttons .jada-btn.jada-btn.filled_green-video {
  margin-left: 20px;
  margin-right: 0px;
  padding: 18px 64px 18px 32px !important; }
  @media only screen and (max-width: 767px) {
    body.arabic .jada-magazine-block .green-container .my-buttons .jada-btn.jada-btn.filled_green-video {
      padding: 14px 61px 14px 28px !important; } }
  @media only screen and (max-width: 500px) {
    body.arabic .jada-magazine-block .green-container .my-buttons .jada-btn.jada-btn.filled_green-video {
      margin-left: 0px; } }

body.arabic .range-slider .swiper-container .swiper-button-next, body.arabic .range-slider .swiper-container .swiper-button-prev {
  transform: rotate(180deg); }

body.arabic .range-slider .swiper-container .swiper-button-prev {
  right: 0px;
  left: auto; }

body.arabic .range-slider .swiper-container .swiper-button-next {
  left: auto;
  right: 113px; }
  @media only screen and (max-width: 500px) {
    body.arabic .range-slider .swiper-container .swiper-button-next {
      right: 83px; } }

.eco-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  top: 0;
  z-index: -1;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  position: fixed; }
  @media only screen and (max-width: 1090px) {
    .eco-bg {
      top: 0; } }

.funds-detail-wrapper .small-jada-banner {
  position: relative;
  height: 80vh;
  background-color: #f2f8f7;
  padding-top: 86px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-height: 609px;
  padding-right: 0px; }
  @media only screen and (max-width: 991px) {
    .funds-detail-wrapper .small-jada-banner {
      max-height: none;
      flex-direction: column-reverse;
      background-color: #FFFFFF;
      padding-right: 0px;
      padding-left: 0px; } }
  @media only screen and (max-width: 767px) {
    .funds-detail-wrapper .small-jada-banner {
      height: 100vh;
      max-height: none; } }
  @media only screen and (max-width: 767px) {
    .funds-detail-wrapper .small-jada-banner .breadcrumb-container {
      top: 110px; } }
  .funds-detail-wrapper .small-jada-banner .breadcrumb-container .breadcrumb {
    opacity: 1; }
  .funds-detail-wrapper .small-jada-banner .breadcrumb-container a {
    font-family: "Cairo", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    color: #373433;
    font-weight: 400; }
    @media only screen and (max-width: 767px) {
      .funds-detail-wrapper .small-jada-banner .breadcrumb-container a {
        font-size: 18px;
        line-height: 27px; } }
  .funds-detail-wrapper .small-jada-banner .breadcrumb-container li.active {
    color: #373433; }
  .funds-detail-wrapper .small-jada-banner .breadcrumb-container li {
    font-family: "Cairo", sans-serif;
    font-size: 1.125rem;
    line-height: 1.5;
    color: #373433;
    font-weight: 400; }
    .funds-detail-wrapper .small-jada-banner .breadcrumb-container li:before {
      color: #373433; }
    @media only screen and (max-width: 767px) {
      .funds-detail-wrapper .small-jada-banner .breadcrumb-container li {
        font-size: 18px;
        line-height: 27px; } }
  .funds-detail-wrapper .small-jada-banner .banner-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 67px;
    position: relative;
    width: 70%; }
    @media only screen and (min-width: 1600px) {
      .funds-detail-wrapper .small-jada-banner .banner-left {
        width: 80%; } }
    @media only screen and (max-width: 1090px) {
      .funds-detail-wrapper .small-jada-banner .banner-left {
        width: 60%; } }
    @media only screen and (max-width: 991px) {
      .funds-detail-wrapper .small-jada-banner .banner-left {
        width: 100%;
        padding-right: 40px;
        padding-left: 40px;
        padding-bottom: 40px; } }
    @media only screen and (max-width: 767px) {
      .funds-detail-wrapper .small-jada-banner .banner-left {
        padding-right: 20px;
        padding-left: 20px; } }
    .funds-detail-wrapper .small-jada-banner .banner-left .title h1 {
      font-weight: 600;
      color: #373433;
      margin-bottom: 35px; }
      @media only screen and (max-width: 1090px) {
        .funds-detail-wrapper .small-jada-banner .banner-left .title h1 {
          max-width: 500px !important; } }
      @media only screen and (max-width: 991px) {
        .funds-detail-wrapper .small-jada-banner .banner-left .title h1 {
          max-width: 100% !important;
          margin-top: 35px; } }
  .funds-detail-wrapper .small-jada-banner .banner-right {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 425px;
    padding-right: 40px; }
    @media only screen and (min-width: 1600px) {
      .funds-detail-wrapper .small-jada-banner .banner-right {
        width: 80%; } }
    @media only screen and (max-width: 1090px) {
      .funds-detail-wrapper .small-jada-banner .banner-right {
        width: 40%; } }
    @media only screen and (max-width: 991px) {
      .funds-detail-wrapper .small-jada-banner .banner-right {
        width: 100%;
        height: 60%;
        margin-top: 80px;
        align-items: center;
        justify-content: center;
        background-color: #F2F8F7;
        padding-left: 40px;
        padding-right: 40px;
        max-width: 100%;
        border-left: none; } }
    @media only screen and (max-width: 767px) {
      .funds-detail-wrapper .small-jada-banner .banner-right {
        padding-right: 20px;
        padding-left: 20px; } }
    .funds-detail-wrapper .small-jada-banner .banner-right img {
      max-width: 80%; }

.venture-info {
  width: 100%; }
  .venture-info .flex-container {
    display: flex; }
    @media only screen and (max-width: 1090px) {
      .venture-info .flex-container {
        flex-wrap: wrap; } }
    .venture-info .flex-container .info-box {
      width: 19%;
      box-sizing: border-box;
      padding: 42px 0px 42px 40px;
      border-top: 1px solid rgba(55, 52, 51, 0.15);
      border-bottom: 1px solid rgba(55, 52, 51, 0.15);
      border-right: 1px solid rgba(55, 52, 51, 0.15); }
      @media only screen and (max-width: 1090px) {
        .venture-info .flex-container .info-box {
          width: 50%;
          padding: 30px 30px 30px 40px; } }
      @media only screen and (max-width: 767px) {
        .venture-info .flex-container .info-box {
          width: 100%;
          padding: 20px 20px 20px 20px; } }
      .venture-info .flex-container .info-box.large {
        width: 43%; }
        @media only screen and (max-width: 1090px) {
          .venture-info .flex-container .info-box.large {
            width: 50%; } }
        @media only screen and (max-width: 767px) {
          .venture-info .flex-container .info-box.large {
            width: 100%; } }
      .venture-info .flex-container .info-box:last-child {
        border-right: none; }
      .venture-info .flex-container .info-box p {
        font-family: "Cairo", sans-serif;
        font-size: 0.875rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #373433;
        margin: 0;
        text-transform: uppercase; }
      .venture-info .flex-container .info-box h6 {
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #373433;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 10px; }

.venture-stats {
  padding-top: 84px;
  padding-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .venture-stats {
      padding-top: 60px;
      padding-bottom: 60px; } }
  .venture-stats p {
    font-weight: 400;
    color: #7f8184;
    font-size: 1.5625rem;
    line-height: 1.36;
    font-family: "Cairo", sans-serif;
    width: 80%;
    margin: 0; }
    @media only screen and (max-width: 991px) {
      .venture-stats p {
        width: 100%; } }
  .venture-stats .chart-section {
    width: 100%;
    justify-content: space-between;
    display: flex; }
    .venture-stats .chart-section .graph-box {
      position: relative;
      width: 20%;
      display: flex;
      justify-content: center; }
      .venture-stats .chart-section .graph-box:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        content: attr(data-percent) " %"; }
    .venture-stats .chart-section .progresss {
      overflow: hidden;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg); }
      .venture-stats .chart-section .progresss circle {
        position: relative;
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 1s ease;
        -webkit-transition: stroke-dashoffset 1s ease;
        -moz-transition: stroke-dashoffset 1s ease;
        -ms-transition: stroke-dashoffset 1s ease;
        -o-transition: stroke-dashoffset 1s ease;
        stroke: #007473; }
      .venture-stats .chart-section .progresss .outer-circle {
        stroke-width: 40px; }
      .venture-stats .chart-section .progresss .bar {
        stroke: #fff; }

.ventures-video-slider {
  margin-top: 115px;
  margin-bottom: 80px; }
  @media only screen and (max-width: 767px) {
    .ventures-video-slider {
      margin-top: 60px;
      margin-bottom: 60px; } }
  .ventures-video-slider .container-fluid, .ventures-video-slider .container-sm, .ventures-video-slider .container-md, .ventures-video-slider .container-lg, .ventures-video-slider .container-xl {
    padding-right: 0px;
    overflow: visible; }
  .ventures-video-slider .swiper-container {
    overflow: visible; }
    .ventures-video-slider .swiper-container .swiper-wrapper {
      overflow: visible; }
      .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide {
        overflow: hidden; }
        .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide .bg-img {
          height: 0;
          width: 100%;
          overflow: hidden;
          padding-top: 67%;
          position: relative; }
          .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide .bg-img .img {
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            background-position: center;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            transition: 0.3s all ease; }
          .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide .bg-img:after {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: '';
            background: rgba(0, 0, 0, 0.2);
            transition: 0.3s all ease; }
          .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide .bg-img .play_white {
            height: 115px;
            width: 115px;
            z-index: 2;
            border-radius: 100%;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            -ms-border-radius: 100%;
            -o-border-radius: 100%;
            position: absolute;
            background-color: #FFFFFF;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transition: 0.2s background-color ease; }
            @media only screen and (max-width: 1090px) {
              .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide .bg-img .play_white {
                height: 75px;
                width: 75px; } }
            .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide .bg-img .play_white:before {
              content: '\E914';
              position: absolute;
              top: 50%;
              left: 47%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%);
              font-family: 'icomoon' !important;
              speak: never;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              font-size: 1.875rem;
              color: #007473; }
              @media only screen and (max-width: 1024px) {
                .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide .bg-img .play_white:before {
                  font-size: 1.5625rem; } }
        @media only screen and (min-width: 1200px) {
          .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide:hover .bg-img .img {
            transform: scale(1.1); }
          .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide:hover .bg-img:after {
            background: rgba(0, 0, 0, 0.6); }
          .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide:hover .bg-img .play_white {
            background-color: #007473; }
            .ventures-video-slider .swiper-container .swiper-wrapper .swiper-slide:hover .bg-img .play_white:before {
              color: #FFFFFF; } }

.company-portfolio-slider {
  margin-top: 80px; }
  @media only screen and (max-width: 767px) {
    .company-portfolio-slider {
      margin-top: 60px; } }
  @media only screen and (max-width: 991px) {
    .company-portfolio-slider .container-fluid, .company-portfolio-slider .container-sm, .company-portfolio-slider .container-md, .company-portfolio-slider .container-lg, .company-portfolio-slider .container-xl {
      padding-right: 0; } }
  .company-portfolio-slider .title {
    margin-bottom: 60px; }
    @media only screen and (max-width: 767px) {
      .company-portfolio-slider .title {
        margin-bottom: 30px; } }
    .company-portfolio-slider .title h3 {
      font-family: "Cairo", sans-serif;
      font-size: 3.75rem;
      line-height: 1.2;
      color: #373433;
      font-weight: 400; }
  @media only screen and (min-width: 991px) {
    .company-portfolio-slider .swiper-container {
      display: none; } }
  .company-portfolio-slider .swiper-container .swiper-wrapper .swiper-slide {
    width: auto;
    height: auto;
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .company-portfolio-slider .swiper-container .swiper-wrapper .swiper-slide:first-child {
        margin-left: 0; } }
    .company-portfolio-slider .swiper-container .swiper-wrapper .swiper-slide img {
      height: auto;
      width: auto;
      object-fit: contain;
      max-width: 240px; }
  .company-portfolio-slider .company-logo-wrap {
    display: flex;
    margin-left: 30px; }
    @media only screen and (max-width: 991px) {
      .company-portfolio-slider .company-logo-wrap {
        display: none; } }
    .company-portfolio-slider .company-logo-wrap .logo-container {
      width: 25%;
      padding-right: 120px;
      display: flex;
      align-items: center; }
      @media only screen and (max-width: 1090px) {
        .company-portfolio-slider .company-logo-wrap .logo-container {
          width: 35%;
          padding-right: 80px; } }
      .company-portfolio-slider .company-logo-wrap .logo-container img {
        object-fit: contain;
        max-width: 240px; }

.venture-social-slider {
  margin-top: 80px;
  margin-bottom: 100px; }
  @media only screen and (max-width: 767px) {
    .venture-social-slider {
      margin-top: 60px;
      margin-bottom: 60px; } }
  .venture-social-slider .container-fluid, .venture-social-slider .container-sm, .venture-social-slider .container-md, .venture-social-slider .container-lg, .venture-social-slider .container-xl {
    padding-right: 0px;
    overflow: visible; }
  .venture-social-slider .title {
    margin-bottom: 60px; }
    @media only screen and (max-width: 767px) {
      .venture-social-slider .title {
        margin-bottom: 30px; } }
    .venture-social-slider .title h3 {
      font-family: "Cairo", sans-serif;
      font-size: 3.75rem;
      line-height: 1.2;
      color: #373433;
      font-weight: 400; }
  .venture-social-slider .swiper-container {
    overflow: visible; }
    .venture-social-slider .swiper-container .swiper-wrapper {
      overflow: visible; }
      .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content {
        position: relative;
        width: 100%;
        padding: 30px;
        background-color: #f2f8f7; }
        @media only screen and (max-width: 767px) {
          .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content {
            padding: 20px; } }
        .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content li {
          position: absolute;
          top: 22px;
          right: 27px;
          list-style: none;
          height: 42px;
          width: 42px;
          border-radius: 3px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          background-color: #007473;
          -webkit-transition: 0.3s all ease-in;
          -moz-transition: 0.3s all ease-in;
          -ms-transition: 0.3s all ease-in;
          -o-transition: 0.3s all ease-in;
          transition: 0.3s all ease-in;
          transition: 0.3s background-color ease-in; }
          @media only screen and (max-width: 767px) {
            .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content li {
              height: 36px;
              width: 36px;
              top: 10px;
              right: 10px; } }
          .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content li:hover {
            background-color: transparent;
            border: 1px solid #007473; }
            .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content li:hover .icomoon:before {
              color: #007473; }
          .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content li:last-child {
            margin-right: 0; }
          .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content li a {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0; }
            .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content li a .icomoon {
              position: absolute;
              font-size: 1.25rem;
              line-height: 1.25rem;
              text-align: center;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%); }
              .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content li a .icomoon:before {
                color: #FFFFFF;
                -webkit-transition: 0.3s all ease-in;
                -moz-transition: 0.3s all ease-in;
                -ms-transition: 0.3s all ease-in;
                -o-transition: 0.3s all ease-in;
                transition: 0.3s all ease-in; }
        .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content .date {
          font-family: "Cairo", sans-serif;
          font-size: 1.25rem;
          line-height: 1.4;
          color: #171717;
          opacity: 0.5;
          font-weight: 600;
          transition: 0.2s all ease; }
        .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .content .desc {
          font-family: "Cairo", sans-serif;
          font-size: 1.25rem;
          line-height: 1.4;
          color: #171717;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 0;
          transition: 0.3s all ease; }
      .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .bg-img {
        height: 0px;
        padding-top: 50%;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center; }
      .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide .links {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; }
      @media only screen and (min-width: 1200px) {
        .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide:hover .content li {
          background-color: transparent;
          border: 1px solid #007473;
          background-color: transparent;
          border: 1px solid #007473; }
          .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide:hover .content li .icomoon:before {
            color: #007473; }
        .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide:hover .content .desc {
          color: #007473; }
        .venture-social-slider .swiper-container .swiper-wrapper .swiper-slide:hover .content .date {
          opacity: 1; } }

.progress-bars {
  margin-top: 80px; }
  .progress-bars .flex-wrapper {
    display: flex;
    justify-content: space-between; }
    @media only screen and (min-width: 1600px) {
      .progress-bars .flex-wrapper {
        justify-content: space-around; } }
    @media only screen and (max-width: 767px) {
      .progress-bars .flex-wrapper {
        flex-direction: column; } }
    .progress-bars .flex-wrapper .circle-wrapper {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media only screen and (max-width: 767px) {
        .progress-bars .flex-wrapper .circle-wrapper {
          width: 100%; } }
    .progress-bars .flex-wrapper p {
      font-family: "Cairo", sans-serif;
      font-size: 1.25rem;
      line-height: 1.3;
      text-align: center;
      color: #000000;
      font-weight: 400; }

* {
  margin: 0;
  padding: 0; }

.conainer {
  width: 600px;
  margin: 100px auto;
  font-family: "Arial"; }

.circle_percent {
  font-size: 200px;
  width: 1em;
  height: 1em;
  position: relative;
  background-image: url("https://res.cloudinary.com/dpdltezhg/image/upload/v1632576066/green-lines_keygxn.svg");
  background-repeat: repeat;
  background-size: contain;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  margin: 20px;
  border: 1px solid #007473; }

.circle_inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 1em;
  height: 1em;
  clip: rect(0 1em 1em 0.5em); }

.round_per {
  position: absolute;
  left: 0;
  top: 0;
  width: 1em;
  height: 1em;
  background: #007473;
  clip: rect(0 1em 1em 0.5em);
  transform: rotate(180deg);
  transition: 1.05s; }

.percent_more .circle_inner {
  clip: rect(0 0.5em 1em 0em); }

.percent_more:after {
  position: absolute;
  left: 0.5em;
  top: 0em;
  right: 0;
  bottom: 0;
  background: #007473;
  content: ""; }

.circle_inbox {
  position: absolute;
  top: 35px;
  left: 35px;
  right: 35px;
  bottom: 35px;
  background: #fff;
  z-index: 3;
  border-radius: 50%;
  border: 1px solid #007473; }

.percent_text {
  position: absolute;
  font-size: 2.5rem;
  font-family: "Cairo", sans-serif;
  line-height: 0.5;
  font-weight: 300;
  color: #000000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3; }

body.arabic .small-jada-banner {
  padding-right: 40px;
  padding-left: 0px;
  padding-left: 40px;
  padding-right: 40px; }
  @media only screen and (min-width: 1600px) {
    body.arabic .small-jada-banner {
      padding-right: 4%; } }
  @media only screen and (max-width: 767px) {
    body.arabic .small-jada-banner {
      padding-left: 0px;
      padding-right: 0px; } }

.portfolio-wrapper .jada-main-banner:after {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.portfolio-wrapper .partners {
  padding-top: 122px;
  background-color: #FFFFFF;
  margin: -1px 0px; }
  @media only screen and (max-width: 767px) {
    .portfolio-wrapper .partners {
      padding-top: 60px; } }
  .portfolio-wrapper .partners .list.active {
    animation: Fade 1s linear;
    -webkit-animation: Fade 1s linear; }
  .portfolio-wrapper .partners .tab-content {
    min-height: 400px; }
    @media only screen and (max-width: 1090px) {
      .portfolio-wrapper .partners .tab-content {
        min-height: 350px; } }
  .portfolio-wrapper .partners .partner-flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .portfolio-wrapper .partners .partner-flex .partner-card {
      width: 33.33%;
      height: 400px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 47px;
      box-sizing: border-box;
      border: 1px solid #e1e1e0;
      cursor: pointer; }
      @media only screen and (max-width: 1090px) {
        .portfolio-wrapper .partners .partner-flex .partner-card {
          height: 350px; } }
      @media only screen and (max-width: 991px) {
        .portfolio-wrapper .partners .partner-flex .partner-card {
          width: 50%; } }
      @media only screen and (max-width: 500px) {
        .portfolio-wrapper .partners .partner-flex .partner-card {
          width: 100%; } }
      @media only screen and (min-width: 1200px) {
        .portfolio-wrapper .partners .partner-flex .partner-card.slide-up {
          border: 0px; }
          .portfolio-wrapper .partners .partner-flex .partner-card.slide-up:after {
            height: 100% !important;
            top: 0 !important;
            bottom: auto;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in; }
          .portfolio-wrapper .partners .partner-flex .partner-card.slide-up .slide-icon {
            opacity: 1; }
          .portfolio-wrapper .partners .partner-flex .partner-card.slide-up .img .dark {
            display: none; }
          .portfolio-wrapper .partners .partner-flex .partner-card.slide-up .img .light {
            display: block; }
          .portfolio-wrapper .partners .partner-flex .partner-card.slide-up p {
            color: #FFFFFF; }
        .portfolio-wrapper .partners .partner-flex .partner-card.slide-down:after {
          bottom: 0px;
          height: 0;
          top: auto;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in; }
        .portfolio-wrapper .partners .partner-flex .partner-card.active-card {
          border: 0px; }
          .portfolio-wrapper .partners .partner-flex .partner-card.active-card:after {
            height: 100% !important;
            top: 0;
            bottom: auto;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in; }
          .portfolio-wrapper .partners .partner-flex .partner-card.active-card .slide-icon {
            opacity: 1; }
          .portfolio-wrapper .partners .partner-flex .partner-card.active-card .img .dark {
            display: none; }
          .portfolio-wrapper .partners .partner-flex .partner-card.active-card .img .light {
            display: block; }
          .portfolio-wrapper .partners .partner-flex .partner-card.active-card p {
            color: #FFFFFF; } }
      .portfolio-wrapper .partners .partner-flex .partner-card:after {
        position: absolute;
        height: 0%;
        width: 100%;
        background-color: #007473;
        content: "";
        top: 0;
        left: 0;
        z-index: -1;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in; }
      .portfolio-wrapper .partners .partner-flex .partner-card .links {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
      .portfolio-wrapper .partners .partner-flex .partner-card img {
        width: auto;
        height: auto;
        max-height: 200px; }
        @media only screen and (max-width: 1300px) {
          .portfolio-wrapper .partners .partner-flex .partner-card img {
            width: 50%; } }
      .portfolio-wrapper .partners .partner-flex .partner-card .img {
        display: flex;
        justify-content: center;
        align-items: center; }
        .portfolio-wrapper .partners .partner-flex .partner-card .img .dark {
          display: block; }
        .portfolio-wrapper .partners .partner-flex .partner-card .img .light {
          display: none; }
    .portfolio-wrapper .partners .partner-flex p {
      position: absolute;
      font-family: "Cairo", sans-serif;
      font-size: 1.25rem;
      line-height: 1.4;
      text-align: center;
      font-weight: 600;
      color: #373433;
      bottom: 47px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
      -webkit-transition: all 0.2s ease-in;
      -moz-transition: all 0.2s ease-in;
      -ms-transition: all 0.2s ease-in;
      -o-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in; }
      @media only screen and (max-width: 767px) {
        .portfolio-wrapper .partners .partner-flex p {
          font-size: 20px; } }

.filters {
  z-index: 2;
  width: 100%;
  padding: 0 40px;
  margin-bottom: -40px; }
  @media only screen and (max-width: 991px) {
    .filters {
      padding: 0; } }
  @media only screen and (max-width: 767px) {
    .filters {
      margin-bottom: -30px; } }
  .filters .nav-tabs {
    display: flex;
    width: 100%;
    border-bottom: none !important; }
  .filters .nav-item {
    margin-right: 0px;
    width: 33.33%;
    background: #373433;
    text-align: center;
    margin-bottom: 0 !important;
    float: none;
    position: relative;
    overflow: hidden; }
    .filters .nav-item:after {
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 0;
      width: 100%;
      content: '';
      background-color: #007473;
      transition: 0.2s all ease; }
    .filters .nav-item .nav-link {
      text-align: center;
      color: #FFFFFF !important;
      padding: 35px 0px !important;
      font-size: 1.75rem !important;
      line-height: 1.2 !important;
      font-weight: 600 !important;
      opacity: 1;
      border-radius: 0 !important;
      margin-right: 0 !important;
      border: none !important;
      background: #373433; }
      @media only screen and (max-width: 767px) {
        .filters .nav-item .nav-link {
          padding: 25px 0 !important;
          font-size: 1.125rem !important;
          line-height: 1.30556 !important; } }
    .filters .nav-item:hover {
      border-radius: 0 !important; }
      .filters .nav-item:hover:after {
        height: 6px; }
      .filters .nav-item:hover .nav-link {
        background: none !important;
        border: none !important; }
    .filters .nav-item.active {
      background: #007473;
      border-radius: 0 !important; }
      .filters .nav-item.active .nav-link {
        background: none !important;
        border: none !important; }
    .filters .nav-item:nth-child(2) {
      border-left: 1px solid rgba(255, 255, 255, 0.15);
      border-right: 1px solid rgba(255, 255, 255, 0.15); }
      @media only screen and (max-width: 767px) {
        .filters .nav-item:nth-child(2) {
          border-left: none;
          border-right: none; } }

@media only screen and (max-width: 700px) {
  .publication-news-wrapper .jada-main-banner {
    height: 100vh;
    max-height: 700px;
    min-height: 670px; } }

.publication-news-wrapper .jada-main-banner .banner-link {
  z-index: 4;
  position: absolute;
  right: 40px;
  top: 121px;
  font-size: 1.625rem;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Cairo", sans-serif;
  color: #FFFFFF; }
  .publication-news-wrapper .jada-main-banner .banner-link:after {
    position: absolute;
    bottom: -12px;
    left: 0;
    height: 2px;
    width: 100%;
    content: "";
    background-color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .publication-news-wrapper .jada-main-banner .banner-link {
      top: 200px;
      left: 20px;
      right: auto; } }

.publication-news-wrapper .jada-main-banner .content {
  position: relative;
  margin-bottom: 50px; }
  @media only screen and (max-width: 767px) {
    .publication-news-wrapper .jada-main-banner .content {
      margin-bottom: 100px; } }
  .publication-news-wrapper .jada-main-banner .content .inner-search {
    position: absolute;
    bottom: 0px;
    right: 40px;
    height: 111px;
    width: 111px;
    border-radius: 100%;
    background-color: #FFFFFF;
    transition: 0.3s all ease; }
    @media only screen and (max-width: 991px) {
      .publication-news-wrapper .jada-main-banner .content .inner-search {
        right: 40px;
        top: 55%; } }
    @media only screen and (max-width: 767px) {
      .publication-news-wrapper .jada-main-banner .content .inner-search {
        right: 20px;
        height: 70px;
        width: 70px; } }
    @media only screen and (max-width: 539px) {
      .publication-news-wrapper .jada-main-banner .content .inner-search {
        height: 50px;
        width: 50px; } }
    .publication-news-wrapper .jada-main-banner .content .inner-search:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "\E91C";
      font-family: icomoon !important;
      font-style: normal;
      font-weight: 300;
      color: #007473;
      font-variant: normal;
      text-transform: none;
      font-size: 30px;
      display: inline;
      vertical-align: middle;
      z-index: 2;
      transition: 0.2s all ease; }
      @media only screen and (max-width: 991px) {
        .publication-news-wrapper .jada-main-banner .content .inner-search:after {
          font-size: 35px; } }
      @media only screen and (max-width: 539px) {
        .publication-news-wrapper .jada-main-banner .content .inner-search:after {
          font-size: 30px; } }
    .publication-news-wrapper .jada-main-banner .content .inner-search:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      z-index: 1;
      height: 85px;
      width: 85px;
      content: "";
      border-radius: 100%;
      opacity: 0;
      transition: 0.3s all ease;
      background-color: #007473; }
    @media only screen and (min-width: 1200px) {
      .publication-news-wrapper .jada-main-banner .content .inner-search:hover {
        height: 127px;
        width: 127px; }
        .publication-news-wrapper .jada-main-banner .content .inner-search:hover:after {
          color: #FFFFFF; }
        .publication-news-wrapper .jada-main-banner .content .inner-search:hover:before {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1); } }
    .publication-news-wrapper .jada-main-banner .content .inner-search.enable-search {
      opacity: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.1s all ease; }

.publication-news-wrapper .jada-main-banner:after {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.publication-news-wrapper .media-filters {
  width: 100%;
  margin-bottom: -50px; }
  @media only screen and (max-width: 767px) {
    .publication-news-wrapper .media-filters {
      margin-bottom: -200px; } }

.search_pop-up {
  opacity: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -2;
  transition: opacity 0.4s linear; }
  .search_pop-up .cancel-search {
    position: absolute;
    bottom: -20px;
    right: 40px;
    height: 111px;
    width: 111px;
    border-radius: 100%;
    z-index: 99999;
    background-color: #007473; }
    @media only screen and (max-width: 991px) {
      .search_pop-up .cancel-search {
        position: fixed;
        top: 120px;
        right: 20px !important; } }
    @media only screen and (max-width: 767px) {
      .search_pop-up .cancel-search {
        height: 90px;
        width: 90px; } }
    @media only screen and (max-width: 539px) {
      .search_pop-up .cancel-search {
        height: 60px;
        width: 60px; } }
    .search_pop-up .cancel-search:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: icomoon !important;
      color: #FFFFFF;
      content: "\E905";
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      font-variant: normal;
      text-transform: none;
      display: inline;
      vertical-align: middle; }
  .search_pop-up .search-container {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: relative; }
    @media only screen and (max-width: 600px) {
      .search_pop-up .search-container {
        height: 55%; } }
    .search_pop-up .search-container .search {
      width: 100%;
      display: flex;
      position: relative; }
      .search_pop-up .search-container .search input {
        width: 80%;
        font-size: 2.625rem;
        line-height: 1.10095;
        padding-bottom: 18px;
        color: #FFFFFF;
        background-color: transparent;
        border: 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4); }
        @media only screen and (max-width: 991px) {
          .search_pop-up .search-container .search input {
            padding-right: 30px; } }
        @media only screen and (max-width: 767px) {
          .search_pop-up .search-container .search input {
            font-size: 24px;
            width: 100%; } }
        .search_pop-up .search-container .search input::placeholder {
          color: rgba(255, 255, 255, 0.5); }
      .search_pop-up .search-container .search button {
        background-color: transparent;
        border: none;
        transform: translateX(-20px);
        opacity: 0;
        transform: translateX(0px);
        margin-left: 25px; }
        .search_pop-up .search-container .search button.active {
          opacity: 1; }
        .search_pop-up .search-container .search button .icomoon:before {
          color: #FFFFFF;
          font-size: 1.25rem;
          font-weight: 300; }
          @media only screen and (max-width: 767px) {
            .search_pop-up .search-container .search button .icomoon:before {
              font-size: 20px; } }
        @media only screen and (max-width: 767px) {
          .search_pop-up .search-container .search button {
            position: absolute;
            right: 0; } }
  .search_pop-up.enable-pop-up {
    z-index: 1000;
    opacity: 1; }

.media-filters {
  z-index: 4;
  display: flex;
  padding: 0 40px;
  flex-wrap: wrap; }
  @media only screen and (max-width: 767px) {
    .media-filters {
      padding: 0 20px; } }

.listing-section {
  padding-top: 100px;
  background-color: #FFFFFF;
  padding-bottom: 120px; }
  @media only screen and (max-width: 991px) {
    .listing-section {
      padding-bottom: 60px; } }
  @media only screen and (max-width: 767px) {
    .listing-section {
      padding-top: 250px;
      padding-bottom: 60px; } }
  .listing-section .three-col-grid {
    display: flex;
    margin-left: -20px;
    margin-right: -20px; }
    @media only screen and (max-width: 1090px) {
      .listing-section .three-col-grid {
        margin-left: -10px;
        margin-right: -10px; } }
    @media only screen and (max-width: 991px) {
      .listing-section .three-col-grid {
        margin-left: -20px;
        margin-right: -20px; } }
  .listing-section .ng-repeat-nf {
    opacity: 0;
    width: 33.33%;
    padding: 0 20px;
    margin-bottom: 50px; }
    @media only screen and (max-width: 1024px) {
      .listing-section .ng-repeat-nf {
        margin-bottom: 50px;
        padding: 0 10px; } }
    @media only screen and (max-width: 991px) {
      .listing-section .ng-repeat-nf {
        width: 50%;
        margin-bottom: 50px;
        padding: 0 20px; } }
    @media only screen and (max-width: 767px) {
      .listing-section .ng-repeat-nf {
        width: 100%; } }
  .listing-section .non-featured__cards {
    width: 100%;
    display: flex;
    flex-direction: column; }
    .listing-section .non-featured__cards .card-banner__container {
      position: relative;
      padding-top: 110%;
      overflow: hidden;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease; }
      .listing-section .non-featured__cards .card-banner__container:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        top: 0;
        left: 0;
        background-position-x: center; }
      .listing-section .non-featured__cards .card-banner__container .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center !important; }
      .listing-section .non-featured__cards .card-banner__container .slide-icon {
        z-index: 2;
        position: absolute;
        bottom: 30px;
        top: auto;
        left: 30px;
        height: 70px;
        width: 70px;
        background-color: #FFFFFF;
        border-radius: 100%;
        opacity: 0;
        transition: opacity 0.2s ease-in; }
        .listing-section .non-featured__cards .card-banner__container .slide-icon:after {
          font-family: icomoon;
          font-style: normal;
          font-size: 0.875rem;
          opacity: 1;
          content: "\E922";
          position: absolute;
          color: #007473;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%); }
        @media only screen and (min-width: 1200px) {
          .listing-section .non-featured__cards .card-banner__container .slide-icon:hover:after {
            color: #FFFFFF; } }
    .listing-section .non-featured__cards .card__content {
      margin-top: 23px; }
      .listing-section .non-featured__cards .card__content .title {
        display: flex;
        margin-bottom: 6px; }
        .listing-section .non-featured__cards .card__content .title p {
          font-family: "Cairo", sans-serif;
          color: #373433;
          font-size: 1.125rem;
          line-height: 2.38167;
          font-weight: 300; }
        .listing-section .non-featured__cards .card__content .title .card__btn {
          padding: 0 10px;
          background-color: #eef3f2;
          border-radius: 15px;
          margin-right: 15px; }
      .listing-section .non-featured__cards .card__content h4 {
        font-family: "Cairo", sans-serif;
        font-size: 2.25rem;
        line-height: 1.2;
        font-weight: 400;
        color: #373433;
        padding-right: 10%;
        word-break: break-word; }
        @media only screen and (max-width: 1090px) {
          .listing-section .non-featured__cards .card__content h4 {
            font-size: 30px;
            line-height: 1.26667; } }
    @media only screen and (min-width: 1200px) {
      .listing-section .non-featured__cards a:hover .card-banner__container {
        transform: scale(0.95); }
        .listing-section .non-featured__cards a:hover .card-banner__container:after {
          background-color: rgba(0, 0, 0, 0.4); }
      .listing-section .non-featured__cards a:hover .slide-icon {
        opacity: 1; }
      .listing-section .non-featured__cards a:hover h4 {
        color: #007473;
        -webkit-transition: color 0.3s ease-in;
        -moz-transition: color 0.3s ease-in;
        -ms-transition: color 0.3s ease-in;
        -o-transition: color 0.3s ease-in;
        transition: color 0.3s ease-in; } }
  .listing-section .ng-repeat-f {
    width: 47%;
    padding: 0 20px;
    margin-bottom: 160px; }
    @media only screen and (max-width: 1090px) {
      .listing-section .ng-repeat-f {
        margin-bottom: 50px;
        padding: 0 10px; } }
    @media only screen and (max-width: 991px) {
      .listing-section .ng-repeat-f {
        width: 50%;
        padding: 0 20px; } }
    @media only screen and (max-width: 767px) {
      .listing-section .ng-repeat-f {
        width: 100%; } }
  .listing-section .featured__cards {
    position: relative;
    width: 100%; }
    .listing-section .featured__cards.dark .img-container:after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: url(../images/image-mask.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center !important; }
    @media only screen and (min-width: 1200px) {
      .listing-section .featured__cards.dark a:hover .img-container:after {
        background-color: rgba(0, 0, 0, 0.4); } }
    @media only screen and (min-width: 1200px) {
      .listing-section .featured__cards a:hover .slide-icon {
        opacity: 1; }
      .listing-section .featured__cards a:hover .img-container {
        transform: scale(0.95); } }
    .listing-section .featured__cards .img-container {
      position: relative;
      height: 0;
      padding-top: 132%;
      width: 100%;
      overflow: hidden;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease; }
      .listing-section .featured__cards .img-container .img-bg {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center !important; }
      .listing-section .featured__cards .img-container .slide-icon {
        z-index: 2;
        position: absolute;
        bottom: 30px;
        top: auto;
        left: 30px;
        height: 70px;
        width: 70px;
        background-color: #FFFFFF;
        border-radius: 100%;
        opacity: 0;
        transition: opacity 0.2s ease-in; }
        .listing-section .featured__cards .img-container .slide-icon:after {
          font-family: icomoon;
          font-style: normal;
          font-size: 0.875rem;
          opacity: 1;
          content: "\E922";
          position: absolute;
          color: #007473;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%); }
    .listing-section .featured__cards .content {
      position: absolute;
      top: 40px;
      left: 40px;
      width: calc(100% - 80px);
      height: 20%;
      z-index: 2; }
      @media only screen and (max-width: 1380px) {
        .listing-section .featured__cards .content {
          width: calc(100% - 40px); } }
      @media only screen and (max-width: 1090px) {
        .listing-section .featured__cards .content {
          left: 20px; } }
      .listing-section .featured__cards .content .title {
        display: flex;
        margin-bottom: 6px; }
        .listing-section .featured__cards .content .title p {
          font-family: "Cairo", sans-serif;
          color: #373433;
          font-size: 1.125rem;
          line-height: 2.38167;
          font-weight: 300; }
        .listing-section .featured__cards .content .title .card__btn {
          padding: 0 10px;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 15px;
          margin-right: 15px;
          color: #FFFFFF; }
        .listing-section .featured__cards .content .title .card__date {
          color: #FFFFFF; }
      .listing-section .featured__cards .content h4 {
        font-family: "Cairo", sans-serif;
        font-size: 3.0625rem;
        line-height: 1.2;
        color: #FFFFFF;
        font-weight: 400;
        display: initial; }
        @media only screen and (max-width: 991px) {
          .listing-section .featured__cards .content h4 {
            font-size: 30px;
            line-height: 1.26667; } }
  .listing-section .image-card .card-banner__container {
    padding-top: 122%; }
    .listing-section .image-card .card-banner__container:after {
      height: 0; }
  .listing-section .video .play_white {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100px;
    width: 100px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    transition: 0.2s all ease; }
    .listing-section .video .play_white:before {
      content: "\E914";
      position: absolute;
      top: 50%;
      left: 47%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 1.875rem;
      color: #007473;
      transition: 0.2s all ease; }
      @media only screen and (max-width: 1090px) {
        .listing-section .video .play_white:before {
          font-size: 1.875rem; } }
  .listing-section .video .img-container {
    position: relative;
    overflow: hidden; }
    .listing-section .video .img-container .img-bg {
      background-position: center !important;
      background-repeat: no-repeat;
      padding-top: 110%; }
    .listing-section .video .img-container:after {
      top: 0;
      left: 0;
      content: "";
      background-color: rgba(0, 0, 0, 0.6);
      height: 100%;
      width: 100%;
      z-index: -1;
      position: absolute;
      transition: 0.3s all ease; }
  @media only screen and (min-width: 1200px) {
    .listing-section .video:hover .img-container:after {
      z-index: 1; }
    .listing-section .video:hover .play_white {
      background-color: #007473; }
      .listing-section .video:hover .play_white:before {
        color: #FFFFFF; } }
  .listing-section .video .slide-icon {
    display: none !important; }

.events-wrapper .banner-bg {
  background-position: top center !important; }

.events-wrapper .jada-main-banner .advertisement {
  position: absolute;
  top: 161px;
  left: 40px;
  right: 40px;
  width: calc(100% - 80px);
  height: 62px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: rgba(0, 116, 115, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  z-index: 4; }
  @media only screen and (max-width: 767px) {
    .events-wrapper .jada-main-banner .advertisement {
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 40px);
      padding-left: 10px;
      padding-right: 10px; } }
  .events-wrapper .jada-main-banner .advertisement .coming-soon {
    font-family: "Cairo", sans-serif;
    font-size: 1.125rem;
    line-height: 1.11111;
    color: #FFFFFF;
    opacity: 0.5;
    font-weight: 300; }
  .events-wrapper .jada-main-banner .advertisement .event-title {
    font-family: "Cairo", sans-serif;
    font-size: 1.125rem;
    line-height: 1.11111;
    color: #FFFFFF;
    margin-left: 18px;
    margin-right: 27px;
    font-weight: 600;
    text-align: center; }
    @media only screen and (max-width: 500px) {
      .events-wrapper .jada-main-banner .advertisement .event-title {
        margin-left: 10px;
        margin-right: 10px;
        width: 36%; } }
  .events-wrapper .jada-main-banner .advertisement .learn-more {
    font-family: "Cairo", sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    color: #FFFFFF;
    letter-spacing: 0.1px;
    font-weight: 700;
    position: relative;
    padding-left: 30px;
    text-transform: uppercase; }
    .events-wrapper .jada-main-banner .advertisement .learn-more:after {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translate(0%, -50%);
      content: "\E922";
      font-family: icomoon !important;
      font-style: normal;
      font-weight: 700;
      color: #FFFFFF;
      font-variant: normal;
      text-transform: none;
      font-size: 0.75rem;
      display: inline;
      vertical-align: middle;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in; }
    @media only screen and (max-width: 500px) {
      .events-wrapper .jada-main-banner .advertisement .learn-more {
        padding-left: 20px; } }

.events-wrapper .jada-main-banner .banner-link {
  z-index: 4;
  position: absolute;
  right: 40px;
  top: 121px;
  font-size: 1.625rem;
  line-height: 1.2;
  font-weight: 600;
  font-family: "Cairo", sans-serif;
  color: #FFFFFF; }
  .events-wrapper .jada-main-banner .banner-link:after {
    position: absolute;
    bottom: -12px;
    left: 0;
    height: 2px;
    width: 100%;
    content: "";
    background-color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .events-wrapper .jada-main-banner .banner-link {
      top: 200px;
      left: 20px;
      right: auto; } }

.events-wrapper .jada-main-banner .content {
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 50px; }
  @media only screen and (max-width: 767px) {
    .events-wrapper .jada-main-banner .content {
      margin-bottom: 100px; } }
  .events-wrapper .jada-main-banner .content h1 {
    margin-bottom: 36px; }
    @media only screen and (max-width: 767px) {
      .events-wrapper .jada-main-banner .content h1 {
        margin-bottom: 0px; } }
  .events-wrapper .jada-main-banner .content .inner-search {
    position: absolute;
    bottom: 0px;
    right: 40px;
    height: 111px;
    width: 111px;
    border-radius: 100%;
    background-color: #FFFFFF;
    transition: 0.3s all ease; }
    @media only screen and (max-width: 991px) {
      .events-wrapper .jada-main-banner .content .inner-search {
        right: 40px;
        top: 65%; } }
    @media only screen and (max-width: 767px) {
      .events-wrapper .jada-main-banner .content .inner-search {
        right: 20px;
        height: 70px;
        width: 70px; } }
    @media only screen and (max-width: 767px) {
      .events-wrapper .jada-main-banner .content .inner-search {
        height: 50px;
        width: 50px; } }
    .events-wrapper .jada-main-banner .content .inner-search:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "\E91C";
      font-family: icomoon !important;
      font-style: normal;
      font-weight: 300;
      color: #007473;
      font-variant: normal;
      text-transform: none;
      font-size: 30px;
      display: inline;
      vertical-align: middle;
      z-index: 2;
      transition: 0.2s all ease; }
      @media only screen and (max-width: 991px) {
        .events-wrapper .jada-main-banner .content .inner-search:after {
          font-size: 35px; } }
      @media only screen and (max-width: 539px) {
        .events-wrapper .jada-main-banner .content .inner-search:after {
          font-size: 30px; } }
    .events-wrapper .jada-main-banner .content .inner-search:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      z-index: 1;
      height: 85px;
      width: 85px;
      content: '';
      border-radius: 100%;
      opacity: 0;
      transition: 0.3s all ease;
      background-color: #007473; }
    @media only screen and (min-width: 1200px) {
      .events-wrapper .jada-main-banner .content .inner-search:hover {
        height: 127px;
        width: 127px; }
        .events-wrapper .jada-main-banner .content .inner-search:hover:after {
          color: #FFFFFF; }
        .events-wrapper .jada-main-banner .content .inner-search:hover:before {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1); } }
    .events-wrapper .jada-main-banner .content .inner-search.enable-search {
      opacity: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.1s all ease; }
  .events-wrapper .jada-main-banner .content .custom-tabs li a {
    font-size: 3.375rem;
    line-height: 1;
    color: #FFFFFF !important;
    font-weight: 600;
    padding-bottom: 12px !important; }
    @media only screen and (max-width: 767px) {
      .events-wrapper .jada-main-banner .content .custom-tabs li a {
        font-size: 1.875rem;
        line-height: 1; } }
    .events-wrapper .jada-main-banner .content .custom-tabs li a.active:after {
      position: absolute;
      bottom: 0px;
      left: 0px;
      content: "";
      height: 5px;
      width: 100%;
      background-color: #FFFFFF; }

.events-wrapper .jada-main-banner:after {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.events-wrapper .jada-main-banner .media-filters {
  width: 100%;
  margin-bottom: -50px; }
  @media only screen and (max-width: 767px) {
    .events-wrapper .jada-main-banner .media-filters {
      margin-bottom: -200px; } }

.events-wrapper .jada-main-banner .list-view__filters {
  display: none; }
  .events-wrapper .jada-main-banner .list-view__filters.el-active {
    display: flex; }

.events-wrapper .jada-main-banner .calender-view__filters.cl-active {
  display: flex; }

.events-wrapper .events-listing {
  margin: -1px 0px;
  background-color: #FFFFFF;
  padding-top: 120px;
  padding-bottom: 80px; }
  @media only screen and (max-width: 767px) {
    .events-wrapper .events-listing {
      padding-top: 250px;
      padding-bottom: 0px; } }
  .events-wrapper .events-listing .events-listing-grid {
    display: flex;
    width: 100%;
    padding-right: 0; }
    @media only screen and (max-width: 1090px) {
      .events-wrapper .events-listing .events-listing-grid {
        padding-right: 40px; } }
    @media only screen and (max-width: 991px) {
      .events-wrapper .events-listing .events-listing-grid {
        flex-direction: column; } }
    @media only screen and (max-width: 767px) {
      .events-wrapper .events-listing .events-listing-grid {
        padding-right: 20px; } }
    .events-wrapper .events-listing .events-listing-grid .featured-section {
      margin-bottom: 180px;
      width: 41%;
      padding-top: 40px; }
      @media only screen and (min-width: 1700px) {
        .events-wrapper .events-listing .events-listing-grid .featured-section {
          width: 35%; } }
      @media only screen and (max-width: 1090px) {
        .events-wrapper .events-listing .events-listing-grid .featured-section {
          width: 50%; } }
      @media only screen and (max-width: 991px) {
        .events-wrapper .events-listing .events-listing-grid .featured-section {
          width: 100%;
          margin-bottom: 40px;
          padding-top: 0px; } }
      .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card {
        display: flex;
        height: 0;
        padding-top: 138%;
        align-items: flex-end;
        position: relative;
        overflow: hidden; }
        @media only screen and (min-width: 1700px) {
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card {
            padding-top: 115%; } }
        @media screen and (min-width: 1440px) and (max-height: 800px) {
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card {
            padding-top: 106%; } }
        @media only screen and (max-width: 1400px) {
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card {
            padding-top: 120%; } }
        @media only screen and (max-width: 1090px) {
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card {
            padding-top: 145%; } }
        @media only screen and (max-width: 991px) {
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card {
            padding-top: 115%; } }
        @media only screen and (max-width: 767px) {
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card {
            padding-top: 154%; } }
        .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .bg-img {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          transition: 0.3s all ease-out;
          background-position: center !important; }
        .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .slide-icon {
          top: 20px;
          left: 20px; }
        .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content {
          margin-left: 30px;
          margin-right: 30px;
          margin-bottom: 20px;
          padding: 32px 57px 39px 30px;
          background-color: #FFFFFF;
          width: 100%;
          overflow: hidden;
          z-index: 1; }
          @media only screen and (max-width: 991px) {
            .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content {
              margin-left: 40px;
              margin-right: 40px;
              margin-bottom: 40px; } }
          @media only screen and (max-width: 767px) {
            .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content {
              padding: 15px;
              margin-left: 15px;
              margin-right: 15px;
              margin-bottom: 15px; } }
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content p {
            font-family: "Cairo", sans-serif;
            font-size: 2.25rem;
            line-height: 1.3;
            font-weight: 600;
            color: #373433;
            margin-bottom: 59px;
            max-width: 468px;
            transition: 0.3s color ease;
            -webkit-transition: 0.3s color ease;
            -moz-transition: 0.3s color ease;
            -ms-transition: 0.3s color ease;
            -o-transition: 0.3s color ease; }
            @media screen and (min-width: 1440px) and (max-height: 800px) {
              .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content p {
                font-size: 1.875rem;
                line-height: 1.33333;
                margin-bottom: 40px; } }
            @media only screen and (max-width: 1300px) {
              .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content p {
                font-size: 1.875rem;
                line-height: 1.33333;
                margin-bottom: 40px; } }
            @media only screen and (max-width: 991px) {
              .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content p {
                font-size: 2.375rem;
                line-height: 1.3;
                max-width: 100%; } }
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content .calender-section {
            padding-top: 25px;
            overflow: visible;
            position: relative; }
            .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content .calender-section:after {
              position: absolute;
              top: 0;
              content: "";
              left: -60px;
              height: 1px;
              width: 130%;
              background-color: rgba(0, 0, 0, 0.07); }
            .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content .calender-section .icomoon {
              font-size: 1.25rem;
              margin-right: 20px; }
            .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content .calender-section span {
              font-family: "Cairo", sans-serif;
              font-weight: 600;
              font-size: 1.25rem;
              line-height: 1.4;
              color: #171717; }
            .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card .content .calender-section .date {
              margin-bottom: 16px; }
        @media only screen and (min-width: 1200px) {
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card:hover .bg-img {
            transform: scale(1.1); }
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card:hover .content p {
            color: #007473; }
          .events-wrapper .events-listing .events-listing-grid .featured-section .featured-event-card:hover .slide-icon {
            opacity: 1 !important; } }
    .events-wrapper .events-listing .events-listing-grid .two-col-grid {
      padding-left: 3%;
      width: 59%;
      column-gap: 40px;
      column-count: 2; }
      @media only screen and (min-width: 1700px) {
        .events-wrapper .events-listing .events-listing-grid .two-col-grid {
          width: 65%;
          padding-left: 6%; } }
      @media only screen and (max-width: 1090px) {
        .events-wrapper .events-listing .events-listing-grid .two-col-grid {
          column-count: 1;
          width: 50%; } }
      @media only screen and (max-width: 991px) {
        .events-wrapper .events-listing .events-listing-grid .two-col-grid {
          width: 100%;
          padding-left: 0px;
          column-count: 2;
          column-gap: 20px; } }
      @media only screen and (max-width: 767px) {
        .events-wrapper .events-listing .events-listing-grid .two-col-grid {
          column-count: 1;
          column-gap: 0px; } }
      .events-wrapper .events-listing .events-listing-grid .two-col-grid .content {
        padding: 24px 30px 26px 30px;
        background-color: #f2f8f7;
        overflow: hidden;
        z-index: 1; }
        @media only screen and (max-width: 767px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .content {
            padding: 20px 20px 25px; } }
        .events-wrapper .events-listing .events-listing-grid .two-col-grid .content p {
          font-family: "Cairo", sans-serif;
          font-size: 1.625rem;
          line-height: 1.3;
          font-weight: 600;
          color: #373433;
          margin-bottom: 59px;
          max-width: 468px;
          transition: 0.3s color ease;
          -webkit-transition: 0.3s color ease;
          -moz-transition: 0.3s color ease;
          -ms-transition: 0.3s color ease;
          -o-transition: 0.3s color ease; }
          @media screen and (min-width: 1440px) and (max-height: 800px) {
            .events-wrapper .events-listing .events-listing-grid .two-col-grid .content p {
              font-size: 1.875rem;
              line-height: 1.33333;
              margin-bottom: 40px; } }
          @media only screen and (max-width: 1300px) {
            .events-wrapper .events-listing .events-listing-grid .two-col-grid .content p {
              font-size: 1.875rem;
              line-height: 1.33333;
              margin-bottom: 40px; } }
          @media only screen and (max-width: 991px) {
            .events-wrapper .events-listing .events-listing-grid .two-col-grid .content p {
              font-size: 2.375rem;
              line-height: 1.3; } }
        .events-wrapper .events-listing .events-listing-grid .two-col-grid .content .calender-section {
          position: relative;
          padding-top: 25px;
          overflow: visible; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .content .calender-section:after {
            position: absolute;
            top: 0;
            content: "";
            left: -60px;
            height: 1px;
            width: 130%;
            background-color: rgba(0, 0, 0, 0.07); }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .content .calender-section .icomoon {
            font-size: 1.25rem;
            margin-right: 20px; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .content .calender-section span {
            font-family: "Cairo", sans-serif;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.4;
            color: #171717; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .content .calender-section .date {
            margin-bottom: 16px; }
      .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__small {
        width: 100%;
        padding-bottom: 80px;
        display: inline-block;
        cursor: pointer;
        position: relative; }
        @media only screen and (max-width: 1090px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__small {
            padding-bottom: 40px; } }
        @media only screen and (max-width: 991px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__small {
            width: 100%; } }
        .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__small .slide-icon {
          top: 20px;
          left: 20px; }
        .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__small .img-bg {
          height: 0;
          padding-top: 74%;
          transition: 0.3s all ease;
          position: relative;
          overflow: hidden;
          background-position: center !important;
          background-repeat: no-repeat;
          background-size: cover; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__small .img-bg .img {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            transition: 0.3s all ease;
            background-position: center !important; }
        @media only screen and (min-width: 1200px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__small:hover .img-bg .img {
            transform: scale(1.1); }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__small:hover .slide-icon {
            opacity: 1 !important; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__small:hover .content p {
            color: #007473; } }
      .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__large {
        width: 100%;
        padding-bottom: 80px;
        display: inline-block;
        position: relative;
        cursor: pointer; }
        @media only screen and (max-width: 1090px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__large {
            padding-bottom: 40px; } }
        @media only screen and (max-width: 991px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__large {
            width: 100%; } }
        .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__large .img-bg {
          height: 0;
          padding-top: 131%;
          position: relative;
          overflow: hidden;
          background-position: center !important;
          background-repeat: no-repeat;
          background-size: cover; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__large .img-bg .img {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            transition: 0.3s all ease;
            background-position: center !important; }
        .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__large .slide-icon {
          top: 20px;
          left: 20px; }
        @media only screen and (min-width: 1200px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__large:hover .img-bg .img {
            transform: scale(1.1); }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__large:hover .slide-icon {
            opacity: 1 !important; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card__large:hover .content p {
            color: #007473; } }
      .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card {
        display: inline-block;
        padding: 27px 0px 27px 30px;
        background-color: #FFFFFF;
        overflow: hidden;
        z-index: 1;
        position: relative;
        margin-bottom: 120px;
        cursor: pointer; }
        @media only screen and (max-width: 1090px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card {
            margin-bottom: 40px; } }
        @media only screen and (max-width: 991px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card {
            width: 100%; } }
        .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 6px;
          background-color: #d8ebe8; }
        @media only screen and (max-width: 767px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card {
            padding: 20px 20px 25px; } }
        .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card p {
          font-family: "Cairo", sans-serif;
          font-size: 1.625rem;
          line-height: 1.3;
          font-weight: 600;
          color: #373433;
          margin-bottom: 59px;
          max-width: 468px;
          transition: 0.3s color ease;
          -webkit-transition: 0.3s color ease;
          -moz-transition: 0.3s color ease;
          -ms-transition: 0.3s color ease;
          -o-transition: 0.3s color ease; }
          @media screen and (min-width: 1440px) and (max-height: 800px) {
            .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card p {
              font-size: 1.875rem;
              line-height: 1.33333;
              margin-bottom: 40px; } }
          @media only screen and (max-width: 1300px) {
            .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card p {
              font-size: 1.875rem;
              line-height: 1.33333;
              margin-bottom: 40px; } }
          @media only screen and (max-width: 991px) {
            .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card p {
              font-size: 2.375rem;
              line-height: 1.3; } }
        .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card .calender-section {
          border-top: 0px;
          padding-top: 25px; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card .calender-section .icomoon {
            font-size: 1.25rem;
            margin-right: 20px; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card .calender-section span {
            font-family: "Cairo", sans-serif;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.4;
            color: #171717; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card .calender-section .date {
            margin-bottom: 16px; }
        .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card .slide-icon {
          position: relative;
          height: 0px;
          top: auto;
          left: auto;
          transition: height 0.2s linear;
          transition: opacity 0.1s linear;
          visibility: hidden; }
        @media only screen and (min-width: 1200px) {
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card:hover .slide-icon {
            opacity: 1 !important;
            height: 70px;
            margin-top: 20px;
            visibility: visible; }
          .events-wrapper .events-listing .events-listing-grid .two-col-grid .event-card:hover p {
            color: #007473; } }
  @media only screen and (max-width: 1090px) {
    .events-wrapper .events-listing #calender-view {
      margin-bottom: 0px; } }
  .events-wrapper .events-listing #calender-view .calender-grid {
    width: 100%; }
    .events-wrapper .events-listing #calender-view .calender-grid .calender-card {
      width: 100%;
      display: flex;
      padding-top: 20px;
      opacity: 0; }
      @media only screen and (max-width: 500px) {
        .events-wrapper .events-listing #calender-view .calender-grid .calender-card {
          flex-direction: column; } }
      .events-wrapper .events-listing #calender-view .calender-grid .calender-card:nth-child(even) {
        background-color: #F2F8F7; }
      .events-wrapper .events-listing #calender-view .calender-grid .calender-card .month-box {
        width: 30%; }
        @media only screen and (max-width: 1300px) {
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .month-box {
            width: 25%; } }
        @media only screen and (max-width: 991px) {
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .month-box {
            width: 40%; } }
        @media only screen and (max-width: 500px) {
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .month-box {
            width: 100%; } }
        .events-wrapper .events-listing #calender-view .calender-grid .calender-card .month-box p {
          font-family: "Cairo", sans-serif;
          font-size: 2.375rem;
          line-height: 1.2;
          color: #373433;
          font-weight: 600;
          position: relative; }
          @media only screen and (max-width: 500px) {
            .events-wrapper .events-listing #calender-view .calender-grid .calender-card .month-box p {
              margin-bottom: 20px;
              padding-top: 12px; }
              .events-wrapper .events-listing #calender-view .calender-grid .calender-card .month-box p:after {
                position: absolute;
                top: 0px;
                left: 0px;
                height: 1px;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.2); } }
      .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-multi-wrap {
        width: 70%; }
        @media only screen and (max-width: 1300px) {
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-multi-wrap {
            width: 75%; } }
        @media only screen and (max-width: 991px) {
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-multi-wrap {
            width: 60%; } }
        @media only screen and (max-width: 500px) {
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-multi-wrap {
            width: 100%; } }
      .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box {
        width: 100%;
        display: flex;
        margin-bottom: 40px;
        cursor: pointer; }
        @media only screen and (max-width: 1090px) {
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box {
            align-items: center; } }
        @media only screen and (max-width: 991px) {
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box {
            flex-direction: column; } }
        @media only screen and (max-width: 500px) {
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box {
            margin-bottom: 30px; } }
        .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box p {
          font-family: "Cairo", sans-serif;
          font-size: 2rem;
          line-height: 1.2;
          color: #373433;
          font-weight: 600;
          margin-right: 17px;
          transition: 0.3s all ease; }
        .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .bg-img {
          width: 53%;
          position: relative;
          overflow: hidden; }
          @media only screen and (max-width: 991px) {
            .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .bg-img {
              width: 100%; } }
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .bg-img .img {
            height: 0;
            padding-top: calc(280 / 500 * 100%);
            background-repeat: no-repeat !important;
            background-size: cover !important;
            transition: all 0.3s  ease; }
        .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content {
          margin-left: 40px;
          padding-top: 26px;
          padding-bottom: 26px;
          overflow: hidden;
          z-index: 1;
          display: flex;
          flex-direction: column;
          justify-content: center; }
          @media only screen and (max-width: 1090px) {
            .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content {
              margin-left: 20px;
              padding-top: 10px; } }
          @media only screen and (max-width: 991px) {
            .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content {
              margin-left: 0px; } }
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content p {
            font-family: "Cairo", sans-serif;
            font-size: 2rem;
            line-height: 1.3;
            font-weight: 600;
            color: #373433;
            margin-bottom: 0px;
            max-width: 332px;
            transition: 0.3s color ease;
            -webkit-transition: 0.3s color ease;
            -moz-transition: 0.3s color ease;
            -ms-transition: 0.3s color ease;
            -o-transition: 0.3s color ease; }
            @media screen and (min-width: 1440px) and (max-height: 800px) {
              .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content p {
                font-size: 1.75rem;
                line-height: 1.37857;
                margin-bottom: 40px; } }
            @media only screen and (max-width: 1300px) {
              .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content p {
                font-size: 1.875rem;
                line-height: 1.33333;
                margin-bottom: 40px; } }
            @media only screen and (max-width: 1090px) {
              .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content p {
                margin-bottom: 0px; } }
            @media only screen and (max-width: 991px) {
              .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content p {
                font-size: 2.375rem;
                line-height: 1.3;
                margin-bottom: 0px;
                max-width: 100%; } }
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content .calender-section {
            padding-top: 30px; }
            .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content .calender-section .icomoon {
              font-size: 1.25rem;
              margin-right: 20px; }
            .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content .calender-section span {
              font-family: "Cairo", sans-serif;
              font-weight: 600;
              font-size: 1.25rem;
              line-height: 1.4;
              color: #171717; }
            .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .content .calender-section .date {
              margin-bottom: 16px; }
        .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box .slide-icon {
          top: 20px;
          left: 20px; }
        @media only screen and (min-width: 1200px) {
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box:hover .bg-img .img {
            transform: scale(1.05); }
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box:hover .bg-img .slide-icon {
            opacity: 1 !important; }
          .events-wrapper .events-listing #calender-view .calender-grid .calender-card .detail-box:hover p {
            color: #007473; } }

.events-wrapper .events-footer__wrapper {
  background-color: #FFFFFF;
  padding-top: 60px;
  margin-top: -1px; }

@media only screen and (max-width: 700px) {
  .emp-listing-wrapper .jada-main-banner {
    height: 100vh;
    max-height: 700px; } }

.emp-listing-wrapper .jada-main-banner .content {
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 100px; }
  @media screen and (max-width: 1440px), screen and (max-height: 800px) {
    .emp-listing-wrapper .jada-main-banner .content {
      margin-bottom: 50px; } }
  .emp-listing-wrapper .jada-main-banner .content h1 {
    margin-bottom: 0px; }
  .emp-listing-wrapper .jada-main-banner .content .inner-search {
    position: absolute;
    bottom: 0px;
    right: 40px;
    height: 111px;
    width: 111px;
    border-radius: 100%;
    background-color: #FFFFFF;
    transition: 0.3s all ease; }
    @media only screen and (max-width: 991px) {
      .emp-listing-wrapper .jada-main-banner .content .inner-search {
        right: 40px;
        top: 50%;
        transform: translateY(-50%); } }
    @media only screen and (max-width: 767px) {
      .emp-listing-wrapper .jada-main-banner .content .inner-search {
        right: 20px;
        height: 90px;
        width: 90px; } }
    @media only screen and (max-width: 767px) {
      .emp-listing-wrapper .jada-main-banner .content .inner-search {
        height: 50px;
        width: 50px; } }
    .emp-listing-wrapper .jada-main-banner .content .inner-search:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: "\E91C";
      font-family: icomoon !important;
      font-style: normal;
      font-weight: 300;
      color: #007473;
      font-variant: normal;
      text-transform: none;
      font-size: 30px;
      display: inline;
      vertical-align: middle;
      z-index: 2;
      transition: 0.2s all ease; }
      @media only screen and (max-width: 991px) {
        .emp-listing-wrapper .jada-main-banner .content .inner-search:after {
          font-size: 35px; } }
      @media only screen and (max-width: 539px) {
        .emp-listing-wrapper .jada-main-banner .content .inner-search:after {
          font-size: 30px; } }
    .emp-listing-wrapper .jada-main-banner .content .inner-search:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      z-index: 1;
      height: 85px;
      width: 85px;
      content: "";
      border-radius: 100%;
      opacity: 0;
      transition: 0.3s all ease;
      background-color: #007473; }
    @media only screen and (min-width: 1200px) {
      .emp-listing-wrapper .jada-main-banner .content .inner-search:hover {
        height: 127px;
        width: 127px; }
        .emp-listing-wrapper .jada-main-banner .content .inner-search:hover:after {
          color: #FFFFFF; }
        .emp-listing-wrapper .jada-main-banner .content .inner-search:hover:before {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1); } }
    .emp-listing-wrapper .jada-main-banner .content .inner-search.enable-search {
      opacity: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.1s all ease; }

.emp-listing-wrapper .jada-main-banner .three-col-block-emp {
  padding-left: 40px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  z-index: 2;
  margin-bottom: -110px;
  display: none; }
  @media only screen and (max-width: 1090px) {
    .emp-listing-wrapper .jada-main-banner .three-col-block-emp {
      display: block; } }
  @media only screen and (max-width: 991px) {
    .emp-listing-wrapper .jada-main-banner .three-col-block-emp {
      padding-left: 40px;
      justify-content: center;
      margin-bottom: -85px; } }
  @media only screen and (max-width: 767px) {
    .emp-listing-wrapper .jada-main-banner .three-col-block-emp {
      padding: 0px 20px;
      margin-bottom: -70px; } }
  .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block {
    width: auto;
    display: flex;
    z-index: 2;
    position: relative; }
    .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .abs-link {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
    .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .slide-icon {
      bottom: 14px;
      left: 14px;
      top: auto;
      opacity: 1; }
      @media only screen and (max-width: 500px) {
        .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .slide-icon {
          height: 45px;
          width: 45px;
          bottom: 10px;
          left: 10px; }
          .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .slide-icon:after {
            font-size: 0.875rem; } }
    .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .image-block-wrapper {
      width: 100%;
      display: flex;
      position: relative; }
    .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .video {
      width: 100%;
      display: flex;
      position: relative; }
      .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .video .img-section {
        position: relative; }
        .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .video .img-section .play_white {
          position: absolute;
          height: 70px;
          width: 70px;
          bottom: 14px;
          left: 14px;
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
          background-color: #FFFFFF; }
          .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .video .img-section .play_white:before {
            content: "\E914";
            position: absolute;
            top: 50%;
            left: 47%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            font-family: "icomoon" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            font-size: 1.25rem;
            color: #007473; }
          @media only screen and (max-width: 500px) {
            .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .video .img-section .play_white {
              height: 45px;
              width: 45px;
              bottom: 10px;
              left: 10px; }
              .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .video .img-section .play_white:after {
                font-size: 0.875rem; } }
    .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .img-section {
      height: 204px;
      width: 28%;
      overflow: hidden;
      position: relative; }
      @media only screen and (max-width: 991px) {
        .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .img-section {
          height: 195px; } }
      @media only screen and (max-width: 767px) {
        .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .img-section {
          height: 150px;
          display: none; } }
      .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .img-section .img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center !important;
        transition: 0.3s all ease; }
      @media only screen and (max-width: 500px) {
        .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .img-section {
          width: 30%; } }
    .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .content-section {
      background-color: #007473;
      padding: 5%;
      width: 70%;
      transition: 0.3s all ease; }
      @media only screen and (max-width: 1300px) {
        .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .content-section {
          padding: 3%; } }
      @media only screen and (max-width: 767px) {
        .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .content-section {
          width: 100%; } }
      .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .content-section h4 {
        font-size: 1.875rem;
        line-height: 1.2;
        font-weight: 400;
        font-family: "Cairo", sans-serif;
        color: #FFFFFF;
        margin-bottom: 27px; }
        @media only screen and (max-width: 500px) {
          .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .content-section h4 {
            margin-bottom: 10px; } }
      .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .content-section span {
        font-family: "Cairo", sans-serif;
        font-weight: 200;
        font-size: 0.875rem;
        line-height: 1.3;
        color: #FFFFFF;
        letter-spacing: 0.5px; }
      .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block .content-section .speaker {
        font-family: "Cairo", sans-serif;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.5;
        color: #FFFFFF;
        margin-bottom: 0px; }
    @media only screen and (min-width: 1200px) {
      .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block:hover .img {
        transform: scale(1.1); }
      .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block:hover .slide-icon {
        opacity: 1; }
      .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block:hover .content-section {
        background-color: #373433; }
      .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block:hover .play_white {
        background-color: #007473 !important; }
        .emp-listing-wrapper .jada-main-banner .three-col-block-emp .col-block:hover .play_white:before {
          color: #FFFFFF !important; } }

.emp-listing-wrapper .jada-main-banner .emp-timeline-desktop {
  width: 100%;
  z-index: 2;
  margin-bottom: -110px;
  overflow: visible;
  position: relative;
  height: 250px; }
  @media only screen and (max-width: 1090px) {
    .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop {
      display: none; } }
  .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .thumbs {
    position: absolute;
    top: 0px;
    left: 0px;
    width: auto;
    overflow: hidden;
    display: flex;
    padding: 0 100px 0px 40px; }
  .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block {
    width: 640px;
    height: 100%;
    display: flex;
    z-index: 2;
    cursor: pointer;
    position: relative; }
    .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .abs-link {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
    .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .slide-icon {
      bottom: 14px;
      left: 14px;
      top: auto;
      opacity: 1; }
      @media only screen and (max-width: 500px) {
        .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .slide-icon {
          height: 45px;
          width: 45px;
          bottom: 10px;
          left: 10px; }
          .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .slide-icon:after {
            font-size: 0.875rem; } }
    .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .image-block-wrapper {
      width: 100%;
      display: flex;
      position: relative; }
    .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .video {
      width: 100%;
      display: flex; }
      .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .video .img-section {
        position: relative; }
        .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .video .img-section .play_white {
          position: absolute;
          height: 70px;
          width: 70px;
          bottom: 14px;
          left: 14px;
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
          background-color: #FFFFFF; }
          .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .video .img-section .play_white:before {
            content: "\E914";
            position: absolute;
            top: 50%;
            left: 47%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            font-family: "icomoon" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            font-size: 1.25rem;
            color: #007473; }
          @media only screen and (max-width: 500px) {
            .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .video .img-section .play_white {
              height: 45px;
              width: 45px;
              bottom: 10px;
              left: 10px; }
              .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .video .img-section .play_white:after {
                font-size: 0.875rem; } }
    .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .img-section {
      height: auto;
      width: 28%;
      overflow: hidden;
      position: relative; }
      .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .img-section .img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center !important;
        transition: 0.3s all ease; }
      @media only screen and (max-width: 500px) {
        .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .img-section {
          width: 30%; } }
    .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .content-section {
      background-color: #007473;
      padding: 5%;
      width: 70%;
      transition: 0.3s all ease; }
      @media only screen and (max-width: 1300px) {
        .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .content-section {
          padding: 3%; } }
      .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .content-section h4 {
        font-size: 1.875rem;
        line-height: 1.2;
        font-weight: 400;
        font-family: "Cairo", sans-serif;
        color: #FFFFFF;
        margin-bottom: 27px; }
        @media only screen and (max-width: 500px) {
          .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .content-section h4 {
            font-size: 1.375rem;
            line-height: 1.27273;
            margin-bottom: 10px; } }
      .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .content-section span {
        font-family: "Cairo", sans-serif;
        font-weight: 200;
        font-size: 0.875rem;
        line-height: 1.3;
        color: #FFFFFF;
        letter-spacing: 0.5px; }
      .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block .content-section .speaker {
        font-family: "Cairo", sans-serif;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.5;
        color: #FFFFFF;
        margin-bottom: 0px; }
    @media only screen and (min-width: 1200px) {
      .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block:hover .img {
        transform: scale(1.1); }
      .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block:hover .slide-icon {
        opacity: 1; }
      .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block:hover .content-section {
        background-color: #373433; }
      .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block:hover .play_white {
        background-color: #007473 !important; }
        .emp-listing-wrapper .jada-main-banner .emp-timeline-desktop .col-block:hover .play_white:before {
          color: #FFFFFF !important; } }

.emp-listing-wrapper .jada-main-banner:after {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.emp-listing-wrapper .all-emps-wrapper {
  padding-top: 192px;
  background-color: #FFFFFF;
  padding-bottom: 80px; }
  @media only screen and (max-width: 767px) {
    .emp-listing-wrapper .all-emps-wrapper {
      padding-bottom: 0px; } }
  @media only screen and (max-width: 500px) {
    .emp-listing-wrapper .all-emps-wrapper {
      padding-top: 100px; } }
  .emp-listing-wrapper .all-emps-wrapper .title-wrap h3 {
    font-family: "Cairo", sans-serif;
    font-size: 3.25rem;
    line-height: 1;
    font-weight: 600;
    color: #373433; }
  .emp-listing-wrapper .all-emps-wrapper .title-wrap .media-filters {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 36px; }
  .emp-listing-wrapper .all-emps-wrapper .three-col-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 52px; }
    @media only screen and (max-width: 1090px) {
      .emp-listing-wrapper .all-emps-wrapper .three-col-grid {
        margin-right: -10px;
        margin-left: -10px; } }
    @media only screen and (max-width: 767px) {
      .emp-listing-wrapper .all-emps-wrapper .three-col-grid {
        margin-left: -20px;
        margin-right: -20px; } }
    .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content {
      padding: 24px 30px 26px 30px;
      background-color: #f2f8f7;
      overflow: hidden;
      z-index: 1; }
      @media only screen and (max-width: 767px) {
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content {
          padding: 20px 20px 25px; } }
      .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content p {
        font-family: "Cairo", sans-serif;
        font-size: 1.625rem;
        line-height: 1.3;
        font-weight: 600;
        color: #373433;
        margin-bottom: 30px;
        max-width: 468px;
        transition: 0.3s color ease;
        -webkit-transition: 0.3s color ease;
        -moz-transition: 0.3s color ease;
        -ms-transition: 0.3s color ease;
        -o-transition: 0.3s color ease;
        min-height: 68px; }
        @media only screen and (max-width: 1090px) {
          .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content p {
            font-size: 1.5rem;
            line-height: 1.325;
            margin-bottom: 40px; } }
        @media only screen and (max-width: 991px) {
          .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content p {
            font-size: 1.625rem;
            line-height: 1.3; } }
        @media only screen and (max-width: 500px) {
          .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content p {
            font-size: 1.75rem;
            line-height: 1.27857; } }
      .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content .calender-section {
        padding-top: 25px;
        position: relative; }
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content .calender-section:after {
          position: absolute;
          content: "";
          top: 0;
          left: -40px;
          height: 1px;
          width: 130%;
          background-color: rgba(0, 0, 0, 0.07); }
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content .calender-section .icomoon {
          font-size: 1.25rem;
          margin-right: 20px; }
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content .calender-section span {
          font-family: "Cairo", sans-serif;
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 1.4;
          color: #171717; }
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .content .calender-section .date {
          margin-bottom: 16px; }
    .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small {
      width: 33.33%;
      margin-bottom: 120px;
      padding: 0 20px;
      overflow: hidden; }
      @media only screen and (max-width: 1090px) {
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small {
          margin-bottom: 60px;
          padding: 0 10px; } }
      @media only screen and (max-width: 991px) {
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small {
          width: 50%; } }
      @media only screen and (max-width: 767px) {
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small {
          width: 100%;
          padding: 0 20px; } }
      .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small .img-container {
        overflow: hidden;
        position: relative; }
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small .img-container .training-batch {
          position: absolute;
          top: 12px;
          left: 12px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 12px;
          font-size: 1.125rem;
          line-height: 2.38167; }
          @media only screen and (max-width: 991px) {
            .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small .img-container .training-batch {
              font-size: 16px;
              line-height: 40.87px; } }
      .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small a:hover .img-bg {
        transform: scale(1.1); }
      .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small a:hover .content p {
        color: #007473; }
      .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small .img-bg {
        background-repeat: no-repeat;
        background-size: cover;
        height: 0;
        padding-top: 64%;
        position: relative;
        background-position: center !important;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        @media only screen and (max-width: 991px) {
          .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small .img-bg {
            padding-top: 80%; } }
        @media only screen and (max-width: 500px) {
          .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__small .img-bg {
            padding-top: 75%; } }
    .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large {
      width: 33.33%;
      padding: 0 20px;
      margin-bottom: 120px;
      overflow: hidden; }
      @media only screen and (max-width: 1090px) {
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large {
          margin-bottom: 60px;
          padding: 0 10px; } }
      @media only screen and (max-width: 991px) {
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large {
          width: 50%; } }
      @media only screen and (max-width: 767px) {
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large {
          width: 100%;
          padding: 0 20px; } }
      .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large .img-container {
        overflow: hidden;
        position: relative; }
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large .img-container .training-batch {
          position: absolute;
          top: 12px;
          left: 12px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 12px;
          font-size: 1.125rem;
          line-height: 2.38167; }
          @media only screen and (max-width: 991px) {
            .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large .img-container .training-batch {
              font-size: 16px;
              line-height: 40.87px; } }
      @media only screen and (min-width: 1200px) {
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large a:hover .img-bg {
          transform: scale(1.1); }
        .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large a:hover .content p {
          color: #007473; } }
      .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large .img-bg {
        background-repeat: no-repeat;
        background-size: cover;
        height: 0;
        padding-top: 117%;
        background-position: center !important;
        position: relative;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        @media only screen and (max-width: 991px) {
          .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large .img-bg {
            padding-top: 80%; } }
        @media only screen and (max-width: 500px) {
          .emp-listing-wrapper .all-emps-wrapper .three-col-grid .event-card__large .img-bg {
            padding-top: 75%; } }
    .emp-listing-wrapper .all-emps-wrapper .three-col-grid .training-batch.deep-dives {
      background-color: #eef3f2;
      color: #373433; }
    .emp-listing-wrapper .all-emps-wrapper .three-col-grid .training-batch.foundational-training {
      background-color: rgba(0, 116, 115, 0.8);
      color: #FFFFFF; }
  @media only screen and (max-width: 991px) {
    .emp-listing-wrapper .all-emps-wrapper .three-col-grid.row > div {
      width: 50% !important; } }
  @media only screen and (max-width: 767px) {
    .emp-listing-wrapper .all-emps-wrapper .three-col-grid.row > div {
      width: 100% !important; } }

.emp-detail-wrapper {
  position: relative; }
  @media only screen and (max-width: 500px) {
    .emp-detail-wrapper .detail-page-banner .breadcrumb {
      flex-direction: column;
      padding: 0px 20px 0px 0px; }
      .emp-detail-wrapper .detail-page-banner .breadcrumb li {
        margin-bottom: 3px; }
        .emp-detail-wrapper .detail-page-banner .breadcrumb li:nth-child(3):before {
          padding-left: 0px; } }
  .emp-detail-wrapper .bg-img {
    z-index: -1;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    left: 0; }
    @media only screen and (max-width: 1090px) {
      .emp-detail-wrapper .bg-img {
        display: none; } }
  .emp-detail-wrapper .emp-stats {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    background-color: #FFFFFF; }
    .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper {
      width: 56%;
      padding: 25px 40px;
      border-top: 1px solid rgba(55, 52, 51, 0.15);
      border-right: 1px solid rgba(55, 52, 51, 0.15); }
      @media only screen and (max-width: 1090px) {
        .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper {
          width: 50%; } }
      @media only screen and (max-width: 991px) {
        .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper {
          width: 100%; } }
      @media only screen and (max-width: 600px) {
        .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper {
          padding: 20px; } }
      .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper .title-wrap {
        display: flex;
        width: 100%;
        justify-content: space-between; }
        .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper .title-wrap p {
          font-family: "Cairo", sans-serif;
          font-size: 0.875rem;
          line-height: 1.3;
          color: #373433;
          text-transform: uppercase;
          font-weight: 400;
          letter-spacing: 0.5px; }
        .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper .title-wrap .total-seats {
          font-family: "Cairo", sans-serif;
          font-size: 1.25rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          font-weight: 700; }
      .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper .horizontal-progress-bar {
        margin-top: 25px;
        width: 100%; }
        .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper .horizontal-progress-bar .meter {
          width: 100%;
          height: 4px;
          background-color: rgba(55, 52, 51, 0.3);
          border-radius: 2px; }
          .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper .horizontal-progress-bar .meter span {
            display: block;
            height: 100%;
            background-color: #007473;
            position: relative;
            overflow: hidden;
            border-radius: 2px; }
            .emp-detail-wrapper .emp-stats .seat-progress-bar-wrapper .horizontal-progress-bar .meter span:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, #007473), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, #007473), color-stop(0.75, #007473), color-stop(0.75, transparent), to(transparent));
              z-index: 1;
              -webkit-background-size: 20px 20px;
              overflow: hidden;
              -webkit-animation: move 2s linear infinite; }
    .emp-detail-wrapper .emp-stats .buttons-wrapper {
      width: 44%;
      display: flex; }
      @media only screen and (max-width: 1090px) {
        .emp-detail-wrapper .emp-stats .buttons-wrapper {
          width: 50%; } }
      @media only screen and (max-width: 991px) {
        .emp-detail-wrapper .emp-stats .buttons-wrapper {
          width: 100%; } }
      @media only screen and (max-width: 767px) {
        .emp-detail-wrapper .emp-stats .buttons-wrapper {
          flex-wrap: wrap; } }
      .emp-detail-wrapper .emp-stats .buttons-wrapper .btns {
        width: 50%;
        height: 100%;
        display: flex; }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .emp-stats .buttons-wrapper .btns {
            width: 100%;
            height: auto;
            min-height: 85px; } }
        .emp-detail-wrapper .emp-stats .buttons-wrapper .btns a {
          width: 100%;
          height: 100%; }
        .emp-detail-wrapper .emp-stats .buttons-wrapper .btns .btn-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          color: #FFFFFF;
          font-family: "Cairo", sans-serif;
          text-transform: uppercase;
          width: 100%;
          height: 100%;
          padding-left: 8px; }
          @media only screen and (max-width: 991px) {
            .emp-detail-wrapper .emp-stats .buttons-wrapper .btns .btn-wrap {
              padding: 15px 0px; } }
          @media only screen and (max-width: 767px) {
            .emp-detail-wrapper .emp-stats .buttons-wrapper .btns .btn-wrap {
              justify-content: center; } }
          .emp-detail-wrapper .emp-stats .buttons-wrapper .btns .btn-wrap span {
            font-size: 1rem;
            line-height: 1.25;
            letter-spacing: 0.5px;
            font-weight: 700;
            margin-left: 16px; }
            @media only screen and (max-width: 991px) {
              .emp-detail-wrapper .emp-stats .buttons-wrapper .btns .btn-wrap span {
                max-width: 100%; } }
            @media only screen and (max-width: 767px) {
              .emp-detail-wrapper .emp-stats .buttons-wrapper .btns .btn-wrap span {
                font-size: 20px; } }
            @media only screen and (max-width: 500px) {
              .emp-detail-wrapper .emp-stats .buttons-wrapper .btns .btn-wrap span {
                max-width: 100%; } }
        .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.hide {
          display: none; }
        .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.register {
          background-color: #007473;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          transition: all 0.3s ease-in;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          z-index: 2; }
          .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.register .time-batch {
            font-size: 12px;
            line-height: 15.6px;
            letter-spacing: 0.37;
            text-transform: capitalize;
            font-weight: 600;
            margin-left: 16px;
            color: #FFFFFF;
            display: block; }
            @media only screen and (max-width: 767px) {
              .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.register .time-batch {
                text-align: left;
                margin-top: 5px; } }
          .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.register:after {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 0%;
            width: 100%;
            background-color: #373433;
            transition: all 0.3s ease-in;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            z-index: -1; }
          .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.register:hover a {
            text-decoration: none; }
          @media only screen and (min-width: 1200px) {
            .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.register:hover:after {
              height: 70%; } }
        .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.download {
          background-color: #373433;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          transition: all 0.3s ease-in;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          z-index: 2; }
          .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.download .time-batch {
            font-size: 0.75rem;
            line-height: 1.3;
            letter-spacing: 0.37;
            text-transform: capitalize;
            font-weight: 600;
            margin-left: 16px;
            color: #FFFFFF;
            display: block; }
          .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.download:after {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 0%;
            width: 100%;
            background-color: #007473;
            transition: all 0.3s ease-in;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            z-index: -1; }
          .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.download:hover a {
            text-decoration: none; }
          @media only screen and (min-width: 1200px) {
            .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.download:hover:after {
              height: 75%; } }
          .emp-detail-wrapper .emp-stats .buttons-wrapper .btns.download .icomoon {
            font-size: 1.125rem; }
        .emp-detail-wrapper .emp-stats .buttons-wrapper .btns li {
          list-style: none;
          position: relative;
          height: 70px;
          width: 70px;
          background-color: rgba(255, 255, 255, 0.15);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%; }
          @media only screen and (max-width: 600px) {
            .emp-detail-wrapper .emp-stats .buttons-wrapper .btns li {
              height: 40px;
              width: 40px; } }
          .emp-detail-wrapper .emp-stats .buttons-wrapper .btns li .icomoon {
            font-size: 0.875rem;
            color: #FFFFFF; }
            @media only screen and (max-width: 600px) {
              .emp-detail-wrapper .emp-stats .buttons-wrapper .btns li .icomoon {
                font-size: 0.75rem; } }
      .emp-detail-wrapper .emp-stats .buttons-wrapper .open-survey {
        width: 100% !important;
        padding: 24px 15px 24px 15px;
        margin-right: 0px; }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .emp-stats .buttons-wrapper .open-survey .btn-wrap {
            justify-content: flex-start; } }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .emp-stats .buttons-wrapper .open-survey .btn-wrap div {
            width: 80%;
            margin-left: 16px; } }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .emp-stats .buttons-wrapper .open-survey .btn-wrap div span {
            text-align: left;
            width: 100%;
            display: block;
            margin-left: 0px; } }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .emp-stats .buttons-wrapper .open-survey .btn-wrap div .time-batch {
            margin-left: 0px; } }
      .emp-detail-wrapper .emp-stats .buttons-wrapper .download-certificate .btn-wrap div span {
        display: block; }
    .emp-detail-wrapper .emp-stats .event-info-container {
      width: 100%;
      display: flex; }
      @media only screen and (max-width: 1090px) {
        .emp-detail-wrapper .emp-stats .event-info-container {
          flex-wrap: wrap; } }
      .emp-detail-wrapper .emp-stats .event-info-container .info-box {
        width: 18.667%;
        box-sizing: border-box;
        padding: 25px 0px 25px 40px;
        border-top: 1px solid rgba(55, 52, 51, 0.15);
        border-bottom: 1px solid rgba(55, 52, 51, 0.15);
        border-right: 1px solid rgba(55, 52, 51, 0.15); }
        @media only screen and (max-width: 991px) {
          .emp-detail-wrapper .emp-stats .event-info-container .info-box {
            width: 50%; }
            .emp-detail-wrapper .emp-stats .event-info-container .info-box:nth-child(3) {
              border-top: 0px; }
            .emp-detail-wrapper .emp-stats .event-info-container .info-box:nth-child(4) {
              border-top: 0px; } }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .emp-stats .event-info-container .info-box {
            width: 100%;
            padding: 20px 20px 20px 20px;
            border-top: 0px; } }
        .emp-detail-wrapper .emp-stats .event-info-container .info-box.large {
          width: 44%; }
          @media only screen and (max-width: 991px) {
            .emp-detail-wrapper .emp-stats .event-info-container .info-box.large {
              width: 50%; } }
          @media only screen and (max-width: 767px) {
            .emp-detail-wrapper .emp-stats .event-info-container .info-box.large {
              width: 100%; } }
        .emp-detail-wrapper .emp-stats .event-info-container .info-box:last-child {
          border-right: none; }
        .emp-detail-wrapper .emp-stats .event-info-container .info-box p {
          font-family: "Cairo", sans-serif;
          font-size: 0.875rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          margin: 0;
          text-transform: uppercase; }
          @media only screen and (max-width: 767px) {
            .emp-detail-wrapper .emp-stats .event-info-container .info-box p {
              font-size: 14px; } }
        .emp-detail-wrapper .emp-stats .event-info-container .info-box h6 {
          font-family: "Cairo", sans-serif;
          font-size: 1.25rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          text-transform: uppercase;
          font-weight: 700;
          margin-top: 10px; }
        .emp-detail-wrapper .emp-stats .event-info-container .info-box a {
          font-family: "Cairo", sans-serif;
          font-size: 1.25rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          text-transform: uppercase;
          font-weight: 700;
          cursor: pointer; }
        .emp-detail-wrapper .emp-stats .event-info-container .info-box .hide {
          display: none; }
        .emp-detail-wrapper .emp-stats .event-info-container .info-box .completed {
          padding-left: 36px;
          position: relative;
          margin-top: 10px; }
          .emp-detail-wrapper .emp-stats .event-info-container .info-box .completed a {
            color: #007473; }
          .emp-detail-wrapper .emp-stats .event-info-container .info-box .completed:after {
            position: absolute;
            top: 0px;
            left: 0;
            content: "";
            background-color: #007473;
            border-radius: 100%;
            height: 26px;
            width: 26px; }
          .emp-detail-wrapper .emp-stats .event-info-container .info-box .completed::before {
            position: absolute;
            top: 16px;
            left: 1px;
            transform: translateY(-50%);
            content: "\E906";
            font-family: icomoon;
            font-size: 18px;
            color: #fff;
            font-weight: 400;
            z-index: 1; }
  .emp-detail-wrapper .banner-img {
    position: relative;
    padding-top: calc(718 / 1440 * 100%);
    display: flex;
    justify-content: center;
    align-items: center; }
    .emp-detail-wrapper .banner-img .content {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center; }
      .emp-detail-wrapper .banner-img .content p {
        opacity: 0; }
    .emp-detail-wrapper .banner-img .bg-img {
      background-size: cover;
      width: 100%;
      background-repeat: no-repeat;
      height: 100%;
      background-position: center !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      left: 0; }
      @media only screen and (max-width: 1090px) {
        .emp-detail-wrapper .banner-img .bg-img {
          display: block; } }
  .emp-detail-wrapper .description-section {
    padding-top: 80px;
    background-color: #FFFFFF; }
    @media only screen and (max-width: 767px) {
      .emp-detail-wrapper .description-section {
        padding-top: 60px; } }
    @media only screen and (max-width: 991px) {
      .emp-detail-wrapper .description-section .title {
        flex: 0 0 40%;
        max-width: 40%; } }
    @media only screen and (max-width: 767px) {
      .emp-detail-wrapper .description-section .title {
        flex: 0 0 100%;
        max-width: 100%; } }
    .emp-detail-wrapper .description-section .title p {
      font-family: "Cairo", sans-serif;
      font-size: 1.25rem;
      line-height: 1.27;
      letter-spacing: 0.5px;
      color: #373433;
      font-weight: 700;
      text-align: center;
      margin-bottom: 0px;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .emp-detail-wrapper .description-section .title p {
          text-align: left;
          font-size: 18px; } }
    @media only screen and (max-width: 767px) {
      .emp-detail-wrapper .description-section .title {
        margin-bottom: 30px; } }
    .emp-detail-wrapper .description-section .desc {
      padding-bottom: 40px; }
      .emp-detail-wrapper .description-section .desc h3 {
        font-family: "Cairo", sans-serif;
        font-size: 1.5625rem;
        line-height: 1.36;
        font-weight: 400;
        color: #373433;
        margin-bottom: 30px; }
      .emp-detail-wrapper .description-section .desc p {
        font-family: "Cairo", sans-serif;
        font-size: 1.5625rem;
        line-height: 1.36;
        font-weight: 300;
        color: #373433;
        margin-bottom: 50px; }
        .emp-detail-wrapper .description-section .desc p:last-child {
          margin-bottom: 0px; }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .description-section .desc p {
            font-size: 18px; } }
        @media only screen and (max-width: 500px) {
          .emp-detail-wrapper .description-section .desc p {
            margin-bottom: 20px; } }
      .emp-detail-wrapper .description-section .desc li {
        font-family: "Cairo", sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 300;
        color: #373433;
        list-style: none; }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .description-section .desc li {
            font-size: 18px; } }
        .emp-detail-wrapper .description-section .desc li:before {
          content: "-";
          top: 0px;
          left: 0px;
          padding-right: 5px; }
      .emp-detail-wrapper .description-section .desc li.list-heading:before {
        padding: 0px;
        content: ""; }
  .emp-detail-wrapper .event-buttons-container {
    margin-top: 0px; }
    .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper {
      display: flex;
      flex-wrap: wrap; }
      .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .register-wrapper {
        width: 50%; }
        @media only screen and (max-width: 991px) {
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .register-wrapper {
            width: 100%; } }
      .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .download-wrapper {
        width: 50%; }
        @media only screen and (max-width: 991px) {
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .download-wrapper {
            width: 100%; } }
      @media only screen and (max-width: 767px) {
        .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper {
          flex-direction: column; } }
      .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns-wrap {
        display: flex;
        align-items: center; }
        @media only screen and (max-width: 991px) {
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns-wrap {
            margin-top: 10px; } }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns-wrap {
            flex-direction: column;
            align-items: flex-start; } }
      .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns {
        width: auto;
        display: flex;
        height: 100%;
        margin-right: 0px;
        justify-content: center; }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns {
            width: auto; } }
        .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns:last-child {
          margin-right: 0px; }
        .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns a {
          width: 100%;
          height: 100%;
          padding: 24px 15px 24px 15px;
          cursor: pointer; }
        .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns .btn-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          color: #FFFFFF;
          font-family: "Cairo", sans-serif;
          text-transform: uppercase; }
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns .btn-wrap span {
            font-size: 1.375rem;
            line-height: 1.3;
            letter-spacing: 0.5px;
            font-weight: 700;
            margin-left: 16px;
            display: block; }
            @media only screen and (max-width: 767px) {
              .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns .btn-wrap span {
                font-size: 16px; } }
        .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.hide {
          display: none; }
        .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.register {
          background-color: #007473;
          position: relative;
          overflow: hidden;
          transition: all 0.3s ease-in;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          z-index: 2; }
          @media only screen and (max-width: 767px) {
            .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.register {
              margin-right: 0px; } }
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.register .time-batch {
            font-size: 0.75rem;
            line-height: 1.3;
            letter-spacing: 0.37;
            text-transform: capitalize;
            font-weight: 600;
            margin-left: 16px;
            color: #FFFFFF;
            display: block; }
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.register:after {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 0%;
            width: 100%;
            display: none;
            background-color: #373433;
            transition: all 0.3s ease-in;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            z-index: -1; }
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.register:hover a {
            text-decoration: none; }
          @media only screen and (min-width: 1090px) {
            .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.register:hover:after {
              height: 70%; } }
        .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.download {
          background-color: #373433;
          position: relative;
          overflow: hidden;
          transition: all 0.3s ease-in;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          z-index: 2; }
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.download:after {
            display: none;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 0%;
            width: 100%;
            background-color: #007473;
            transition: all 0.3s ease-in;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            z-index: -1; }
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.download:hover a {
            text-decoration: none; }
          @media only screen and (min-width: 1090px) {
            .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.download:hover:after {
              height: 75%; } }
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.download .icomoon {
            font-size: 1.125rem; }
        .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.disabled {
          opacity: 0.7; }
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns.disabled a {
            cursor: not-allowed; }
        .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns li {
          list-style: none;
          position: relative;
          height: 70px;
          width: 70px;
          background-color: rgba(255, 255, 255, 0.15);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%; }
          @media only screen and (max-width: 600px) {
            .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns li {
              height: 50px;
              width: 50px; } }
          .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns li .icomoon {
            font-size: 1.25rem;
            color: #FFFFFF; }
            @media only screen and (max-width: 600px) {
              .emp-detail-wrapper .event-buttons-container .event-buttons-wrapper .btns li .icomoon {
                font-size: 14px; } }
  .emp-detail-wrapper .course-section {
    padding-bottom: 100px; }
    @media only screen and (max-width: 767px) {
      .emp-detail-wrapper .course-section {
        padding-bottom: 60px; } }
    .emp-detail-wrapper .course-section.act {
      padding-bottom: 20px !important; }
      .emp-detail-wrapper .course-section.act .title:after {
        background-image: url(../images/expand-icon.svg) !important; }
    .emp-detail-wrapper .course-section .title {
      position: relative;
      cursor: pointer; }
      .emp-detail-wrapper .course-section .title p {
        font-family: "Cairo", sans-serif;
        font-size: 1.5625rem;
        line-height: 1.36;
        color: #373433;
        font-weight: 700;
        margin-bottom: 30px; }
      .emp-detail-wrapper .course-section .title:after {
        position: absolute;
        top: 0px;
        right: 20px;
        content: "";
        background-image: url(../images/collapse-icon.svg);
        height: 30px;
        width: 30px; }
    .emp-detail-wrapper .course-section .desc h3 {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      color: #373433;
      font-weight: 700;
      margin-bottom: 30px; }
    .emp-detail-wrapper .course-section .desc p {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      font-weight: 400;
      color: #373433;
      margin-bottom: 30px; }
      .emp-detail-wrapper .course-section .desc p:last-child {
        margin-bottom: 0px; }
      @media only screen and (max-width: 767px) {
        .emp-detail-wrapper .course-section .desc p {
          font-size: 18px; } }
      @media only screen and (max-width: 500px) {
        .emp-detail-wrapper .course-section .desc p {
          margin-bottom: 20px; } }
    .emp-detail-wrapper .course-section .desc li {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      font-weight: 400;
      color: #373433;
      list-style: none; }
      @media only screen and (max-width: 767px) {
        .emp-detail-wrapper .course-section .desc li {
          font-size: 18px; } }
      .emp-detail-wrapper .course-section .desc li:before {
        content: "-";
        top: 0px;
        left: 0px;
        padding-right: 5px; }
    .emp-detail-wrapper .course-section .desc li.list-heading:before {
      padding: 0px;
      content: ""; }
  .emp-detail-wrapper .biography-section {
    background-color: #fff;
    padding-bottom: 40px;
    margin: -1px 0px; }
    @media only screen and (max-width: 767px) {
      .emp-detail-wrapper .biography-section {
        padding-bottom: 20px; } }
    .emp-detail-wrapper .biography-section.act {
      padding-bottom: 20px !important; }
      .emp-detail-wrapper .biography-section.act .title:after {
        background-image: url(../images/expand-icon.svg) !important; }
    .emp-detail-wrapper .biography-section .title {
      position: relative;
      cursor: pointer; }
      .emp-detail-wrapper .biography-section .title p {
        font-family: "Cairo", sans-serif;
        font-size: 1.5625rem;
        line-height: 1.36;
        color: #373433;
        font-weight: 700;
        margin-bottom: 40px; }
      .emp-detail-wrapper .biography-section .title:after {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0%, -50%);
        content: "";
        background-image: url(../images/collapse-icon.svg);
        height: 30px;
        width: 30px; }
    .emp-detail-wrapper .biography-section .trainer-desc .img-bg {
      height: 0;
      padding-top: 60%;
      width: 51%;
      background-position: center !important;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 31px;
      margin-top: 28px; }
    .emp-detail-wrapper .biography-section .trainer-desc h3 {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      color: #373433;
      font-weight: 400;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .emp-detail-wrapper .biography-section .trainer-desc h3 {
          margin-bottom: 20px; } }
    .emp-detail-wrapper .biography-section .trainer-desc .education-section {
      margin-bottom: 40px; }
      @media only screen and (max-width: 767px) {
        .emp-detail-wrapper .biography-section .trainer-desc .education-section {
          margin-bottom: 20px; } }
      .emp-detail-wrapper .biography-section .trainer-desc .education-section p {
        font-size: 1.5625rem;
        line-height: 1.36;
        font-weight: 400;
        color: #373433;
        margin-bottom: 10px; }
      .emp-detail-wrapper .biography-section .trainer-desc .education-section h4 {
        font-size: 1.5625rem;
        line-height: 1.36;
        font-weight: 400;
        color: #373433;
        margin-bottom: 10px; }
        .emp-detail-wrapper .biography-section .trainer-desc .education-section h4 strong {
          font-weight: 700; }
    .emp-detail-wrapper .biography-section .trainer-desc h4 {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      color: #373433;
      font-weight: 400;
      margin-bottom: 45px; }
      @media only screen and (max-width: 767px) {
        .emp-detail-wrapper .biography-section .trainer-desc h4 {
          margin-bottom: 20px; } }
    .emp-detail-wrapper .biography-section .trainer-desc p {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      font-weight: 400;
      color: #373433;
      margin-bottom: 20px; }
      @media only screen and (max-width: 767px) {
        .emp-detail-wrapper .biography-section .trainer-desc p {
          margin-bottom: 20px;
          font-size: 18px; } }
    .emp-detail-wrapper .biography-section .trainer-header {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(55, 52, 51, 0.2);
      position: relative;
      cursor: pointer; }
      .emp-detail-wrapper .biography-section .trainer-header .open-close {
        height: 35px;
        width: 35px;
        position: absolute;
        top: 50%;
        right: 0px;
        border: 1px solid rgba(55, 52, 51, 0.3);
        border-radius: 100%;
        transform: translateY(-50%);
        cursor: pointer; }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .biography-section .trainer-header .open-close {
            right: 10px; } }
        .emp-detail-wrapper .biography-section .trainer-header .open-close:after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          content: "\E90D";
          font-family: icomoon !important;
          font-style: normal;
          font-weight: 700;
          color: rgba(55, 52, 51, 0.3);
          font-variant: normal;
          text-transform: none;
          font-size: 1.25rem;
          display: inline;
          vertical-align: middle;
          transition: all 0.3s ease-in;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in; }
      .emp-detail-wrapper .biography-section .trainer-header img {
        height: 60px;
        width: 60px;
        object-fit: cover;
        object-fit: contain;
        padding: 3px;
        border-radius: 50%;
        border: 1px solid rgba(55, 52, 51, 0.2); }
      .emp-detail-wrapper .biography-section .trainer-header p {
        margin: 0;
        text-transform: uppercase;
        font-size: 1.25rem;
        padding-left: 21px;
        line-height: 1.3;
        letter-spacing: 0.1px;
        font-weight: 700;
        font-family: "Cairo", sans-serif; }
    .emp-detail-wrapper .biography-section .trainer-desc-wrap {
      margin-bottom: 15px; }
      .emp-detail-wrapper .biography-section .trainer-desc-wrap .trainer-dropdown {
        margin-top: 30px; }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .biography-section .trainer-desc-wrap .trainer-dropdown {
            margin-top: 15px; } }
  .emp-detail-wrapper .training-section {
    padding-top: 6%;
    padding-bottom: 6%;
    background-color: #FFFFFF;
    margin: -1px 0px; }
    .emp-detail-wrapper .training-section .accordion-wrapper {
      width: 90%;
      margin: auto; }
      .emp-detail-wrapper .training-section .accordion-wrapper .title {
        display: flex;
        margin-bottom: 30px; }
        .emp-detail-wrapper .training-section .accordion-wrapper .title p {
          font-family: "Cairo", sans-serif;
          font-size: 1.125rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          font-weight: 700;
          margin-bottom: 0px;
          text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .emp-detail-wrapper .training-section .accordion-wrapper {
          width: 100%;
          padding-right: 20px;
          padding-left: 20px; } }
      .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab {
        padding-top: 30px;
        position: relative;
        width: 100%; }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab:before {
          position: absolute;
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          height: 1px;
          width: 100%;
          background-color: rgba(55, 52, 51, 0.15); }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab .time-topic-wrap {
          display: flex;
          width: 80%; }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab .date {
          width: 20%; }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab .time {
          width: 36%; }
          @media only screen and (max-width: 991px) {
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab .time {
              width: 40%; } }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab .topic {
          width: 64%; }
          @media only screen and (max-width: 991px) {
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab .topic {
              width: 60%; } }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab p {
          font-family: "Cairo", sans-serif;
          font-size: 14px;
          line-height: 22px;
          text-transform: uppercase;
          letter-spacing: 1px;
          color: #373433;
          font-weight: 400; }
          @media only screen and (max-width: 767px) {
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab p {
              font-size: 14px; } }
        @media only screen and (max-width: 767px) {
          .emp-detail-wrapper .training-section .accordion-wrapper .accordion-tab .title {
            display: none; } }
      .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc {
        position: relative;
        padding-bottom: 45px;
        margin-bottom: 45px; }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc:last-child {
          padding-bottom: 0px;
          margin-bottom: 45px; }
          .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc:last-child:after {
            display: none; }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc:after {
          position: absolute;
          content: "";
          bottom: 0px;
          left: 0px;
          height: 1px;
          width: 100%;
          background: rgba(55, 52, 51, 0.15); }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .header-wrap {
          display: flex;
          width: 100%; }
          @media only screen and (max-width: 767px) {
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .header-wrap {
              flex-direction: column; } }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .accordion-multi-wrap {
          width: 80%; }
          @media only screen and (max-width: 767px) {
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .accordion-multi-wrap {
              width: 100%; } }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap {
          width: 100%;
          flex-direction: column;
          cursor: pointer; }
          .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap {
            padding: 32px 0px 28px 0px;
            display: flex;
            width: 100%;
            background-color: #f5fbfa;
            border-radius: 8px;
            position: relative;
            cursor: pointer;
            margin-bottom: 5px;
            transition: 0.3s all ease; }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap p {
              color: #373433; }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap .open-close {
              height: 35px;
              width: 35px;
              position: absolute;
              top: 50%;
              right: 40px;
              border: 1px solid rgba(55, 52, 51, 0.3);
              border-radius: 100%;
              transform: translateY(-50%);
              cursor: pointer; }
              @media only screen and (max-width: 767px) {
                .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap .open-close {
                  right: 10px; } }
              .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap .open-close:after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "\E90D";
                font-family: icomoon !important;
                font-style: normal;
                font-weight: 700;
                color: rgba(55, 52, 51, 0.3);
                font-variant: normal;
                text-transform: none;
                font-size: 1.25rem;
                display: inline;
                vertical-align: middle;
                transition: all 0.3s ease-in;
                -webkit-transition: all 0.3s ease-in;
                -moz-transition: all 0.3s ease-in;
                -ms-transition: all 0.3s ease-in;
                -o-transition: all 0.3s ease-in; }
          .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap.active {
            background-color: #007473;
            border-radius: 8px 8px 0px 0px; }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap.active p {
              color: #FFFFFF; }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap.active .open-close {
              border: 1px solid rgba(255, 255, 255, 0.3);
              transform: translateY(-50%) rotate(-180deg);
              transition: 0.3s all ease; }
              .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap.active .open-close:after {
                color: #FFFFFF;
                transition: 0.3s all ease; }
              @media only screen and (max-width: 767px) {
                .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap.active .open-close {
                  transform: translateY(-50%) rotate(-180deg); } }
          .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap:hover {
            background-color: #007473;
            border-radius: 8px 8px 0px 0px; }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap:hover p {
              color: #FFFFFF; }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap:hover .open-close {
              border: 1px solid rgba(255, 255, 255, 0.3);
              transition: 0.3s all ease; }
              .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time-topic-wrap .wrap:hover .open-close:after {
                color: #FFFFFF;
                transition: 0.3s all ease; }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .date {
          width: 20%; }
          .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .date p {
            font-family: "Cairo", sans-serif;
            font-size: 1.875rem;
            line-height: 1.2;
            color: #007473 !important;
            font-weight: 600;
            margin-bottom: 4px; }
          .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .date span {
            font-family: "Cairo", sans-serif;
            font-size: 0.875rem;
            line-height: 1.42857;
            color: #007473 !important;
            font-weight: 400;
            opacity: 0.5; }
            @media only screen and (max-width: 767px) {
              .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .date span {
                font-size: 14px;
                margin-bottom: 5px; } }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time {
          width: 36%;
          padding-left: 40px; }
          @media only screen and (max-width: 991px) {
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time {
              width: 40%;
              padding-right: 20px; } }
          @media only screen and (max-width: 767px) {
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time {
              padding-left: 20px; } }
          .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time p {
            font-family: "Cairo", sans-serif;
            font-size: 1.25rem;
            line-height: 1.3;
            letter-spacing: 0.5px;
            font-weight: 700;
            max-width: 189px;
            text-transform: none;
            margin-bottom: 0px; }
            @media only screen and (max-width: 991px) {
              .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time p {
                font-size: 1rem;
                line-height: 1.375; } }
            @media only screen and (max-width: 767px) {
              .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time p {
                max-width: 100%; } }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .time p span {
              font-size: 1rem;
              line-height: 1.3;
              color: inherit;
              font-weight: inherit; }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .topic {
          width: 64%;
          padding-right: 15%;
          display: flex;
          align-items: center; }
          @media only screen and (max-width: 991px) {
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .topic {
              width: 60%; } }
          @media only screen and (max-width: 767px) {
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .topic {
              padding-right: 65px; } }
          .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .topic p {
            font-family: "Cairo", sans-serif;
            font-size: 1.25rem;
            line-height: 1.3;
            letter-spacing: 0.5px;
            font-weight: 700;
            text-transform: none;
            margin-bottom: 0px; }
            @media only screen and (max-width: 991px) {
              .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .topic p {
                font-size: 1rem;
                line-height: 1.375; } }
        .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info {
          background-color: #f5fbfa;
          border-radius: 0px 0px 8px 8px;
          width: 100%;
          display: none; }
          .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info .overview {
            width: 64%;
            padding-right: 57px;
            margin-left: auto;
            padding-top: 27px;
            padding-bottom: 27px; }
            @media only screen and (max-width: 991px) {
              .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info .overview {
                width: 60%; } }
            @media only screen and (max-width: 767px) {
              .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info .overview {
                padding-right: 20px; } }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info .overview li,
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info .overview p {
              font-size: 1.125rem;
              font-family: "Cairo", sans-serif;
              line-height: 1.5;
              font-weight: 300;
              color: #373433;
              text-transform: none;
              letter-spacing: normal; }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info .overview p {
              margin-bottom: 30px; }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info .overview li {
              list-style: none; }
              .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info .overview li:before {
                padding-right: 10px;
                top: 0;
                left: 0px;
                content: "\25AA"; }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info .overview li.head:before {
              content: "";
              padding-right: 0px; }
            .emp-detail-wrapper .training-section .accordion-wrapper .accordion-desc .drop-down-info .overview strong {
              font-weight: 700; }
  .emp-detail-wrapper .register-for-training {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    position: relative;
    margin: -1px 0px;
    background-size: cover;
    background-position: center !important;
    overflow: hidden;
    background-repeat: no-repeat; }
    @media only screen and (max-width: 767px) {
      .emp-detail-wrapper .register-for-training {
        padding-top: 60px;
        padding-bottom: 60px; } }
    .emp-detail-wrapper .register-for-training:after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, #373433 0%, rgba(55, 52, 51, 0) 100%); }
    .emp-detail-wrapper .register-for-training .content {
      width: 50%;
      text-align: center;
      z-index: 2; }
      @media only screen and (max-width: 991px) {
        .emp-detail-wrapper .register-for-training .content {
          width: 95%; } }
      .emp-detail-wrapper .register-for-training .content h3 {
        font-family: "Cairo", sans-serif;
        font-size: 3.75rem;
        line-height: 1.2;
        letter-spacing: -0.2px;
        color: #FFFFFF;
        margin-bottom: 15px;
        font-weight: 600;
        text-align: center; }
      .emp-detail-wrapper .register-for-training .content p {
        font-family: "Cairo", sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        color: #FFFFFF;
        margin-bottom: 50px;
        text-align: center;
        font-weight: 400;
        padding: 0 15%; }
      .emp-detail-wrapper .register-for-training .content .jada-btn {
        padding: 18px 60px 18px 56px; }

@-webkit-keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 30px 30px; } }

@-moz-keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 30px 30px; } }

::-ms-fill-lower {
  background: #58bfff; }

body.arabic .emp-detail-wrapper .biography-section .trainer-header p {
  padding-left: 0px;
  padding-right: 21px; }

body.arabic .emp-detail-wrapper .biography-section .trainer-header .open-close {
  right: auto;
  left: 0px; }

body.arabic .emp-detail-wrapper .biography-section .trainer-desc {
  padding-right: 15px;
  padding-left: 15px; }

body.arabic .emp-detail-wrapper .emp-stats .buttons-wrapper .open-survey {
  text-align: right; }
  @media only screen and (max-width: 767px) {
    body.arabic .emp-detail-wrapper .emp-stats .buttons-wrapper .open-survey .btn-wrap div {
      margin-left: 0px;
      margin-right: 16px; } }
  body.arabic .emp-detail-wrapper .emp-stats .buttons-wrapper .open-survey .btn-wrap div span {
    text-align: right; }
    @media only screen and (max-width: 767px) {
      body.arabic .emp-detail-wrapper .emp-stats .buttons-wrapper .open-survey .btn-wrap div span {
        margin-right: 0px; } }

.dashboard-page {
  display: flex;
  background-color: #f2f8f7;
  min-height: calc(100vh - 112px);
  padding-top: 85px; }

.dashboard-navigation {
  height: auto;
  background: #ffffff;
  width: 308px;
  padding-top: 50px; }
  @media only screen and (max-width: 1090px) {
    .dashboard-navigation {
      position: fixed;
      transform: translateX(-350px);
      z-index: 1000; } }
  .dashboard-navigation__header {
    padding: 18px;
    margin: 0 0 32px; }
  .dashboard-navigation__links {
    padding-right: 40px; }
    .dashboard-navigation__links li {
      position: relative; }
      .dashboard-navigation__links li:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 7px;
        border-radius: 0 8px 8px 0;
        background: #007473;
        opacity: 0;
        transition: 300ms all; }
      .dashboard-navigation__links li.active:before, .dashboard-navigation__links li:hover:before {
        opacity: 1; }
      .dashboard-navigation__links li.active a, .dashboard-navigation__links li:hover a {
        color: #007473 !important; }
        .dashboard-navigation__links li.active a:before, .dashboard-navigation__links li:hover a:before {
          width: 100%; }
      .dashboard-navigation__links li a {
        padding: 22px 40px;
        display: block;
        font-weight: bold;
        font-size: 20px;
        line-height: 1.2;
        color: #878585 !important;
        cursor: pointer;
        transition: 300ms all; }
        .dashboard-navigation__links li a:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background: rgba(208, 226, 223, 0.2);
          transition: 500ms all; }

.dashboard-content {
  position: relative;
  flex: 1;
  overflow-y: auto;
  width: calc(100% - 308px); }
  @media only screen and (max-width: 1090px) {
    .dashboard-content {
      width: 100%; } }
  .dashboard-content__header {
    background: white;
    display: flex;
    height: 85px; }
  .dashboard-content__body {
    padding: 40px; }
    @media only screen and (max-width: 767px) {
      .dashboard-content__body {
        padding: 20px;
        padding-right: 0px !important; } }
    .dashboard-content__body h1,
    .dashboard-content__body .h1 {
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 2.625rem !important;
      line-height: 1.2 !important;
      letter-spacing: -0.2px !important;
      color: #373433 !important;
      margin: 0 0 53px !important; }
      @media only screen and (max-width: 767px) {
        .dashboard-content__body h1,
        .dashboard-content__body .h1 {
          margin-bottom: 30px !important; } }

.dashboard-leaderboard hr {
  opacity: 0.1;
  border: 1px solid #373433;
  border-top: none;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 24px rgba(62, 45, 162, 0.04)); }

.dashboard-tabs {
  padding: 0;
  margin: 0 0 56px;
  display: flex;
  align-items: end;
  list-style: none; }
  .dashboard-tabs li {
    padding: 22px 24px;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.2;
    background: #ffffff;
    color: rgba(55, 52, 51, 0.4);
    cursor: pointer;
    transition: 300ms all; }
    .dashboard-tabs li.active {
      color: #ffffff;
      background: #007473; }

.leaderboard__header {
  padding: 31px 40px;
  list-style: NONE;
  margin: 0;
  display: flex; }
  @media only screen and (max-width: 767px) {
    .leaderboard__header {
      display: none; } }
  .leaderboard__header li {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #373433;
    flex: 0 0 auto; }
    .leaderboard__header li:first-child {
      flex: 0 0 100px; }
    .leaderboard__header li:last-child {
      flex: 0 0 130px;
      margin-left: auto; }

.leaderboard__list {
  position: relative; }

.leaderboard__card {
  padding: 20px 40px;
  margin: 0 0 5px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #ffffff; }
  .leaderboard__card:nth-child(11) {
    position: relative; }
    .leaderboard__card:nth-child(11):before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 86px;
      background: linear-gradient(180deg, #f2f8f7 39.52%, rgba(242, 248, 247, 0) 100%); }
  @media only screen and (max-width: 767px) {
    .leaderboard__card {
      padding: 20px;
      flex-direction: column; } }
  .leaderboard__card:first-of-type:not(.self) {
    background: #007473; }
  .leaderboard__card:nth-child(2):not(.self) {
    background: rgba(0, 116, 115, 0.8); }
  .leaderboard__card:nth-child(3):not(.self) {
    background: rgba(0, 116, 115, 0.6); }
  .leaderboard__card:first-of-type:not(.self) span, .leaderboard__card:nth-child(2):not(.self) span, .leaderboard__card:nth-child(3):not(.self) span {
    color: #ffffff; }
  .leaderboard__card:nth-child(n + 4) ul {
    display: none; }
  .leaderboard__card.self .leaderboard__card__avatar span {
    color: #007473; }
  .leaderboard__card.self .leaderboard__card__avatar img {
    border: 3px solid #007473; }
  .leaderboard__card > div {
    flex: 0 0 auto; }
    @media only screen and (max-width: 767px) {
      .leaderboard__card > div {
        width: 100%; } }
    .leaderboard__card > div:first-child {
      flex: 0 0 100px; }
      @media only screen and (max-width: 767px) {
        .leaderboard__card > div:first-child {
          width: 100%;
          flex: 0 0 auto; } }
    .leaderboard__card > div:last-child {
      flex: 0 0 130px;
      margin-left: auto; }
      @media only screen and (max-width: 767px) {
        .leaderboard__card > div:last-child {
          width: 100%;
          flex: 0 0 auto; } }
  .leaderboard__card span {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */
    letter-spacing: 0.5px;
    color: #373433; }
  .leaderboard__card__avatar {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px; }
    @media only screen and (max-width: 767px) {
      .leaderboard__card__avatar {
        width: 100%; } }
    .leaderboard__card__avatar span {
      margin: 0 13px 0 22px; }
    .leaderboard__card__avatar img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%; }
    .leaderboard__card__avatar ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center; }
      .leaderboard__card__avatar ul li {
        background: rgba(208, 226, 223, 0.3);
        border-radius: 50%;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px; }
    .leaderboard__card__avatar .badges img {
      height: auto;
      width: auto;
      border: none !important;
      border-radius: 0px; }

.asset-card {
  background: #fff;
  display: flex;
  flex: 0 0 calc(50% - 20px);
  align-items: center;
  margin-bottom: 10px; }
  @media only screen and (max-width: 991px) {
    .asset-card {
      flex: 0 0 100%;
      margin-bottom: 20px; } }
  .asset-card:last-child {
    margin-bottom: 0px; }
  .asset-card:hover .asset-card__link:before {
    width: 100%; }
  .asset-card__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .asset-card__title {
    padding: 30px;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: 0.5px;
    color: #373433; }
    @media only screen and (max-width: 767px) {
      .asset-card__title {
        padding: 20px;
        font-size: 18px; } }
  .asset-card__link {
    border-left: 1px solid rgba(55, 52, 51, 0.15);
    padding: 20px 25px;
    margin-left: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    @media only screen and (max-width: 767px) {
      .asset-card__link {
        padding: 20px; } }
    .asset-card__link:before {
      content: "";
      transition: 300ms all;
      height: 4px;
      background: #007473;
      bottom: 0;
      left: 0;
      width: 0;
      position: absolute; }
    .asset-card__link > div {
      border: 1px solid rgba(55, 52, 51, 0.15);
      border-radius: 50%;
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center; }

.jada-dashboard__header {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  z-index: 999;
  width: calc(100% - 74px);
  height: 85px;
  box-sizing: border-box;
  padding-left: 0px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  @media only screen and (max-width: 1090px) {
    .jada-dashboard__header {
      width: calc(100% - 85px); } }
  .jada-dashboard__header.top {
    top: -85px;
    width: calc(100% - 85px);
    position: fixed;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1); }
  .jada-dashboard__header.sticky {
    top: 0px !important;
    width: calc(100% - 85px);
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1); }
  .jada-dashboard__header.sticky, .jada-dashboard__header.dark-header {
    width: calc(100% - 85px);
    background-color: #FFFFFF; }
    .jada-dashboard__header.sticky .custom-navbar, .jada-dashboard__header.dark-header .custom-navbar {
      justify-content: flex-end; }
      @media only screen and (max-width: 1090px) {
        .jada-dashboard__header.sticky .custom-navbar, .jada-dashboard__header.dark-header .custom-navbar {
          justify-content: space-between; } }
      @media only screen and (max-width: 767px) {
        .jada-dashboard__header.sticky .custom-navbar, .jada-dashboard__header.dark-header .custom-navbar {
          justify-content: flex-end; } }
      .jada-dashboard__header.sticky .custom-navbar .logo-wrap, .jada-dashboard__header.dark-header .custom-navbar .logo-wrap {
        border-right: 0px;
        display: none;
        padding-left: 20px; }
        @media only screen and (max-width: 1090px) {
          .jada-dashboard__header.sticky .custom-navbar .logo-wrap, .jada-dashboard__header.dark-header .custom-navbar .logo-wrap {
            display: block; } }
        @media only screen and (max-width: 767px) {
          .jada-dashboard__header.sticky .custom-navbar .logo-wrap, .jada-dashboard__header.dark-header .custom-navbar .logo-wrap {
            padding-right: 20px;
            padding-left: 0px; } }
        .jada-dashboard__header.sticky .custom-navbar .logo-wrap .logo-icon, .jada-dashboard__header.dark-header .custom-navbar .logo-wrap .logo-icon {
          display: none; }
        .jada-dashboard__header.sticky .custom-navbar .logo-wrap .logo-green, .jada-dashboard__header.dark-header .custom-navbar .logo-wrap .logo-green {
          display: block; }
      .jada-dashboard__header.sticky .custom-navbar .navigation-container .social-links li, .jada-dashboard__header.dark-header .custom-navbar .navigation-container .social-links li {
        background-color: #007473; }
      .jada-dashboard__header.sticky .custom-navbar .navigation-container .language-switcher li:hover, .jada-dashboard__header.dark-header .custom-navbar .navigation-container .language-switcher li:hover {
        opacity: 0.5; }
      .jada-dashboard__header.sticky .custom-navbar .navigation-container .language-switcher span, .jada-dashboard__header.dark-header .custom-navbar .navigation-container .language-switcher span {
        color: #007473;
        opacity: 0.5; }
  .jada-dashboard__header.mega-menu-active {
    width: calc(100vw - 104px) !important;
    background-color: transparent !important;
    position: fixed;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
    left: 0;
    top: 0; }
    @media only screen and (max-width: 1090px) {
      .jada-dashboard__header.mega-menu-active {
        background-color: #373433 !important; } }
    @media only screen and (max-width: 500px) {
      .jada-dashboard__header.mega-menu-active {
        width: calc(100vw - 85px) !important; } }
    .jada-dashboard__header.mega-menu-active .custom-navbar {
      justify-content: space-between; }
    .jada-dashboard__header.mega-menu-active .logo-wrap {
      display: block !important;
      border-right: 1px solid rgba(255, 255, 255, 0.15) !important; }
      @media only screen and (max-width: 767px) {
        .jada-dashboard__header.mega-menu-active .logo-wrap {
          border-right: none !important; } }
      .jada-dashboard__header.mega-menu-active .logo-wrap .logo-icon {
        display: block !important; }
      .jada-dashboard__header.mega-menu-active .logo-wrap .logo-green {
        display: none !important; }
    .jada-dashboard__header.mega-menu-active .navigation-container .social-links {
      display: none !important; }
  .jada-dashboard__header.inner-page-header {
    width: calc(100% - 85px); }
  .jada-dashboard__header.dark .custom-navbar .navigation-container .social-links li {
    background-color: rgba(55, 52, 51, 0.05); }
    .jada-dashboard__header.dark .custom-navbar .navigation-container .social-links li a .icomoon:before {
      color: #373433; }
  .jada-dashboard__header.dark .custom-navbar .navigation-container .language-switcher li {
    color: #373433; }
    .jada-dashboard__header.dark .custom-navbar .navigation-container .language-switcher li.active:after {
      background-color: #373433; }
  .jada-dashboard__header.dark .custom-navbar .navigation-container .language-switcher span {
    color: #373433; }
  .jada-dashboard__header .custom-navbar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: transparent; }
    .jada-dashboard__header .custom-navbar .navigation-container {
      display: flex;
      align-items: center; }
      .jada-dashboard__header .custom-navbar .navigation-container .social-links {
        display: flex;
        justify-content: center;
        align-items: center; }
        @media only screen and (max-width: 1090px) {
          .jada-dashboard__header .custom-navbar .navigation-container .social-links {
            display: none; } }
        .jada-dashboard__header .custom-navbar .navigation-container .social-links li {
          position: relative;
          list-style: none;
          height: 40px;
          width: 40px;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          -ms-border-radius: 5px;
          -o-border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 13px;
          overflow: hidden;
          transition: background-color 0.3s ease-in;
          -webkit-transition: background-color 0.3s ease-in;
          -moz-transition: background-color 0.3s ease-in;
          -ms-transition: background-color 0.3s ease-in;
          -o-transition: background-color 0.3s ease-in; }
          .jada-dashboard__header .custom-navbar .navigation-container .social-links li:last-child {
            margin-right: 0px; }
          .jada-dashboard__header .custom-navbar .navigation-container .social-links li a {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%; }
            .jada-dashboard__header .custom-navbar .navigation-container .social-links li a .icomoon {
              font-size: 18px;
              text-align: center;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%); }
          .jada-dashboard__header .custom-navbar .navigation-container .social-links li:hover {
            background-color: rgba(255, 255, 255, 0.25); }
      .jada-dashboard__header .custom-navbar .navigation-container .language-switcher {
        margin-left: 28px;
        margin-right: 40px;
        font-family: "Cairo", sans-serif;
        display: flex;
        align-items: center; }
        @media only screen and (max-width: 1090px) {
          .jada-dashboard__header .custom-navbar .navigation-container .language-switcher {
            display: none; } }
        .jada-dashboard__header .custom-navbar .navigation-container .language-switcher li {
          list-style: none;
          font-family: inherit;
          font-size: 1rem;
          line-height: 1.3;
          letter-spacing: 1px;
          color: #FFFFFF;
          font-weight: 700;
          opacity: 0.5;
          position: relative;
          transition: 0.3s all ease-in;
          -webkit-transition: 0.3s all ease-in;
          -moz-transition: 0.3s all ease-in;
          -ms-transition: 0.3s all ease-in;
          -o-transition: 0.3s all ease-in; }
          .jada-dashboard__header .custom-navbar .navigation-container .language-switcher li.active {
            opacity: 1; }
            .jada-dashboard__header .custom-navbar .navigation-container .language-switcher li.active:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              height: 1px;
              width: 100%;
              background-color: #FFFFFF;
              margin-top: 1px; }
          .jada-dashboard__header .custom-navbar .navigation-container .language-switcher li a {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            color: inherit; }
          .jada-dashboard__header .custom-navbar .navigation-container .language-switcher li:hover {
            opacity: 0.5; }
            .jada-dashboard__header .custom-navbar .navigation-container .language-switcher li:hover a {
              text-decoration: none; }
            .jada-dashboard__header .custom-navbar .navigation-container .language-switcher li:hover.active:after {
              opacity: 0.5; }
        .jada-dashboard__header .custom-navbar .navigation-container .language-switcher span {
          margin: 0 5px;
          font-family: inherit;
          font-size: 1rem;
          line-height: 1.3;
          letter-spacing: 1px;
          color: #FFFFFF;
          font-weight: 700;
          opacity: 0.5; }
      .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu {
        display: flex;
        height: 85px; }
        @media only screen and (max-width: 767px) {
          .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu {
            display: none; } }
        .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu ul {
          display: flex;
          margin: 0; }
        .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .icons-wrap {
          height: 36px;
          width: 36px;
          border-radius: 46px;
          -webkit-border-radius: 46px;
          -moz-border-radius: 46px;
          -ms-border-radius: 46px;
          -o-border-radius: 46px;
          border: 0.9px solid rgba(55, 52, 51, 0.15);
          text-align: center;
          list-style: none; }
          @media only screen and (min-width: 767px) {
            .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .icons-wrap {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%); } }
        .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .icomoon {
          font-size: 1.25rem;
          line-height: 36px;
          color: #373433; }
        .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links {
          cursor: pointer;
          position: relative;
          padding: 24px;
          width: 85px;
          box-sizing: border-box;
          list-style: none;
          background-color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 0.8px solid rgba(55, 52, 51, 0.1);
          border-bottom: 0.8px solid rgba(55, 52, 51, 0.1);
          transition: 0.2s width linear; }
          .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links ul {
            position: absolute;
            min-width: 165px;
            top: 105px;
            left: 50%;
            transform: translateX(-50%);
            padding: 25px 0px;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            display: none;
            z-index: 2; }
            .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links ul li {
              margin-bottom: 20px;
              list-style: none;
              text-align: left;
              padding: 0 24px; }
              .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links ul li:last-child {
                margin-bottom: 0px; }
              .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links ul li a {
                font-size: 1rem;
                line-height: 1.4;
                letter-spacing: 0.1px;
                font-weight: 700;
                color: #373433;
                text-transform: uppercase; }
              .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links ul li button {
                background: transparent;
                font-size: 1rem;
                line-height: 1.4;
                letter-spacing: 0.1px;
                font-weight: 700;
                color: #373433;
                text-transform: uppercase;
                border: none;
                width: 100%;
                min-width: auto;
                text-align: left; }
              .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links ul li:hover li {
                background-color: rgba(55, 52, 51, 0.07); }
            .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links ul:after {
              position: absolute;
              content: "";
              top: -10px;
              left: 50%;
              transform: translateX(-50%);
              width: 32px;
              height: 14px;
              border-bottom: solid 14px #FFFFFF;
              border-left: solid 15px transparent;
              border-right: solid 15px transparent; }
          .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links:last-child .icomoon, .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links:first-child .icomoon, .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links:nth-child(2) .icomoon {
            font-size: 1.5rem; }
          .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links span {
            opacity: 0;
            width: 0px;
            overflow: hidden;
            white-space: nowrap;
            text-transform: uppercase;
            font-family: "Cairo", sans-serif;
            font-size: 1rem;
            line-height: 1.4;
            font-weight: bold;
            color: #373433;
            transition: 0.45s opacity ease-in; }
          .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links:after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            height: 0px;
            width: 100%;
            background-color: #007473;
            transition: 0.2s all ease; }
          @media only screen and (min-width: 767px) {
            .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links:hover {
              border-bottom: 0px;
              width: auto; }
              .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links:hover:after {
                height: 6px; }
              .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links:hover .icons-wrap {
                left: 25px;
                top: 50%;
                transform: translateX(0);
                transform: translateY(-50%); }
              .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .d-links:hover span {
                opacity: 1;
                overflow: visible;
                padding-left: 50px; } }
        .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .access ul {
          min-width: 245px; }
          .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .access ul li {
            text-align: left; }
            .jada-dashboard__header .custom-navbar .navigation-container .dashboard-menu .access ul li button .icomoon {
              font-size: 1.375rem;
              padding-right: 15px; }

.my-dashboard .filters {
  padding: 0px; }
  .my-dashboard .filters .nav-item {
    width: auto;
    background: none;
    -webkit-transition: 0.2s all ease;
    -moz-transition: 0.2s all ease;
    -ms-transition: 0.2s all ease;
    -o-transition: 0.2s all ease;
    transition: 0.2s all ease; }
    .my-dashboard .filters .nav-item:after {
      display: none; }
    .my-dashboard .filters .nav-item .nav-link {
      padding: 20px 24px 22px 24px !important;
      color: rgba(55, 52, 51, 0.4) !important;
      font-size: 1.125rem !important;
      line-height: 1.2 !important;
      font-weight: 700 !important;
      background: #FFFFFF;
      -webkit-transition: 0.2s all ease;
      -moz-transition: 0.2s all ease;
      -ms-transition: 0.2s all ease;
      -o-transition: 0.2s all ease;
      transition: 0.2s all ease; }
    .my-dashboard .filters .nav-item:nth-child(2) {
      border-left: 1px solid rgba(55, 52, 51, 0.05);
      border-right: 1px solid rgba(55, 52, 51, 0.05); }
    .my-dashboard .filters .nav-item:hover, .my-dashboard .filters .nav-item:active, .my-dashboard .filters .nav-item.active {
      background: #007473 !important; }
      .my-dashboard .filters .nav-item:hover .nav-link, .my-dashboard .filters .nav-item:active .nav-link, .my-dashboard .filters .nav-item.active .nav-link {
        color: #FFFFFF !important;
        opacity: 1 !important; }

.my-dashboard .title {
  font-family: "Cairo", sans-serif;
  font-size: 2.625rem;
  line-height: 1.2;
  letter-spacing: -0.2px;
  font-weight: 600;
  color: #373433; }

.my-dashboard .training-wrapper {
  margin-top: 70px; }
  @media only screen and (max-width: 767px) {
    .my-dashboard .training-wrapper {
      padding-right: 20px; } }

.my-dashboard .event-card_wrap {
  margin: 0 -20px;
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  animation: fadeIn 1s forwards; }

.my-dashboard .event-card__small {
  width: 33.33%;
  margin-bottom: 100px;
  padding: 0 20px;
  overflow: hidden; }
  @media only screen and (max-width: 1090px) {
    .my-dashboard .event-card__small {
      margin-bottom: 60px;
      padding: 0 10px; } }
  @media only screen and (max-width: 991px) {
    .my-dashboard .event-card__small {
      width: 50%; } }
  @media only screen and (max-width: 500px) {
    .my-dashboard .event-card__small {
      width: 100%;
      padding: 0 20px; } }
  .my-dashboard .event-card__small .img-container {
    overflow: hidden;
    position: relative; }
    .my-dashboard .event-card__small .img-container .training-batch {
      position: absolute;
      top: 12px;
      left: 12px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 12px;
      font-size: 1.125rem;
      line-height: 2.38167; }
      @media only screen and (max-width: 991px) {
        .my-dashboard .event-card__small .img-container .training-batch {
          font-size: 16px;
          line-height: 40.87px; } }
    .my-dashboard .event-card__small .img-container .training-status {
      position: absolute;
      text-transform: uppercase;
      bottom: 12px;
      left: 12px;
      padding-left: 16px;
      color: #FFFFFF;
      padding-right: 16px;
      padding-top: 12px;
      padding-bottom: 12px;
      border-radius: 8px;
      font-size: 1rem;
      line-height: 1.4; }
      @media only screen and (max-width: 991px) {
        .my-dashboard .event-card__small .img-container .training-status {
          font-size: 1rem;
          line-height: 2.55437; } }
      .my-dashboard .event-card__small .img-container .training-status.pending {
        background-color: #fb9357; }
      .my-dashboard .event-card__small .img-container .training-status.accepted {
        background-color: #5eb1a9; }
      .my-dashboard .event-card__small .img-container .training-status.completed {
        background-color: #4bd070; }
  .my-dashboard .event-card__small a:hover .img-bg {
    transform: scale(1.1); }
  .my-dashboard .event-card__small a:hover .content p {
    color: #007473; }
  .my-dashboard .event-card__small .img-bg {
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
    padding-top: 64%;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    @media only screen and (max-width: 500px) {
      .my-dashboard .event-card__small .img-bg {
        padding-top: 75%; } }

.my-dashboard .content {
  padding: 24px 30px 26px 30px;
  background-color: #fff;
  overflow: hidden;
  z-index: 1; }
  @media only screen and (max-width: 767px) {
    .my-dashboard .content {
      padding: 20px 20px 25px; } }
  .my-dashboard .content p {
    font-family: "Cairo", sans-serif;
    font-size: 1.625rem;
    line-height: 1.3;
    font-weight: 600;
    color: #373433;
    margin-bottom: 30px;
    max-width: 468px;
    transition: 0.3s color ease;
    -webkit-transition: 0.3s color ease;
    -moz-transition: 0.3s color ease;
    -ms-transition: 0.3s color ease;
    -o-transition: 0.3s color ease; }
    @media only screen and (max-width: 1090px) {
      .my-dashboard .content p {
        font-size: 1.5rem;
        line-height: 1.325;
        margin-bottom: 40px; } }
    @media only screen and (max-width: 991px) {
      .my-dashboard .content p {
        font-size: 1.625rem;
        line-height: 1.3; } }
    @media only screen and (max-width: 500px) {
      .my-dashboard .content p {
        font-size: 1.75rem;
        line-height: 1.27857; } }
  .my-dashboard .content .calender-section {
    padding-top: 25px;
    position: relative; }
    .my-dashboard .content .calender-section:after {
      position: absolute;
      content: "";
      top: 0;
      left: -40px;
      height: 1px;
      width: 130%;
      background-color: rgba(0, 0, 0, 0.07); }
    .my-dashboard .content .calender-section .icomoon {
      font-size: 1.25rem;
      margin-right: 20px; }
    .my-dashboard .content .calender-section span {
      font-family: "Cairo", sans-serif;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.4;
      color: #171717; }
    .my-dashboard .content .calender-section .date {
      margin-bottom: 16px; }

.my-dashboard .deep-dives.training-batch {
  background-color: #eef3f2;
  color: #373433; }

.my-dashboard .foundational-training.training-batch {
  background-color: rgba(0, 116, 115, 0.8);
  color: #FFFFFF; }

.my-dashboard .achievement-card {
  padding: 0 20px;
  min-height: 20vw;
  width: 33.33%; }
  @media only screen and (max-width: 991px) {
    .my-dashboard .achievement-card {
      width: 50%;
      margin-bottom: 30px; } }
  @media only screen and (max-width: 767px) {
    .my-dashboard .achievement-card {
      width: 100%; } }
  .my-dashboard .achievement-card .content {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
    .my-dashboard .achievement-card .content .logo {
      height: 54px;
      width: 54px;
      border-radius: 100%;
      background-color: rgba(208, 226, 223, 0.2);
      display: flex;
      justify-content: center;
      align-items: center; }
      .my-dashboard .achievement-card .content .logo svg {
        z-index: 1; }
    .my-dashboard .achievement-card .content h4 {
      font-family: "Cairo", sans-serif;
      font-size: 1.625rem;
      line-height: 1.3;
      color: #373433;
      margin: 9px 0px;
      font-weight: 600; }
    .my-dashboard .achievement-card .content p {
      font-family: "Cairo", sans-serif;
      font-size: 1rem;
      line-height: 1.5;
      color: #373433;
      font-weight: 300;
      margin: 0; }
    .my-dashboard .achievement-card .content .date {
      font-weight: 600;
      line-height: 1.75; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.emp-training-wrapper .title-wrap {
  margin-bottom: 28px; }
  .emp-training-wrapper .title-wrap .title {
    font-family: "Cairo", sans-serif;
    font-size: 2.625rem;
    line-height: 1.2;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #373433; }

.emp-training-wrapper .search-filter-wrap {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .emp-training-wrapper .search-filter-wrap {
      flex-direction: column; } }

.emp-training-wrapper .search-wrap {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .emp-training-wrapper .search-wrap {
      margin-bottom: 20px; }
      .emp-training-wrapper .search-wrap:last-child {
        margin-bottom: 0px; } }
  .emp-training-wrapper .search-wrap input {
    width: 337px;
    margin-right: 30px;
    border: none;
    background: transparent;
    font-size: 1.375rem;
    line-height: 0.90909;
    color: rgba(55, 52, 51, 0.3);
    border-bottom: 1px solid rgba(55, 52, 51, 0.15); }
    @media only screen and (max-width: 767px) {
      .emp-training-wrapper .search-wrap input {
        width: 70%; } }
    .emp-training-wrapper .search-wrap input::placeholder {
      color: rgba(55, 52, 51, 0.3);
      font-size: 1.375rem;
      line-height: 0.90909; }
  .emp-training-wrapper .search-wrap li {
    list-style: none;
    height: 58px;
    width: 58px;
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%; }
    .emp-training-wrapper .search-wrap li .icomoon {
      font-size: 1.5625rem; }
      .emp-training-wrapper .search-wrap li .icomoon:before {
        color: #007473; }

.emp-training-wrapper .filter-wrap {
  display: flex; }
  .emp-training-wrapper .filter-wrap .filter {
    width: auto;
    margin-right: 30px;
    height: 1.3125rem; }
    .emp-training-wrapper .filter-wrap .filter:after {
      display: none; }
    .emp-training-wrapper .filter-wrap .filter:last-child {
      margin-right: 0px; }
    .emp-training-wrapper .filter-wrap .filter .dropdown .btn {
      background-color: transparent;
      color: #373433;
      font-size: 18px;
      line-height: 1.11111;
      letter-spacing: 0.2px; }
    .emp-training-wrapper .filter-wrap .filter .dropdown:focus-visible {
      outline: none !important; }
    .emp-training-wrapper .filter-wrap .filter .dropdown-menu {
      width: 300px; }
      .emp-training-wrapper .filter-wrap .filter .dropdown-menu li a {
        font-size: 18px;
        line-height: 18px;
        padding: 10px 0px; }
    .emp-training-wrapper .filter-wrap .filter .dropdown-toggle {
      padding: 0px; }
      .emp-training-wrapper .filter-wrap .filter .dropdown-toggle:after {
        margin-top: 6px;
        margin-left: 6px; }

.emp-training-wrapper .training-wrapper {
  margin-top: 70px; }
  @media only screen and (max-width: 767px) {
    .emp-training-wrapper .training-wrapper {
      margin-right: 20px; } }

.emp-training-wrapper .event-card_wrap {
  margin: 0 -20px;
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  animation: fadeIn 1s forwards; }

.emp-training-wrapper .event-card__small {
  width: 33.33%;
  margin-bottom: 100px;
  padding: 0 20px;
  overflow: hidden; }
  @media only screen and (max-width: 1090px) {
    .emp-training-wrapper .event-card__small {
      margin-bottom: 60px;
      padding: 0 10px; } }
  @media only screen and (max-width: 991px) {
    .emp-training-wrapper .event-card__small {
      width: 50%; } }
  @media only screen and (max-width: 500px) {
    .emp-training-wrapper .event-card__small {
      width: 100%;
      padding: 0 20px; } }
  .emp-training-wrapper .event-card__small .img-container {
    overflow: hidden;
    position: relative; }
    .emp-training-wrapper .event-card__small .img-container .training-batch {
      position: absolute;
      top: 12px;
      left: 12px;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 12px;
      font-size: 1.125rem;
      line-height: 2.38167; }
      @media only screen and (max-width: 991px) {
        .emp-training-wrapper .event-card__small .img-container .training-batch {
          font-size: 16px;
          line-height: 40.87px; } }
    .emp-training-wrapper .event-card__small .img-container .training-status {
      position: absolute;
      text-transform: uppercase;
      bottom: 12px;
      left: 12px;
      padding-left: 16px;
      color: #FFFFFF;
      padding-right: 16px;
      padding-top: 12px;
      padding-bottom: 12px;
      border-radius: 8px;
      font-size: 1rem;
      line-height: 1.4; }
      @media only screen and (max-width: 991px) {
        .emp-training-wrapper .event-card__small .img-container .training-status {
          font-size: 1rem;
          line-height: 2.55437; } }
      .emp-training-wrapper .event-card__small .img-container .training-status.pending {
        background-color: #fb9357; }
      .emp-training-wrapper .event-card__small .img-container .training-status.accepted {
        background-color: #5eb1a9; }
      .emp-training-wrapper .event-card__small .img-container .training-status.completed {
        background-color: #4bd070; }
  .emp-training-wrapper .event-card__small a:hover .img-bg {
    transform: scale(1.1); }
  .emp-training-wrapper .event-card__small a:hover .content p {
    color: #007473; }
  .emp-training-wrapper .event-card__small .img-bg {
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
    padding-top: 64%;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    @media only screen and (max-width: 500px) {
      .emp-training-wrapper .event-card__small .img-bg {
        padding-top: 75%; } }

.emp-training-wrapper .content {
  padding: 24px 30px 26px 30px;
  background-color: #fff;
  overflow: hidden;
  z-index: 1; }
  @media only screen and (max-width: 767px) {
    .emp-training-wrapper .content {
      padding: 20px 20px 25px; } }
  .emp-training-wrapper .content p {
    font-family: "Cairo", sans-serif;
    font-size: 1.625rem;
    line-height: 1.3;
    font-weight: 600;
    color: #373433;
    margin-bottom: 30px;
    max-width: 468px;
    transition: 0.3s color ease;
    -webkit-transition: 0.3s color ease;
    -moz-transition: 0.3s color ease;
    -ms-transition: 0.3s color ease;
    -o-transition: 0.3s color ease; }
    @media only screen and (max-width: 1090px) {
      .emp-training-wrapper .content p {
        font-size: 1.5rem;
        line-height: 1.325;
        margin-bottom: 40px; } }
    @media only screen and (max-width: 991px) {
      .emp-training-wrapper .content p {
        font-size: 1.625rem;
        line-height: 1.3; } }
    @media only screen and (max-width: 500px) {
      .emp-training-wrapper .content p {
        font-size: 1.75rem;
        line-height: 1.27857; } }
  .emp-training-wrapper .content .calender-section {
    padding-top: 25px;
    position: relative; }
    .emp-training-wrapper .content .calender-section:after {
      position: absolute;
      content: "";
      top: 0;
      left: -40px;
      height: 1px;
      width: 130%;
      background-color: rgba(0, 0, 0, 0.07); }
    .emp-training-wrapper .content .calender-section .icomoon {
      font-size: 1.25rem;
      margin-right: 20px; }
    .emp-training-wrapper .content .calender-section span {
      font-family: "Cairo", sans-serif;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.4;
      color: #171717; }
    .emp-training-wrapper .content .calender-section .date {
      margin-bottom: 16px; }

.emp-training-wrapper .deep-dives.training-batch {
  background-color: #eef3f2;
  color: #373433; }

.emp-training-wrapper .foundational-training.training-batch {
  background-color: rgba(0, 116, 115, 0.8);
  color: #FFFFFF; }

.emp-training-detail-wrapper .title-wrap {
  margin-bottom: 44px;
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 991px) {
    .emp-training-detail-wrapper .title-wrap {
      flex-direction: column; } }
  .emp-training-detail-wrapper .title-wrap .title {
    font-family: "Cairo", sans-serif;
    font-size: 2.625rem;
    line-height: 1.2;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #373433; }
  .emp-training-detail-wrapper .title-wrap p {
    margin-bottom: 0px;
    margin-top: 24px;
    font-family: "Cairo", sans-serif;
    font-size: 1.125rem;
    line-height: 1.55556;
    color: #373433;
    padding-left: 15px;
    font-weight: 400;
    position: relative;
    cursor: pointer; }
    .emp-training-detail-wrapper .title-wrap p:after {
      position: absolute;
      height: 12px;
      width: 6px;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-image: url(../images/back-icon.svg);
      transition: all 0.2s ease; }
    .emp-training-detail-wrapper .title-wrap p:hover:after {
      left: -6px; }

.emp-training-detail-wrapper .biography-section {
  padding-top: 70px;
  padding-bottom: 40px;
  max-width: 100%; }
  @media only screen and (max-width: 767px) {
    .emp-training-detail-wrapper .biography-section {
      padding-top: 60px;
      padding-bottom: 20px;
      max-width: 100%; } }
  .emp-training-detail-wrapper .biography-section.act {
    padding-bottom: 20px !important; }
    .emp-training-detail-wrapper .biography-section.act .title:after {
      background-image: url(../images/expand-icon.svg) !important; }
  .emp-training-detail-wrapper .biography-section .title {
    position: relative;
    cursor: pointer; }
    .emp-training-detail-wrapper .biography-section .title p {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      color: #373433;
      font-weight: 700;
      letter-spacing: -0.2px;
      margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .biography-section .title {
        margin-bottom: 30px; } }
    .emp-training-detail-wrapper .biography-section .title:after {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0%, -50%);
      content: "";
      background-image: url(../images/collapse-icon.svg);
      height: 30px;
      width: 30px; }
  .emp-training-detail-wrapper .biography-section .trainer-desc .img-bg {
    height: 0;
    padding-top: 60%;
    width: 51%;
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 31px;
    margin-top: 28px;
    display: none; }
  .emp-training-detail-wrapper .biography-section .trainer-desc h3 {
    font-family: "Cairo", sans-serif;
    font-size: 1.5625rem;
    line-height: 1.36;
    color: #373433;
    font-weight: 400;
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .biography-section .trainer-desc h3 {
        margin-bottom: 20px; } }
  .emp-training-detail-wrapper .biography-section .trainer-desc .education-section {
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .biography-section .trainer-desc .education-section {
        margin-bottom: 20px; } }
    .emp-training-detail-wrapper .biography-section .trainer-desc .education-section p {
      font-size: 1.5625rem;
      line-height: 1.36;
      font-weight: 400;
      color: #373433;
      margin-bottom: 10px;
      text-transform: uppercase; }
    .emp-training-detail-wrapper .biography-section .trainer-desc .education-section h4 {
      font-size: 1.5625rem;
      line-height: 1.36;
      font-weight: 400;
      color: #373433; }
      .emp-training-detail-wrapper .biography-section .trainer-desc .education-section h4 strong {
        font-weight: 700; }
  .emp-training-detail-wrapper .biography-section .trainer-desc h4 {
    font-family: "Cairo", sans-serif;
    font-size: 1.5625rem;
    line-height: 1.36;
    font-weight: 400;
    color: #373433;
    margin-bottom: 45px; }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .biography-section .trainer-desc h4 {
        margin-bottom: 20px; } }
  .emp-training-detail-wrapper .biography-section .trainer-desc p {
    font-family: "Cairo", sans-serif;
    font-size: 1.5625rem;
    line-height: 1.36;
    color: #373433;
    font-weight: 400;
    margin-bottom: 40px; }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .biography-section .trainer-desc p {
        margin-bottom: 20px;
        font-size: 18px; } }
  .emp-training-detail-wrapper .biography-section .trainer-header {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(55, 52, 51, 0.2);
    position: relative;
    cursor: pointer; }
    .emp-training-detail-wrapper .biography-section .trainer-header .open-close {
      height: 35px;
      width: 35px;
      position: absolute;
      top: 50%;
      right: 0px;
      border: 1px solid rgba(55, 52, 51, 0.3);
      border-radius: 100%;
      transform: translateY(-50%);
      cursor: pointer; }
      @media only screen and (max-width: 767px) {
        .emp-training-detail-wrapper .biography-section .trainer-header .open-close {
          right: 10px; } }
      .emp-training-detail-wrapper .biography-section .trainer-header .open-close:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "\E90D";
        font-family: icomoon !important;
        font-style: normal;
        font-weight: 700;
        color: rgba(55, 52, 51, 0.3);
        font-variant: normal;
        text-transform: none;
        font-size: 1.25rem;
        display: inline;
        vertical-align: middle;
        transition: all 0.3s ease-in;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in; }
    .emp-training-detail-wrapper .biography-section .trainer-header img {
      height: 60px;
      width: 60px;
      object-fit: cover;
      object-fit: contain;
      padding: 3px;
      border-radius: 50%;
      border: 1px solid rgba(55, 52, 51, 0.2); }
    .emp-training-detail-wrapper .biography-section .trainer-header p {
      margin: 0;
      text-transform: uppercase;
      font-size: 1.5625rem;
      padding-left: 21px;
      line-height: 1.2;
      letter-spacing: 0.1px;
      font-weight: 700;
      font-family: "Cairo", sans-serif; }
  .emp-training-detail-wrapper .biography-section .trainer-desc-wrap {
    margin-bottom: 15px; }
    .emp-training-detail-wrapper .biography-section .trainer-desc-wrap .trainer-dropdown {
      border-bottom: 1px solid rgba(55, 52, 51, 0.2);
      margin-top: 30px; }
      @media only screen and (max-width: 767px) {
        .emp-training-detail-wrapper .biography-section .trainer-desc-wrap .trainer-dropdown {
          margin-top: 15px; } }
    .emp-training-detail-wrapper .biography-section .trainer-desc-wrap:nth-child(n + 2) .trainer-dropdown {
      display: none; }

.emp-training-detail-wrapper .course-section {
  padding-top: 70px; }
  @media only screen and (max-width: 767px) {
    .emp-training-detail-wrapper .course-section {
      padding-top: 60px; } }
  .emp-training-detail-wrapper .course-section.act {
    padding-bottom: 20px !important; }
    .emp-training-detail-wrapper .course-section.act .title:after {
      background-image: url(../images/expand-icon.svg) !important; }
  .emp-training-detail-wrapper .course-section .title {
    position: relative;
    width: 100%;
    cursor: pointer; }
    .emp-training-detail-wrapper .course-section .title p {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      color: #373433;
      font-weight: 700;
      margin-bottom: 30px; }
    .emp-training-detail-wrapper .course-section .title:after {
      position: absolute;
      top: 0px;
      right: 20px;
      content: '';
      background-image: url(../images/collapse-icon.svg);
      height: 30px;
      width: 30px; }
  .emp-training-detail-wrapper .course-section .desc {
    width: 100%; }
    .emp-training-detail-wrapper .course-section .desc h3 {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      color: #373433;
      font-weight: 700;
      margin-bottom: 30px; }
    .emp-training-detail-wrapper .course-section .desc p {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      font-weight: 400;
      color: #373433;
      margin-bottom: 30px; }
      .emp-training-detail-wrapper .course-section .desc p:last-child {
        margin-bottom: 0px; }
      @media only screen and (max-width: 767px) {
        .emp-training-detail-wrapper .course-section .desc p {
          font-size: 18px; } }
      @media only screen and (max-width: 500px) {
        .emp-training-detail-wrapper .course-section .desc p {
          margin-bottom: 20px; } }
    .emp-training-detail-wrapper .course-section .desc li {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      font-weight: 400;
      color: #373433;
      list-style: none; }
      @media only screen and (max-width: 767px) {
        .emp-training-detail-wrapper .course-section .desc li {
          font-size: 18px; } }
      .emp-training-detail-wrapper .course-section .desc li:before {
        content: "-";
        top: 0px;
        left: 0px;
        padding-right: 5px; }
    .emp-training-detail-wrapper .course-section .desc li.list-heading:before {
      padding: 0px;
      content: ""; }

.emp-training-detail-wrapper .btns-wrap {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 991px) {
    .emp-training-detail-wrapper .btns-wrap {
      margin-top: 10px; } }
  @media only screen and (max-width: 767px) {
    .emp-training-detail-wrapper .btns-wrap {
      flex-direction: column;
      align-items: flex-start; } }

.emp-training-detail-wrapper .btns {
  width: auto;
  display: flex;
  padding: 24px 15px 24px 15px;
  margin-right: 0px; }
  @media only screen and (max-width: 767px) {
    .emp-training-detail-wrapper .btns {
      width: auto;
      height: 80px; } }
  .emp-training-detail-wrapper .btns:last-child {
    margin-right: 0px; }
  .emp-training-detail-wrapper .btns a {
    width: 100%;
    height: 100%; }
  .emp-training-detail-wrapper .btns .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    color: #FFFFFF;
    font-family: "Cairo", sans-serif;
    text-transform: uppercase;
    width: 100%;
    height: 100%; }
    .emp-training-detail-wrapper .btns .btn-wrap span {
      font-size: 1rem;
      line-height: 1.25;
      letter-spacing: 0.37px;
      font-weight: 700;
      margin-left: 16px; }
      @media only screen and (max-width: 767px) {
        .emp-training-detail-wrapper .btns .btn-wrap span {
          font-size: 16px;
          display: block; } }
  .emp-training-detail-wrapper .btns.hide {
    display: none; }
  .emp-training-detail-wrapper .btns.register {
    background-color: #007473;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    z-index: 2; }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .btns.register {
        margin-right: 0px;
        margin-bottom: 20px; } }
    .emp-training-detail-wrapper .btns.register .time-batch {
      font-size: 0.75rem;
      line-height: 1.3;
      letter-spacing: 0.37;
      text-transform: capitalize;
      font-weight: 600;
      margin-left: 16px;
      color: #FFFFFF;
      display: block; }
    .emp-training-detail-wrapper .btns.register:after {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 0%;
      width: 100%;
      display: none;
      background-color: #373433;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in;
      z-index: -1; }
    .emp-training-detail-wrapper .btns.register:hover a {
      text-decoration: none; }
    @media only screen and (min-width: 1090px) {
      .emp-training-detail-wrapper .btns.register:hover:after {
        height: 70%; } }
  .emp-training-detail-wrapper .btns.download {
    background-color: #373433;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    z-index: 2;
    margin-left: 15px; }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .btns.download {
        margin-left: 0px; } }
    .emp-training-detail-wrapper .btns.download:after {
      display: none;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 0%;
      width: 100%;
      background-color: #007473;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in;
      z-index: -1; }
    .emp-training-detail-wrapper .btns.download:hover a {
      text-decoration: none; }
    @media only screen and (min-width: 1090px) {
      .emp-training-detail-wrapper .btns.download:hover:after {
        height: 75%; } }
    .emp-training-detail-wrapper .btns.download .icomoon {
      font-size: 1.125rem; }
  .emp-training-detail-wrapper .btns.disabled {
    opacity: 0.5; }
  .emp-training-detail-wrapper .btns li {
    list-style: none;
    position: relative;
    height: 50px;
    width: 50px;
    background-color: rgba(255, 255, 255, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%; }
    .emp-training-detail-wrapper .btns li .icomoon {
      font-size: 0.875rem;
      color: #FFFFFF; }

.emp-training-detail-wrapper .open-survey {
  width: auto;
  padding: 24px 15px 24px 15px;
  margin-right: 0px; }

.emp-training-detail-wrapper .emp-details .img-container {
  position: relative; }
  .emp-training-detail-wrapper .emp-details .img-container .img-bg {
    width: 100%;
    height: 0;
    padding-top: 50%; }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .emp-details .img-container .img-bg {
        padding-top: 70%; } }
  .emp-training-detail-wrapper .emp-details .img-container .training-status {
    position: absolute;
    text-transform: uppercase;
    top: 14px;
    left: 14px;
    padding-left: 16px;
    color: #FFFFFF;
    padding-right: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.4; }
    @media only screen and (max-width: 991px) {
      .emp-training-detail-wrapper .emp-details .img-container .training-status {
        font-size: 1rem;
        line-height: 2.55437; } }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .emp-details .img-container .training-status {
        padding-top: 4px;
        padding-left: 8px;
        padding-bottom: 4px;
        padding-right: 8px; } }
    .emp-training-detail-wrapper .emp-details .img-container .training-status.pending {
      background-color: #fb9357; }
    .emp-training-detail-wrapper .emp-details .img-container .training-status.accepted {
      background-color: #5eb1a9; }
    .emp-training-detail-wrapper .emp-details .img-container .training-status.completed {
      background-color: #4bd070; }

.emp-training-detail-wrapper .emp-details .emp-content-wrap {
  background-color: #FFFFFF;
  padding: 50px 30px 30px 30px; }
  @media only screen and (max-width: 767px) {
    .emp-training-detail-wrapper .emp-details .emp-content-wrap {
      padding-top: 50px;
      padding-left: 10px;
      padding-right: 10px; } }
  .emp-training-detail-wrapper .emp-details .emp-content-wrap h4 {
    font-family: "Cairo", sans-serif;
    font-size: 1.5625rem;
    line-height: 1.2;
    letter-spacing: -0.2px;
    font-weight: 700;
    color: #373433;
    margin-bottom: 30px; }
  .emp-training-detail-wrapper .emp-details .emp-content-wrap .seat-progress-bar-wrapper {
    width: 70%; }
    @media only screen and (max-width: 991px) {
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .seat-progress-bar-wrapper {
        width: 100%; } }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .seat-progress-bar-wrapper .titlee-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .seat-progress-bar-wrapper .titlee-wrap p {
        font-family: "Cairo", sans-serif;
        font-size: 0.875rem;
        line-height: 1.3;
        color: #373433;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.5px;
        margin: 0; }
        @media only screen and (max-width: 767px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .seat-progress-bar-wrapper .titlee-wrap p {
            font-size: 14px; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .seat-progress-bar-wrapper .titlee-wrap .total-seats {
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #373433;
        font-weight: 700; }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .seat-progress-bar-wrapper .horizontal-progress-bar {
      margin-top: 28px;
      width: 100%; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .seat-progress-bar-wrapper .horizontal-progress-bar .meter {
        width: 100%;
        height: 4px;
        background-color: rgba(55, 52, 51, 0.3);
        border-radius: 2px; }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .seat-progress-bar-wrapper .horizontal-progress-bar .meter span {
          display: block;
          height: 100%;
          background-color: #007473;
          position: relative;
          overflow: hidden;
          border-radius: 2px; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .seat-progress-bar-wrapper .horizontal-progress-bar .meter span:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, #007473), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, #007473), color-stop(0.75, #007473), color-stop(0.75, transparent), to(transparent));
            z-index: 1;
            -webkit-background-size: 20px 20px;
            overflow: hidden;
            -webkit-animation: move 2s linear infinite; }
  .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container {
    width: 70%;
    display: flex;
    margin-top: 30px; }
    @media only screen and (max-width: 1090px) {
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container {
        flex-wrap: wrap; } }
    @media only screen and (max-width: 991px) {
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container {
        width: 100%; } }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box {
      width: 33.3%;
      box-sizing: border-box; }
      @media only screen and (max-width: 991px) {
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box {
          width: 50%; } }
      @media only screen and (max-width: 767px) {
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box {
          width: 100%;
          margin-top: 10px; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box:first-child h6 {
        margin-right: 10px; }
        @media only screen and (max-width: 767px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box:first-child h6 {
            margin-right: 0px; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box:last-child {
        border-right: none; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box:nth-child(2) {
        border-right: 1px solid rgba(55, 52, 51, 0.1);
        border-left: 1px solid rgba(55, 52, 51, 0.1);
        padding: 0 40px 0 40px;
        box-sizing: content-box;
        margin-right: 20px; }
        @media only screen and (max-width: 991px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box:nth-child(2) {
            border: none;
            padding-left: 0px;
            padding-right: 0px;
            margin-right: 0px; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box:nth-child(3) {
        margin-top: 10px; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box p {
        font-family: "Cairo", sans-serif;
        font-size: 0.875rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #373433;
        margin: 0;
        text-transform: uppercase; }
        @media only screen and (max-width: 767px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box p {
            font-size: 14px; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .event-info-container .info-box h6 {
        font-family: "Cairo", sans-serif;
        font-size: 1.25rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #373433;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 10px; }
  .emp-training-detail-wrapper .emp-details .emp-content-wrap .achievement-block {
    margin-top: 50px; }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .achievement-block p {
      font-family: "Cairo", sans-serif;
      font-size: 0.875rem;
      line-height: 1.3;
      letter-spacing: 0.5px;
      color: #373433;
      margin: 0;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .achievement-block p {
          font-size: 14px; } }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .achievement-block h6 {
      font-family: "Cairo", sans-serif;
      font-size: 1.25rem;
      line-height: 1.3;
      letter-spacing: 0.5px;
      color: #373433;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 10px;
      position: relative; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .achievement-block h6.completed {
        padding-left: 36px; }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .achievement-block h6.completed::after {
          background-color: #007473;
          border-radius: 100%;
          height: 26px;
          width: 26px;
          position: absolute;
          top: 0px;
          left: 0px;
          content: ""; }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .achievement-block h6.completed::before {
          position: absolute;
          top: 16px;
          left: 1px;
          transform: translateY(-50%);
          content: "\E906";
          font-family: "icomoon";
          font-size: 18px;
          color: #fff;
          font-weight: 400;
          z-index: 1; }
  .emp-training-detail-wrapper .emp-details .emp-content-wrap .description-block {
    margin-top: 80px; }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .description-block {
        margin-top: 40px; } }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .description-block h3 {
      margin-bottom: 0px;
      font-size: 1.5625rem;
      line-height: 1.36;
      color: #373433;
      font-weight: 400; }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .description-block li {
      font-size: 1.5625rem;
      line-height: 1.36;
      color: #373433;
      font-weight: 400; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .description-block li:first-child {
        margin-top: 10px; }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .description-block p {
      font-family: "Cairo", sans-serif;
      font-size: 1.5625rem;
      line-height: 1.36;
      color: #373433;
      font-weight: 400;
      margin-top: 17px;
      margin-bottom: 0px;
      max-width: 100%; }
      @media only screen and (max-width: 767px) {
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .description-block p {
          max-width: 100%; } }
  .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper {
    width: 100%;
    margin-top: 60px; }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .title {
      display: flex;
      margin-bottom: 30px; }
      @media only screen and (max-width: 767px) {
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .title {
          display: none; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .title p {
        font-family: "Cairo", sans-serif;
        font-size: 1.125rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #373433;
        font-weight: 700;
        margin-bottom: 0px;
        text-transform: uppercase; }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper {
        width: 100%; } }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-tab {
      padding-top: 30px;
      position: relative;
      width: 100%; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-tab:before {
        position: absolute;
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 1px;
        width: 100%;
        background-color: rgba(55, 52, 51, 0.15); }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-tab .time-topic-wrap {
        display: flex;
        width: 80%; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-tab .date {
        width: 20%; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-tab .time {
        width: 36%; }
        @media only screen and (max-width: 991px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-tab .time {
            width: 40%; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-tab .topic {
        width: 64%; }
        @media only screen and (max-width: 991px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-tab .topic {
            width: 60%; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-tab p {
        font-family: "Cairo", sans-serif;
        font-size: 0.75rem;
        line-height: 1.66667;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #373433;
        font-weight: 400; }
        @media only screen and (max-width: 767px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-tab p {
            font-size: 14px; } }
    .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc {
      position: relative;
      padding-bottom: 45px;
      margin-bottom: 45px; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc:last-child {
        padding-bottom: 0px;
        margin-bottom: 45px; }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc:last-child:after {
          display: none; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc:after {
        position: absolute;
        content: "";
        bottom: 0px;
        left: 0px;
        height: 1px;
        width: 100%;
        background: rgba(55, 52, 51, 0.15); }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .header-wrap {
        display: flex;
        width: 100%; }
        @media only screen and (max-width: 767px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .header-wrap {
            flex-direction: column; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .accordion-multi-wrap {
        width: 80%; }
        @media only screen and (max-width: 767px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .accordion-multi-wrap {
            width: 100%; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap {
        width: 100%;
        flex-direction: column;
        cursor: pointer; }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap {
          padding: 32px 0px 28px 0px;
          display: flex;
          width: 100%;
          background-color: #f5fbfa;
          border-radius: 8px;
          position: relative;
          cursor: pointer;
          margin-bottom: 5px;
          transition: 0.3s all ease; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap p {
            color: #373433; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap .open-close {
            height: 35px;
            width: 35px;
            position: absolute;
            top: 50%;
            right: 40px;
            border: 1px solid rgba(55, 52, 51, 0.3);
            border-radius: 100%;
            transform: translateY(-50%);
            cursor: pointer; }
            @media only screen and (max-width: 767px) {
              .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap .open-close {
                right: 10px; } }
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap .open-close:after {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              content: "\E90D";
              font-family: icomoon !important;
              font-style: normal;
              font-weight: 700;
              color: rgba(55, 52, 51, 0.3);
              font-variant: normal;
              text-transform: none;
              font-size: 1.25rem;
              display: inline;
              vertical-align: middle;
              transition: all 0.3s ease-in;
              -webkit-transition: all 0.3s ease-in;
              -moz-transition: all 0.3s ease-in;
              -ms-transition: all 0.3s ease-in;
              -o-transition: all 0.3s ease-in; }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap.active {
          background-color: #007473;
          border-radius: 8px 8px 0px 0px; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap.active p {
            color: #FFFFFF; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap.active .open-close {
            border: 1px solid rgba(255, 255, 255, 0.3);
            transform: translateY(-50%) rotate(-180deg);
            transition: 0.3s all ease; }
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap.active .open-close:after {
              color: #FFFFFF;
              transition: 0.3s all ease; }
            @media only screen and (max-width: 767px) {
              .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap.active .open-close {
                transform: translateY(-50%) rotate(-180deg); } }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap:hover {
          background-color: #007473;
          border-radius: 8px 8px 0px 0px; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap:hover p {
            color: #FFFFFF; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap:hover .open-close {
            border: 1px solid rgba(255, 255, 255, 0.3);
            transition: 0.3s all ease; }
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time-topic-wrap .wrap:hover .open-close:after {
              color: #FFFFFF;
              transition: 0.3s all ease; }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .date {
        width: 20%; }
        @media only screen and (max-width: 767px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .date {
            display: flex;
            align-items: baseline;
            margin-bottom: 5px; } }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .date p {
          font-family: "Cairo", sans-serif;
          font-size: 1.875rem;
          line-height: 1.2;
          color: #007473 !important;
          font-weight: 600;
          margin-bottom: 4px; }
          @media only screen and (max-width: 767px) {
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .date p {
              margin-bottom: 0; } }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .date span {
          font-family: "Cairo", sans-serif;
          font-size: 0.875rem;
          line-height: 1.42857;
          color: #007473 !important;
          font-weight: 400;
          opacity: 0.5; }
          @media only screen and (max-width: 767px) {
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .date span {
              font-size: 14px;
              margin-bottom: 0px;
              margin-left: 10px; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time {
        width: 36%;
        padding-left: 40px; }
        @media only screen and (max-width: 991px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time {
            width: 40%;
            padding-right: 20px; } }
        @media only screen and (max-width: 767px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time {
            padding-left: 20px; } }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time p {
          font-family: "Cairo", sans-serif;
          font-size: 1.25rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          font-weight: 700;
          max-width: 189px;
          text-transform: none; }
          @media only screen and (max-width: 991px) {
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time p {
              font-size: 1rem;
              line-height: 1.375; } }
          @media only screen and (max-width: 767px) {
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .time p {
              max-width: 100%; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .topic {
        width: 64%;
        padding-right: 15%; }
        @media only screen and (max-width: 991px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .topic {
            width: 60%; } }
        @media only screen and (max-width: 767px) {
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .topic {
            padding-right: 65px; } }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .topic p {
          font-family: "Cairo", sans-serif;
          font-size: 1.25rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          font-weight: 700;
          text-transform: none; }
          @media only screen and (max-width: 991px) {
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .topic p {
              font-size: 1rem;
              line-height: 1.375; } }
      .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info {
        background-color: #f5fbfa;
        border-radius: 0px 0px 8px 8px;
        width: 100%;
        display: none; }
        .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info .overview {
          width: 64%;
          padding-right: 57px;
          margin-left: auto;
          padding-top: 27px;
          padding-bottom: 27px; }
          @media only screen and (max-width: 991px) {
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info .overview {
              width: 60%; } }
          @media only screen and (max-width: 767px) {
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info .overview {
              padding-right: 20px; } }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info .overview li,
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info .overview p {
            font-size: 1.125rem;
            font-family: "Cairo", sans-serif;
            line-height: 1.5;
            font-weight: 300;
            color: #373433;
            text-transform: none;
            letter-spacing: normal; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info .overview p {
            margin-bottom: 30px; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info .overview li {
            list-style: none; }
            .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info .overview li:before {
              padding-right: 10px;
              top: 0;
              left: 0px;
              content: "\25AA"; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info .overview li.head:before {
            content: "";
            padding-right: 0px; }
          .emp-training-detail-wrapper .emp-details .emp-content-wrap .accordion-wrapper .accordion-desc .drop-down-info .overview strong {
            font-weight: 700; }

.emp-training-detail-wrapper .status-pending {
  background: rgba(255, 92, 0, 0.65);
  width: 100%;
  border-radius: 8px;
  padding: 20px 10px;
  margin-bottom: 20px;
  min-height: 62px;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .emp-training-detail-wrapper .status-pending {
      flex-direction: column; } }
  .emp-training-detail-wrapper .status-pending p {
    font-size: 1.125rem;
    line-height: 1.11111;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin: 0; }
    @media only screen and (max-width: 991px) {
      .emp-training-detail-wrapper .status-pending p {
        text-align: left;
        max-width: 60%; } }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .status-pending p {
        font-size: 18px;
        max-width: 100%;
        text-align: center; } }
  .emp-training-detail-wrapper .status-pending .cancel-booking {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%); }
    @media only screen and (max-width: 767px) {
      .emp-training-detail-wrapper .status-pending .cancel-booking {
        position: relative;
        margin-top: 40px;
        margin-bottom: 10px;
        top: 0px;
        right: 0px;
        transform: none; } }
    .emp-training-detail-wrapper .status-pending .cancel-booking a {
      border: 1px solid #fff;
      border-radius: 8px;
      color: #fff;
      padding: 13px 27px 13px 24px;
      text-decoration: none;
      font-size: 0.8125rem;
      line-height: 1.46154;
      font-weight: 700;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .emp-training-detail-wrapper .status-pending .cancel-booking a {
          font-size: 13px; } }

.emp-training-detail-wrapper .register-wrap {
  background-color: #373433;
  width: 100%;
  padding: 40px;
  margin-top: 30px; }
  @media only screen and (max-width: 991px) {
    .emp-training-detail-wrapper .register-wrap {
      padding: 20px; } }
  .emp-training-detail-wrapper .register-wrap .register-container {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    @media only screen and (max-width: 991px) {
      .emp-training-detail-wrapper .register-wrap .register-container {
        flex-direction: column;
        align-items: center; } }
    .emp-training-detail-wrapper .register-wrap .register-container h3 {
      font-size: 3.25rem;
      line-height: 1.2;
      letter-spacing: -0.2px;
      font-weight: 600;
      color: #FFFFFF; }
      @media only screen and (max-width: 991px) {
        .emp-training-detail-wrapper .register-wrap .register-container h3 {
          margin-bottom: 20px;
          text-align: center; } }

.my-profile__wrapper .title {
  font-family: "Cairo", sans-serif;
  font-size: 2.625rem;
  line-height: 1.2;
  letter-spacing: -0.2px;
  font-weight: 600;
  color: #373433; }

@media only screen and (max-width: 767px) {
  .my-profile__wrapper .profile-card {
    margin-top: 30px; } }

.my-profile__wrapper .profile-card .profile-icons_wrap {
  display: flex;
  justify-content: flex-end; }
  .my-profile__wrapper .profile-card .profile-icons_wrap li {
    border: 0.8px solid rgba(55, 52, 51, 0.1);
    padding: 13px;
    box-sizing: border-box;
    list-style: none;
    background-color: #FFFFFF;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center; }
    .my-profile__wrapper .profile-card .profile-icons_wrap li:after {
      position: absolute;
      width: 100%;
      height: 0;
      content: "";
      background-color: #007473;
      transition: all 0.2s ease;
      bottom: 0px;
      left: 0px; }
    .my-profile__wrapper .profile-card .profile-icons_wrap li span {
      font-family: "Cairo", sans-serif;
      font-size: 1rem;
      line-height: 1.4;
      letter-spacing: 0.1px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0px 17px;
      display: none; }
    .my-profile__wrapper .profile-card .profile-icons_wrap li:hover:after {
      height: 6px; }
    .my-profile__wrapper .profile-card .profile-icons_wrap li:hover span {
      display: block; }
    .my-profile__wrapper .profile-card .profile-icons_wrap li a {
      border: 1.01px solid rgba(55, 52, 51, 0.15);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      width: 46px; }
      .my-profile__wrapper .profile-card .profile-icons_wrap li a .icomoon {
        font-size: 1rem; }
        .my-profile__wrapper .profile-card .profile-icons_wrap li a .icomoon:before {
          color: #373433; }
      .my-profile__wrapper .profile-card .profile-icons_wrap li a .icon-delete_black,
      .my-profile__wrapper .profile-card .profile-icons_wrap li a .icon-edit_black {
        font-size: 1.5rem; }

.my-profile__wrapper .profile-card .profile-card {
  display: flex;
  justify-content: space-between; }

.my-profile__wrapper .profile-card .details {
  padding: 25px 35px 39px 23px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 1090px) {
    .my-profile__wrapper .profile-card .details {
      flex-direction: column; } }
  @media only screen and (max-width: 767px) {
    .my-profile__wrapper .profile-card .details {
      padding: 20px; } }
  .my-profile__wrapper .profile-card .details .profile-img {
    height: 119px;
    width: 119px;
    border-radius: 100%; }
  .my-profile__wrapper .profile-card .details .info-box {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .my-profile__wrapper .profile-card .details .info-box {
        flex-direction: column; } }
  .my-profile__wrapper .profile-card .details .col {
    display: flex;
    flex-direction: column;
    margin-left: 29px; }
    @media only screen and (max-width: 767px) {
      .my-profile__wrapper .profile-card .details .col {
        flex-direction: column;
        margin-left: 0px;
        padding-left: 0px;
        margin-top: 20px; } }
    .my-profile__wrapper .profile-card .details .col span {
      font-family: "Cairo", sans-serif;
      font-size: 1rem;
      line-height: 1.75;
      font-weight: 600; }
      @media only screen and (max-width: 767px) {
        .my-profile__wrapper .profile-card .details .col span {
          font-size: 14px; } }
    .my-profile__wrapper .profile-card .details .col h4 {
      font-size: 1.5rem;
      line-height: 1.375;
      font-weight: 700;
      padding-right: 10px;
      color: #373433; }
      @media only screen and (max-width: 767px) {
        .my-profile__wrapper .profile-card .details .col h4 {
          font-size: 22px; } }
      .my-profile__wrapper .profile-card .details .col h4 span {
        color: #373433;
        opacity: 0.4;
        margin-left: 10px; }
    .my-profile__wrapper .profile-card .details .col p {
      font-size: 1rem;
      line-height: 1.75;
      color: #373433; }
      @media only screen and (max-width: 767px) {
        .my-profile__wrapper .profile-card .details .col p {
          font-size: 14px; } }
      .my-profile__wrapper .profile-card .details .col p .company {
        text-transform: uppercase;
        padding-right: 10px;
        color: #007473; }
      .my-profile__wrapper .profile-card .details .col p .duration {
        opacity: 0.4; }
    .my-profile__wrapper .profile-card .details .col .contact-boxes {
      display: flex;
      margin-top: 17px; }
      @media only screen and (max-width: 767px) {
        .my-profile__wrapper .profile-card .details .col .contact-boxes {
          flex-direction: column; } }
      .my-profile__wrapper .profile-card .details .col .contact-boxes .phone,
      .my-profile__wrapper .profile-card .details .col .contact-boxes .email {
        display: flex;
        align-items: center; }
        .my-profile__wrapper .profile-card .details .col .contact-boxes .phone .icomoon,
        .my-profile__wrapper .profile-card .details .col .contact-boxes .email .icomoon {
          font-size: 1.25rem; }
          .my-profile__wrapper .profile-card .details .col .contact-boxes .phone .icomoon:before,
          .my-profile__wrapper .profile-card .details .col .contact-boxes .email .icomoon:before {
            color: #373433; }
        .my-profile__wrapper .profile-card .details .col .contact-boxes .phone span,
        .my-profile__wrapper .profile-card .details .col .contact-boxes .email span {
          font-size: 1rem;
          line-height: 1.5;
          font-weight: 300;
          color: #373433;
          padding-left: 10px; }
          @media only screen and (max-width: 767px) {
            .my-profile__wrapper .profile-card .details .col .contact-boxes .phone span,
            .my-profile__wrapper .profile-card .details .col .contact-boxes .email span {
              font-size: 14px; } }
      .my-profile__wrapper .profile-card .details .col .contact-boxes .phone {
        margin-right: 28px; }
        @media only screen and (max-width: 767px) {
          .my-profile__wrapper .profile-card .details .col .contact-boxes .phone {
            margin-right: 0px;
            margin-bottom: 10px; } }
      .my-profile__wrapper .profile-card .details .col .contact-boxes .email .icomoon {
        font-size: 0.875rem; }
  .my-profile__wrapper .profile-card .details .date {
    font-size: 1rem;
    line-height: 1.75;
    font-weight: 300;
    color: #373433;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: right; }
    @media only screen and (max-width: 767px) {
      .my-profile__wrapper .profile-card .details .date {
        font-size: 16px;
        text-align: left; } }

.edit-profile_wrapper {
  display: none; }
  @media only screen and (max-width: 1090px) {
    .edit-profile_wrapper .years-exp {
      width: 100%;
      padding-right: 0px; } }
  .edit-profile_wrapper .years-exp .selection {
    width: 25% !important;
    padding-right: 10px; }
    .edit-profile_wrapper .years-exp .selection:last-child {
      padding-right: 0px; }
    @media only screen and (max-width: 991px) {
      .edit-profile_wrapper .years-exp .selection {
        padding-right: 25px !important;
        padding-left: 0px !important; }
        .edit-profile_wrapper .years-exp .selection:last-child {
          padding-right: 0px !important; } }
    @media only screen and (max-width: 767px) {
      .edit-profile_wrapper .years-exp .selection {
        padding-right: 10px !important; } }
  .edit-profile_wrapper .title {
    font-family: "Cairo", sans-serif;
    font-size: 2.625rem;
    line-height: 1.2;
    letter-spacing: -0.2px;
    font-weight: 600;
    color: #373433; }
  .edit-profile_wrapper #my-profile-btn {
    margin-bottom: 0px;
    margin-top: 24px;
    font-family: "Cairo", sans-serif;
    font-size: 1.125rem;
    line-height: 1.55556;
    color: #373433;
    padding-left: 15px;
    font-weight: 400;
    position: relative; }
    .edit-profile_wrapper #my-profile-btn:after {
      position: absolute;
      height: 12px;
      width: 6px;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background-image: url(../images/back-icon.svg);
      transition: all 0.2s ease; }
    .edit-profile_wrapper #my-profile-btn:hover:after {
      left: -6px; }
  .edit-profile_wrapper .contact-us {
    margin-top: 40px;
    padding: 20px 40px;
    display: flex;
    background-color: #373433;
    border-radius: 8px; }
    @media only screen and (max-width: 1090px) {
      .edit-profile_wrapper .contact-us {
        padding: 20px; } }
    @media only screen and (max-width: 767px) {
      .edit-profile_wrapper .contact-us {
        flex-direction: column; } }
    .edit-profile_wrapper .contact-us p {
      font-family: "Cairo", sans-serif;
      font-size: 1.125rem;
      line-height: 1.11111;
      font-weight: 600;
      color: #FFFFFF;
      margin: 0px;
      margin-right: 27px; }
      @media only screen and (max-width: 1090px) {
        .edit-profile_wrapper .contact-us p {
          font-size: 15px;
          line-height: 24px; } }
      @media only screen and (max-width: 767px) {
        .edit-profile_wrapper .contact-us p {
          margin-bottom: 15px;
          padding-right: 0px; } }
    .edit-profile_wrapper .contact-us .phone,
    .edit-profile_wrapper .contact-us .email {
      display: flex;
      align-items: center; }
      .edit-profile_wrapper .contact-us .phone .icomoon,
      .edit-profile_wrapper .contact-us .email .icomoon {
        font-size: 1.25rem; }
        .edit-profile_wrapper .contact-us .phone .icomoon:before,
        .edit-profile_wrapper .contact-us .email .icomoon:before {
          color: #FFFFFF; }
      .edit-profile_wrapper .contact-us .phone span,
      .edit-profile_wrapper .contact-us .email span {
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 300;
        color: #FFFFFF;
        padding-left: 10px; }
        @media only screen and (max-width: 767px) {
          .edit-profile_wrapper .contact-us .phone span,
          .edit-profile_wrapper .contact-us .email span {
            font-size: 16px; } }
    .edit-profile_wrapper .contact-us .phone {
      margin-right: 26px; }
      @media only screen and (max-width: 767px) {
        .edit-profile_wrapper .contact-us .phone {
          margin-right: 0px;
          margin-bottom: 15px; } }
    .edit-profile_wrapper .contact-us .email .icomoon {
      font-size: 0.875rem; }
  .edit-profile_wrapper .edit-form_wrapper {
    margin-top: 30px;
    padding: 27px 22px;
    background-color: #FFFFFF; }
    @media only screen and (max-width: 500px) {
      .edit-profile_wrapper .edit-form_wrapper {
        padding: 27px 10px; } }
    .edit-profile_wrapper .edit-form_wrapper p {
      font-size: 1.5rem;
      line-height: 1.16667;
      font-weight: 600;
      letter-spacing: -0.2px;
      color: #373433;
      margin: 0; }
    .edit-profile_wrapper .edit-form_wrapper .edit-image-wrap {
      padding-bottom: 37px;
      border-bottom: 1px solid rgba(55, 52, 51, 0.1); }
    .edit-profile_wrapper .edit-form_wrapper .edit-img {
      display: flex;
      margin-top: 30px;
      align-items: center; }
      @media only screen and (max-width: 767px) {
        .edit-profile_wrapper .edit-form_wrapper .edit-img {
          flex-direction: column;
          align-items: flex-start; } }
      .edit-profile_wrapper .edit-form_wrapper .edit-img .avatar {
        position: relative;
        margin-right: 47px; }
        .edit-profile_wrapper .edit-form_wrapper .edit-img .avatar img {
          height: 116px;
          width: 116px;
          border-radius: 100%; }
        .edit-profile_wrapper .edit-form_wrapper .edit-img .avatar .icomoon {
          position: absolute;
          top: -13px;
          right: 0;
          height: 46px;
          width: 46px;
          background-color: #fff;
          border-radius: 100%;
          font-size: 1.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(55, 52, 51, 0.15);
          cursor: pointer; }
          .edit-profile_wrapper .edit-form_wrapper .edit-img .avatar .icomoon:before {
            color: #373433; }
      .edit-profile_wrapper .edit-form_wrapper .edit-img input {
        display: none; }
      .edit-profile_wrapper .edit-form_wrapper .edit-img label {
        padding: 18px 32px 18px 28px;
        background-color: transparent;
        border: 1px solid #007473;
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: 0.1px;
        font-weight: 700;
        text-transform: uppercase;
        color: #007473;
        border-radius: 15px;
        height: fit-content;
        cursor: pointer; }
        @media only screen and (max-width: 767px) {
          .edit-profile_wrapper .edit-form_wrapper .edit-img label {
            margin-top: 20px; } }
    .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap {
      margin-top: 30px; }
      .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap p {
        margin-bottom: 26px; }
      .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .editable-input-box {
        width: 50%;
        margin-bottom: 30px;
        position: relative; }
        @media only screen and (max-width: 767px) {
          .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .editable-input-box {
            width: 100%; } }
      .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap label {
        font-family: "Cairo", sans-serif;
        font-size: 0.75rem;
        line-height: 1.66667;
        font-weight: 400;
        color: #7f8184;
        margin-bottom: 9px; }
        @media only screen and (max-width: 767px) {
          .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap label {
            font-size: 14px; } }
      .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .abs-label {
        margin: 0;
        position: absolute;
        top: -12px;
        left: 16px;
        padding: 4px;
        background-color: #FFFFFF; }
      .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .editable-input {
        width: 100%;
        font-size: 1.25rem;
        line-height: 1.25;
        font-weight: 400;
        color: #373433;
        padding: 17px 20px;
        border-radius: 8px;
        border: 1px solid rgba(127, 129, 132, 0.1);
        background-color: rgba(55, 52, 51, 0.04); }
      .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .editable-input-box.phone .abs-label {
        left: 32px; }
      .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .flex {
        display: flex;
        flex-wrap: wrap; }
        .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .flex .editable-input-box {
          padding-right: 15px; }
          .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .flex .editable-input-box:nth-child(2n) {
            padding-right: 0px;
            padding-left: 15px; }
            @media only screen and (max-width: 767px) {
              .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .flex .editable-input-box:nth-child(2n) {
                padding-left: 0px;
                padding-right: 0px; } }
          @media only screen and (max-width: 767px) {
            .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .flex .editable-input-box {
              padding-left: 0px;
              padding-right: 0px; } }
      .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap {
        width: 50%;
        padding-right: 15px; }
        .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap:nth-child(2n) {
          padding-right: 0px;
          padding-left: 15px; }
        @media only screen and (max-width: 991px) {
          .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap {
            width: 100%;
            margin-bottom: 20px;
            padding-right: 0px; }
            .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap:nth-child(2) {
              padding-left: 0px; } }
        .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap p {
          font-size: 1rem;
          line-height: 1.25;
          font-weight: 400;
          color: #373433;
          margin: 10px; }
        .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap .flex {
          display: flex;
          width: 100%; }
          @media only screen and (max-width: 991px) {
            .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap .flex {
              padding-right: 0px; } }
          .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap .flex .selection {
            width: 50%; }
            .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap .flex .selection label {
              font-size: 1.25rem;
              line-height: 1.3;
              font-weight: 400;
              color: #373433; }
            @media only screen and (max-width: 991px) {
              .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap .flex .selection {
                padding-right: 15px; }
                .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap .flex .selection:nth-child(2n) {
                  padding-right: 0px;
                  padding-left: 15px; } }
            .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap .flex .selection:last-child {
              padding-right: 0px; }
            @media only screen and (max-width: 767px) {
              .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap .flex .selection {
                padding-right: 7px; }
                .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap .flex .selection:last-child {
                  padding-left: 7px; } }
        .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .gender-wrap .pr-0 {
          padding-right: 0px; }
      @media only screen and (max-width: 1090px) {
        .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .years-exp {
          width: 100%;
          padding-right: 0px; }
          .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .years-exp .selection {
            width: 25%;
            padding-right: 30px; }
            .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .years-exp .selection:last-child {
              padding-right: 0px; } }
    @media only screen and (max-width: 1090px) and (max-width: 991px) {
      .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .years-exp .selection {
        padding-right: 25px !important;
        padding-left: 0px !important; }
        .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .years-exp .selection:last-child {
          padding-right: 0px !important; } }
    @media only screen and (max-width: 1090px) and (max-width: 767px) {
      .edit-profile_wrapper .edit-form_wrapper .multi-info-wrap .years-exp .selection {
        padding-right: 10px !important; } }
    .edit-profile_wrapper .edit-form_wrapper .additional-info-wrap {
      margin-top: 50px;
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(55, 52, 51, 0.1); }
      .edit-profile_wrapper .edit-form_wrapper .additional-info-wrap p {
        margin-bottom: 20px; }
      .edit-profile_wrapper .edit-form_wrapper .additional-info-wrap .abs-label {
        margin: 0;
        position: absolute;
        top: -12px;
        left: 16px;
        padding: 4px;
        background-color: #FFFFFF;
        color: #7f8184;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400; }
      .edit-profile_wrapper .edit-form_wrapper .additional-info-wrap .additional-info {
        display: flex;
        flex-wrap: wrap; }
        @media only screen and (max-width: 991px) {
          .edit-profile_wrapper .edit-form_wrapper .additional-info-wrap .additional-info .select-box {
            width: 100%;
            padding-right: 0px; } }
        .edit-profile_wrapper .edit-form_wrapper .additional-info-wrap .additional-info .share_input {
          width: 100%; }
    .edit-profile_wrapper .edit-form_wrapper .save-wrapper {
      margin-top: 35px; }
      @media only screen and (max-width: 767px) {
        .edit-profile_wrapper .edit-form_wrapper .save-wrapper {
          text-align: center; } }
      .edit-profile_wrapper .edit-form_wrapper .save-wrapper input {
        padding-top: 18px;
        padding-bottom: 18px;
        width: 234px;
        font-size: 1rem;
        line-height: 1.4;
        letter-spacing: 0.1px;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 15px;
        margin-right: 20px; }
        .edit-profile_wrapper .edit-form_wrapper .save-wrapper input:last-child {
          margin-right: 0px; }
        @media only screen and (max-width: 767px) {
          .edit-profile_wrapper .edit-form_wrapper .save-wrapper input {
            margin-right: 0px; } }
      .edit-profile_wrapper .edit-form_wrapper .save-wrapper .save {
        background-color: #007473;
        color: #fff;
        border: none; }
        @media only screen and (max-width: 767px) {
          .edit-profile_wrapper .edit-form_wrapper .save-wrapper .save {
            margin-bottom: 30px; } }
        .edit-profile_wrapper .edit-form_wrapper .save-wrapper .save.disabled {
          opacity: 0.5;
          cursor: not-allowed; }
      .edit-profile_wrapper .edit-form_wrapper .save-wrapper .cancel {
        border: 1px solid #007473;
        color: #007473;
        background-color: transparent; }
        .edit-profile_wrapper .edit-form_wrapper .save-wrapper .cancel.disabled {
          opacity: 0.5;
          cursor: not-allowed; }

.success-message {
  z-index: 1000;
  padding: 18px 12px 19px 24px;
  background-color: rgba(0, 116, 115, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  backdrop-filter: blur(84px);
  width: 414px;
  position: fixed;
  top: 30px;
  right: 40px;
  display: none; }
  @media only screen and (max-width: 767px) {
    .success-message {
      width: auto;
      right: 20px; }
      .success-message h5 {
        max-width: 220px; } }
  .success-message.active {
    display: block; }
  .success-message h5 {
    font-size: 1.375rem;
    line-height: 1.18591;
    font-weight: 700;
    color: #fff;
    margin-bottom: 4px; }
  .success-message p {
    font-size: 1rem;
    line-height: 1.63062;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7); }
  .success-message span {
    position: absolute;
    height: 28px;
    width: 28px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    top: 12px;
    right: 12px;
    border-radius: 100%;
    cursor: pointer;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .success-message span:after {
      content: "\E905";
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 0.625rem;
      color: #fff; }

.edit {
  position: absolute;
  height: 46px;
  width: 46px;
  border: 1px solid rgba(55, 52, 51, 0.15);
  border-radius: 100%;
  top: 50%;
  right: 23px;
  transform: translateY(-50%); }
  @media only screen and (max-width: 500px) {
    .edit {
      height: 30px;
      width: 30px;
      right: 5px; } }
  .edit:after {
    content: "\E90E";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 1.25rem;
    color: #373433; }

.dashboard-mobile-menu__wrapper {
  background-color: #fff;
  height: 63px;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid rgba(55, 52, 51, 0.1);
  border-top: 1px solid rgba(55, 52, 51, 0.1);
  padding: 0 40px;
  position: relative; }
  .dashboard-mobile-menu__wrapper:after {
    top: 115px;
    right: 0;
    transform: translateY(-50%);
    height: 55px;
    width: 70px;
    position: fixed;
    background: linear-gradient(270deg, #ffffff 20.48%, rgba(255, 255, 255, 0) 100%); }
  @media only screen and (min-width: 1090px) {
    .dashboard-mobile-menu__wrapper {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .dashboard-mobile-menu__wrapper {
      padding: 0 0 0 20px;
      overflow-x: auto;
      justify-content: flex-start; } }
  @media only screen and (min-width: 767px) {
    .dashboard-mobile-menu__wrapper:after {
      display: none; } }
  .dashboard-mobile-menu__wrapper ul {
    display: flex;
    margin-bottom: 0px;
    height: 100%;
    width: auto;
    position: absolute;
    padding: 0; }
    @media only screen and (max-width: 600px) {
      .dashboard-mobile-menu__wrapper ul {
        width: 600px; } }
    .dashboard-mobile-menu__wrapper ul li {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      margin-right: 30px; }
      @media only screen and (max-width: 767px) {
        .dashboard-mobile-menu__wrapper ul li {
          width: auto; } }
      .dashboard-mobile-menu__wrapper ul li:last-child {
        margin-right: 0; }
      .dashboard-mobile-menu__wrapper ul li:before {
        display: none; }
      .dashboard-mobile-menu__wrapper ul li:after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        height: 0;
        width: 100%;
        background-color: #007473;
        transition: 0.3s all ease;
        border-radius: 8px 8px 0px 0px; }
      .dashboard-mobile-menu__wrapper ul li.active {
        color: #007473; }
        .dashboard-mobile-menu__wrapper ul li.active:after {
          height: 5px; }
      .dashboard-mobile-menu__wrapper ul li a {
        padding: 0 !important;
        font-family: "Cairo", sans-serif;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 700;
        color: rgba(55, 52, 51, 0.6);
        padding-bottom: 20px;
        position: relative; }
        .dashboard-mobile-menu__wrapper ul li a:last-child {
          padding-right: 0px; }
        .dashboard-mobile-menu__wrapper ul li a:before {
          display: none; }

#my-emp-detail_wrapper {
  display: none; }
  @media only screen and (max-width: 767px) {
    #my-emp-detail_wrapper {
      padding-right: 20px; } }

#emp-detail_wrapper {
  display: none; }
  @media only screen and (max-width: 767px) {
    #emp-detail_wrapper {
      padding-right: 20px; } }

@media only screen and (max-width: 767px) {
  #training-listing__wrapper .filters {
    position: relative; }
    #training-listing__wrapper .filters:after {
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      height: 55px;
      width: 70px;
      position: absolute;
      background: linear-gradient(270deg, #f2f8f7 20.48%, rgba(242, 248, 247, 0) 100%); }
    #training-listing__wrapper .filters ul {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden; }
      #training-listing__wrapper .filters ul li {
        overflow: visible; }
        #training-listing__wrapper .filters ul li a {
          min-width: 118px;
          width: 150px;
          padding: 10px !important;
          padding-top: 16px !important;
          padding-bottom: 18px !important;
          font-size: 14px !important; } }

@media only screen and (max-width: 767px) {
  .my-profile__wrapper {
    padding-right: 20px !important; } }

@media only screen and (max-width: 767px) {
  #emp-detail_wrapper {
    padding-right: 20px !important; } }

@media only screen and (max-width: 767px) {
  .dashboard-leaderboard {
    padding-right: 20px !important; } }

.dashboard-gp-in-box h2 {
  font-size: 2.625rem;
  line-height: 1.2;
  letter-spacing: -0.2px;
  font-weight: 600;
  color: #373433;
  margin-bottom: 37px; }

@media only screen and (max-width: 767px) {
  .dashboard-gp-in-box .dashboard-tabs__content {
    padding-right: 20px; } }

.training-listing__wrapper .filters .nav-item:after {
  display: none; }

@media only screen and (max-width: 991px) {
  .editable-input-box.editemail,
  .editable-input-box.editphone {
    width: 100% !important;
    padding: 0px !important; } }

.editable-input-box.editemail input,
.editable-input-box.editphone input {
  padding-right: 65px !important; }

@media only screen and (max-width: 991px) {
  .editable-input-box.editphone .abs-label {
    left: 16px !important; } }

.disable-btn {
  opacity: 0.4;
  cursor: not-allowed !important;
  /*pointer-events: none !important;*/
  pointer-events: none; }
  .disable-btn a {
    pointer-events: none; }
  .disable-btn:after {
    display: none !important; }

#questionnaire-completed-message {
  width: 460px; }
  @media only screen and (max-width: 767px) {
    #questionnaire-completed-message {
      width: auto;
      right: 20px; }
      #questionnaire-completed-message h5 {
        max-width: 220px; } }

.questionnaire {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  padding-top: 44px;
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 0;
  transition: 0.5s all ease-in-out;
  transform: translateX(-100vw); }
  .questionnaire div {
    direction: ltr !important; }
  .questionnaire .abs_label {
    right: auto !important;
    left: 16px !important; }
  @media only screen and (max-width: 991px) {
    .questionnaire {
      padding-right: 40px;
      padding-left: 40px; } }
  @media only screen and (max-width: 767px) {
    .questionnaire {
      padding-left: 20px;
      padding-right: 20px; } }
  .questionnaire.active {
    display: block;
    opacity: 1;
    z-index: 1000;
    transform: translateX(0); }
  .questionnaire .q-close {
    position: absolute;
    z-index: 2;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .questionnaire .q-close {
        right: 20px; } }
    .questionnaire .q-close:after {
      position: absolute;
      font-family: "icomoon" !important;
      speak: never;
      content: "\E905";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .questionnaire .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 330px;
    width: 100%;
    background-color: #f2f8f7;
    z-index: -1; }
  .questionnaire .wrapper {
    position: relative;
    height: auto;
    width: 100%;
    max-width: 806px;
    margin: auto;
    padding-bottom: 85px; }
    .questionnaire .wrapper .logo-wrap {
      display: block;
      text-align: center; }
    .questionnaire .wrapper .background-bg {
      margin-top: 45px;
      height: 0px;
      width: 100%;
      padding-top: calc(386 / 806 * 100%);
      background-size: cover;
      background-position-x: center;
      background-position-y: top;
      background-repeat: no-repeat; }
    .questionnaire .wrapper .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 49px; }
      @media only screen and (max-width: 767px) {
        .questionnaire .wrapper .title {
          flex-direction: column-reverse;
          align-items: flex-start; } }
      .questionnaire .wrapper .title h3 {
        font-family: "Cairo", sans-serif;
        font-size: 2.625rem;
        line-height: 1.2;
        font-weight: 600;
        color: #373433;
        max-width: 570px;
        text-transform: capitalize;
        text-align: left !important; }
      .questionnaire .wrapper .title span {
        font-size: 2rem;
        line-height: 1.2;
        color: #373433;
        font-weight: 600; }
        @media only screen and (max-width: 767px) {
          .questionnaire .wrapper .title span {
            margin-bottom: 20px; } }
    .questionnaire .wrapper h4 {
      font-family: "Cairo", sans-serif;
      font-size: 2rem;
      line-height: 1.1875;
      color: #373433;
      margin-top: 16px;
      max-width: 650px;
      font-weight: 600;
      text-align: left !important; }
    .questionnaire .wrapper p {
      font-family: "Cairo", sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #373433;
      margin-top: 25px;
      max-width: 620px;
      text-align: left !important; }
    .questionnaire .wrapper .question-title {
      font-family: "Cairo", sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #373433;
      position: relative;
      margin-top: 11px;
      text-transform: capitalize; }
      .questionnaire .wrapper .question-title i {
        position: absolute;
        top: 0px;
        color: red;
        right: -5px; }
    .questionnaire .wrapper .q {
      font-family: "Cairo", sans-serif;
      font-size: 1.5rem;
      line-height: 1.2;
      letter-spacing: -0.2px;
      font-weight: 600;
      color: #373433;
      margin-top: 8px;
      max-width: 600px;
      margin-bottom: 23px; }
    .questionnaire .wrapper .flex {
      display: flex; }
    .questionnaire .wrapper .selection {
      max-width: 190px !important; }
    .questionnaire .wrapper .submit {
      padding: 18px 32px 18px 28px;
      color: #FFFFFF;
      font-size: 1rem;
      line-height: 1.4;
      font-weight: 700;
      letter-spacing: 0.1px;
      margin-top: 50px;
      background-color: #007473;
      border-radius: 8px;
      cursor: not-allowed;
      box-shadow: none;
      outline: none;
      border: none;
      text-transform: uppercase;
      width: 241px;
      text-align: center;
      opacity: 0.4; }
      .questionnaire .wrapper .submit.activate {
        opacity: 1;
        cursor: pointer; }
    .questionnaire .wrapper .back-to-q1 {
      margin-bottom: 0px;
      margin-top: 24px;
      font-family: "Cairo", sans-serif;
      font-size: 1.125rem;
      line-height: 1.55556;
      color: #373433;
      padding-left: 15px;
      font-weight: 400;
      position: relative;
      cursor: pointer;
      text-align: left !important;
      max-width: 100%; }
      .questionnaire .wrapper .back-to-q1:after {
        position: absolute;
        height: 12px;
        width: 6px;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-image: url(../images/back-icon.svg);
        transition: all 0.2s ease; }
      .questionnaire .wrapper .back-to-q1:hover:after {
        left: -6px; }
    .questionnaire .wrapper .q-box.error .error-msg {
      display: block !important; }
    .questionnaire .wrapper .q-box {
      margin-bottom: 40px; }
      .questionnaire .wrapper .q-box .error-msg {
        font-family: "Cairo", sans-serif;
        font-size: 1rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #d62525;
        margin-top: 14px;
        display: none; }
        .questionnaire .wrapper .q-box .error-msg.show {
          display: block; }
    .questionnaire .wrapper .input-box {
      width: 620px !important;
      padding-right: 0px !important;
      padding-left: 0px !important; }
      @media only screen and (max-width: 767px) {
        .questionnaire .wrapper .input-box {
          width: 100% !important; } }
      .questionnaire .wrapper .input-box .input.error {
        border-color: #d62525 !important; }
  .questionnaire .q1-container {
    display: block; }
    .questionnaire .q1-container .hide {
      display: none; }
  .questionnaire .professional {
    display: none; }
    .questionnaire .professional .submit {
      opacity: 1 !important;
      cursor: pointer !important; }
    .questionnaire .professional.active {
      display: block; }
  .questionnaire .interested {
    display: none; }
    .questionnaire .interested .submit {
      opacity: 1 !important;
      cursor: pointer !important; }
    .questionnaire .interested.active {
      display: block; }

.survey {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  padding-top: 44px;
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 0;
  transform: translateX(-100vw);
  transition: 0.5s all ease-in-out; }
  @media only screen and (max-width: 991px) {
    .survey {
      padding-right: 40px;
      padding-left: 40px; } }
  @media only screen and (max-width: 767px) {
    .survey {
      padding-left: 20px;
      padding-right: 20px; } }
  .survey div {
    direction: ltr !important; }
  .survey .abs_label {
    right: auto !important;
    left: 16px !important; }
  .survey.active {
    opacity: 1;
    z-index: 1000;
    transform: translateX(0); }
  .survey .s-close {
    position: absolute;
    z-index: 2;
    top: 40px;
    right: 40px;
    width: 45px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background-color: #FFFFFF;
    min-width: none;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .survey .s-close {
        right: 20px; } }
    .survey .s-close:after {
      position: absolute;
      font-family: "icomoon" !important;
      speak: never;
      content: "\E905";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .survey .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 330px;
    width: 100%;
    background-color: #f2f8f7;
    z-index: -1; }
  .survey .wrapper {
    position: relative;
    height: auto;
    width: 100%;
    max-width: 806px;
    margin: auto;
    padding-bottom: 85px; }
    .survey .wrapper .logo-wrap {
      display: block;
      text-align: center; }
    .survey .wrapper .background-bg {
      margin-top: 45px;
      height: 0px;
      width: 100%;
      padding-top: calc(386 / 806 * 100%);
      background-size: cover;
      background-position-x: center;
      background-position-y: top;
      background-repeat: no-repeat; }
    .survey .wrapper .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 49px; }
      @media only screen and (max-width: 767px) {
        .survey .wrapper .title {
          flex-direction: column-reverse;
          align-items: flex-start; } }
      .survey .wrapper .title h3 {
        font-family: "Cairo", sans-serif;
        font-size: 2.625rem;
        line-height: 1.2;
        font-weight: 600;
        color: #373433;
        max-width: 551px;
        text-transform: capitalize;
        text-align: left !important; }
      .survey .wrapper .title span {
        font-size: 2rem;
        line-height: 1.2;
        color: #373433;
        font-weight: 600; }
        @media only screen and (max-width: 767px) {
          .survey .wrapper .title span {
            margin-bottom: 20px; } }
    .survey .wrapper h4 {
      font-family: "Cairo", sans-serif;
      font-size: 2rem;
      line-height: 1.1875;
      color: #373433;
      margin-top: 16px;
      max-width: 650px;
      font-weight: 600;
      text-align: left !important; }
    .survey .wrapper h5 {
      font-family: "Cairo", sans-serif;
      font-size: 1.875rem;
      line-height: 1.2;
      color: #373433;
      margin-top: 80px;
      max-width: 600px;
      font-weight: 600;
      letter-spacing: -0.2px;
      text-align: left !important; }
    .survey .wrapper p {
      font-family: "Cairo", sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: #373433;
      margin-top: 25px;
      max-width: 620px;
      text-align: left !important; }
    .survey .wrapper .question-title {
      font-family: "Cairo", sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #373433;
      position: relative;
      margin-top: 11px;
      text-transform: capitalize; }
      .survey .wrapper .question-title i {
        position: absolute;
        top: 0px;
        color: red;
        right: -5px; }
    .survey .wrapper .q {
      font-family: "Cairo", sans-serif;
      font-size: 1.5rem;
      line-height: 1.2;
      letter-spacing: -0.2px;
      font-weight: 600;
      color: #373433;
      margin-top: 8px;
      max-width: 600px;
      margin-bottom: 23px;
      text-align: left !important; }
    .survey .wrapper .submit {
      padding: 18px 32px 18px 28px;
      color: #FFFFFF;
      font-size: 1rem;
      line-height: 1.4;
      font-weight: 700;
      letter-spacing: 0.1px;
      margin-top: 50px;
      background-color: #007473;
      border-radius: 8px;
      cursor: not-allowed;
      box-shadow: none;
      outline: none;
      border: none;
      text-transform: uppercase;
      width: 241px;
      text-align: center;
      opacity: 0.4; }
      .survey .wrapper .submit.activate {
        opacity: 1;
        cursor: pointer; }
    .survey .wrapper .back-to-q1 {
      margin-bottom: 0px;
      margin-top: 24px;
      font-family: "Cairo", sans-serif;
      font-size: 1.125rem;
      line-height: 1.55556;
      color: #373433;
      padding-left: 15px;
      font-weight: 400;
      position: relative;
      cursor: pointer; }
      .survey .wrapper .back-to-q1:after {
        position: absolute;
        height: 12px;
        width: 6px;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-image: url(../images/back-icon.svg);
        transition: all 0.2s ease; }
      .survey .wrapper .back-to-q1:hover:after {
        left: -6px; }
    .survey .wrapper .q-box.error .error-msg {
      display: block !important; }
    .survey .wrapper .q-box {
      margin-bottom: 40px; }
      .survey .wrapper .q-box.rating-5 .flex {
        display: flex;
        max-width: 601px; }
        @media only screen and (max-width: 767px) {
          .survey .wrapper .q-box.rating-5 .flex {
            flex-wrap: wrap; } }
      .survey .wrapper .q-box.rating-5 .selection {
        width: 16.667%;
        padding-left: 0px !important;
        padding-right: 10px !important; }
        @media only screen and (max-width: 767px) {
          .survey .wrapper .q-box.rating-5 .selection label {
            padding: 12px 0px; } }
        @media only screen and (max-width: 767px) {
          .survey .wrapper .q-box.rating-5 .selection {
            width: 33.33%;
            flex-wrap: wrap;
            margin-bottom: 5px; } }
      .survey .wrapper .q-box.rating-10 .flex {
        flex-wrap: wrap;
        display: flex;
        max-width: 601px; }
      .survey .wrapper .q-box.rating-10 .selection {
        width: 20%;
        margin-bottom: 10px;
        padding-left: 0px !important;
        padding-right: 10px !important; }
        @media only screen and (max-width: 767px) {
          .survey .wrapper .q-box.rating-10 .selection label {
            padding: 12px 0px; } }
      .survey .wrapper .q-box .desc {
        font-size: 14px;
        line-height: 18.2px;
        font-weight: 400;
        font-family: "Cairo", sans-serif;
        max-width: 600px;
        margin-top: 10px; }
      .survey .wrapper .q-box .error-msg {
        font-family: "Cairo", sans-serif;
        font-size: 1rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        color: #d62525;
        margin-top: 14px;
        display: none; }
        .survey .wrapper .q-box .error-msg.show {
          display: block; }
    .survey .wrapper .input-box {
      width: 620px !important;
      padding-right: 0px !important;
      padding-left: 0px !important; }
      @media only screen and (max-width: 767px) {
        .survey .wrapper .input-box {
          width: 100% !important; } }
      .survey .wrapper .input-box .input.error {
        border-color: #d62525 !important; }

body.local-ar .abs_label {
  left: auto !important;
  right: 16px !important; }

body.local-ar .questionnaire div, body.local-ar .survey div {
  direction: rtl !important; }

body.local-ar .questionnaire .wrapper h4 {
  text-align: right !important; }

body.local-ar .questionnaire .wrapper .q {
  text-align: right !important; }

body.local-ar .questionnaire .wrapper .back-to-q1 {
  padding-left: 0px !important;
  padding-right: 15px !important;
  text-align: right !important; }
  body.local-ar .questionnaire .wrapper .back-to-q1:after {
    left: auto;
    right: 0px;
    transform: translateY(-50%) rotate(180deg) !important; }

body.local-ar .survey .wrapper h4 {
  text-align: right !important; }

body.local-ar .survey .wrapper p {
  text-align: right !important; }

body.local-ar .survey .wrapper h5 {
  text-align: right !important; }

body.local-ar .survey .wrapper .q {
  text-align: right !important; }

body.local-ar .survey .selection {
  padding-right: 0px !important;
  padding-left: 10px !important; }

body.local-ar .back-to-q1 {
  padding-left: 0px !important;
  padding-right: 15px !important;
  text-align: right !important; }
  body.local-ar .back-to-q1:after {
    left: auto;
    right: 0px;
    transform: translateY(-50%) rotate(180deg) !important; }

body.local-ar .q1-container {
  text-align: right; }

body.local-ar .professional {
  text-align: right; }

body.local-ar .interested {
  text-align: right; }

body.local-ar .emp-feedback {
  text-align: right; }
  body.local-ar .emp-feedback .input-box {
    padding-left: 0px !important; }
  body.local-ar .emp-feedback .selection:last-child {
    padding-left: 10px !important; }

body.local-ar .instructor-feedback {
  text-align: right; }
  body.local-ar .instructor-feedback .input-box {
    padding-left: 0px !important; }
  body.local-ar .instructor-feedback .selection:last-child {
    padding-left: 10px !important; }

body.arabic .emp-training-detail-wrapper .course-section li {
  text-align: right; }

.thank-you .thankyoucontainer {
  position: relative; }
  .thank-you .thankyoucontainer .breadcrumb-container .breadcrumb li {
    color: #373433; }
    .thank-you .thankyoucontainer .breadcrumb-container .breadcrumb li a {
      color: #373433; }

.thank-you .container-section {
  width: 100%;
  height: 100vh;
  display: flex;
  padding-top: 0px;
  margin-bottom: 0 !important;
  max-height: 100vh !important;
  height: 100vh !important; }
  .thank-you .container-section .swiper-container {
    margin: auto;
    position: relative;
    opacity: 0.2; }
    .thank-you .container-section .swiper-container .swiper-slide {
      height: auto; }
  .thank-you .container-section .centered {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; }
    .thank-you .container-section .centered h1 {
      font-size: 7.5rem;
      font-weight: 600;
      font-family: "Cairo", sans-serif;
      line-height: 6rem; }
    .thank-you .container-section .centered p {
      font-size: 1.375rem;
      margin-top: 24px;
      font-family: "Cairo", sans-serif; }
    .thank-you .container-section .centered .jada-btn.filled_green:after {
      background-color: #373433;
      color: #FFFFFF !important; }
    .thank-you .container-section .centered .jada-btn.filled_green:hover {
      color: #FFFFFF !important; }
    .thank-you .container-section .centered button {
      width: 234px;
      border: none;
      height: 58px;
      background: #007473;
      border-radius: 15px;
      margin-top: 25px; }
      .thank-you .container-section .centered button a {
        padding: 18px 32px 18px 28px;
        text-decoration: none;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1rem; }

.privacy-policy .content {
  margin-top: 65px;
  margin-bottom: 60px; }

.privacy-policy .privacycontainer {
  position: relative; }

.privacy-policy .privacypolicycon {
  width: 100%;
  height: 60vh;
  padding-top: 86px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(55, 52, 51, 0.15); }
  @media only screen and (max-width: 991px) {
    .privacy-policy .privacypolicycon {
      height: auto; } }
  .privacy-policy .privacypolicycon .content {
    overflow: hidden; }
    .privacy-policy .privacypolicycon .content h1 {
      opacity: 0; }
      @media only screen and (max-width: 991px) {
        .privacy-policy .privacypolicycon .content h1 {
          margin-bottom: 60px; } }
      @media only screen and (max-width: 767px) {
        .privacy-policy .privacypolicycon .content h1 {
          margin-bottom: 30px; } }

.privacy-policy .contentcontainer {
  display: flex;
  width: 50%;
  margin: 0px; }
  @media only screen and (max-width: 991px) {
    .privacy-policy .contentcontainer {
      width: 100%; } }
  .privacy-policy .contentcontainer .content > * {
    opacity: 0; }
  .privacy-policy .contentcontainer .content-box {
    margin-bottom: 40px; }
    .privacy-policy .contentcontainer .content-box p {
      margin-bottom: 10px;
      font-size: 1.375rem;
      font-family: "Cairo", sans-serif;
      line-height: 1.5;
      color: #373433; }
    .privacy-policy .contentcontainer .content-box li {
      color: #373433;
      margin-bottom: 5px;
      opacity: 1;
      cursor: text;
      position: relative;
      padding-left: 20px; }
      .privacy-policy .contentcontainer .content-box li:before {
        content: "-";
        position: absolute;
        top: 0px;
        left: 0px; }
  .privacy-policy .contentcontainer li {
    list-style: none;
    color: #007473;
    font-size: 1.375rem;
    line-height: 1.5;
    margin-bottom: 20px;
    cursor: pointer;
    opacity: 0.8; }
    .privacy-policy .contentcontainer li:last-child {
      margin-bottom: 30px; }
    .privacy-policy .contentcontainer li:hover {
      opacity: 1; }
  .privacy-policy .contentcontainer h3 {
    font-size: 2.625rem;
    line-height: 1.2;
    font-family: "Cairo", sans-serif;
    font-weight: 400;
    margin-bottom: 32px;
    color: #373433; }
  .privacy-policy .contentcontainer h4 {
    font-size: 2.625rem;
    line-height: 1.2;
    font-family: "Cairo", sans-serif;
    font-weight: 400;
    margin-bottom: 25px;
    color: #373433; }
  .privacy-policy .contentcontainer p {
    font-size: 1.375rem;
    font-family: "Cairo", sans-serif;
    margin-bottom: 40px;
    line-height: 1.5;
    color: #373433; }

body.arabic .privacy-policy li {
  text-align: right; }

body.arabic .privacy-policy .content-box li {
  padding-left: 0px;
  padding-right: 20px; }
  body.arabic .privacy-policy .content-box li:before {
    left: auto;
    right: 0px; }

.error .errorcontainer {
  position: relative;
  padding-top: 86px; }

.error .errorcontent {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .error .errorcontent .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0; }
    .error .errorcontent .content h3 {
      color: #007473;
      font-weight: 200;
      font-size: 80px; }
      @media only screen and (max-width: 600px) {
        .error .errorcontent .content h3 {
          font-size: 50px; } }
    .error .errorcontent .content h1 {
      font-size: 120px;
      margin-top: 21px; }
      @media only screen and (max-width: 600px) {
        .error .errorcontent .content h1 {
          font-size: 37px;
          text-align: center; } }
    .error .errorcontent .content p {
      margin-top: 25px;
      font-size: 22px;
      margin-bottom: 80px;
      font-weight: 300;
      text-align: center; }
      @media only screen and (max-width: 600px) {
        .error .errorcontent .content p {
          font-size: 18px;
          text-align: center; } }
    .error .errorcontent .content .serachbarmain {
      display: flex;
      width: 100%;
      justify-content: center; }
      .error .errorcontent .content .serachbarmain .searchbar .search {
        width: 430px;
        border-bottom: 1px solid lightgray;
        position: relative;
        display: flex; }
        @media only screen and (max-width: 600px) {
          .error .errorcontent .content .serachbarmain .searchbar .search {
            width: 290px; } }
        .error .errorcontent .content .serachbarmain .searchbar .search ::-webkit-input-placeholder {
          /* Edge */
          color: lightgray;
          font-family: "Cairo", sans-serif;
          font-size: 22px; }
        .error .errorcontent .content .serachbarmain .searchbar .search .searchTerm {
          width: 100%;
          border: none;
          padding: 5px;
          border-radius: 5px 0 0 5px;
          outline: none; }
        .error .errorcontent .content .serachbarmain .searchbar .search .searchButton {
          padding: 15px;
          background: #007473;
          text-align: center;
          color: #fff;
          border-radius: 1234.18px;
          cursor: pointer;
          font-size: 30px;
          border: none;
          margin-bottom: 5px;
          display: flex;
          justify-content: center;
          align-items: center; }

.community .jada-btn {
  border-radius: 8px !important; }

.community .jada-main-banner {
  max-height: 100vh;
  height: 100vh; }
  @media only screen and (max-width: 991px) {
    .community .jada-main-banner {
      height: 50vh !important;
      min-height: 500px;
      max-height: none; } }
  .community .jada-main-banner:after {
    content: "";
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }
  @media only screen and (max-width: 700px) {
    .community .jada-main-banner {
      height: 100vh; } }
  .community .jada-main-banner .jada-btn.filled_green:after {
    background-color: #373433;
    color: #FFFFFF !important; }
  .community .jada-main-banner .jada-btn.filled_green:hover {
    color: #FFFFFF !important; }
  .community .jada-main-banner .content {
    position: relative;
    margin-bottom: 60px;
    align-items: flex-end;
    padding-top: 80px;
    padding-right: 0px; }
    @media only screen and (max-width: 991px) {
      .community .jada-main-banner .content {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom: 30px; } }
    .community .jada-main-banner .content .heading-content {
      height: 100%;
      display: flex;
      align-items: end; }
      @media only screen and (max-width: 991px) {
        .community .jada-main-banner .content .heading-content {
          height: auto; } }
    .community .jada-main-banner .content .community-sec {
      display: flex;
      width: 100%;
      z-index: 1;
      justify-content: end; }
      @media only screen and (max-height: 850px) {
        .community .jada-main-banner .content .community-sec {
          margin-bottom: 0px; } }
      @media only screen and (max-width: 700px) {
        .community .jada-main-banner .content .community-sec {
          justify-content: center; } }
      .community .jada-main-banner .content .community-sec .community-form {
        width: 411px;
        background-color: white;
        padding: 30px;
        border-radius: 15px;
        margin-right: 20px; }
        @media only screen and (max-height: 850px) {
          .community .jada-main-banner .content .community-sec .community-form {
            margin-bottom: 0px !important;
            max-width: 100%;
            width: 450px; } }
        @media only screen and (max-width: 1300px) {
          .community .jada-main-banner .content .community-sec .community-form {
            margin-bottom: 0px;
            width: 411px; } }
        @media only screen and (max-width: 1090px) {
          .community .jada-main-banner .content .community-sec .community-form {
            padding: 25px; } }
        @media only screen and (max-width: 991px) {
          .community .jada-main-banner .content .community-sec .community-form {
            display: none; } }
        @media only screen and (max-width: 700px) {
          .community .jada-main-banner .content .community-sec .community-form {
            padding: 20px;
            margin-right: 0px;
            margin-top: 80px; } }
        .community .jada-main-banner .content .community-sec .community-form button {
          width: 100%;
          border: none;
          border-radius: 8px;
          background-color: #007473;
          margin-top: 30px; }
          @media only screen and (max-width: 700px) {
            .community .jada-main-banner .content .community-sec .community-form button {
              min-width: unset;
              padding: 10px;
              margin-top: 0px; } }
          .community .jada-main-banner .content .community-sec .community-form button a {
            color: white; }
        .community .jada-main-banner .content .community-sec .community-form h3 {
          font-family: "Cairo", sans-serif;
          font-size: 2.625rem;
          line-height: 1;
          font-weight: 600;
          color: #373433;
          margin-bottom: 20px;
          max-width: 321px; }
          @media only screen and (max-height: 850px) {
            .community .jada-main-banner .content .community-sec .community-form h3 {
              max-width: none; } }
          @media only screen and (max-width: 360px) {
            .community .jada-main-banner .content .community-sec .community-form h3 {
              font-size: 1.3125rem;
              font-weight: 900;
              text-align: center; } }
        .community .jada-main-banner .content .community-sec .community-form p {
          font-family: "Cairo", sans-serif;
          font-size: 1rem;
          line-height: 1.5;
          font-weight: 300;
          color: #373433;
          margin-bottom: 40px;
          max-width: 356px; }
          @media only screen and (max-width: 600px) {
            .community .jada-main-banner .content .community-sec .community-form p {
              display: none; } }
          @media only screen and (max-height: 850px) {
            .community .jada-main-banner .content .community-sec .community-form p {
              max-width: none; } }
        .community .jada-main-banner .content .community-sec .community-form form {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column; }
        .community .jada-main-banner .content .community-sec .community-form label {
          display: flex;
          cursor: pointer;
          font-weight: 500;
          position: relative;
          overflow: hidden;
          margin-bottom: 14px; }
          .community .jada-main-banner .content .community-sec .community-form label:last-child {
            margin-bottom: 0px; }
          .community .jada-main-banner .content .community-sec .community-form label input {
            position: absolute;
            width: 100%;
            left: -9999px; }
            .community .jada-main-banner .content .community-sec .community-form label input:checked + span {
              border: 1px solid #007473; }
              .community .jada-main-banner .content .community-sec .community-form label input:checked + span:before {
                box-shadow: inset 0 0 0 0.3375em white;
                background-color: #007473; }
            .community .jada-main-banner .content .community-sec .community-form label input:hover + span {
              border: 1px solid #007473; }
              .community .jada-main-banner .content .community-sec .community-form label input:hover + span:before {
                box-shadow: inset 0 0 0 0.3375em white;
                background-color: #007473; }
          .community .jada-main-banner .content .community-sec .community-form label span {
            display: flex;
            align-items: center;
            border: 1px solid lightgray;
            padding: 0.375em 0.75em 0.375em 0.375em;
            border-radius: 8px;
            width: 357px;
            transition: 0.25s ease;
            padding-bottom: 17px;
            padding-top: 17px;
            padding-left: 17px;
            font-size: 1.25rem;
            line-height: 1.3;
            width: 100%; }
            .community .jada-main-banner .content .community-sec .community-form label span:before {
              display: flex;
              flex-shrink: 0;
              content: "";
              background-color: #fff;
              width: 22px;
              height: 22px;
              border-radius: 50%;
              border: 1px solid rgba(55, 52, 51, 0.2);
              margin-right: 13px;
              transition: 0.25s ease; }

.community .registerd {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
  padding-top: 100px;
  padding-bottom: 100px; }
  @media only screen and (max-width: 991px) {
    .community .registerd {
      padding-right: 40px;
      padding-left: 40px; } }
  @media only screen and (max-width: 767px) {
    .community .registerd {
      height: auto;
      padding-top: 60px;
      padding-bottom: 60px;
      padding-right: 20px;
      padding-left: 20px; } }
  .community .registerd h1 {
    font-size: 60px;
    margin-bottom: 15px; }
    @media only screen and (max-width: 1024px) {
      .community .registerd h1 {
        text-align: center; } }
    @media only screen and (max-width: 700px) {
      .community .registerd h1 {
        font-size: 40px; } }
  .community .registerd p {
    font-size: 18px;
    margin-bottom: 50px;
    text-align: center; }
    @media only screen and (max-width: 700px) {
      .community .registerd p {
        font-size: 12px; } }
  .community .registerd .buttons-container {
    text-align: center; }
    .community .registerd .buttons-container .jada-btn {
      min-width: 187px; }
    .community .registerd .buttons-container .filled_green {
      margin-right: 20px; }
      @media only screen and (max-width: 500px) {
        .community .registerd .buttons-container .filled_green {
          margin-right: 0px;
          margin-bottom: 20px; } }
    .community .registerd .buttons-container .jada-btn.filled_green:after {
      background-color: #373433;
      color: #FFFFFF !important; }
    .community .registerd .buttons-container .jada-btn.filled_green:hover {
      color: #FFFFFF !important; }
  .community .registerd::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url(../images/Jadashape2.jpg);
    background-size: cover;
    background-position-y: center;
    background-position-x: center;
    z-index: -1;
    left: 0;
    top: 0;
    opacity: 0.1; }

.community .community-sec {
  display: flex;
  width: 100%;
  z-index: 1;
  justify-content: end;
  margin-bottom: 60px; }
  @media only screen and (max-width: 1400px) {
    .community .community-sec {
      margin-bottom: 0px; } }
  @media screen and (min-width: 1440px) and (max-height: 800px) {
    .community .community-sec {
      margin-bottom: 0px; } }
  @media only screen and (max-width: 991px) {
    .community .community-sec {
      justify-content: flex-start;
      margin-bottom: 60px; } }
  @media only screen and (max-width: 767px) {
    .community .community-sec {
      margin-bottom: 0px;
      padding-top: 60px;
      justify-content: center; } }
  .community .community-sec .community-form {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    margin-right: 20px; }
    @media only screen and (max-width: 1090px) {
      .community .community-sec .community-form {
        padding: 25px; } }
    @media only screen and (max-width: 700px) {
      .community .community-sec .community-form {
        padding: 20px;
        margin-right: 0px; } }
    .community .community-sec .community-form button {
      width: 100%;
      border: none;
      border-radius: 8px;
      background-color: #007473;
      margin-top: 30px; }
      .community .community-sec .community-form button a {
        color: white; }
    .community .community-sec .community-form h3 {
      font-family: "Cairo", sans-serif;
      font-size: 2.625rem;
      line-height: 1;
      font-weight: 600;
      color: #373433;
      margin-bottom: 20px;
      max-width: 321px; }
      @media screen and (min-width: 1440px) and (max-height: 800px) {
        .community .community-sec .community-form h3 {
          max-width: 400px; } }
      @media only screen and (max-width: 1440px) {
        .community .community-sec .community-form h3 {
          max-width: 400px; } }
      @media only screen and (max-width: 767px) {
        .community .community-sec .community-form h3 {
          font-size: 36px;
          line-height: 1.38462; } }
    .community .community-sec .community-form p {
      font-family: "Cairo", sans-serif;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 300;
      color: #373433;
      margin-bottom: 40px;
      max-width: 356px; }
      @media screen and (min-width: 1440px) and (max-height: 800px) {
        .community .community-sec .community-form p {
          max-width: 420px; } }
      @media only screen and (max-width: 1440px) {
        .community .community-sec .community-form p {
          max-width: 420px; } }
      @media only screen and (max-width: 767px) {
        .community .community-sec .community-form p {
          font-size: 20px; } }
    .community .community-sec .community-form form {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column; }
    .community .community-sec .community-form label {
      display: flex;
      cursor: pointer;
      font-weight: 500;
      position: relative;
      overflow: hidden;
      margin-bottom: 14px; }
      .community .community-sec .community-form label:last-child {
        margin-bottom: 0px; }
      .community .community-sec .community-form label input {
        position: absolute;
        width: 100%;
        left: -9999px; }
        .community .community-sec .community-form label input:checked + span {
          border: 1px solid #007473; }
          .community .community-sec .community-form label input:checked + span:before {
            box-shadow: inset 0 0 0 4.5px white;
            background-color: #007473; }
      .community .community-sec .community-form label span {
        display: flex;
        align-items: center;
        border: 1px solid lightgray;
        padding: 0.375em 0.75em 0.375em 0.375em;
        border-radius: 8px;
        width: 357px;
        transition: 0.25s ease;
        padding-bottom: 17px;
        padding-top: 17px;
        padding-left: 13px;
        font-size: 1.25rem;
        line-height: 1.3;
        width: 100%; }
        .community .community-sec .community-form label span:before {
          display: flex;
          flex-shrink: 0;
          content: "";
          background-color: #fff;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          border: 1px solid rgba(55, 52, 51, 0.2);
          margin-right: 13px;
          transition: 0.25s ease; }

@media only screen and (max-width: 767px) {
  .community .community-sec.desktop {
    display: none; } }

@media only screen and (min-width: 767px) {
  .community .mobile-community_section {
    padding-top: 60px; } }

@media only screen and (min-width: 991px) {
  .community .mobile-community_section {
    display: none; } }

@media only screen and (min-width: 767px) {
  .community .mobile-community_section .community-sec .community-form {
    margin-left: auto;
    margin-right: auto; } }

body.arabic .community .community-sec .community-form label span:before {
  margin-right: 0px;
  margin-left: 13px; }

body.arabic .community .jada-main-banner .content {
  padding-right: 40px; }
  @media only screen and (max-width: 767px) {
    body.arabic .community .jada-main-banner .content {
      padding-right: 20px; } }

body.arabic .community .registerd .buttons-container .filled_green {
  margin-right: 0px;
  margin-left: 20px; }
  @media only screen and (max-width: 500px) {
    body.arabic .community .registerd .buttons-container .filled_green {
      margin-left: 0px; } }

.serach-results {
  position: relative; }
  .serach-results .search-item {
    width: 100%;
    height: 65vh;
    padding-top: 86px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    background: #f2f8f7; }
    .serach-results .search-item .searchbar {
      width: 95%; }
      .serach-results .search-item .searchbar .search {
        border-bottom: 1px solid lightgray;
        position: relative;
        display: flex; }
        .serach-results .search-item .searchbar .search input {
          font-size: 2.625rem; }
        .serach-results .search-item .searchbar .search ::-webkit-input-placeholder {
          /* Edge */
          color: lightgray;
          font-family: "Cairo", sans-serif;
          font-size: 2.625rem;
          margin-bottom: 31px;
          margin-left: 0px;
          padding-right: 0px; }
          @media only screen and (max-width: 800px) {
            .serach-results .search-item .searchbar .search ::-webkit-input-placeholder {
              font-size: 27px; } }
          @media only screen and (max-width: 600px) {
            .serach-results .search-item .searchbar .search ::-webkit-input-placeholder {
              font-size: 20px; } }
        .serach-results .search-item .searchbar .search .searchTerm {
          width: 100%;
          border: none;
          padding: 5px;
          border-radius: 5px 0 0 5px;
          outline: none;
          background: #f2f8f7; }
        .serach-results .search-item .searchbar .search .searchButton {
          padding: 34px;
          background: #007473;
          text-align: center;
          color: #fff;
          border-radius: 1234.18px;
          cursor: pointer;
          font-size: 1.5625rem;
          border: none;
          margin-bottom: 22px;
          display: flex;
          justify-content: center;
          align-items: center; }
          @media only screen and (max-width: 800px) {
            .serach-results .search-item .searchbar .search .searchButton {
              padding: 23px; } }
          @media only screen and (max-width: 600px) {
            .serach-results .search-item .searchbar .search .searchButton {
              padding: 17px;
              font-size: 14px; } }
    .serach-results .search-item .content {
      display: flex;
      justify-content: flex-start;
      margin-top: 36px;
      margin-bottom: 107px; }
      @media only screen and (max-width: 767px) {
        .serach-results .search-item .content {
          margin-bottom: 30px; } }
      .serach-results .search-item .content p {
        font-size: 1.5rem; }
  .serach-results .results .listing-section {
    padding-top: 0px;
    position: relative; }
    .serach-results .results .listing-section .listingpicker {
      position: absolute;
      top: 3%;
      transform: translateY(-50%); }
    .serach-results .results .listing-section .green-overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 50px;
      background-color: #F2F8F7;
      width: 100%; }
      @media only screen and (max-width: 767px) {
        .serach-results .results .listing-section .green-overlay {
          display: none; } }
    .serach-results .results .listing-section .top-left {
      position: absolute;
      top: 20px;
      left: 20px; }
      @media only screen and (max-width: 600px) {
        .serach-results .results .listing-section .top-left {
          top: 15px;
          left: 15px; } }
      .serach-results .results .listing-section .top-left p {
        font-size: 1.125rem;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 15px;
        color: #ffffff; }
        @media only screen and (max-width: 900px) {
          .serach-results .results .listing-section .top-left p {
            padding: 5px;
            font-size: 17px; } }
    .serach-results .results .listing-section .media-filters {
      padding: 0px;
      margin-bottom: 57px; }
    .serach-results .results .listing-section .listcards {
      padding-top: 0px;
      margin-top: -60px; }
      @media only screen and (max-width: 767px) {
        .serach-results .results .listing-section .listcards {
          margin-top: 40px; } }
      .serach-results .results .listing-section .listcards .non-featured__cards {
        width: 33.33%;
        margin-bottom: 60px;
        padding-left: 20px;
        padding-right: 20px; }
        @media only screen and (max-width: 1024px) {
          .serach-results .results .listing-section .listcards .non-featured__cards {
            width: 50%; } }
        @media only screen and (max-width: 767px) {
          .serach-results .results .listing-section .listcards .non-featured__cards {
            width: 100%; } }
        .serach-results .results .listing-section .listcards .non-featured__cards .card-banner__container:after {
          background-image: none; }
        .serach-results .results .listing-section .listcards .non-featured__cards .card-banner__container.portfolio {
          background-color: #373433;
          position: relative; }
          .serach-results .results .listing-section .listcards .non-featured__cards .card-banner__container.portfolio img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 50%;
            transform: translate(-50%, -50%); }

.login-regsiter_wrapper {
  display: flex;
  position: relative; }
  .login-regsiter_wrapper header {
    padding: 0px 20px; }
  .login-regsiter_wrapper .form-wrapper {
    width: 73%;
    padding-bottom: 50px; }
    @media only screen and (max-width: 991px) {
      .login-regsiter_wrapper .form-wrapper {
        width: 100%; } }
    @media only screen and (max-width: 767px) {
      .login-regsiter_wrapper .form-wrapper {
        width: 100%; } }
    .login-regsiter_wrapper .form-wrapper .login-form {
      padding: 0px 142px 0 100px;
      padding-top: 60px !important; }
      @media only screen and (max-width: 1300px) {
        .login-regsiter_wrapper .form-wrapper .login-form {
          padding: 0 13% 0 10%; } }
      @media only screen and (max-width: 1090px) {
        .login-regsiter_wrapper .form-wrapper .login-form {
          padding: 0 40px; } }
      @media only screen and (max-width: 767px) {
        .login-regsiter_wrapper .form-wrapper .login-form {
          padding: 0 20px;
          padding-top: 30px !important; } }
      .login-regsiter_wrapper .form-wrapper .login-form .coperate-info-wrap {
        margin-top: 50px; }
        @media only screen and (max-width: 767px) {
          .login-regsiter_wrapper .form-wrapper .login-form .coperate-info-wrap {
            margin-top: 30px; } }
      .login-regsiter_wrapper .form-wrapper .login-form .select-buttons {
        width: 47%;
        padding: 18px;
        font-size: 1.25rem;
        line-height: 1.3;
        text-align: center;
        background-color: transparent;
        border: 1px solid rgba(127, 129, 132, 0.2);
        color: #373433;
        border-radius: 8px;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
        margin-right: 10px; }
        .login-regsiter_wrapper .form-wrapper .login-form .select-buttons:last-child {
          margin-right: 0px; }
        .login-regsiter_wrapper .form-wrapper .login-form .select-buttons:hover, .login-regsiter_wrapper .form-wrapper .login-form .select-buttons:focus, .login-regsiter_wrapper .form-wrapper .login-form .select-buttons:active {
          border-color: #007473; }
      .login-regsiter_wrapper .form-wrapper .login-form p {
        font-size: 1rem;
        line-height: 1.25;
        color: #373433;
        margin-bottom: 20px;
        font-weight: 400;
        font-family: "Cairo", sans-serif; }
        @media only screen and (max-width: 767px) {
          .login-regsiter_wrapper .form-wrapper .login-form p {
            font-size: 16px; } }
      .login-regsiter_wrapper .form-wrapper .login-form .dropdown {
        width: 100%; }
        .login-regsiter_wrapper .form-wrapper .login-form .dropdown .btn {
          padding: 20px;
          border: 1px solid rgba(127, 129, 132, 0.2);
          border-radius: 8px;
          z-index: 1;
          font-size: 1.25rem;
          line-height: 1.25;
          color: #373433;
          font-weight: 400;
          background-color: transparent; }
          .login-regsiter_wrapper .form-wrapper .login-form .dropdown .btn:focus, .login-regsiter_wrapper .form-wrapper .login-form .dropdown .btn:hover {
            border-color: #007473; }
          .login-regsiter_wrapper .form-wrapper .login-form .dropdown .btn:active {
            box-shadow: none;
            border-color: #007473; }
          .login-regsiter_wrapper .form-wrapper .login-form .dropdown .btn:focus-visible {
            border: 1px solid rgba(0, 116, 115, 0.2); }
      .login-regsiter_wrapper .form-wrapper .login-form h2 {
        font-family: "Cairo", sans-serif;
        font-size: 3.5rem;
        line-height: 1;
        font-weight: 700;
        letter-spacing: -0.06;
        color: #202c43;
        margin-bottom: 45px; }
      .login-regsiter_wrapper .form-wrapper .login-form h3 {
        font-size: 1.5rem;
        font-family: "Cairo", sans-serif;
        line-height: 1.2;
        font-weight: 600;
        color: #373433;
        letter-spacing: -0.2px;
        margin-bottom: 30px; }
        @media only screen and (max-width: 767px) {
          .login-regsiter_wrapper .form-wrapper .login-form h3 {
            font-size: 22px;
            line-height: 26.8px; } }
      .login-regsiter_wrapper .form-wrapper .login-form .personal-info {
        display: flex;
        flex-wrap: wrap; }
      .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap {
        width: 50%; }
        @media only screen and (max-width: 991px) {
          .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap {
            width: 100%;
            margin-bottom: 20px; } }
        .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap .flex {
          display: flex;
          width: 100%;
          padding-right: 30px; }
          @media only screen and (max-width: 991px) {
            .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap .flex {
              padding-right: 0px; } }
          .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap .flex .selection {
            width: 50%; }
            @media only screen and (max-width: 991px) {
              .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap .flex .selection {
                padding-right: 30px; }
                .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap .flex .selection:last-child {
                  padding-right: 0px; } }
            .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap .flex .selection:last-child {
              padding-right: 0px; }
            @media only screen and (max-width: 767px) {
              .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap .flex .selection {
                padding-right: 7px; }
                .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap .flex .selection:last-child {
                  padding-left: 7px; } }
        .login-regsiter_wrapper .form-wrapper .login-form .gender-wrap .pr-0 {
          padding-right: 0px; }
      .login-regsiter_wrapper .form-wrapper .login-form .password-wrap {
        margin-top: 50px; }
        @media only screen and (max-width: 767px) {
          .login-regsiter_wrapper .form-wrapper .login-form .password-wrap {
            margin-top: 30px; } }
      .login-regsiter_wrapper .form-wrapper .login-form .company-select label {
        font-size: 12px !important;
        line-height: 20px !important;
        color: #7f8184 !important;
        top: -14px !important;
        left: 16px;
        transform: translateY(0);
        font-weight: 400;
        margin: 0;
        padding: 4px;
        background-color: #fff;
        z-index: 2;
        position: absolute; }
        @media only screen and (max-width: 767px) {
          .login-regsiter_wrapper .form-wrapper .login-form .company-select label {
            font-size: 14px !important;
            line-height: 22px !important; } }
      .login-regsiter_wrapper .form-wrapper .login-form .years-exp {
        margin-top: 24px; }
        @media only screen and (max-width: 1300px) {
          .login-regsiter_wrapper .form-wrapper .login-form .years-exp {
            width: 100%; } }
        @media only screen and (max-width: 991px) {
          .login-regsiter_wrapper .form-wrapper .login-form .years-exp {
            margin-top: 0px; } }
        .login-regsiter_wrapper .form-wrapper .login-form .years-exp .flex {
          flex-wrap: wrap; }
          @media only screen and (max-width: 1300px) {
            .login-regsiter_wrapper .form-wrapper .login-form .years-exp .flex {
              padding-right: 0px; } }
          .login-regsiter_wrapper .form-wrapper .login-form .years-exp .flex .selection {
            width: 25%; }
            .login-regsiter_wrapper .form-wrapper .login-form .years-exp .flex .selection:last-child {
              padding-right: 0px; }
            @media only screen and (max-width: 1300px) {
              .login-regsiter_wrapper .form-wrapper .login-form .years-exp .flex .selection {
                width: 25%;
                padding-right: 20px; } }
            @media only screen and (max-width: 767px) {
              .login-regsiter_wrapper .form-wrapper .login-form .years-exp .flex .selection {
                padding-right: 10px; } }
          @media only screen and (max-width: 1400px) {
            .login-regsiter_wrapper .form-wrapper .login-form .years-exp .flex label {
              margin-bottom: 10px; } }
      .login-regsiter_wrapper .form-wrapper .login-form .additional-info-wrap {
        margin-top: 50px; }
        @media only screen and (max-width: 767px) {
          .login-regsiter_wrapper .form-wrapper .login-form .additional-info-wrap {
            margin-top: 30px; } }
        .login-regsiter_wrapper .form-wrapper .login-form .additional-info-wrap .additional-info {
          display: flex;
          flex-wrap: wrap; }
          @media only screen and (max-width: 991px) {
            .login-regsiter_wrapper .form-wrapper .login-form .additional-info-wrap .additional-info .select-box {
              width: 100%;
              padding-right: 0px; } }
          .login-regsiter_wrapper .form-wrapper .login-form .additional-info-wrap .additional-info .share_input {
            width: 100%; }
      .login-regsiter_wrapper .form-wrapper .login-form .terms-section {
        margin-top: 80px;
        text-align: center;
        width: 100%; }
        @media only screen and (max-width: 767px) {
          .login-regsiter_wrapper .form-wrapper .login-form .terms-section {
            margin-top: 50px; } }
        .login-regsiter_wrapper .form-wrapper .login-form .terms-section .flex {
          display: flex;
          justify-content: center; }
          .login-regsiter_wrapper .form-wrapper .login-form .terms-section .flex .in {
            position: relative; }
          .login-regsiter_wrapper .form-wrapper .login-form .terms-section .flex input[type="checkbox"] {
            opacity: 0;
            position: absolute;
            height: 24px;
            width: 24px;
            z-index: 1; }
          .login-regsiter_wrapper .form-wrapper .login-form .terms-section .flex label {
            height: 24px;
            width: 24px;
            border-radius: 8px;
            border: 1px solid rgba(55, 52, 51, 0.2);
            position: relative;
            cursor: pointer; }
            .login-regsiter_wrapper .form-wrapper .login-form .terms-section .flex label:after {
              position: absolute;
              top: -6px;
              right: -4px;
              content: "\E906";
              font-family: "icomoon";
              font-size: 20px;
              color: #007473;
              opacity: 0; }
          .login-regsiter_wrapper .form-wrapper .login-form .terms-section .flex input[type="checkbox"]:checked ~ label:after {
            opacity: 1; }
        .login-regsiter_wrapper .form-wrapper .login-form .terms-section p {
          margin-left: 20px;
          font-family: "Cairo", sans-serif;
          font-size: 1rem;
          line-height: 1.5;
          font-weight: 400;
          color: #373433; }
          .login-regsiter_wrapper .form-wrapper .login-form .terms-section p a {
            font-weight: 700;
            text-decoration: underline;
            color: #007473; }
          @media only screen and (max-width: 767px) {
            .login-regsiter_wrapper .form-wrapper .login-form .terms-section p {
              font-size: 16px; } }
        .login-regsiter_wrapper .form-wrapper .login-form .terms-section .login {
          line-height: 1.5625;
          margin-top: 20px; }
          .login-regsiter_wrapper .form-wrapper .login-form .terms-section .login a {
            margin-left: 20px; }
        .login-regsiter_wrapper .form-wrapper .login-form .terms-section .jada-btn {
          width: 100%;
          max-width: 293px; }
          .login-regsiter_wrapper .form-wrapper .login-form .terms-section .jada-btn:hover {
            border-color: #007473 !important;
            border-bottom: none; }
  .login-regsiter_wrapper .side-band {
    width: 27%;
    min-height: 100vh;
    height: 100%;
    background-image: url(../images/register-image.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0px;
    top: 0px; }
    @media only screen and (max-width: 991px) {
      .login-regsiter_wrapper .side-band {
        display: none; } }
    @media only screen and (max-width: 767px) {
      .login-regsiter_wrapper .side-band {
        display: none; } }

.input:hover {
  border-color: #007473 !important;
  background-color: transparent !important;
  box-shadow: none !important; }

.input-box {
  width: 50%;
  position: relative;
  margin-bottom: 20px;
  text-align: left; }
  .input-box span {
    color: red; }
  .input-box.first_name, .input-box.email, .input-box.pr-30 {
    padding-right: 30px; }
    @media only screen and (max-width: 767px) {
      .input-box.first_name, .input-box.email, .input-box.pr-30 {
        padding-right: 0px; } }
  @media only screen and (max-width: 767px) {
    .input-box {
      width: 100%; } }

.input {
  width: 100%;
  padding: 20px !important;
  border: 1px solid rgba(127, 129, 132, 0.2) !important;
  border-radius: 8px;
  z-index: 1;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #373433 !important;
  font-weight: 400;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  -ms-transition: 0.2s all ease;
  -o-transition: 0.2s all ease;
  transition: 0.2s all ease; }
  .input:focus {
    border-color: #007473 !important; }
  @media only screen and (max-width: 767px) {
    .input {
      font-size: 20px;
      line-height: 25px; } }

.input:focus + .abs_label {
  font-size: 12px !important;
  list-style: 20px !important;
  color: #7f8184 !important;
  transform: translateY(-30px) !important; }

.input:invalid + .abs_label {
  font-size: 1.25rem;
  line-height: 1.3;
  color: #373433 !important;
  transform: translateY(0px); }
  @media only screen and (max-width: 767px) {
    .input:invalid + .abs_label {
      font-size: 20px; } }

.abs_label {
  position: absolute;
  font-size: 12px;
  line-height: 20px;
  color: #7f8184;
  margin: 0;
  font-weight: 400;
  font-family: "Cairo", sans-serif;
  left: 16px;
  top: 16px;
  padding: 4px;
  cursor: text;
  transition: 0.2s all ease;
  pointer-events: none;
  z-index: 2;
  transform: translateY(-30px);
  background-color: #fff; }
  @media only screen and (max-width: 767px) {
    .abs_label {
      font-size: 14px;
      line-height: 22px; } }

.abs_label.green {
  font-size: 12px !important;
  list-style: 20px !important;
  color: #7f8184 !important;
  top: -10px !important;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .abs_label.green {
      font-size: 14px;
      line-height: 22px; } }

.intl-tel-input {
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border: 1px solid rgba(127, 129, 132, 0.2);
  border-radius: 8px; }
  .intl-tel-input .country-list {
    width: 270px;
    z-index: 4; }
    .intl-tel-input .country-list li {
      z-index: 4; }
    .intl-tel-input .country-list span {
      color: #373433; }
  .intl-tel-input .flag-container {
    width: 130px;
    max-width: 130px;
    z-index: 1;
    border-right: 1px solid rgba(55, 52, 51, 0.15); }
    @media only screen and (max-width: 500px) {
      .intl-tel-input .flag-container {
        width: 100px; } }
  .intl-tel-input .selected-flag {
    justify-content: space-between;
    padding-left: 0px;
    background-color: transparent !important; }
    .intl-tel-input .selected-flag .iti-arrow {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 8px solid #373433; }
      .intl-tel-input .selected-flag .iti-arrow.up {
        border-top: 8px solid #373433 !important;
        border-bottom: none; }
    .intl-tel-input .selected-flag .iti-flag {
      margin-left: 20px;
      margin-right: 9px; }
      @media only screen and (max-width: 500px) {
        .intl-tel-input .selected-flag .iti-flag {
          margin-left: 10px;
          margin-right: 0px; } }
    .intl-tel-input .selected-flag .selected-dial-code {
      font-size: 1.25rem;
      line-height: 1.3;
      font-weight: 400;
      color: #373433; }
  .intl-tel-input input {
    padding-left: 150px !important; }
    @media only screen and (max-width: 500px) {
      .intl-tel-input input {
        padding-left: 110px !important; } }

.country-select {
  width: 100%;
  border: 1px solid #007473; }
  .country-select .country-list {
    width: 100% !important; }
    .country-select .country-list span {
      color: #373433; }
  .country-select .input {
    border: none; }
  .country-select .selected-flag {
    padding-left: 20px;
    padding-right: 15px;
    width: 100% !important; }
    .country-select .selected-flag .flag {
      height: 14px; }
    .country-select .selected-flag .arrow {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 8px solid #373433;
      right: 20px; }
      .country-select .selected-flag .arrow.up {
        border-bottom: 8px solid #555; }
  .country-select.inside .flag-dropdown {
    width: 100%; }
  .country-select.inside .selected-flag {
    width: 100%; }
  .country-select .flag-dropdown {
    right: auto;
    left: 0px;
    width: 100%; }
  .country-select .input {
    padding-left: 48px !important;
    padding-right: 55px !important; }

.select-box label {
  font-size: 12px !important;
  line-height: 20px !important;
  color: #7f8184 !important;
  top: -14px !important;
  left: 16px;
  transform: translateY(0);
  font-weight: 400;
  margin: 0;
  padding: 4px;
  background-color: #fff;
  z-index: 2;
  position: absolute; }

.flag-dropdown:hover .select {
  border-color: #007473; }

.flag-dropdown:hover .selected-flag {
  background-color: transparent !important; }

.selection {
  width: 25%;
  padding-right: 10px; }
  @media only screen and (max-width: 1300px) {
    .selection {
      width: 50%; } }
  .selection label {
    width: 100%;
    padding: 18px 0px;
    border: 1px solid rgba(127, 129, 132, 0.2);
    font-size: 1.25rem;
    line-height: 1.3;
    border-radius: 8px;
    font-weight: 400;
    text-align: center;
    cursor: pointer; }
    @media only screen and (max-width: 767px) {
      .selection label {
        font-size: 20px; } }
  .selection input {
    display: none; }
    .selection input:checked ~ label {
      border-color: #007473; }

.submit-btn {
  margin: 0px !important;
  width: 293px;
  padding: 18px !important;
  text-align: center;
  background-color: #007473 !important;
  color: #FFFFFF !important;
  border-radius: 8px;
  font-size: 1rem !important;
  line-height: 1.4;
  letter-spacing: 0.1px;
  font-weight: 700;
  box-sizing: none;
  border: none !important;
  transition: 0.3s all ease;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .submit-btn {
      font-size: 18px !important;
      width: 100%; } }
  .submit-btn:hover {
    background-color: transparent !important;
    color: #007473 !important;
    border: 1px solid #007473 !important; }

.login-regsiter_wrapper.login-wrapper #ctl00_PlaceHolderMain_signInControl_FailureText {
  opacity: 0 !important; }

.login-regsiter_wrapper.login-wrapper .login-error-msg {
  width: 70%; }
  @media only screen and (max-width: 991px) {
    .login-regsiter_wrapper.login-wrapper .login-error-msg {
      width: 100%; } }
  .login-regsiter_wrapper.login-wrapper .login-error-msg span {
    color: #bf0000; }

.login-regsiter_wrapper.login-wrapper header {
  height: 10%; }

.login-regsiter_wrapper.login-wrapper .login-form {
  min-height: 100vh;
  width: 60%; }
  @media only screen and (max-width: 767px) {
    .login-regsiter_wrapper.login-wrapper .login-form {
      width: 80%; } }
  @media only screen and (max-width: 500px) {
    .login-regsiter_wrapper.login-wrapper .login-form {
      width: 100%; } }
  .login-regsiter_wrapper.login-wrapper .login-form header {
    padding: 0px 20px; }
  .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap {
    padding: 0 142px 0 100px;
    height: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media only screen and (min-width: 1600px) {
      .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap {
        max-width: 90%;
        margin: auto; } }
    @media only screen and (max-width: 991px) {
      .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap {
        padding: 0 40px; } }
    .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap h2 {
      font-family: "Cairo", sans-serif;
      font-size: 3.5rem;
      line-height: 1;
      font-weight: 700;
      letter-spacing: -0.06;
      color: #202c43;
      margin-bottom: 45px; }
    .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap .input-box {
      width: 70%;
      text-align: right; }
      @media only screen and (max-width: 1279px) {
        .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap .input-box {
          width: 100%;
          padding-right: 0px; } }
      .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap .input-box p {
        margin-top: 12px;
        margin-bottom: 0px; }
      .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap .input-box a {
        font-size: 14px;
        line-height: 19.6px;
        letter-spacing: 0.1px;
        text-transform: uppercase;
        font-weight: 700;
        color: #007473;
        margin-top: 12px; }
    .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap .login-box {
      width: 70%;
      text-align: center;
      margin-top: 30px;
      padding-right: 0px; }
      @media only screen and (max-width: 1279px) {
        .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap .login-box {
          width: 100%;
          padding-right: 0px; } }
      .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap .login-box input {
        width: 100%; }
      .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap .login-box p {
        margin-top: 20px;
        font-size: 1rem;
        line-height: 1.5625;
        font-weight: 400;
        color: #373433; }
        @media only screen and (max-width: 767px) {
          .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap .login-box p {
            font-size: 16px;
            line-height: 25px; } }
        .login-regsiter_wrapper.login-wrapper .login-form .login-content-wrap .login-box p a {
          color: #007473;
          text-decoration: underline;
          position: relative;
          font-weight: 700;
          margin-left: 20px; }

.login-regsiter_wrapper.login-wrapper .side-band {
  width: 40%;
  min-height: 100vh;
  height: 100%;
  background-image: url(../images/login-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 0px;
  top: 0px; }
  @media only screen and (max-width: 991px) {
    .login-regsiter_wrapper.login-wrapper .side-band {
      display: block !important; } }
  @media only screen and (max-width: 767px) {
    .login-regsiter_wrapper.login-wrapper .side-band {
      display: none !important; } }

#ms-error-body {
  width: 100% !important; }
  #ms-error-body #ms-error-header {
    display: none; }
  #ms-error-body .input-box {
    padding-right: 0px !important; }

.server-button {
  display: none; }

.company-select-profile label {
  font-size: 12px !important;
  line-height: 20px !important;
  color: #7f8184 !important;
  top: -14px !important;
  left: 16px;
  transform: translateY(0);
  font-weight: 400;
  margin: 0;
  padding: 4px;
  background-color: #fff;
  z-index: 2;
  position: absolute; }
  @media only screen and (max-width: 767px) {
    .company-select-profile label {
      font-size: 14px !important;
      line-height: 22px !important; } }

.company-select-profile .dropdown {
  width: 100% !important; }
  .company-select-profile .dropdown .dropdown-menu, .company-select-profile .dropdown .inner {
    max-height: none !important;
    min-height: auto !important;
    height: auto !important; }
  .company-select-profile .dropdown .btn {
    padding: 20px;
    border: 1px solid rgba(127, 129, 132, 0.2);
    border-radius: 8px;
    z-index: 1;
    font-size: 1.25rem;
    line-height: 1.25;
    color: #373433;
    font-weight: 400;
    background-color: transparent; }
    .company-select-profile .dropdown .btn:focus, .company-select-profile .dropdown .btn:hover {
      border-color: #007473; }
    .company-select-profile .dropdown .btn:active {
      box-shadow: none;
      border-color: #007473; }
    .company-select-profile .dropdown .btn:focus-visible {
      border: 1px solid rgba(0, 116, 115, 0.2); }

.mail-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 500px) {
    .mail-wrapper {
      height: auto;
      padding-right: 20px;
      padding-left: 20px; } }
  .mail-wrapper .form-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    height: 25vh; }
    @media only screen and (max-width: 500px) {
      .mail-wrapper .form-wrapper {
        height: auto; } }
  .mail-wrapper .sent {
    display: none;
    width: 414px;
    background: #007473;
    border-radius: 12px;
    position: fixed;
    right: 20px;
    top: 20px; }
    @media only screen and (max-width: 500px) {
      .mail-wrapper .sent {
        width: 80%; } }
    .mail-wrapper .sent .icon-cancel:before {
      right: 10px;
      top: 10px;
      position: absolute;
      color: white;
      border: 1px solid white;
      padding: 5px;
      border-radius: 50%;
      font-size: 10px; }
    .mail-wrapper .sent h5 {
      font-size: 1.375rem;
      color: white;
      font-weight: 700;
      margin-top: 19px;
      margin-bottom: 4px;
      margin-left: 24px; }
    .mail-wrapper .sent p {
      font-size: 1rem;
      margin-bottom: 19px;
      color: white;
      margin-left: 24px; }
  .mail-wrapper .login-form {
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media only screen and (max-width: 500px) {
      .mail-wrapper .login-form {
        height: auto; } }
    .mail-wrapper .login-form .goback {
      width: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 33px; }
      @media only screen and (max-width: 500px) {
        .mail-wrapper .login-form .goback {
          justify-content: center;
          width: 100%;
          margin-bottom: 0px;
          text-align: center; } }
      .mail-wrapper .login-form .goback .gobackaerrow {
        width: 461px;
        display: flex;
        justify-content: flex-start; }
        @media only screen and (max-width: 500px) {
          .mail-wrapper .login-form .goback .gobackaerrow {
            justify-content: center; } }
        .mail-wrapper .login-form .goback .gobackaerrow a {
          font-size: 1.125rem;
          color: #373433; }
    .mail-wrapper .login-form .personal-info-wrap .personal-info {
      width: 461px;
      border: 1px solid rgba(55, 52, 51, 0.15);
      border-radius: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media only screen and (max-width: 500px) {
        .mail-wrapper .login-form .personal-info-wrap .personal-info {
          width: 100%;
          border: none; } }
      .mail-wrapper .login-form .personal-info-wrap .personal-info .first_name {
        width: 395px;
        padding-right: 0px;
        margin-bottom: 27px; }
        @media only screen and (max-width: 500px) {
          .mail-wrapper .login-form .personal-info-wrap .personal-info .first_name {
            width: 100%; } }
      .mail-wrapper .login-form .personal-info-wrap .personal-info h3 {
        font-size: 2.25rem;
        line-height: 1.10111;
        color: #000000;
        font-weight: 700;
        text-align: center;
        margin-top: 62px;
        margin-bottom: 10px; }
        @media only screen and (max-width: 500px) {
          .mail-wrapper .login-form .personal-info-wrap .personal-info h3 {
            margin-top: 20px; } }
      .mail-wrapper .login-form .personal-info-wrap .personal-info p {
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 47px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: 355px; }
      .mail-wrapper .login-form .personal-info-wrap .personal-info button {
        width: 395px;
        padding: 18px 32px 18px 28px;
        background: #007473;
        border: none;
        border-radius: 15px;
        margin-left: 60px;
        margin-right: 60px;
        margin-bottom: 26px; }
        @media only screen and (max-width: 500px) {
          .mail-wrapper .login-form .personal-info-wrap .personal-info button {
            width: 100%; } }
        .mail-wrapper .login-form .personal-info-wrap .personal-info button a {
          color: white;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 700; }
      .mail-wrapper .login-form .personal-info-wrap .personal-info .inline {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 26px; }
        .mail-wrapper .login-form .personal-info-wrap .personal-info .inline p {
          padding: 0px;
          font-size: 1.125rem;
          line-height: 1.55556;
          margin-left: 0px;
          margin-right: 10px; }
        .mail-wrapper .login-form .personal-info-wrap .personal-info .inline a {
          text-decoration: underline;
          color: #007473;
          font-size: 1rem; }

.arabic .mail-wrapper .sent {
  right: auto;
  left: 20px; }
  .arabic .mail-wrapper .sent h5 {
    margin-left: 0px;
    margin-right: 24px; }
  .arabic .mail-wrapper .sent p {
    margin-left: 0px;
    margin-right: 24px; }
  .arabic .mail-wrapper .sent .icon-cancel:before {
    right: auto;
    left: 10px; }

.password-wrapper {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px; }
  @media only screen and (max-width: 500px) {
    .password-wrapper {
      height: auto;
      padding-left: 20px;
      padding-right: 20px; } }
  .password-wrapper .form-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 25vh;
    padding-top: 30px; }
    @media only screen and (max-width: 500px) {
      .password-wrapper .form-wrapper {
        height: auto; } }
  .password-wrapper .login-form {
    width: 100%;
    height: 75vh;
    max-width: 461px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px; }
    @media only screen and (max-width: 500px) {
      .password-wrapper .login-form {
        height: auto; } }
    .password-wrapper .login-form .goback {
      width: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 33px; }
      .password-wrapper .login-form .goback .gobackaerrow {
        width: 461px;
        display: flex;
        justify-content: flex-start; }
        @media only screen and (max-width: 500px) {
          .password-wrapper .login-form .goback .gobackaerrow {
            width: 100%; } }
        .password-wrapper .login-form .goback .gobackaerrow a {
          font-size: 1.125rem;
          color: #373433; }
    .password-wrapper .login-form .personal-info-wrap .personal-info {
      border: 1px solid rgba(55, 52, 51, 0.15);
      border-radius: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 60px;
      padding-bottom: 60px;
      padding: 32px; }
      @media only screen and (max-width: 500px) {
        .password-wrapper .login-form .personal-info-wrap .personal-info {
          width: 100%;
          padding: 0px;
          border: none; } }
      .password-wrapper .login-form .personal-info-wrap .personal-info .first_name {
        width: 395px;
        padding-right: 0px;
        margin-bottom: 27px; }
        @media only screen and (max-width: 500px) {
          .password-wrapper .login-form .personal-info-wrap .personal-info .first_name {
            width: 100%; } }
      .password-wrapper .login-form .personal-info-wrap .personal-info h1 {
        font-size: 2.25rem !important;
        line-height: 1.10111 !important;
        margin-top: 0px;
        font-weight: 700 !important;
        margin-bottom: 10px !important;
        text-align: center; }
      .password-wrapper .login-form .personal-info-wrap .personal-info p {
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 47px;
        text-align: center; }
      .password-wrapper .login-form .personal-info-wrap .personal-info button {
        width: 395px;
        padding: 18px 32px 18px 28px;
        background: #007473;
        border: none;
        border-radius: 15px; }
        @media only screen and (max-width: 500px) {
          .password-wrapper .login-form .personal-info-wrap .personal-info button {
            width: 100%; } }
        .password-wrapper .login-form .personal-info-wrap .personal-info button a {
          color: white;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 700; }

.arabic .password-wrapper .login-form .personal-info-wrap .personal-info .first_name {
  padding-left: 0px; }

.passwordsuccss-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100vh; }
  @media only screen and (max-width: 500px) {
    .passwordsuccss-wrapper {
      height: auto;
      padding-right: 20px;
      padding-left: 20px; } }
  .passwordsuccss-wrapper .form-wrapper {
    display: flex;
    justify-content: center;
    width: 100%; }
    @media only screen and (max-width: 500px) {
      .passwordsuccss-wrapper .form-wrapper {
        padding-top: 30px; } }
  .passwordsuccss-wrapper .login-form {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media only screen and (max-width: 500px) {
      .passwordsuccss-wrapper .login-form {
        height: auto; } }
    .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info {
      width: 500px;
      border: 1px solid rgba(55, 52, 51, 0.15);
      border-radius: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media only screen and (max-width: 500px) {
        .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info {
          width: 100%;
          border: none; } }
      .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info img {
        margin-top: 55px; }
      .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info h1 {
        font-size: 2.25rem !important;
        line-height: 1.10111 !important;
        margin-top: 31px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-right: 36px;
        margin-left: 36px; }
        @media only screen and (max-width: 500px) {
          .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info h1 {
            margin-left: 0px;
            margin-right: 0px; } }
      .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info p {
        font-size: 1.625rem;
        line-height: 1.2;
        margin-bottom: 47px;
        margin-right: 55px;
        font-weight: 600;
        margin-left: 55px;
        color: #373433; }
        @media only screen and (max-width: 500px) {
          .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info p {
            margin-left: 0px;
            margin-right: 0px; } }
      .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info button {
        width: 395px;
        padding: 18px 32px 18px 28px;
        background: #007473;
        border: none;
        border-radius: 15px;
        margin-left: 60px;
        margin-right: 60px;
        margin-bottom: 56px; }
        @media only screen and (max-width: 500px) {
          .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info button {
            width: 100%;
            margin-left: 0px;
            margin-right: 0px; } }
        .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info button a {
          color: white;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 700; }

.arabic .passwordsuccss-wrapper .login-form .personal-info-wrap .personal-info p {
  text-align: center; }

.passwordchange-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: column; }
  @media only screen and (max-width: 500px) {
    .passwordchange-wrapper {
      height: auto;
      padding-right: 20px;
      padding-left: 20px; } }
  .passwordchange-wrapper .form-wrapper {
    display: flex;
    justify-content: center;
    width: 100%; }
    @media only screen and (max-width: 500px) {
      .passwordchange-wrapper .form-wrapper {
        padding-top: 30px; } }
  .passwordchange-wrapper .login-form {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    @media only screen and (max-width: 500px) {
      .passwordchange-wrapper .login-form {
        height: auto; } }
    .passwordchange-wrapper .login-form .personal-info-wrap .personal-info {
      width: 500px;
      border: 1px solid rgba(55, 52, 51, 0.15);
      border-radius: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media only screen and (max-width: 500px) {
        .passwordchange-wrapper .login-form .personal-info-wrap .personal-info {
          width: 100%;
          border: none; } }
      .passwordchange-wrapper .login-form .personal-info-wrap .personal-info .inline {
        width: 100%;
        display: flex;
        justify-content: center; }
        .passwordchange-wrapper .login-form .personal-info-wrap .personal-info .inline p {
          padding: 0px;
          font-size: 1.125rem;
          margin-left: 0px;
          margin-right: 10px; }
        .passwordchange-wrapper .login-form .personal-info-wrap .personal-info .inline a {
          text-decoration: underline;
          color: #007473;
          font-size: 1rem; }
      .passwordchange-wrapper .login-form .personal-info-wrap .personal-info .paragraph1 p {
        font-size: 1rem;
        line-height: 1.5;
        max-width: 355px; }
      .passwordchange-wrapper .login-form .personal-info-wrap .personal-info img {
        margin-top: 55px; }
      .passwordchange-wrapper .login-form .personal-info-wrap .personal-info h1 {
        font-size: 2.25rem !important;
        line-height: 1.10111 !important;
        margin-top: 31px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-right: 36px;
        margin-left: 36px; }
        @media only screen and (max-width: 500px) {
          .passwordchange-wrapper .login-form .personal-info-wrap .personal-info h1 {
            margin-left: 0px;
            margin-right: 0px; } }
      .passwordchange-wrapper .login-form .personal-info-wrap .personal-info p {
        font-size: 1.625rem;
        line-height: 1.2;
        margin-bottom: 47px;
        margin-right: 55px;
        font-weight: 600;
        margin-left: 55px;
        color: #373433; }
        @media only screen and (max-width: 500px) {
          .passwordchange-wrapper .login-form .personal-info-wrap .personal-info p {
            margin-left: 0px;
            margin-right: 0px; } }
      .passwordchange-wrapper .login-form .personal-info-wrap .personal-info button {
        width: 395px;
        padding: 18px 32px 18px 28px;
        background: #007473;
        border: none;
        border-radius: 15px;
        margin-left: 60px;
        margin-right: 60px;
        margin-bottom: 56px; }
        @media only screen and (max-width: 500px) {
          .passwordchange-wrapper .login-form .personal-info-wrap .personal-info button {
            width: 100%;
            margin-left: 0px;
            margin-right: 0px; } }
        .passwordchange-wrapper .login-form .personal-info-wrap .personal-info button a {
          color: white;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: 700; }

.arabic .passwordchange-wrapper .login-form .personal-info-wrap .personal-info .paragraph1 p {
  text-align: center; }

.arabic .passwordchange-wrapper .login-form .personal-info-wrap .personal-info .inline p {
  margin-right: 0px;
  margin-left: 10px; }

.event-detail-wrapper {
  position: relative; }
  .event-detail-wrapper .banner-bg {
    background-size: cover;
    background-position-y: top !important;
    background-position-x: center !important; }
  .event-detail-wrapper .jada-main-banner {
    position: relative; }
    @media only screen and (max-width: 1090px) {
      .event-detail-wrapper .jada-main-banner {
        height: auto;
        min-height: 500px; } }
    .event-detail-wrapper .jada-main-banner:after {
      position: absolute;
      top: 0px;
      left: 0px;
      content: "";
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: -1; }
    .event-detail-wrapper .jada-main-banner .breadcrumb-container {
      z-index: 2; }
    .event-detail-wrapper .jada-main-banner h1 {
      z-index: 2;
      max-width: 1017px; }
      @media only screen and (max-width: 1090px) {
        .event-detail-wrapper .jada-main-banner h1 {
          font-size: 4.5rem;
          line-height: 1.08333;
          max-width: 900px !important; } }
      @media only screen and (max-width: 991px) {
        .event-detail-wrapper .jada-main-banner h1 {
          font-size: 3.375rem;
          line-height: 1.14815;
          max-width: 100%; } }
      @media only screen and (max-width: 767px) {
        .event-detail-wrapper .jada-main-banner h1 {
          font-size: 50px !important;
          line-height: 57.5px !important; } }
      @media only screen and (max-width: 500px) {
        .event-detail-wrapper .jada-main-banner h1 {
          font-size: 40px !important;
          line-height: 46px !important; } }
  .event-detail-wrapper .bg-img {
    z-index: -1;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: center !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    left: 0; }
  .event-detail-wrapper section {
    margin-top: -2px;
    margin-bottom: -2px; }
  @media only screen and (min-width: 1200px) {
    .event-detail-wrapper .detail-page-banner .content {
      padding-bottom: 87px; } }
  .event-detail-wrapper .emp-stats {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    background-color: #FFFFFF;
    margin: -1px 0px; }
    @media only screen and (max-width: 1090px) {
      .event-detail-wrapper .emp-stats {
        margin: 0px; } }
    .event-detail-wrapper .emp-stats .btns {
      width: 100%;
      height: 100%;
      display: flex; }
      .event-detail-wrapper .emp-stats .btns a {
        width: 100%;
        height: 100%; }
      @media only screen and (max-width: 500px) {
        .event-detail-wrapper .emp-stats .btns {
          padding: 10px 20px 10px 20px; } }
      .event-detail-wrapper .emp-stats .btns .btn-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        color: #FFFFFF;
        font-family: "Cairo", sans-serif;
        text-transform: uppercase;
        width: 100%;
        height: 100%;
        padding-left: 8px; }
        @media only screen and (max-width: 991px) {
          .event-detail-wrapper .emp-stats .btns .btn-wrap {
            padding: 15px 0px; } }
        @media only screen and (max-width: 500px) {
          .event-detail-wrapper .emp-stats .btns .btn-wrap {
            padding: 8px; } }
        .event-detail-wrapper .emp-stats .btns .btn-wrap span {
          font-size: 1.375rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          font-weight: 700;
          margin-left: 16px; }
          @media only screen and (max-width: 1400px) {
            .event-detail-wrapper .emp-stats .btns .btn-wrap span {
              font-size: 20px; } }
          @media only screen and (max-width: 991px) {
            .event-detail-wrapper .emp-stats .btns .btn-wrap span {
              font-size: 18px; } }
          @media only screen and (max-width: 500px) {
            .event-detail-wrapper .emp-stats .btns .btn-wrap span {
              margin-left: 4px;
              max-width: 100%; } }
      .event-detail-wrapper .emp-stats .btns.register {
        background-color: #007473;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        z-index: 2;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in; }
        .event-detail-wrapper .emp-stats .btns.register:after {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 0%;
          width: 100%;
          content: "";
          background-color: #373433;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
          z-index: -1; }
        .event-detail-wrapper .emp-stats .btns.register:hover a {
          text-decoration: none; }
        @media only screen and (min-width: 1090px) {
          .event-detail-wrapper .emp-stats .btns.register:hover:after {
            height: 100%; } }
      .event-detail-wrapper .emp-stats .btns li {
        list-style: none;
        position: relative;
        height: 70px;
        width: 70px;
        background-color: rgba(255, 255, 255, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%; }
        @media only screen and (max-width: 600px) {
          .event-detail-wrapper .emp-stats .btns li {
            height: 40px;
            width: 40px; } }
        .event-detail-wrapper .emp-stats .btns li .icomoon {
          font-size: 0.875rem;
          color: #FFFFFF; }
          @media only screen and (max-width: 600px) {
            .event-detail-wrapper .emp-stats .btns li .icomoon {
              font-size: 0.75rem; } }
    .event-detail-wrapper .emp-stats .event-info-container {
      width: 100%;
      display: flex; }
      @media only screen and (max-width: 1090px) {
        .event-detail-wrapper .emp-stats .event-info-container {
          flex-wrap: wrap; } }
      .event-detail-wrapper .emp-stats .event-info-container .info-box {
        width: 18.667%;
        box-sizing: border-box;
        padding: 25px 0px 25px 40px;
        border-top: 1px solid rgba(55, 52, 51, 0.15);
        border-bottom: 2.5px solid rgba(55, 52, 51, 0.15);
        border-right: 1px solid rgba(55, 52, 51, 0.15); }
        @media only screen and (max-width: 1090px) {
          .event-detail-wrapper .emp-stats .event-info-container .info-box {
            padding: 25px 0px 25px 20px;
            border-bottom: 1px solid rgba(55, 52, 51, 0.15); } }
        @media only screen and (max-width: 991px) {
          .event-detail-wrapper .emp-stats .event-info-container .info-box {
            width: 50%;
            border-bottom: 0.5px solid rgba(55, 52, 51, 0.15); }
            .event-detail-wrapper .emp-stats .event-info-container .info-box:nth-child(3) {
              border-bottom: 1px solid rgba(55, 52, 51, 0.15); } }
        @media only screen and (max-width: 767px) {
          .event-detail-wrapper .emp-stats .event-info-container .info-box {
            width: 100%;
            padding: 20px 20px 20px 20px;
            border-left: 0px;
            border-radius: 0px;
            border-top: 0.5px solid rgba(55, 52, 51, 0.15); }
            .event-detail-wrapper .emp-stats .event-info-container .info-box:nth-child(3) {
              border-bottom: 0.5px solid rgba(55, 52, 51, 0.15); } }
        .event-detail-wrapper .emp-stats .event-info-container .info-box.large {
          width: 44%;
          border: 0px; }
          @media only screen and (max-width: 991px) {
            .event-detail-wrapper .emp-stats .event-info-container .info-box.large {
              width: 50%; } }
          @media only screen and (max-width: 767px) {
            .event-detail-wrapper .emp-stats .event-info-container .info-box.large {
              width: 100%; } }
        .event-detail-wrapper .emp-stats .event-info-container .info-box:last-child {
          border-right: none; }
        .event-detail-wrapper .emp-stats .event-info-container .info-box p {
          font-family: "Cairo", sans-serif;
          font-size: 0.875rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          margin: 0;
          text-transform: uppercase; }
          @media only screen and (max-width: 767px) {
            .event-detail-wrapper .emp-stats .event-info-container .info-box p {
              font-size: 14px; } }
        .event-detail-wrapper .emp-stats .event-info-container .info-box h6 {
          font-family: "Cairo", sans-serif;
          font-size: 1.25rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          text-transform: uppercase;
          font-weight: 700;
          margin-top: 10px; }
  .event-detail-wrapper .all-about-jada {
    position: relative;
    background-color: #FFFFFF;
    padding-bottom: 0px; }
    @media only screen and (max-width: 767px) {
      .event-detail-wrapper .all-about-jada {
        margin-top: 0px; } }
    .event-detail-wrapper .all-about-jada .video-block-container .video-block:hover .play_white {
      background-color: #007473; }
      .event-detail-wrapper .all-about-jada .video-block-container .video-block:hover .play_white:before {
        color: #FFFFFF; }
    .event-detail-wrapper .all-about-jada .video-block-container {
      margin-top: 0px;
      margin-bottom: 0px; }
      .event-detail-wrapper .all-about-jada .video-block-container .video-block .img-container {
        position: relative;
        padding-top: calc(939 / 1876 * 100%) !important;
        overflow: hidden;
        height: 0; }
        .event-detail-wrapper .all-about-jada .video-block-container .video-block .img-container:after {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          background-color: rgba(0, 0, 0, 0.3);
          height: 100%;
          width: 100%;
          z-index: 2; }
      .event-detail-wrapper .all-about-jada .video-block-container .video-block .bg-img {
        height: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center !important;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }
      .event-detail-wrapper .all-about-jada .video-block-container .video-block:hover .bg-img {
        transform: none !important; }
      .event-detail-wrapper .all-about-jada .video-block-container .video-block .play_white {
        height: 120px;
        width: 120px;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
        position: absolute;
        background-color: #FFFFFF;
        top: 50%;
        left: 50%;
        z-index: 3;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
        .event-detail-wrapper .all-about-jada .video-block-container .video-block .play_white:before {
          content: "\E914";
          position: absolute;
          top: 50%;
          left: 47%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          font-family: "icomoon" !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          font-size: 1.625rem;
          color: #007473; }
        @media only screen and (max-width: 767px) {
          .event-detail-wrapper .all-about-jada .video-block-container .video-block .play_white {
            height: 60px;
            width: 60px; }
            .event-detail-wrapper .all-about-jada .video-block-container .video-block .play_white:before {
              font-size: 20px; } }
  .event-detail-wrapper .description-section {
    padding-top: 80px;
    background-color: #FFFFFF;
    margin: 0px !important; }
    @media only screen and (max-width: 767px) {
      .event-detail-wrapper .description-section {
        padding-top: 60px; } }
    @media only screen and (max-width: 991px) {
      .event-detail-wrapper .description-section .title {
        flex: 0 0 40%;
        max-width: 40%; } }
    @media only screen and (max-width: 767px) {
      .event-detail-wrapper .description-section .title {
        flex: 0 0 100%;
        max-width: 100%; } }
    .event-detail-wrapper .description-section .title p {
      font-family: "Cairo", sans-serif;
      font-size: 2.625rem;
      line-height: 1.2;
      color: #373433;
      font-weight: 400;
      text-align: center;
      margin-bottom: 0px;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .event-detail-wrapper .description-section .title p {
          text-align: left; } }
    @media only screen and (max-width: 767px) {
      .event-detail-wrapper .description-section .title {
        margin-bottom: 30px; } }
    .event-detail-wrapper .description-section .desc {
      padding-bottom: 100px;
      flex: 0 0 70%;
      max-width: 70%;
      margin-left: auto;
      margin-right: auto; }
      @media only screen and (max-width: 1090px) {
        .event-detail-wrapper .description-section .desc {
          flex: 0 0 80%;
          max-width: 80%; } }
      @media only screen and (max-width: 991px) {
        .event-detail-wrapper .description-section .desc {
          flex: 0 0 100%;
          max-width: 100%;
          padding-bottom: 60px; } }
      .event-detail-wrapper .description-section .desc h3 {
        font-family: "Cairo", sans-serif;
        font-size: 1.5625rem;
        line-height: 1.36;
        color: #373433;
        font-weight: 400;
        margin-bottom: 30px; }
      .event-detail-wrapper .description-section .desc p {
        font-family: "Cairo", sans-serif;
        font-size: 1.5625rem;
        line-height: 1.36;
        font-weight: 400;
        color: #373433;
        margin-bottom: 30px; }
        .event-detail-wrapper .description-section .desc p:last-child {
          margin-bottom: 0px; }
        @media only screen and (max-width: 767px) {
          .event-detail-wrapper .description-section .desc p {
            font-size: 18px; } }
        @media only screen and (max-width: 500px) {
          .event-detail-wrapper .description-section .desc p {
            margin-bottom: 20px; } }
      .event-detail-wrapper .description-section .desc li {
        font-family: "Cairo", sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 300;
        color: #373433;
        list-style: none; }
        @media only screen and (max-width: 767px) {
          .event-detail-wrapper .description-section .desc li {
            font-size: 18px; } }
        .event-detail-wrapper .description-section .desc li:before {
          content: "-";
          top: 0px;
          left: 0px;
          padding-right: 5px; }
      .event-detail-wrapper .description-section .desc li.list-heading:before {
        padding: 0px;
        content: ""; }
  .event-detail-wrapper .panelists-section {
    background-color: #f2f8f7;
    padding-top: 70px;
    margin: -1px 0px; }
    @media only screen and (max-width: 767px) {
      .event-detail-wrapper .panelists-section {
        padding-top: 60px; } }
    .event-detail-wrapper .panelists-section h2 {
      margin-bottom: 40px;
      font-family: "Cairo", sans-serif;
      font-size: 2.625rem;
      line-height: 1.2;
      color: #373433;
      font-weight: 400;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .event-detail-wrapper .panelists-section h2 {
          text-align: left; } }
    @media only screen and (max-width: 991px) {
      .event-detail-wrapper .panelists-section .title {
        flex: 0 0 40%;
        max-width: 40%; } }
    @media only screen and (max-width: 767px) {
      .event-detail-wrapper .panelists-section .title {
        flex: 0 0 100%;
        max-width: 100%; } }
    .event-detail-wrapper .panelists-section .title p {
      font-family: "Cairo", sans-serif;
      font-size: 1.25rem;
      line-height: 1.27;
      letter-spacing: 0.5px;
      color: #373433;
      font-weight: 700;
      text-align: center;
      margin-bottom: 0px; }
      @media only screen and (max-width: 767px) {
        .event-detail-wrapper .panelists-section .title p {
          text-align: left;
          font-size: 20px; } }
    @media only screen and (max-width: 767px) {
      .event-detail-wrapper .panelists-section .title {
        margin-bottom: 30px; } }
    @media only screen and (max-width: 991px) {
      .event-detail-wrapper .panelists-section .desc {
        flex: 0 0 100%;
        max-width: 100%; } }
    @media only screen and (max-width: 767px) {
      .event-detail-wrapper .panelists-section .desc {
        flex: 0 0 100%;
        max-width: 100%; } }
    .event-detail-wrapper .panelists-section .desc h3 {
      font-family: "Cairo", sans-serif;
      font-size: 2.625rem;
      line-height: 1.2;
      color: #373433;
      font-weight: 400;
      margin-bottom: 40px; }
      @media only screen and (max-width: 767px) {
        .event-detail-wrapper .panelists-section .desc h3 {
          margin-bottom: 20px; } }
    .event-detail-wrapper .panelists-section .desc h4 {
      font-family: "Cairo", sans-serif;
      font-size: 1.25rem;
      line-height: 1.4;
      color: #373433;
      font-weight: 600;
      margin-bottom: 10px; }
    .event-detail-wrapper .panelists-section .desc h5 {
      color: rgba(55, 52, 51, 0.6);
      text-transform: uppercase;
      font-size: 0.875rem;
      line-height: 1.42857;
      font-weight: 300; }
      @media only screen and (max-width: 767px) {
        .event-detail-wrapper .panelists-section .desc h5 {
          font-size: 14px;
          line-height: 20px; } }
  .event-detail-wrapper .events-listing-grid {
    display: block;
    width: 100%;
    padding-right: 0; }
    @media only screen and (max-width: 991px) {
      .event-detail-wrapper .events-listing-grid {
        flex-direction: column;
        width: auto; } }
    .event-detail-wrapper .events-listing-grid .two-col-grid {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -15px;
      width: auto;
      flex: 0 0 100%; }
      @media only screen and (max-width: 991px) {
        .event-detail-wrapper .events-listing-grid .two-col-grid {
          margin: 0px -20px; } }
      @media only screen and (max-width: 500px) {
        .event-detail-wrapper .events-listing-grid .two-col-grid {
          margin: 0px -10px; } }
      .event-detail-wrapper .events-listing-grid .two-col-grid .event-card__small {
        padding-bottom: 80px;
        display: inline-block;
        width: 20%;
        padding-left: 15px;
        padding-right: 15px; }
        @media only screen and (max-width: 1090px) {
          .event-detail-wrapper .events-listing-grid .two-col-grid .event-card__small {
            padding-bottom: 40px; } }
        @media only screen and (max-width: 991px) {
          .event-detail-wrapper .events-listing-grid .two-col-grid .event-card__small {
            width: 50%;
            padding-left: 20px;
            padding-right: 20px; } }
        @media only screen and (max-width: 500px) {
          .event-detail-wrapper .events-listing-grid .two-col-grid .event-card__small {
            padding-left: 10px;
            padding-right: 10px; } }
        .event-detail-wrapper .events-listing-grid .two-col-grid .event-card__small .img-bg {
          background-repeat: no-repeat;
          background-size: cover;
          height: 0;
          padding-top: 100%;
          margin-bottom: 20px; }
  .event-detail-wrapper .register-for-attend-event {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #373433;
    height: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    position: relative;
    margin: -1px 0px; }
    @media only screen and (max-width: 767px) {
      .event-detail-wrapper .register-for-attend-event {
        padding-top: 60px;
        padding-bottom: 60px; } }
    .event-detail-wrapper .register-for-attend-event:after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: url(../images/jada-wave-pattern.png) no-repeat;
      background-position: center center;
      background-size: cover;
      opacity: 0.3; }
    .event-detail-wrapper .register-for-attend-event:before {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background: linear-gradient(180deg, #373433 0%, rgba(55, 52, 51, 0) 100%); }
    .event-detail-wrapper .register-for-attend-event .content {
      width: 50%;
      text-align: center;
      z-index: 2; }
      @media only screen and (max-width: 991px) {
        .event-detail-wrapper .register-for-attend-event .content {
          width: 95%; } }
      .event-detail-wrapper .register-for-attend-event .content h3 {
        font-family: "Cairo", sans-serif;
        font-size: 3.75rem;
        line-height: 1.2;
        letter-spacing: -0.2px;
        color: #FFFFFF;
        margin-bottom: 15px;
        font-weight: 600;
        text-align: center; }
      .event-detail-wrapper .register-for-attend-event .content p {
        font-family: "Cairo", sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        color: #FFFFFF;
        margin-bottom: 50px;
        text-align: center;
        font-weight: 400;
        padding: 0 15%; }
      .event-detail-wrapper .register-for-attend-event .content .jada-btn {
        padding: 18px 60px 18px 56px; }

@-webkit-keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 30px 30px; } }

@-moz-keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 30px 30px; } }

::-ms-fill-lower {
  background: #58bfff; }

@media only screen and (max-width: 767px) {
  body.arabic .description-section .title p {
    text-align: right; } }

body.arabic .event-detail-wrapper .emp-stats .btns .btn-wrap span {
  margin-left: 0;
  margin-right: 16px;
  text-align: right; }
  @media only screen and (max-width: 500px) {
    body.arabic .event-detail-wrapper .emp-stats .btns .btn-wrap span {
      margin-right: 4px; } }

body.arabic .event-detail-wrapper .emp-stats .event-info-container .info-box {
  padding: 25px 40px 25px 0;
  border-right: 0px;
  border-left: 1px solid rgba(55, 52, 51, 0.15); }
  body.arabic .event-detail-wrapper .emp-stats .event-info-container .info-box:last-child {
    border-left: 0px; }
  @media only screen and (max-width: 1090px) {
    body.arabic .event-detail-wrapper .emp-stats .event-info-container .info-box {
      padding: 20px 20px 20px 0px; } }

.emailwelcome-wrapper {
  width: 600px;
  flex-direction: column; }
  .emailwelcome-wrapper .form-wrapper {
    padding-top: 35px;
    padding-bottom: 35px;
    border-bottom: 1px solid rgba(55, 52, 51, 0.15);
    width: 100%; }
  .emailwelcome-wrapper .emailcontent {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 30px 60px 30px;
    align-items: center; }
    .emailwelcome-wrapper .emailcontent img {
      width: 100%; }
    .emailwelcome-wrapper .emailcontent .emailtext {
      width: 100%;
      padding: 0px 4px 0px 4px; }
      .emailwelcome-wrapper .emailcontent .emailtext h1 {
        font-size: 2.8125rem;
        font-weight: 600;
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: center; }
      .emailwelcome-wrapper .emailcontent .emailtext p {
        font-size: 0.9375rem;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: center;
        line-height: 24px; }
      .emailwelcome-wrapper .emailcontent .emailtext h5 {
        font-size: 0.9375rem;
        font-weight: 500;
        text-align: center;
        line-height: 24px; }
        .emailwelcome-wrapper .emailcontent .emailtext h5 span {
          font-weight: 700; }
  .emailwelcome-wrapper .emailcards {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: #eef3f2;
    align-items: center; }
    .emailwelcome-wrapper .emailcards h1 {
      font-size: 2.1875rem;
      margin-bottom: 54px;
      margin-top: 20px;
      font-weight: 600; }
    .emailwelcome-wrapper .emailcards .img-text {
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      .emailwelcome-wrapper .emailcards .img-text .card {
        width: 50%;
        padding-right: 8px;
        padding-left: 8px;
        margin-bottom: 40px;
        background-color: #eef3f2;
        border: none; }
        .emailwelcome-wrapper .emailcards .img-text .card h1 {
          font-size: 1.75rem;
          margin-bottom: 14px;
          margin-top: 18px; }
        .emailwelcome-wrapper .emailcards .img-text .card p {
          font-size: 0.875rem;
          margin-bottom: 13px; }
        .emailwelcome-wrapper .emailcards .img-text .card button {
          width: 60%;
          padding: 10px 32px 10px 32px; }
          .emailwelcome-wrapper .emailcards .img-text .card button a {
            color: #007473;
            font-size: 0.8125rem; }
            .emailwelcome-wrapper .emailcards .img-text .card button a:hover {
              color: white; }
  .emailwelcome-wrapper #jadaFooter {
    padding-top: 0px; }
    .emailwelcome-wrapper #jadaFooter .cen-links {
      padding-left: 0px;
      margin-bottom: 15px; }
      .emailwelcome-wrapper #jadaFooter .cen-links ul li a {
        font-size: 1rem; }
    .emailwelcome-wrapper #jadaFooter .cen-links2 {
      padding-left: 50px; }
      .emailwelcome-wrapper #jadaFooter .cen-links2 ul li a {
        font-size: 1rem; }
    .emailwelcome-wrapper #jadaFooter .logo-wrapper {
      justify-content: flex-start;
      width: 100%; }
      .emailwelcome-wrapper #jadaFooter .logo-wrapper .social-links {
        flex-direction: column; }
        .emailwelcome-wrapper #jadaFooter .logo-wrapper .social-links ul {
          flex-direction: row;
          width: 200%; }

.emailattandnce-wrapper {
  width: 600px;
  flex-direction: column; }
  .emailattandnce-wrapper .form-wrapper {
    padding-top: 35px;
    padding-bottom: 35px;
    border-bottom: 1px solid rgba(55, 52, 51, 0.15);
    width: 100%; }
  .emailattandnce-wrapper .centered {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px; }
    .emailattandnce-wrapper .centered h1 {
      font-size: 2.5rem;
      font-weight: 500;
      margin-top: 40px;
      line-height: 54px;
      text-align: center; }
    .emailattandnce-wrapper .centered h2 {
      font-weight: 700;
      font-size: 2.5rem;
      text-align: center; }
      .emailattandnce-wrapper .centered h2 span {
        font-weight: 500; }
    .emailattandnce-wrapper .centered p {
      font-size: 0.9375rem;
      line-height: 24px;
      text-align: center;
      margin-bottom: 35px;
      margin-top: 23px;
      width: 80%; }
    .emailattandnce-wrapper .centered button {
      padding: 15px 50px 15px 50px;
      margin-bottom: 60px;
      border: 1px solid transparent; }
      .emailattandnce-wrapper .centered button:hover {
        border: 1px solid #007473; }
      .emailattandnce-wrapper .centered button a {
        color: white;
        font-size: 0.8125rem; }
        .emailattandnce-wrapper .centered button a:hover {
          color: black; }
  .emailattandnce-wrapper #jadaFooter {
    padding-top: 0px; }
    .emailattandnce-wrapper #jadaFooter .cen-links {
      padding-left: 0px;
      margin-bottom: 15px; }
      .emailattandnce-wrapper #jadaFooter .cen-links ul li a {
        font-size: 1rem; }
    .emailattandnce-wrapper #jadaFooter .cen-links2 {
      padding-left: 50px; }
      .emailattandnce-wrapper #jadaFooter .cen-links2 ul li a {
        font-size: 1rem; }
    .emailattandnce-wrapper #jadaFooter .logo-wrapper {
      justify-content: flex-start;
      width: 100%; }
      .emailattandnce-wrapper #jadaFooter .logo-wrapper .social-links {
        flex-direction: column; }
        .emailattandnce-wrapper #jadaFooter .logo-wrapper .social-links ul {
          flex-direction: row;
          width: 200%; }

@media only screen and (max-width: 767px) {
  .account-deleting .login-form {
    height: auto;
    padding-bottom: 50px; } }

.account-deleting .personal-info-wrap .personal-info .otp-boxes .span {
  font-size: 0.75rem;
  line-height: 1.66667;
  letter-spacing: 0.1px;
  font-weight: 400;
  color: #7F8184;
  margin-bottom: 10px; }

.account-deleting .personal-info-wrap .personal-info .otp-boxes .number-inputs {
  border: 1px solid rgba(55, 52, 51, 0.1);
  border-radius: 6px;
  background-color: transparent;
  padding: 18px;
  display: flex; }

.account-deleting .personal-info-wrap .personal-info .otp-boxes input {
  width: 25%;
  margin-right: 9px;
  border: 1.14px solid #B2B4CC;
  font-size: 2.25rem;
  line-height: 1;
  font-weight: 700;
  color: #373433;
  border-radius: 6px;
  text-align: center; }
  .account-deleting .personal-info-wrap .personal-info .otp-boxes input:focus, .account-deleting .personal-info-wrap .personal-info .otp-boxes input:hover {
    border-color: #007473;
    outline: none;
    background-color: transparent; }
  .account-deleting .personal-info-wrap .personal-info .otp-boxes input:last-child {
    margin-right: 0px; }

.account-deleting .personal-info-wrap .personal-info h1 {
  text-align: center; }

.account-deleting .personal-info-wrap .personal-info p {
  text-align: center; }

.account-deleting .personal-info-wrap .personal-info .inline {
  width: 100%;
  display: flex;
  justify-content: center; }
  .account-deleting .personal-info-wrap .personal-info .inline p {
    padding: 0px;
    font-size: 1.125rem;
    margin-left: 0px;
    margin-right: 10px;
    font-weight: 400;
    margin-top: 26px; }
  .account-deleting .personal-info-wrap .personal-info .inline a {
    text-decoration: underline;
    color: #007473;
    font-size: 1rem;
    font-weight: 700;
    margin-top: 26px; }

.account-deleting .personal-info-wrap .personal-info .pr-30 {
  display: flex;
  width: 100%;
  padding: 18px 21px;
  border: 1px solid rgba(55, 52, 51, 0.15);
  border-radius: 6px;
  margin-bottom: 27px; }
  .account-deleting .personal-info-wrap .personal-info .pr-30 .selection {
    padding-right: 0px;
    display: flex;
    justify-content: center;
    /* Firefox */ }
    .account-deleting .personal-info-wrap .personal-info .pr-30 .selection input {
      display: flex;
      width: 80%;
      padding: 18px 0px;
      border: 1px solid rgba(127, 129, 132, 0.2);
      font-size: 1.25rem;
      line-height: 1.3;
      border-radius: 8px;
      font-weight: 400;
      text-align: center;
      margin-right: 0px; }
      .account-deleting .personal-info-wrap .personal-info .pr-30 .selection input:hover {
        border: 1px solid #007473; }
    .account-deleting .personal-info-wrap .personal-info .pr-30 .selection input::-webkit-outer-spin-button,
    .account-deleting .personal-info-wrap .personal-info .pr-30 .selection input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .account-deleting .personal-info-wrap .personal-info .pr-30 .selection input[type="number"] {
      -moz-appearance: textfield; }

.arabic .mail-wrapper .login-form .personal-info-wrap .personal-info .first_name {
  padding-left: 0px; }

.arabic .mail-wrapper .login-form .personal-info-wrap .personal-info .inline p {
  margin-right: 0px;
  margin-left: 10px; }

.arabic .account-deleting .personal-info-wrap .personal-info .otp-boxes input {
  margin-right: 0px;
  margin-left: 9px; }
  .arabic .account-deleting .personal-info-wrap .personal-info .otp-boxes input:last-child {
    margin-left: 0px; }

.arabic .account-deleting .personal-info-wrap .personal-info .inline p {
  margin-left: 10px;
  margin-right: 0px; }

.case-study-detail-wrapper {
  position: relative; }
  .case-study-detail-wrapper .full-image {
    height: 80vh; }
    @media only screen and (min-width: 992px) {
      .case-study-detail-wrapper .full-image {
        height: 100vh; } }
  .case-study-detail-wrapper .bg-img {
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: top center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    left: 0; }
  .case-study-detail-wrapper section {
    margin-top: -2px;
    margin-bottom: -2px; }
  @media only screen and (min-width: 1200px) {
    .case-study-detail-wrapper .detail-page-banner .content {
      padding-bottom: 50px; } }
  @media only screen and (min-width: 1600px) {
    .case-study-detail-wrapper .detail-page-banner .content {
      padding-bottom: 87px; } }
  .case-study-detail-wrapper .emp-stats {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    background-color: #FFFFFF;
    margin: 0px; }
    .case-study-detail-wrapper .emp-stats .btns {
      width: 100%;
      height: 100%;
      display: flex; }
      .case-study-detail-wrapper .emp-stats .btns a {
        width: 100%;
        height: 100%; }
      .case-study-detail-wrapper .emp-stats .btns .btn-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        color: #373433;
        font-family: "Cairo", sans-serif;
        text-transform: uppercase;
        width: 100%;
        height: 100%;
        padding-left: 8px; }
        @media only screen and (max-width: 991px) {
          .case-study-detail-wrapper .emp-stats .btns .btn-wrap {
            padding: 15px 0px;
            justify-content: space-between; } }
        @media only screen and (max-width: 500px) {
          .case-study-detail-wrapper .emp-stats .btns .btn-wrap {
            padding: 20px 8px; } }
        .case-study-detail-wrapper .emp-stats .btns .btn-wrap span {
          font-size: 1.375rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          font-weight: 700;
          margin-left: 16px; }
          @media only screen and (max-width: 1400px) {
            .case-study-detail-wrapper .emp-stats .btns .btn-wrap span {
              font-size: 20px; } }
          @media only screen and (max-width: 991px) {
            .case-study-detail-wrapper .emp-stats .btns .btn-wrap span {
              font-size: 18px; } }
          @media only screen and (max-width: 500px) {
            .case-study-detail-wrapper .emp-stats .btns .btn-wrap span {
              margin-left: 10px;
              max-width: 70%; } }
      .case-study-detail-wrapper .emp-stats .btns .social-links {
        display: flex;
        flex-direction: column;
        margin-left: 40px; }
        @media only screen and (max-width: 1200px) {
          .case-study-detail-wrapper .emp-stats .btns .social-links {
            margin-left: 20px;
            margin-right: 15px; } }
        @media only screen and (max-width: 500px) {
          .case-study-detail-wrapper .emp-stats .btns .social-links {
            width: 35%; } }
        .case-study-detail-wrapper .emp-stats .btns .social-links ul {
          list-style: none;
          margin-bottom: 0;
          display: flex; }
          .case-study-detail-wrapper .emp-stats .btns .social-links ul li {
            position: relative;
            list-style: none;
            height: 42px;
            width: 42px;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            background-color: #007473;
            margin-right: 14px;
            -webkit-transition: 0.3s all ease-in;
            -moz-transition: 0.3s all ease-in;
            -ms-transition: 0.3s all ease-in;
            -o-transition: 0.3s all ease-in;
            transition: 0.3s all ease-in;
            transition: 0.3s background-color ease-in; }
            @media only screen and (max-width: 767px) {
              .case-study-detail-wrapper .emp-stats .btns .social-links ul li {
                height: 36px;
                width: 36px; } }
            .case-study-detail-wrapper .emp-stats .btns .social-links ul li:hover {
              background-color: transparent;
              border: 1px solid #007473; }
              .case-study-detail-wrapper .emp-stats .btns .social-links ul li:hover .icomoon:before {
                color: #007473; }
            .case-study-detail-wrapper .emp-stats .btns .social-links ul li:last-child {
              margin-right: 0; }
            .case-study-detail-wrapper .emp-stats .btns .social-links ul li a {
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0; }
              .case-study-detail-wrapper .emp-stats .btns .social-links ul li a .icomoon {
                position: absolute;
                font-size: 1.25rem;
                line-height: 1.25rem;
                text-align: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%); }
                .case-study-detail-wrapper .emp-stats .btns .social-links ul li a .icomoon:before {
                  color: #FFFFFF;
                  -webkit-transition: 0.3s all ease-in;
                  -moz-transition: 0.3s all ease-in;
                  -ms-transition: 0.3s all ease-in;
                  -o-transition: 0.3s all ease-in;
                  transition: 0.3s all ease-in; }
        .case-study-detail-wrapper .emp-stats .btns .social-links label {
          text-transform: uppercase;
          font-family: "Cairo", sans-serif;
          font-size: 1rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #FFFFFF;
          opacity: 0.5;
          margin-bottom: 16px;
          font-weight: 700; }
    .case-study-detail-wrapper .emp-stats .event-info-container {
      width: 100%;
      display: flex; }
      @media only screen and (max-width: 1090px) {
        .case-study-detail-wrapper .emp-stats .event-info-container {
          flex-wrap: wrap; } }
      .case-study-detail-wrapper .emp-stats .event-info-container .info-box {
        width: 18.667%;
        box-sizing: border-box;
        padding: 25px 0px 25px 40px;
        border-top: 1px solid rgba(55, 52, 51, 0.15);
        border-bottom: 1px solid rgba(55, 52, 51, 0.15);
        border-right: 1px solid rgba(55, 52, 51, 0.15); }
        @media only screen and (max-width: 991px) {
          .case-study-detail-wrapper .emp-stats .event-info-container .info-box {
            width: 50%; } }
        @media only screen and (max-width: 767px) {
          .case-study-detail-wrapper .emp-stats .event-info-container .info-box {
            width: 100%;
            padding: 20px 20px 20px 20px; } }
        .case-study-detail-wrapper .emp-stats .event-info-container .info-box.large {
          width: 44%;
          background-color: #F8FBFB; }
          @media only screen and (max-width: 991px) {
            .case-study-detail-wrapper .emp-stats .event-info-container .info-box.large {
              width: 50%; } }
          @media only screen and (max-width: 767px) {
            .case-study-detail-wrapper .emp-stats .event-info-container .info-box.large {
              width: 100%; } }
        .case-study-detail-wrapper .emp-stats .event-info-container .info-box:last-child {
          border-right: none; }
        .case-study-detail-wrapper .emp-stats .event-info-container .info-box p {
          font-family: "Cairo", sans-serif;
          font-size: 0.875rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          margin: 0;
          text-transform: uppercase; }
          @media only screen and (max-width: 767px) {
            .case-study-detail-wrapper .emp-stats .event-info-container .info-box p {
              font-size: 14px; } }
        .case-study-detail-wrapper .emp-stats .event-info-container .info-box h6 {
          font-family: "Cairo", sans-serif;
          font-size: 1.25rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          text-transform: uppercase;
          font-weight: 700;
          margin-top: 10px; }
  .case-study-detail-wrapper .banner-img {
    position: relative;
    height: 718px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 1090px) {
      .case-study-detail-wrapper .banner-img {
        height: 80vh; } }
    @media only screen and (max-width: 1090px) {
      .case-study-detail-wrapper .banner-img.bg-grey .img-fluid {
        width: 200px; } }
    .case-study-detail-wrapper .banner-img .content {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center; }
      .case-study-detail-wrapper .banner-img .content p {
        opacity: 0; }
    .case-study-detail-wrapper .banner-img .bg-img {
      background-size: cover;
      width: 100%;
      background-repeat: no-repeat;
      height: 100%;
      background-position: center top !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      left: 0;
      display: none; }
      @media only screen and (max-width: 1090px) {
        .case-study-detail-wrapper .banner-img .bg-img {
          display: block; } }
  .case-study-detail-wrapper .full-banner-img {
    position: relative;
    height: 718px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F2F2F2; }
    @media only screen and (max-width: 1090px) {
      .case-study-detail-wrapper .full-banner-img {
        height: 80vh; } }
    @media only screen and (max-width: 991px) {
      .case-study-detail-wrapper .full-banner-img .content {
        padding-right: 40px;
        padding-left: 40px; } }
    @media only screen and (max-width: 767px) {
      .case-study-detail-wrapper .full-banner-img .content {
        padding-right: 20px;
        padding-left: 20px; } }
    .case-study-detail-wrapper .full-banner-img .bg-img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center !important;
      background-size: cover; }
    @media only screen and (max-width: 1090px) {
      .case-study-detail-wrapper .full-banner-img.bg-grey .img-fluid {
        width: 100px; } }
    .case-study-detail-wrapper .full-banner-img.banner-spacer-green {
      padding-bottom: 110px;
      height: auto; }
      @media only screen and (max-width: 767px) {
        .case-study-detail-wrapper .full-banner-img.banner-spacer-green {
          padding-bottom: 30px;
          height: auto; } }
    .case-study-detail-wrapper .full-banner-img.negative-spacer .container {
      margin-top: 80px; }
      @media only screen and (min-width: 1200px) {
        .case-study-detail-wrapper .full-banner-img.negative-spacer .container {
          margin-top: -350px; } }
    .case-study-detail-wrapper .full-banner-img .author {
      font-size: 1.625rem;
      line-height: 32.5px;
      padding-top: 20px; }
    .case-study-detail-wrapper .full-banner-img .btns {
      height: 104px;
      width: 316.5px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      display: flex; }
      @media only screen and (max-width: 500px) {
        .case-study-detail-wrapper .full-banner-img .btns {
          height: 65px;
          width: 200px; } }
      .case-study-detail-wrapper .full-banner-img .btns a {
        width: 100%;
        height: 100%; }
      .case-study-detail-wrapper .full-banner-img .btns .btn-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        color: #FFFFFF;
        font-family: "Cairo", sans-serif;
        text-transform: uppercase;
        width: 100%;
        height: 100%;
        padding-left: 8px; }
        @media only screen and (max-width: 991px) {
          .case-study-detail-wrapper .full-banner-img .btns .btn-wrap {
            padding: 15px 0px; } }
        @media only screen and (max-width: 500px) {
          .case-study-detail-wrapper .full-banner-img .btns .btn-wrap {
            padding: 8px;
            justify-content: flex-start; } }
        .case-study-detail-wrapper .full-banner-img .btns .btn-wrap span {
          font-size: 1.375rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          font-weight: 700;
          margin-left: 16px; }
          @media only screen and (max-width: 1400px) {
            .case-study-detail-wrapper .full-banner-img .btns .btn-wrap span {
              font-size: 20px; } }
          @media only screen and (max-width: 1090px) {
            .case-study-detail-wrapper .full-banner-img .btns .btn-wrap span {
              font-size: 1rem;
              line-height: 1.5375;
              text-align: center;
              max-width: none; } }
          @media only screen and (max-width: 991px) {
            .case-study-detail-wrapper .full-banner-img .btns .btn-wrap span {
              max-width: 100%;
              font-size: 20px; } }
          @media only screen and (max-width: 767px) {
            .case-study-detail-wrapper .full-banner-img .btns .btn-wrap span {
              font-size: 16px; } }
          @media only screen and (max-width: 500px) {
            .case-study-detail-wrapper .full-banner-img .btns .btn-wrap span {
              margin-left: 6px;
              max-width: 100%;
              font-size: 12px; } }
      .case-study-detail-wrapper .full-banner-img .btns.download {
        background-color: #373433;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.3s ease-in;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        z-index: 2; }
        .case-study-detail-wrapper .full-banner-img .btns.download:after {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 0%;
          width: 100%;
          content: "";
          background-color: #007473;
          transition: all 0.3s ease-in;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          z-index: -1; }
        .case-study-detail-wrapper .full-banner-img .btns.download:hover a {
          text-decoration: none; }
        @media only screen and (min-width: 1200px) {
          .case-study-detail-wrapper .full-banner-img .btns.download:hover:after {
            height: 75%; } }
        .case-study-detail-wrapper .full-banner-img .btns.download .icomoon {
          font-size: 1.125rem; }
      .case-study-detail-wrapper .full-banner-img .btns li {
        list-style: none;
        position: relative;
        height: 70px;
        width: 70px;
        background-color: rgba(255, 255, 255, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%; }
        @media only screen and (max-width: 600px) {
          .case-study-detail-wrapper .full-banner-img .btns li {
            height: 40px;
            width: 40px; } }
        .case-study-detail-wrapper .full-banner-img .btns li .icomoon {
          font-size: 0.875rem;
          color: #FFFFFF; }
          @media only screen and (max-width: 600px) {
            .case-study-detail-wrapper .full-banner-img .btns li .icomoon {
              font-size: 0.75rem; } }
  .case-study-detail-wrapper .bottom-footer-banner .img-fluid {
    width: 100%; }
  .case-study-detail-wrapper .description-section {
    position: relative;
    top: 0px;
    padding-top: 80px;
    background-color: #FFFFFF; }
    @media only screen and (max-width: 767px) {
      .case-study-detail-wrapper .description-section {
        padding-top: 30px; } }
    .case-study-detail-wrapper .description-section.desc-bottom-spacer {
      padding-bottom: 410px; }
      @media only screen and (max-width: 1200px) {
        .case-study-detail-wrapper .description-section.desc-bottom-spacer {
          padding-bottom: 50px; } }
    @media only screen and (max-width: 991px) {
      .case-study-detail-wrapper .description-section .title {
        flex: 0 0 40%;
        max-width: 40%; } }
    @media only screen and (max-width: 767px) {
      .case-study-detail-wrapper .description-section .title {
        flex: 0 0 100%;
        max-width: 100%; } }
    .case-study-detail-wrapper .description-section .title p {
      font-family: "Cairo", sans-serif;
      font-size: 1.125rem;
      line-height: 1.3;
      letter-spacing: 0.5px;
      color: #373433;
      font-weight: 700;
      margin-bottom: 0px;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .case-study-detail-wrapper .description-section .title p {
          text-align: left;
          font-size: 18px; } }
    @media only screen and (max-width: 767px) {
      .case-study-detail-wrapper .description-section .title {
        margin-bottom: 30px; } }
    .case-study-detail-wrapper .description-section .desc {
      padding-right: 5%;
      padding-bottom: 11.6%; }
      @media only screen and (max-width: 991px) {
        .case-study-detail-wrapper .description-section .desc {
          padding-right: 15px;
          flex: 0 0 60%;
          max-width: 60%; } }
      @media only screen and (max-width: 767px) {
        .case-study-detail-wrapper .description-section .desc {
          flex: 0 0 100%;
          max-width: 100%; } }
      .case-study-detail-wrapper .description-section .desc h3 {
        font-family: "Cairo", sans-serif;
        font-size: 2.625rem;
        line-height: 1.2;
        color: #373433;
        font-weight: 400;
        margin-bottom: 30px; }
      .case-study-detail-wrapper .description-section .desc h4 {
        font-size: 2rem;
        line-height: 1.3;
        color: #373433;
        font-weight: 400;
        margin-bottom: 30px; }
      .case-study-detail-wrapper .description-section .desc p {
        font-family: "Cairo", sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 300;
        color: #373433;
        margin-bottom: 50px; }
        .case-study-detail-wrapper .description-section .desc p:last-child {
          margin-bottom: 0px; }
        @media only screen and (max-width: 767px) {
          .case-study-detail-wrapper .description-section .desc p {
            font-size: 18px; } }
        @media only screen and (max-width: 500px) {
          .case-study-detail-wrapper .description-section .desc p {
            margin-bottom: 20px; } }
      .case-study-detail-wrapper .description-section .desc li {
        font-family: "Cairo", sans-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 300;
        color: #373433;
        list-style: none; }
        @media only screen and (max-width: 767px) {
          .case-study-detail-wrapper .description-section .desc li {
            font-size: 18px; } }
        .case-study-detail-wrapper .description-section .desc li:before {
          content: "-";
          top: 0px;
          left: 0px;
          padding-right: 5px; }
      .case-study-detail-wrapper .description-section .desc li.list-heading:before {
        padding: 0px;
        content: ""; }
    .case-study-detail-wrapper .description-section .mid-desc {
      padding-bottom: 11.6%; }
      .case-study-detail-wrapper .description-section .mid-desc h3 {
        font-size: 2rem;
        line-height: 1.3; }
      .case-study-detail-wrapper .description-section .mid-desc h4 {
        line-height: 1.5; }
    .case-study-detail-wrapper .description-section .md-desc {
      padding-bottom: 6.6%; }
    .case-study-detail-wrapper .description-section.bg-green {
      background-color: #f2f8f7; }
    .case-study-detail-wrapper .description-section.z-2 {
      z-index: 2; }
  .case-study-detail-wrapper .content-wrapper {
    margin-top: 150px;
    margin-bottom: 350px;
    display: block; }
    @media only screen and (max-width: 767px) {
      .case-study-detail-wrapper .content-wrapper {
        margin-top: 80px;
        margin-bottom: 150px; } }
    .case-study-detail-wrapper .content-wrapper::before {
      content: "";
      position: absolute;
      left: 0;
      top: -21%;
      right: 0;
      width: 100%;
      height: 170%;
      z-index: -1;
      background-color: #FFFFFF; }
    .case-study-detail-wrapper .content-wrapper.banner-spacer-green {
      margin: 0;
      padding-top: 150px;
      padding-bottom: 350px;
      display: block;
      height: auto; }
      @media only screen and (max-width: 992px) {
        .case-study-detail-wrapper .content-wrapper.banner-spacer-green {
          padding-bottom: 150px; } }
      @media only screen and (max-width: 767px) {
        .case-study-detail-wrapper .content-wrapper.banner-spacer-green {
          padding-top: 80px; } }
      .case-study-detail-wrapper .content-wrapper.banner-spacer-green::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: -3px;
        height: 40%;
        z-index: -1;
        background-color: #f2f8f7; }
        @media only screen and (max-width: 992px) {
          .case-study-detail-wrapper .content-wrapper.banner-spacer-green::after {
            height: 100px; } }
        @media only screen and (max-width: 767px) {
          .case-study-detail-wrapper .content-wrapper.banner-spacer-green::after {
            height: 40px; } }
    .case-study-detail-wrapper .content-wrapper .vertical-bottom-img {
      position: relative;
      bottom: -425px; }
      @media only screen and (max-width: 992px) {
        .case-study-detail-wrapper .content-wrapper .vertical-bottom-img {
          bottom: 0; } }
    .case-study-detail-wrapper .content-wrapper .img-fluid {
      width: 100%; }
  .case-study-detail-wrapper .core-features-section .events-listing-grid {
    display: flex;
    width: 100%;
    padding-right: 0; }
    @media only screen and (max-width: 991px) {
      .case-study-detail-wrapper .core-features-section .events-listing-grid {
        flex-direction: column; } }
    .case-study-detail-wrapper .core-features-section .events-listing-grid h4 {
      font-size: 2rem;
      line-height: 38.4px;
      margin-bottom: 20px; }
    .case-study-detail-wrapper .core-features-section .events-listing-grid .two-col-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      column-gap: 100px;
      column-count: 2; }
      @media only screen and (max-width: 1090px) {
        .case-study-detail-wrapper .core-features-section .events-listing-grid .two-col-grid {
          column-gap: 50px; } }
      @media only screen and (max-width: 991px) {
        .case-study-detail-wrapper .core-features-section .events-listing-grid .two-col-grid {
          width: 100%;
          column-count: 2;
          column-gap: 20px; } }
      @media only screen and (max-width: 767px) {
        .case-study-detail-wrapper .core-features-section .events-listing-grid .two-col-grid {
          column-count: 1;
          column-gap: 0px;
          grid-template-columns: repeat(1, 1fr); } }
      .case-study-detail-wrapper .core-features-section .events-listing-grid .two-col-grid .event-card__small {
        width: 100%;
        padding-bottom: 80px;
        display: inline-block; }
        @media only screen and (max-width: 1090px) {
          .case-study-detail-wrapper .core-features-section .events-listing-grid .two-col-grid .event-card__small {
            padding-bottom: 40px; } }
        @media only screen and (max-width: 991px) {
          .case-study-detail-wrapper .core-features-section .events-listing-grid .two-col-grid .event-card__small {
            width: 100%; } }
        .case-study-detail-wrapper .core-features-section .events-listing-grid .two-col-grid .event-card__small .img-bg {
          margin-bottom: 20px; }
  .case-study-detail-wrapper .gallery-section {
    margin-top: 30px; }
    .case-study-detail-wrapper .gallery-section .bg-img {
      position: static;
      height: 85vh; }
      @media only screen and (max-width: 1300px) {
        .case-study-detail-wrapper .gallery-section .bg-img {
          height: 70vh; } }
      @media only screen and (max-width: 1024px) {
        .case-study-detail-wrapper .gallery-section .bg-img {
          height: 80vh; } }
      @media only screen and (max-width: 992px) {
        .case-study-detail-wrapper .gallery-section .bg-img {
          height: 50vh; } }
      .case-study-detail-wrapper .gallery-section .bg-img.slider-1, .case-study-detail-wrapper .gallery-section .bg-img.bg-contain {
        background-size: contain; }
        @media only screen and (max-width: 1600px) {
          .case-study-detail-wrapper .gallery-section .bg-img.slider-1, .case-study-detail-wrapper .gallery-section .bg-img.bg-contain {
            background-position: right; } }
    .case-study-detail-wrapper .gallery-section.banner-spacer-green::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: -25%;
      height: 50%;
      z-index: 0;
      background-color: #f2f8f7; }
    .case-study-detail-wrapper .gallery-section .numberSlides {
      font-size: 1.125rem;
      line-height: 21.6px;
      margin-bottom: 20px; }
      .case-study-detail-wrapper .gallery-section .numberSlides .swiper-pagination {
        position: relative;
        height: 25px;
        bottom: 0;
        text-align: left;
        font-size: 1.125rem;
        line-height: 21.6px;
        font-weight: bold; }
        .case-study-detail-wrapper .gallery-section .numberSlides .swiper-pagination span {
          font-weight: bold; }
    .case-study-detail-wrapper .gallery-section .swiper-container {
      cursor: none !important;
      overflow: visible; }
      @media only screen and (min-width: 1200px) {
        .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper {
          left: calc((100% - 102%)/2); } }
      @media only screen and (min-width: 1300px) {
        .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper {
          left: calc((100% - 94%)/2); } }
      @media only screen and (min-width: 1400px) {
        .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper {
          left: calc((100% - 90%)/2); } }
      @media only screen and (min-width: 1500px) {
        .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper {
          left: calc((100% - 120%)/2); } }
      @media only screen and (min-width: 1600px) {
        .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper {
          left: calc((100vh - 39%)/2); } }
      .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper .swiper-slide {
        position: relative;
        overflow: hidden; }
        .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper .swiper-slide img {
          display: block;
          height: 37.5vw;
          object-fit: cover;
          width: 100%;
          transition: all 0.4s ease;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          -ms-transition: all 0.4s ease;
          -o-transition: all 0.4s ease; }
          @media screen and (min-width: 1440px) and (max-height: 800px) {
            .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper .swiper-slide img {
              height: 35vw; } }
          @media only screen and (min-width: 1600px) {
            .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper .swiper-slide img {
              height: 35vw; } }
          @media only screen and (max-width: 991px) {
            .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper .swiper-slide img {
              height: 62vw; } }
          @media only screen and (max-width: 767px) {
            .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper .swiper-slide img {
              height: 375px; } }
        .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper .swiper-slide a {
          cursor: none; }
          .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper .swiper-slide a:hover .bg-events .img {
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            transform: scale(1.1); }
          .case-study-detail-wrapper .gallery-section .swiper-container .swiper-wrapper .swiper-slide a:hover .content p {
            color: #007473; }
  .case-study-detail-wrapper .jada-cursor {
    display: none;
    pointer-events: none;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    opacity: 1 !important; }
  .case-study-detail-wrapper .batch {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 1px solid #e6e6e6;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background-color: #FFFFFF;
    height: 92px;
    width: 92px; }
    .case-study-detail-wrapper .batch:after {
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease; }
    .case-study-detail-wrapper .batch::after {
      font-family: icomoon;
      font-style: normal;
      font-size: 0.875rem;
      opacity: 0.2;
      content: "\E907";
      position: absolute;
      color: #373433;
      top: 50%;
      left: 10%;
      transform: translate(0%, -50%);
      -webkit-transform: translate(0%, -50%);
      -moz-transform: translate(0%, -50%);
      -ms-transform: translate(0%, -50%);
      -o-transform: translate(0%, -50%); }
    .case-study-detail-wrapper .batch:before {
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease; }
    .case-study-detail-wrapper .batch:before {
      font-family: icomoon;
      font-style: normal;
      font-size: 0.875rem;
      opacity: 1;
      content: "\E908";
      position: absolute;
      color: #373433;
      top: 50%;
      right: 10%;
      transform: translate(0%, -50%);
      -webkit-transform: translate(0%, -50%);
      -moz-transform: translate(0%, -50%);
      -ms-transform: translate(0%, -50%);
      -o-transform: translate(0%, -50%); }
  .case-study-detail-wrapper .mouse-active {
    display: block; }
  .case-study-detail-wrapper .slide-icon {
    z-index: 2;
    position: absolute;
    top: 30px;
    left: 30px;
    height: 70px;
    width: 70px;
    background-color: #FFFFFF;
    border-radius: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in; }
    .case-study-detail-wrapper .slide-icon:after {
      font-family: icomoon;
      font-style: normal;
      font-size: 0.875rem;
      opacity: 1;
      content: "\E922";
      position: absolute;
      color: #007473;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%); }

body.arabic .case-study-detail-wrapper .event-info-container .info-box {
  padding: 25px 40px 25px 0; }

body.arabic .case-study-detail-wrapper .emp-stats .btns .social-links ul li:last-child {
  margin-right: 14px; }

body.arabic .case-study-detail-wrapper .testimonial h3 {
  border-right: 3px solid #007473;
  padding-right: 54px;
  padding-left: 0;
  border-left: none; }

body.arabic .case-study-detail-wrapper .testimonial .author {
  text-indent: -40px;
  padding-left: 0;
  padding-right: 100px; }
  body.arabic .case-study-detail-wrapper .testimonial .author span {
    margin-right: 15px;
    margin-left: 0; }

body.arabic .case-study-detail-wrapper .gallery-section .numberSlides {
  text-align: right; }
  body.arabic .case-study-detail-wrapper .gallery-section .numberSlides .swiper-pagination {
    text-align: right; }

body.arabic .case-study-detail-wrapper .social-share-box .emp-stats .btns .btn-wrap span {
  margin-left: 0;
  margin-right: 16px; }

body.arabic .case-study-detail-wrapper .full-banner-img .btns {
  left: 0px;
  right: auto; }
  body.arabic .case-study-detail-wrapper .full-banner-img .btns .btn-wrap span {
    margin-left: 0px;
    margin-right: 16px; }
    @media only screen and (max-width: 500px) {
      body.arabic .case-study-detail-wrapper .full-banner-img .btns .btn-wrap span {
        margin-right: 5px; } }

.publications-and-resources-wrapper {
  position: relative; }
  .publications-and-resources-wrapper .banner-bg {
    background-color: #373433; }
  .publications-and-resources-wrapper .cs-banner {
    min-height: 775px; }
    @media only screen and (min-width: 991px) {
      .publications-and-resources-wrapper .cs-banner {
        min-height: 100vh; } }
    .publications-and-resources-wrapper .cs-banner img {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%); }
      @media only screen and (max-width: 767px) {
        .publications-and-resources-wrapper .cs-banner img {
          display: none; } }
  .publications-and-resources-wrapper .banner-bg {
    background-size: cover;
    background-position-y: top !important;
    background-position-x: center !important; }
  .publications-and-resources-wrapper .jada-main-banner {
    position: relative;
    height: 80vh; }
    @media only screen and (max-width: 1090px) {
      .publications-and-resources-wrapper .jada-main-banner {
        height: 100vh; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .jada-main-banner {
        height: auto;
        min-height: 500px; } }
    .publications-and-resources-wrapper .jada-main-banner .content {
      height: auto;
      min-height: 500px;
      padding-top: 200px; }
    .publications-and-resources-wrapper .jada-main-banner:after {
      position: absolute;
      top: 0px;
      left: 0px;
      content: "";
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: -1; }
    .publications-and-resources-wrapper .jada-main-banner .breadcrumb-container {
      z-index: 2; }
    .publications-and-resources-wrapper .jada-main-banner h1 {
      z-index: 2;
      max-width: 1017px; }
      @media only screen and (max-width: 1090px) {
        .publications-and-resources-wrapper .jada-main-banner h1 {
          font-size: 4.5rem;
          line-height: 1.08333;
          max-width: 900px !important; } }
      @media only screen and (max-width: 991px) {
        .publications-and-resources-wrapper .jada-main-banner h1 {
          font-size: 3.375rem;
          line-height: 1.14815;
          max-width: 100%; } }
      @media only screen and (max-width: 767px) {
        .publications-and-resources-wrapper .jada-main-banner h1 {
          font-size: 50px !important;
          line-height: 57.5px !important; } }
      @media only screen and (max-width: 500px) {
        .publications-and-resources-wrapper .jada-main-banner h1 {
          font-size: 40px !important;
          line-height: 48px !important; } }
  .publications-and-resources-wrapper .bg-grey {
    background-color: #373433; }
  .publications-and-resources-wrapper .bg-green {
    background-color: #f2f8f7; }
  .publications-and-resources-wrapper section {
    margin-top: -2px;
    margin-bottom: -2px; }
    @media only screen and (max-width: 991px) {
      .publications-and-resources-wrapper section {
        margin-top: 0px;
        margin-bottom: 0px; } }
  .publications-and-resources-wrapper .bg-img {
    z-index: -1;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 100vh;
    background-position: top center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    left: 0; }
  @media only screen and (min-width: 1200px) {
    .publications-and-resources-wrapper .detail-page-banner .content {
      padding-bottom: 50px; } }
  @media only screen and (min-width: 1600px) {
    .publications-and-resources-wrapper .detail-page-banner .content {
      padding-bottom: 87px; } }
  .publications-and-resources-wrapper .full-banner-block {
    position: relative;
    height: 55vh;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2; }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .full-banner-block {
        display: flex; } }
    @media only screen and (max-width: 991px) {
      .publications-and-resources-wrapper .full-banner-block .content {
        padding-right: 40px;
        padding-left: 40px; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .full-banner-block .content {
        padding-right: 20px;
        padding-left: 20px; } }
    .publications-and-resources-wrapper .full-banner-block .bg-img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center !important;
      background-size: cover; }
    @media only screen and (max-width: 1090px) {
      .publications-and-resources-wrapper .full-banner-block.bg-grey .img-fluid {
        width: 100px; } }
    .publications-and-resources-wrapper .full-banner-block.banner-spacer-green {
      padding-bottom: 110px;
      height: auto; }
      @media only screen and (max-width: 767px) {
        .publications-and-resources-wrapper .full-banner-block.banner-spacer-green {
          padding-bottom: 30px;
          height: auto; } }
    .publications-and-resources-wrapper .full-banner-block.negative-spacer .container {
      margin-top: 80px; }
      @media only screen and (min-width: 1200px) {
        .publications-and-resources-wrapper .full-banner-block.negative-spacer .container {
          margin-top: -350px; } }
    .publications-and-resources-wrapper .full-banner-block .author {
      font-size: 1.625rem;
      line-height: 32.5px;
      padding-top: 20px; }
    .publications-and-resources-wrapper .full-banner-block .btns {
      height: 104px;
      width: 316.5px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      display: flex; }
      @media only screen and (max-width: 500px) {
        .publications-and-resources-wrapper .full-banner-block .btns {
          height: 65px;
          width: 200px; } }
      .publications-and-resources-wrapper .full-banner-block .btns a {
        width: 100%;
        height: 100%; }
      .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        color: #FFFFFF;
        font-family: "Cairo", sans-serif;
        text-transform: uppercase;
        width: 100%;
        height: 100%;
        padding-left: 8px; }
        @media only screen and (max-width: 991px) {
          .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap {
            padding: 15px 0px; } }
        @media only screen and (max-width: 500px) {
          .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap {
            padding: 8px;
            justify-content: flex-start; } }
        .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap span {
          font-size: 1.375rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          font-weight: 700;
          margin-left: 16px; }
          @media only screen and (max-width: 1400px) {
            .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap span {
              font-size: 20px; } }
          @media only screen and (max-width: 1090px) {
            .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap span {
              font-size: 1rem;
              line-height: 1.5375;
              text-align: center;
              max-width: none; } }
          @media only screen and (max-width: 991px) {
            .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap span {
              max-width: 100%;
              font-size: 20px; } }
          @media only screen and (max-width: 767px) {
            .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap span {
              font-size: 16px; } }
          @media only screen and (max-width: 500px) {
            .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap span {
              margin-left: 6px;
              max-width: 100%;
              font-size: 12px; } }
      .publications-and-resources-wrapper .full-banner-block .btns.download {
        background-color: #373433;
        height: 100%;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.3s ease-in;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        z-index: 2; }
        .publications-and-resources-wrapper .full-banner-block .btns.download:after {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 0%;
          width: 100%;
          content: "";
          background-color: #007473;
          transition: all 0.3s ease-in;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          z-index: -1; }
        .publications-and-resources-wrapper .full-banner-block .btns.download:hover a {
          text-decoration: none; }
        @media only screen and (min-width: 1200px) {
          .publications-and-resources-wrapper .full-banner-block .btns.download:hover:after {
            height: 100%; } }
        .publications-and-resources-wrapper .full-banner-block .btns.download .icomoon {
          font-size: 1.125rem; }
      .publications-and-resources-wrapper .full-banner-block .btns li {
        list-style: none;
        position: relative;
        height: 70px;
        width: 70px;
        background-color: rgba(255, 255, 255, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%; }
        @media only screen and (max-width: 600px) {
          .publications-and-resources-wrapper .full-banner-block .btns li {
            height: 40px;
            width: 40px; } }
        .publications-and-resources-wrapper .full-banner-block .btns li .icomoon {
          font-size: 0.875rem;
          color: #FFFFFF; }
          @media only screen and (max-width: 600px) {
            .publications-and-resources-wrapper .full-banner-block .btns li .icomoon {
              font-size: 0.75rem; } }
  .publications-and-resources-wrapper .emp-stats.cs-stats .btns {
    height: 104px;
    width: 316.5px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: flex; }
    @media only screen and (max-width: 500px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .btns {
        height: 65px;
        width: 200px; } }
    .publications-and-resources-wrapper .emp-stats.cs-stats .btns a {
      width: 100%;
      height: 100%; }
    .publications-and-resources-wrapper .emp-stats.cs-stats .btns .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      color: #FFFFFF;
      font-family: "Cairo", sans-serif;
      text-transform: uppercase;
      width: 100%;
      height: 100%;
      padding-left: 8px; }
      @media only screen and (max-width: 991px) {
        .publications-and-resources-wrapper .emp-stats.cs-stats .btns .btn-wrap {
          padding: 15px 0px; } }
      @media only screen and (max-width: 500px) {
        .publications-and-resources-wrapper .emp-stats.cs-stats .btns .btn-wrap {
          padding: 8px;
          justify-content: flex-start; } }
      .publications-and-resources-wrapper .emp-stats.cs-stats .btns .btn-wrap span {
        font-size: 1.375rem;
        line-height: 1.3;
        letter-spacing: 0.5px;
        font-weight: 700;
        margin-left: 16px; }
        @media only screen and (max-width: 1400px) {
          .publications-and-resources-wrapper .emp-stats.cs-stats .btns .btn-wrap span {
            font-size: 20px; } }
        @media only screen and (max-width: 1090px) {
          .publications-and-resources-wrapper .emp-stats.cs-stats .btns .btn-wrap span {
            font-size: 1rem;
            line-height: 1.5375;
            text-align: center;
            max-width: none; } }
        @media only screen and (max-width: 991px) {
          .publications-and-resources-wrapper .emp-stats.cs-stats .btns .btn-wrap span {
            max-width: 100%;
            font-size: 20px; } }
        @media only screen and (max-width: 767px) {
          .publications-and-resources-wrapper .emp-stats.cs-stats .btns .btn-wrap span {
            font-size: 16px; } }
        @media only screen and (max-width: 500px) {
          .publications-and-resources-wrapper .emp-stats.cs-stats .btns .btn-wrap span {
            margin-left: 6px;
            max-width: 100%;
            font-size: 12px; } }
    .publications-and-resources-wrapper .emp-stats.cs-stats .btns.download {
      background-color: #373433;
      height: 100%;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in;
      z-index: 2; }
      .publications-and-resources-wrapper .emp-stats.cs-stats .btns.download:after {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 0%;
        width: 100%;
        content: "";
        background-color: #007473;
        transition: all 0.3s ease-in;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        z-index: -1; }
      .publications-and-resources-wrapper .emp-stats.cs-stats .btns.download:hover a {
        text-decoration: none; }
      @media only screen and (min-width: 1200px) {
        .publications-and-resources-wrapper .emp-stats.cs-stats .btns.download:hover:after {
          height: 100%; } }
      .publications-and-resources-wrapper .emp-stats.cs-stats .btns.download .icomoon {
        font-size: 1.125rem; }
    .publications-and-resources-wrapper .emp-stats.cs-stats .btns li {
      list-style: none;
      position: relative;
      height: 70px;
      width: 70px;
      background-color: rgba(255, 255, 255, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%; }
      @media only screen and (max-width: 600px) {
        .publications-and-resources-wrapper .emp-stats.cs-stats .btns li {
          height: 40px;
          width: 40px; } }
      .publications-and-resources-wrapper .emp-stats.cs-stats .btns li .icomoon {
        font-size: 0.875rem;
        color: #FFFFFF; }
        @media only screen and (max-width: 600px) {
          .publications-and-resources-wrapper .emp-stats.cs-stats .btns li .icomoon {
            font-size: 0.75rem; } }
  @media only screen and (max-width: 1090px) {
    .publications-and-resources-wrapper .emp-stats.cs-stats .info-box {
      padding-top: 10px;
      padding-bottom: 10px; } }
  @media only screen and (max-width: 991px) {
    .publications-and-resources-wrapper .emp-stats.cs-stats .info-box {
      width: 50%; } }
  @media only screen and (max-width: 767px) {
    .publications-and-resources-wrapper .emp-stats.cs-stats .info-box {
      padding-top: 10px !important;
      padding-bottom: 10px !important; } }
  .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large {
    width: calc(62.66% - 344px) !important; }
    @media only screen and (max-width: 991px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large {
        width: 50% !important; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large {
        width: 100% !important; } }
    @media only screen and (max-width: 1090px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .btn-wrap {
        flex-direction: column; }
        .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .btn-wrap span {
          margin-bottom: 10px;
          margin-right: 0px; } }
    @media only screen and (max-width: 991px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .btn-wrap {
        padding: 0px !important; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .btn-wrap {
        align-items: flex-start !important; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .btn-wrap span {
        margin-left: 0px !important; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .btn-wrap .social-links {
        margin-left: 0px; } }
  .publications-and-resources-wrapper .emp-stats.cs-stats .download {
    width: 344px;
    position: relative !important; }
    @media only screen and (max-width: 991px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .download {
        height: 117px !important;
        width: 50%; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .download {
        width: 100% !important;
        height: auto !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .emp-stats.cs-stats .download .btn-wrap {
        justify-content: center !important; } }
  .publications-and-resources-wrapper .emp-stats {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    background-color: #F8FBFB;
    margin: -1px 0px; }
    .publications-and-resources-wrapper .emp-stats .event-info-container {
      width: 100%;
      display: flex; }
      @media only screen and (max-width: 1090px) {
        .publications-and-resources-wrapper .emp-stats .event-info-container {
          flex-wrap: wrap; } }
      .publications-and-resources-wrapper .emp-stats .event-info-container .info-box {
        width: 18.667%;
        box-sizing: border-box;
        background-color: #FFFFFF;
        padding: 25px 20px 25px 40px;
        border-top: 1px solid rgba(55, 52, 51, 0.15);
        border-bottom: 1px solid rgba(55, 52, 51, 0.15);
        border-right: 1px solid rgba(55, 52, 51, 0.15);
        border-bottom: 2px solid rgba(55, 52, 51, 0.15); }
        @media only screen and (max-width: 1090px) {
          .publications-and-resources-wrapper .emp-stats .event-info-container .info-box {
            padding: 20px; } }
        @media only screen and (max-width: 991px) {
          .publications-and-resources-wrapper .emp-stats .event-info-container .info-box {
            width: 50%;
            border-bottom: 1px solid rgba(55, 52, 51, 0.15); } }
        @media only screen and (max-width: 767px) {
          .publications-and-resources-wrapper .emp-stats .event-info-container .info-box {
            width: 100%;
            padding: 20px 20px 20px 20px; } }
        .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large {
          width: 44%;
          background-color: #F8FBFB; }
          .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss {
            width: 100%;
            height: 100%;
            display: flex; }
            .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss a {
              width: 100%;
              height: 100%; }
            .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .btn-wrap {
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 2;
              color: #373433;
              font-family: "Cairo", sans-serif;
              text-transform: uppercase;
              width: 100%;
              height: 100%;
              padding-left: 8px; }
              @media only screen and (max-width: 991px) {
                .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .btn-wrap {
                  padding: 15px 0px;
                  justify-content: space-between; } }
              @media only screen and (max-width: 500px) {
                .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .btn-wrap {
                  padding: 0px;
                  flex-direction: column;
                  align-items: flex-start; } }
              .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .btn-wrap span {
                font-size: 1.375rem;
                line-height: 1.3;
                letter-spacing: 0.5px;
                font-weight: 700;
                margin-left: 16px; }
                @media only screen and (max-width: 1400px) {
                  .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .btn-wrap span {
                    font-size: 20px; } }
                @media only screen and (max-width: 991px) {
                  .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .btn-wrap span {
                    font-size: 18px; } }
                @media only screen and (max-width: 500px) {
                  .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .btn-wrap span {
                    margin-left: 0px;
                    margin-bottom: 10px; } }
            .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links {
              display: flex;
              flex-direction: column;
              margin-left: 40px; }
              @media only screen and (max-width: 1200px) {
                .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links {
                  margin-left: 20px;
                  margin-right: 15px; } }
              @media only screen and (max-width: 500px) {
                .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links {
                  width: 100%;
                  margin: 0px !important; } }
              .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links ul {
                list-style: none;
                margin-bottom: 0;
                display: flex; }
                .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links ul li {
                  position: relative;
                  list-style: none;
                  height: 42px;
                  width: 42px;
                  border-radius: 3px;
                  -webkit-border-radius: 3px;
                  -moz-border-radius: 3px;
                  -ms-border-radius: 3px;
                  -o-border-radius: 3px;
                  background-color: #007473;
                  margin-right: 14px;
                  -webkit-transition: 0.3s all ease-in;
                  -moz-transition: 0.3s all ease-in;
                  -ms-transition: 0.3s all ease-in;
                  -o-transition: 0.3s all ease-in;
                  transition: 0.3s all ease-in;
                  transition: 0.3s background-color ease-in; }
                  @media only screen and (max-width: 767px) {
                    .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links ul li {
                      height: 36px;
                      width: 36px; } }
                  .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links ul li:hover {
                    background-color: transparent;
                    border: 1px solid #007473; }
                    .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links ul li:hover .icomoon:before {
                      color: #007473; }
                  .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links ul li:last-child {
                    margin-right: 0; }
                  .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links ul li a {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0; }
                    .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links ul li a .icomoon {
                      position: absolute;
                      font-size: 1.25rem;
                      line-height: 1.25rem;
                      text-align: center;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      -webkit-transform: translate(-50%, -50%);
                      -moz-transform: translate(-50%, -50%);
                      -ms-transform: translate(-50%, -50%);
                      -o-transform: translate(-50%, -50%); }
                      .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links ul li a .icomoon:before {
                        color: #FFFFFF;
                        -webkit-transition: 0.3s all ease-in;
                        -moz-transition: 0.3s all ease-in;
                        -ms-transition: 0.3s all ease-in;
                        -o-transition: 0.3s all ease-in;
                        transition: 0.3s all ease-in; }
              .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btnsss .social-links label {
                text-transform: uppercase;
                font-family: "Cairo", sans-serif;
                font-size: 1rem;
                line-height: 1.3;
                letter-spacing: 0.5px;
                color: #FFFFFF;
                opacity: 0.5;
                margin-bottom: 16px;
                font-weight: 700; }
          @media only screen and (max-width: 991px) {
            .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large {
              width: 50%; } }
          @media only screen and (max-width: 767px) {
            .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large {
              width: 100%; } }
          .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btn-wrap {
            padding: 0px; }
            .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btn-wrap span {
              margin: 0px; }
            @media only screen and (max-width: 767px) {
              .publications-and-resources-wrapper .emp-stats .event-info-container .info-box.large .btn-wrap .social-links {
                margin-right: 0px;
                margin-left: 5px;
                width: 40%; } }
        .publications-and-resources-wrapper .emp-stats .event-info-container .info-box:last-child {
          border-right: none; }
        .publications-and-resources-wrapper .emp-stats .event-info-container .info-box p {
          font-family: "Cairo", sans-serif;
          font-size: 0.875rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          margin: 0;
          text-transform: uppercase; }
          @media only screen and (max-width: 767px) {
            .publications-and-resources-wrapper .emp-stats .event-info-container .info-box p {
              font-size: 14px; } }
        .publications-and-resources-wrapper .emp-stats .event-info-container .info-box h6 {
          font-family: "Cairo", sans-serif;
          font-size: 1.25rem;
          line-height: 1.3;
          letter-spacing: 0.5px;
          color: #373433;
          text-transform: uppercase;
          font-weight: 700;
          margin-top: 10px; }
  .publications-and-resources-wrapper .banner-img {
    position: relative;
    height: 0;
    padding-top: calc(910 / 1440 * 100%);
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 1090px) {
      .publications-and-resources-wrapper .banner-img {
        height: 80vh; } }
    @media only screen and (max-width: 1090px) {
      .publications-and-resources-wrapper .banner-img.bg-grey .img-fluid {
        width: 200px; } }
    .publications-and-resources-wrapper .banner-img .content {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center; }
      .publications-and-resources-wrapper .banner-img .content p {
        opacity: 0; }
    .publications-and-resources-wrapper .banner-img .bg-img {
      background-size: cover;
      width: 100%;
      background-repeat: no-repeat;
      height: 100%;
      background-position: center top !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      left: 0; }
      @media only screen and (max-width: 1090px) {
        .publications-and-resources-wrapper .banner-img .bg-img {
          display: block; } }
  .publications-and-resources-wrapper .full-banner-img {
    position: relative;
    height: 718px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 1090px) {
      .publications-and-resources-wrapper .full-banner-img {
        height: 80vh; } }
    .publications-and-resources-wrapper .full-banner-img .bg-img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center top !important;
      background-size: cover; }
    @media only screen and (max-width: 1090px) {
      .publications-and-resources-wrapper .full-banner-img.bg-grey .img-fluid {
        width: 100px; } }
    .publications-and-resources-wrapper .full-banner-img.banner-spacer-green {
      padding-bottom: 110px; }
      @media only screen and (max-width: 767px) {
        .publications-and-resources-wrapper .full-banner-img.banner-spacer-green {
          padding-bottom: 30px;
          height: auto; } }
      .publications-and-resources-wrapper .full-banner-img.banner-spacer-green::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        height: 0;
        z-index: -1;
        background-color: #f2f8f7; }
    .publications-and-resources-wrapper .full-banner-img .author {
      font-size: 1.625rem;
      line-height: 32.5px;
      padding-top: 20px; }
  .publications-and-resources-wrapper .description-section {
    position: relative;
    top: 0px;
    padding-top: 80px;
    background-color: #FFFFFF; }
    @media only screen and (max-width: 992px) {
      .publications-and-resources-wrapper .description-section {
        padding-top: 80px;
        padding-bottom: 80px; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .description-section {
        padding-top: 30px;
        padding-bottom: 30px; } }
    .publications-and-resources-wrapper .description-section.desc-bottom-spacer {
      padding-bottom: 100px; }
      @media only screen and (max-width: 767px) {
        .publications-and-resources-wrapper .description-section.desc-bottom-spacer {
          padding-bottom: 60px; } }
    @media only screen and (max-width: 991px) {
      .publications-and-resources-wrapper .description-section .title {
        flex: 0 0 40%;
        max-width: 40%; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .description-section .title {
        flex: 0 0 100%;
        max-width: 100%; } }
    .publications-and-resources-wrapper .description-section .title p {
      font-family: "Cairo", sans-serif;
      font-size: 1.125rem;
      line-height: 1.3;
      letter-spacing: 0.5px;
      color: #373433;
      font-weight: 700;
      margin-bottom: 0px;
      text-transform: uppercase; }
      @media only screen and (max-width: 767px) {
        .publications-and-resources-wrapper .description-section .title p {
          text-align: left;
          font-size: 18px; } }
    @media only screen and (max-width: 767px) {
      .publications-and-resources-wrapper .description-section .title {
        margin-bottom: 30px; } }
    .publications-and-resources-wrapper .description-section .desc {
      padding-right: 15px;
      padding-bottom: 0px; }
      .publications-and-resources-wrapper .description-section .desc h3 {
        font-family: "Cairo", sans-serif;
        font-size: 1.5625rem;
        line-height: 1.36;
        color: #373433;
        font-weight: 400;
        margin-bottom: 30px; }
      .publications-and-resources-wrapper .description-section .desc h4 {
        font-size: 1.5625rem;
        line-height: 1.36;
        color: #373433;
        font-weight: 400;
        margin-bottom: 30px; }
      .publications-and-resources-wrapper .description-section .desc p {
        font-family: "Cairo", sans-serif;
        font-size: 1.5625rem;
        line-height: 1.36;
        font-weight: 400;
        color: #373433;
        margin-bottom: 50px; }
        .publications-and-resources-wrapper .description-section .desc p:last-child {
          margin-bottom: 0px; }
        @media only screen and (max-width: 767px) {
          .publications-and-resources-wrapper .description-section .desc p {
            font-size: 18px; } }
        @media only screen and (max-width: 500px) {
          .publications-and-resources-wrapper .description-section .desc p {
            margin-bottom: 20px; } }
      .publications-and-resources-wrapper .description-section .desc li {
        font-family: "Cairo", sans-serif;
        font-size: 1.5625rem;
        line-height: 1.36;
        font-weight: 400;
        color: #373433;
        list-style: none; }
        @media only screen and (max-width: 767px) {
          .publications-and-resources-wrapper .description-section .desc li {
            font-size: 18px; } }
        .publications-and-resources-wrapper .description-section .desc li:before {
          content: "-";
          top: 0px;
          left: 0px;
          padding-right: 5px; }
      .publications-and-resources-wrapper .description-section .desc li.list-heading:before {
        padding: 0px;
        content: ""; }
    .publications-and-resources-wrapper .description-section .mid-desc {
      padding-bottom: 11.6%; }
      .publications-and-resources-wrapper .description-section .mid-desc h3 {
        font-size: 2rem;
        line-height: 1.3; }
      .publications-and-resources-wrapper .description-section .mid-desc h4 {
        line-height: 1.5; }
    .publications-and-resources-wrapper .description-section .md-desc {
      padding-bottom: 6.6%; }
    .publications-and-resources-wrapper .description-section.bg-green {
      background-color: #f2f8f7; }
    .publications-and-resources-wrapper .description-section.z-2 {
      z-index: 2; }
  .publications-and-resources-wrapper .content-wrapper {
    margin: 0;
    padding-top: 100px;
    padding-bottom: 430px;
    display: block;
    height: auto; }
    @media only screen and (max-width: 992px) {
      .publications-and-resources-wrapper .content-wrapper {
        padding-top: 80px;
        padding-bottom: 150px; } }
    .publications-and-resources-wrapper .content-wrapper.banner-spacer-white::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: -3px;
      height: 35%;
      z-index: -1;
      background-color: #FFFFFF; }
      @media only screen and (max-width: 992px) {
        .publications-and-resources-wrapper .content-wrapper.banner-spacer-white::after {
          height: 100px; } }
      @media only screen and (max-width: 767px) {
        .publications-and-resources-wrapper .content-wrapper.banner-spacer-white::after {
          height: 40px; } }
    .publications-and-resources-wrapper .content-wrapper .vertical-bottom-img {
      position: relative;
      top: 500px; }
      @media only screen and (min-width: 1600px) {
        .publications-and-resources-wrapper .content-wrapper .vertical-bottom-img {
          top: 600px; } }
      @media only screen and (max-width: 1200px) {
        .publications-and-resources-wrapper .content-wrapper .vertical-bottom-img {
          top: 365px; } }
      @media only screen and (max-width: 992px) {
        .publications-and-resources-wrapper .content-wrapper .vertical-bottom-img {
          top: 50px; } }
      @media only screen and (max-width: 767px) {
        .publications-and-resources-wrapper .content-wrapper .vertical-bottom-img img {
          width: 100%; } }
    .publications-and-resources-wrapper .content-wrapper .vertical-top-img {
      position: relative;
      top: -150px; }
      @media only screen and (max-width: 992px) {
        .publications-and-resources-wrapper .content-wrapper .vertical-top-img {
          top: 0;
          padding-bottom: 50px; } }
      .publications-and-resources-wrapper .content-wrapper .vertical-top-img .img-fluid {
        height: 640px;
        object-fit: cover; }
    .publications-and-resources-wrapper .content-wrapper .img-fluid {
      width: 100%; }
  .publications-and-resources-wrapper .about-jada-section {
    padding-bottom: 120px; }
    @media only screen and (max-width: 1200px) {
      .publications-and-resources-wrapper .about-jada-section {
        padding-bottom: 0px;
        margin-bottom: 80px; } }
    @media only screen and (max-width: 992px) {
      .publications-and-resources-wrapper .about-jada-section {
        padding-bottom: 80px; } }
  @media only screen and (min-width: 992px) {
    .publications-and-resources-wrapper .testimonial {
      padding-bottom: 100px; } }
  @media only screen and (max-width: 767px) {
    .publications-and-resources-wrapper .testimonial {
      margin-top: 60px; } }
  .publications-and-resources-wrapper .testimonial h3 {
    padding-bottom: 35px; }
  @media only screen and (min-width: 1200px) {
    .publications-and-resources-wrapper .bottom-footer-banner:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 37%;
      background-color: #f2f8f7; } }

body.arabic .publications-and-resources-wrapper .emp-stats.cs-stats .btns.download span {
  margin-left: 0px;
  margin-right: 16px !important; }
  @media only screen and (max-width: 500px) {
    body.arabic .publications-and-resources-wrapper .emp-stats.cs-stats .btns.download span {
      margin-right: 10px !important; } }

body.arabic .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss span {
  margin-left: 0px !important; }

body.arabic .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .btn-wrap {
  padding-left: 0px !important; }

body.arabic .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .social-links {
  margin-left: 0px;
  margin-right: 40px; }
  @media only screen and (max-width: 1090px) {
    body.arabic .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .social-links {
      margin-right: 0px; } }
  body.arabic .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .social-links ul li {
    margin-right: 0px;
    margin-left: 14px; }
    body.arabic .publications-and-resources-wrapper .emp-stats.cs-stats .info-box.large .btnsss .social-links ul li:last-child {
      margin-left: 0px; }

body.arabic .publications-and-resources-wrapper .event-info-container .info-box {
  padding: 25px 40px 25px 0;
  border-right: 0px;
  border-left: 1px solid rgba(55, 52, 51, 0.15); }
  @media only screen and (max-width: 1090px) {
    body.arabic .publications-and-resources-wrapper .event-info-container .info-box .btnsss .btn-wrap {
      justify-content: space-between; } }
  body.arabic .publications-and-resources-wrapper .event-info-container .info-box .btnsss .btn-wrap span {
    text-align: right; }
    @media only screen and (max-width: 991px) {
      body.arabic .publications-and-resources-wrapper .event-info-container .info-box .btnsss .btn-wrap span {
        margin-left: 0px !important; } }
  body.arabic .publications-and-resources-wrapper .event-info-container .info-box .btnsss .btn-wrap .social-links {
    margin-left: 0px;
    margin-right: 39px; }
    body.arabic .publications-and-resources-wrapper .event-info-container .info-box .btnsss .btn-wrap .social-links ul li {
      margin-right: 0px;
      margin-left: 14px; }
      body.arabic .publications-and-resources-wrapper .event-info-container .info-box .btnsss .btn-wrap .social-links ul li:last-child {
        margin-left: 0px; }
    @media only screen and (max-width: 1090px) {
      body.arabic .publications-and-resources-wrapper .event-info-container .info-box .btnsss .btn-wrap .social-links {
        margin-right: 20px; } }
  body.arabic .publications-and-resources-wrapper .event-info-container .info-box:last-child {
    border-left: 0px; }
  @media only screen and (max-width: 1090px) {
    body.arabic .publications-and-resources-wrapper .event-info-container .info-box {
      padding: 20px; } }

body.arabic .publications-and-resources-wrapper .full-banner-block .btns {
  left: 0px;
  right: auto; }
  body.arabic .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap span {
    margin-left: 0px;
    margin-right: 16px; }
    @media only screen and (max-width: 500px) {
      body.arabic .publications-and-resources-wrapper .full-banner-block .btns .btn-wrap span {
        margin-right: 5px; } }

body.arabic .publications-and-resources-wrapper .emp-stats .btns .social-links ul li:last-child {
  margin-right: 14px; }

body.arabic .publications-and-resources-wrapper .testimonial h3 {
  border-right: 3px solid #007473;
  padding-right: 54px;
  padding-left: 0;
  border-left: none; }

body.arabic .publications-and-resources-wrapper .testimonial .author {
  text-indent: -40px;
  padding-left: 0;
  padding-right: 100px; }
  body.arabic .publications-and-resources-wrapper .testimonial .author span {
    margin-right: 15px;
    margin-left: 0; }

body.arabic .publications-and-resources-wrapper .social-share-box .emp-stats .btns .btn-wrap span {
  margin-left: 0;
  margin-right: 16px; }

.publications-and-resources-wrapper .description-section.desc-bottom-spacer p {
  font-family: "Cairo", sans-serif;
  font-size: 1.5625rem;
  line-height: 1.36;
  font-weight: 400;
  color: #373433;
  margin-bottom: 20px; }

.publications-and-resources-wrapper .description-section.desc-bottom-spacer h4,
.publications-and-resources-wrapper .description-section.desc-bottom-spacer h3 {
  font-family: "Cairo", sans-serif;
  font-size: 2rem;
  line-height: 1.3;
  color: #373433;
  font-weight: 600;
  margin-bottom: 30px; }

.publications-and-resources-wrapper .description-section.desc-bottom-spacer a {
  color: #007473; }

.chairman .banner-img .bg-img {
  display: block !important; }

.chairman .description-section {
  margin-top: 0px !important; }

.chairman .about-jada-section {
  padding-bottom: 0px; }
  .chairman .about-jada-section .bcg-green {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background-color: #f2f8f7; }
    @media only screen and (max-width: 991px) {
      .chairman .about-jada-section .bcg-green {
        height: 80%; } }
    @media only screen and (max-width: 767px) {
      .chairman .about-jada-section .bcg-green {
        height: 85%; } }
  .chairman .about-jada-section .our-path {
    padding-top: 100px; }
    .chairman .about-jada-section .our-path .desc {
      padding-bottom: 60px; }
    @media only screen and (max-width: 767px) {
      .chairman .about-jada-section .our-path {
        padding-top: 60px; } }

.chairman .bottom-footer-banner::before {
  display: none; }

@media only screen and (max-width: 991px) {
  .bottom-footer-banner .social-share-box {
    width: 80%; } }

@media only screen and (max-width: 500px) {
  .bottom-footer-banner .social-share-box {
    width: 100%; } }

@media only screen and (max-width: 991px) {
  .bottom-footer-banner .social-share-box .emp-stats .info-box {
    width: 100%; } }

@media only screen and (max-width: 991px) {
  .bottom-footer-banner .social-share-box .emp-stats .info-box .btn-wrap {
    justify-content: space-between; } }

@media only screen and (max-width: 500px) {
  .bottom-footer-banner .social-share-box .emp-stats .info-box .btn-wrap {
    flex-direction: column; } }

.bottom-footer-banner .social-share-box .emp-stats .info-box .btn-wrap span {
  margin-left: 0px; }

@media only screen and (max-width: 500px) {
  .bottom-footer-banner .social-share-box .emp-stats .info-box .btn-wrap .social-links {
    width: 100%;
    margin-left: 0px; } }

@media only screen and (max-width: 500px) {
  .bottom-footer-banner .social-share-box .emp-stats .info-box .btn-wrap .social-links ul {
    justify-content: center;
    margin-top: 20px; } }

@media only screen and (max-height: 700px) {
  .careers-wrapper .jada-main-banner {
    height: 60vh;
    min-height: 600px; } }

.careers-wrapper .jada-main-banner:after {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.careers-wrapper .description-section {
  position: relative;
  top: 0px;
  padding-top: 100px;
  padding-bottom: 80px;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .careers-wrapper .description-section {
      padding-top: 30px; } }
  @media only screen and (max-width: 991px) {
    .careers-wrapper .description-section .title {
      flex: 0 0 40%;
      max-width: 40%; } }
  @media only screen and (max-width: 767px) {
    .careers-wrapper .description-section .title {
      flex: 0 0 100%;
      max-width: 100%; } }
  .careers-wrapper .description-section .title h2 {
    font-family: "Cairo", sans-serif;
    color: #373433;
    font-weight: 400;
    margin-bottom: 30px; }
    @media only screen and (min-width: 991px) {
      .careers-wrapper .description-section .title h2 {
        font-size: 4rem;
        line-height: 0.7875; } }
  @media only screen and (max-width: 767px) {
    .careers-wrapper .description-section .title {
      margin-bottom: 30px; } }
  .careers-wrapper .description-section .desc {
    padding-bottom: 50px; }
    @media only screen and (max-width: 991px) {
      .careers-wrapper .description-section .desc {
        padding-right: 15px;
        flex: 0 0 60%;
        max-width: 60%; } }
    @media only screen and (max-width: 767px) {
      .careers-wrapper .description-section .desc {
        flex: 0 0 100%;
        max-width: 100%; } }
    .careers-wrapper .description-section .desc h3 {
      font-family: "Cairo", sans-serif;
      font-size: 2.625rem;
      line-height: 1.2;
      color: #373433;
      font-weight: 400;
      margin-bottom: 30px; }
    .careers-wrapper .description-section .desc h4 {
      font-size: 2rem;
      line-height: 1.3;
      color: #373433;
      font-weight: 400;
      margin-bottom: 30px; }
    .careers-wrapper .description-section .desc p {
      font-family: "Cairo", sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      font-weight: 300;
      color: #373433;
      margin-bottom: 50px; }
      .careers-wrapper .description-section .desc p:last-child {
        margin-bottom: 0px; }
      @media only screen and (max-width: 767px) {
        .careers-wrapper .description-section .desc p {
          font-size: 1.125rem; } }
      @media only screen and (max-width: 500px) {
        .careers-wrapper .description-section .desc p {
          margin-bottom: 20px; } }

.careers-wrapper .apply-jada-section {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-color: #373433;
  padding-top: 100px;
  padding-bottom: 100px;
  margin: -2px 0px; }
  @media only screen and (max-width: 767px) {
    .careers-wrapper .apply-jada-section {
      padding-top: 60px;
      padding-bottom: 60px; } }
  .careers-wrapper .apply-jada-section h1 {
    font-size: 3.75rem;
    margin-bottom: 15px;
    z-index: 3; }
    @media only screen and (max-width: 1024px) {
      .careers-wrapper .apply-jada-section h1 {
        text-align: center; } }
    @media only screen and (max-width: 700px) {
      .careers-wrapper .apply-jada-section h1 {
        font-size: 2.5rem !important;
        line-height: 40px !important; } }
  .careers-wrapper .apply-jada-section p {
    font-size: 1.125rem;
    margin-bottom: 50px;
    text-align: center;
    max-width: 570px;
    z-index: 3; }
    @media only screen and (max-width: 700px) {
      .careers-wrapper .apply-jada-section p {
        font-size: 1rem; } }
  .careers-wrapper .apply-jada-section .buttons-container {
    text-align: center; }
    .careers-wrapper .apply-jada-section .buttons-container .filled_green {
      min-width: 187px;
      margin-right: 20px;
      text-align: center;
      z-index: 3; }
      @media only screen and (max-width: 400px) {
        .careers-wrapper .apply-jada-section .buttons-container .filled_green {
          margin-right: 0px;
          margin-bottom: 20px; } }
  .careers-wrapper .apply-jada-section:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: url(../images/jada-wave-pattern.png) no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 0.3; }
  .careers-wrapper .apply-jada-section:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(180deg, #373433 0%, rgba(55, 52, 51, 0) 100%); }

.careers-wrapper .container-section, .thank-you .container-section {
  width: 100%;
  height: 100vh;
  display: flex;
  padding-top: 20px; }
  @media only screen and (max-width: 767px) {
    .careers-wrapper .container-section, .thank-you .container-section {
      height: 60vh; } }
  .careers-wrapper .container-section .images, .thank-you .container-section .images {
    width: 100%;
    height: 100vh;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .careers-wrapper .container-section .images, .thank-you .container-section .images {
        height: 60vh; } }
    .careers-wrapper .container-section .images .img1, .thank-you .container-section .images .img1 {
      display: flex;
      align-items: center;
      width: 30%;
      justify-content: center; }
      .careers-wrapper .container-section .images .img1 .child1, .thank-you .container-section .images .img1 .child1 {
        width: 22%;
        height: 46%;
        position: absolute;
        left: 0; }
        @media only screen and (max-width: 767px) {
          .careers-wrapper .container-section .images .img1 .child1, .thank-you .container-section .images .img1 .child1 {
            height: 27%; } }
    .careers-wrapper .container-section .images .img2, .thank-you .container-section .images .img2 {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      width: 30%; }
      .careers-wrapper .container-section .images .img2 .child2, .thank-you .container-section .images .img2 .child2 {
        width: 87%;
        height: 33%;
        margin-bottom: 50px; }
        @media only screen and (max-width: 767px) {
          .careers-wrapper .container-section .images .img2 .child2, .thank-you .container-section .images .img2 .child2 {
            height: 26%;
            margin-bottom: 20px; } }
      .careers-wrapper .container-section .images .img2 .child3, .thank-you .container-section .images .img2 .child3 {
        width: 87%;
        height: 33%; }
        @media only screen and (max-width: 767px) {
          .careers-wrapper .container-section .images .img2 .child3, .thank-you .container-section .images .img2 .child3 {
            height: 26%; } }
    .careers-wrapper .container-section .images .img3, .thank-you .container-section .images .img3 {
      display: flex;
      width: 30%;
      align-items: center;
      justify-content: center; }
      .careers-wrapper .container-section .images .img3 .child4, .thank-you .container-section .images .img3 .child4 {
        width: 100%;
        height: 55%; }
        @media only screen and (max-width: 767px) {
          .careers-wrapper .container-section .images .img3 .child4, .thank-you .container-section .images .img3 .child4 {
            height: 37%; } }
    .careers-wrapper .container-section .images .img4, .thank-you .container-section .images .img4 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%; }
      .careers-wrapper .container-section .images .img4 .child5, .thank-you .container-section .images .img4 .child5 {
        width: 22%;
        height: 60%;
        position: absolute;
        right: 0; }
        @media only screen and (max-width: 767px) {
          .careers-wrapper .container-section .images .img4 .child5, .thank-you .container-section .images .img4 .child5 {
            height: 32%; } }

.careers-wrapper .container-section, .thank-you .container-section {
  max-height: 47.5vw;
  margin-bottom: 100px; }
  @media only screen and (max-width: 991px) {
    .careers-wrapper .container-section, .thank-you .container-section {
      max-height: 64.5vw;
      margin-bottom: 20px; } }
  @media only screen and (max-width: 500px) {
    .careers-wrapper .container-section, .thank-you .container-section {
      max-height: 84.5vw; } }
  .careers-wrapper .container-section .career-slider-images.images, .thank-you .container-section .career-slider-images.images {
    max-height: 47.5vw; }
    @media only screen and (max-width: 991px) {
      .careers-wrapper .container-section .career-slider-images.images, .thank-you .container-section .career-slider-images.images {
        max-height: 64.5vw; } }
    @media only screen and (max-width: 500px) {
      .careers-wrapper .container-section .career-slider-images.images, .thank-you .container-section .career-slider-images.images {
        max-height: 84.5vw; } }
    .careers-wrapper .container-section .career-slider-images.images img, .thank-you .container-section .career-slider-images.images img {
      object-position: center;
      object-fit: cover; }
    .careers-wrapper .container-section .career-slider-images.images .img1, .thank-you .container-section .career-slider-images.images .img1 {
      width: 100%;
      height: 100%; }
      .careers-wrapper .container-section .career-slider-images.images .img1 .child1, .thank-you .container-section .career-slider-images.images .img1 .child1 {
        width: 100%;
        height: 100%;
        max-height: 41vw;
        position: relative;
        object-position: center; }
        @media only screen and (max-width: 991px) {
          .careers-wrapper .container-section .career-slider-images.images .img1 .child1, .thank-you .container-section .career-slider-images.images .img1 .child1 {
            max-height: 58vw; } }
        @media only screen and (max-width: 500px) {
          .careers-wrapper .container-section .career-slider-images.images .img1 .child1, .thank-you .container-section .career-slider-images.images .img1 .child1 {
            max-height: 78vw; } }
    .careers-wrapper .container-section .career-slider-images.images .img2, .thank-you .container-section .career-slider-images.images .img2 {
      width: 100%;
      justify-content: space-between;
      height: 100%; }
      .careers-wrapper .container-section .career-slider-images.images .img2 .child2, .thank-you .container-section .career-slider-images.images .img2 .child2 {
        width: 100%;
        height: calc(50% - 25px); }
        @media only screen and (max-width: 991px) {
          .careers-wrapper .container-section .career-slider-images.images .img2 .child2, .thank-you .container-section .career-slider-images.images .img2 .child2 {
            height: calc(50% - 15px); } }
        @media only screen and (max-width: 767px) {
          .careers-wrapper .container-section .career-slider-images.images .img2 .child2, .thank-you .container-section .career-slider-images.images .img2 .child2 {
            height: calc(50% - 7px); } }
      .careers-wrapper .container-section .career-slider-images.images .img2 .child3, .thank-you .container-section .career-slider-images.images .img2 .child3 {
        width: 100%;
        height: calc(50% - 25px); }
        @media only screen and (max-width: 991px) {
          .careers-wrapper .container-section .career-slider-images.images .img2 .child3, .thank-you .container-section .career-slider-images.images .img2 .child3 {
            height: calc(50% - 15px); } }
        @media only screen and (max-width: 767px) {
          .careers-wrapper .container-section .career-slider-images.images .img2 .child3, .thank-you .container-section .career-slider-images.images .img2 .child3 {
            height: calc(50% - 7px); } }
    .careers-wrapper .container-section .career-slider-images.images .img3, .thank-you .container-section .career-slider-images.images .img3 {
      width: 100%;
      height: 100%; }
      .careers-wrapper .container-section .career-slider-images.images .img3 .child4, .thank-you .container-section .career-slider-images.images .img3 .child4 {
        width: 100%;
        height: 36vw; }
        @media only screen and (max-width: 991px) {
          .careers-wrapper .container-section .career-slider-images.images .img3 .child4, .thank-you .container-section .career-slider-images.images .img3 .child4 {
            height: 53vw; } }
        @media only screen and (max-width: 500px) {
          .careers-wrapper .container-section .career-slider-images.images .img3 .child4, .thank-you .container-section .career-slider-images.images .img3 .child4 {
            height: 73vw; } }
    .careers-wrapper .container-section .career-slider-images.images .img4, .thank-you .container-section .career-slider-images.images .img4 {
      height: 100%;
      width: 100%; }
      .careers-wrapper .container-section .career-slider-images.images .img4 .child5, .thank-you .container-section .career-slider-images.images .img4 .child5 {
        width: 100%;
        height: 100%;
        position: relative; }

.contact-us-wrapper .jada-main-banner:after {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.contact-us-wrapper section {
  margin-top: -2px;
  margin-bottom: -2px; }

.contact-us-wrapper .enquiry-now-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #FFFFFF;
  position: relative;
  z-index: 2; }
  @media only screen and (max-width: 991px) {
    .contact-us-wrapper .enquiry-now-form {
      padding: 0px; } }
  .contact-us-wrapper .enquiry-now-form .container-fluid, .contact-us-wrapper .enquiry-now-form .container-sm, .contact-us-wrapper .enquiry-now-form .container-md, .contact-us-wrapper .enquiry-now-form .container-lg, .contact-us-wrapper .enquiry-now-form .container-xl {
    padding: 0 !important; }
    .contact-us-wrapper .enquiry-now-form .container-fluid .row, .contact-us-wrapper .enquiry-now-form .container-sm .row, .contact-us-wrapper .enquiry-now-form .container-md .row, .contact-us-wrapper .enquiry-now-form .container-lg .row, .contact-us-wrapper .enquiry-now-form .container-xl .row {
      margin-right: 0px;
      margin-left: 0px; }
    @media only screen and (max-width: 1300px) {
      .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0px !important; } }
    @media only screen and (max-width: 991px) {
      .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6 {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%;
        padding-left: 15px !important;
        padding-right: 15px !important; }
        .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:first-child, .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:first-child, .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:first-child, .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:first-child, .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:first-child {
          padding-left: 0px !important; }
        .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:nth-child(2), .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:nth-child(2), .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:nth-child(2), .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:nth-child(2), .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:nth-child(2) {
          padding-right: 0px !important; } }
    @media only screen and (max-width: 767px) {
      .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6, .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0px !important; } }
    .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:first-child, .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:first-child, .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:first-child, .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:first-child, .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:first-child {
      padding-left: 0px; }
    .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:nth-child(2), .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:nth-child(2), .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:nth-child(2), .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:nth-child(2), .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:nth-child(2) {
      padding-right: 0px; }
  .contact-us-wrapper .enquiry-now-form h2 {
    font-family: "Cairo", sans-serif;
    font-size: 3.125rem;
    line-height: 1.12;
    font-weight: 400;
    color: #373433;
    margin-bottom: 35px; }
    @media only screen and (min-width: 992px) {
      .contact-us-wrapper .enquiry-now-form h2 {
        margin-bottom: 75px;
        font-size: 5rem;
        line-height: 1.075; } }
  .contact-us-wrapper .enquiry-now-form .dropdown {
    width: 100% !important; }
    .contact-us-wrapper .enquiry-now-form .dropdown .btn {
      padding: 20px;
      border: 1px solid rgba(127, 129, 132, 0.2);
      border-radius: 8px;
      z-index: 0;
      font-size: 1.25rem;
      line-height: 1.25;
      color: #373433;
      font-weight: 400;
      background-color: #FFFFFF; }
      .contact-us-wrapper .enquiry-now-form .dropdown .btn:focus, .contact-us-wrapper .enquiry-now-form .dropdown .btn:hover {
        border-color: #007473; }
      .contact-us-wrapper .enquiry-now-form .dropdown .btn:active {
        box-shadow: none;
        border-color: #007473; }
      .contact-us-wrapper .enquiry-now-form .dropdown .btn:focus-visible {
        border: 1px solid rgba(0, 116, 115, 0.2); }
  .contact-us-wrapper .enquiry-now-form .input-box {
    position: relative;
    text-align: left;
    width: 100%; }
    .contact-us-wrapper .enquiry-now-form .input-box p {
      margin-top: 12px;
      margin-bottom: 0px; }

.contact-us-wrapper .buttons-container {
  margin-top: 35px; }
  .contact-us-wrapper .buttons-container .jada-btn {
    border: 1px solid #007473;
    min-width: 234px !important; }
    @media only screen and (max-width: 767px) {
      .contact-us-wrapper .buttons-container .jada-btn {
        min-width: 184px !important; } }
    @media only screen and (min-width: 992px) {
      .contact-us-wrapper .buttons-container .jada-btn {
        padding: 18px 49px 18px 49px; } }

.contact-us-wrapper .enquiry-now-info-wrap {
  padding: 75px 70px 85px 35px;
  opacity: 0; }
  @media only screen and (max-width: 1400px) {
    .contact-us-wrapper .enquiry-now-info-wrap {
      padding: 40px; } }
  @media only screen and (max-width: 767px) {
    .contact-us-wrapper .enquiry-now-info-wrap {
      padding-right: 20px;
      padding-left: 20px; } }
  @media only screen and (max-width: 991px) {
    .contact-us-wrapper .enquiry-now-info-wrap .enquiry-now-info .message-box {
      width: 100%;
      padding-right: 0px; } }
  .contact-us-wrapper .enquiry-now-info-wrap .enquiry-now-info .share_input {
    width: 100%; }

.contact-us-wrapper .input-box {
  width: 50%;
  position: relative;
  margin-bottom: 20px; }
  .contact-us-wrapper .input-box.first_name, .contact-us-wrapper .input-box.email {
    padding-right: 0px; }
  @media only screen and (max-width: 767px) {
    .contact-us-wrapper .input-box {
      width: 100%; } }

.contact-us-wrapper textarea {
  resize: none;
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(127, 129, 132, 0.2);
  border-radius: 8px;
  z-index: 1;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #373433;
  font-weight: 400;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  -ms-transition: 0.2s all ease;
  -o-transition: 0.2s all ease;
  transition: 0.2s all ease; }
  .contact-us-wrapper textarea:focus {
    border-color: #007473;
    text-shadow: none; }
  .contact-us-wrapper textarea::placeholder {
    font-family: "Cairo", sans-serif;
    color: #373433;
    font-size: 1.25rem;
    line-height: 1.3;
    font-weight: 400; }

.contact-us-wrapper .last_name .abs_label {
  z-index: 0; }

.contact-us-wrapper .selection {
  width: 25%;
  padding-right: 10px; }
  @media only screen and (max-width: 1300px) {
    .contact-us-wrapper .selection {
      width: 50%; } }
  .contact-us-wrapper .selection label {
    width: 100%;
    padding: 18px 0px;
    border: 1px solid rgba(127, 129, 132, 0.2);
    font-size: 1.25rem;
    line-height: 1.3;
    border-radius: 8px;
    font-weight: 400;
    text-align: center;
    cursor: pointer; }
  .contact-us-wrapper .selection input {
    display: none; }
    .contact-us-wrapper .selection input:checked ~ label {
      border-color: #007473; }

.contact-us-wrapper .headquarters-info-wrap {
  position: relative;
  background-color: #373433;
  padding: 0px; }
  .contact-us-wrapper .headquarters-info-wrap .content {
    padding: 45px 25px 45px 25px; }
    @media only screen and (max-width: 991px) {
      .contact-us-wrapper .headquarters-info-wrap .content {
        padding-right: 40px;
        padding-left: 40px; } }
    @media only screen and (max-width: 767px) {
      .contact-us-wrapper .headquarters-info-wrap .content {
        padding-right: 20px;
        padding-left: 20px; } }
    .contact-us-wrapper .headquarters-info-wrap .content .col-md-6:first-child {
      padding-left: 0px; }
    .contact-us-wrapper .headquarters-info-wrap .content .col-md-6:nth-child(2) {
      padding-right: 0px; }
    @media only screen and (max-width: 767px) {
      .contact-us-wrapper .headquarters-info-wrap .content .col-md-6 {
        padding: 0px; } }
  .contact-us-wrapper .headquarters-info-wrap #map_canvas {
    height: 0;
    width: 100%;
    padding-top: calc( 465 / 703 * 100%);
    position: relative;
    opacity: 0; }
    .contact-us-wrapper .headquarters-info-wrap #map_canvas:after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      background: rgba(0, 0, 0, 0.2);
      height: 100%;
      width: 100%; }
    .contact-us-wrapper .headquarters-info-wrap #map_canvas img {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
  .contact-us-wrapper .headquarters-info-wrap .bg-img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    width: 50%;
    padding-top: 50%;
    margin-bottom: 36px; }
  .contact-us-wrapper .headquarters-info-wrap h3 {
    font-size: 2.25rem;
    line-height: 1.11111;
    margin-bottom: 15px; }
  .contact-us-wrapper .headquarters-info-wrap p {
    font-size: 1.5625rem;
    line-height: 1.12;
    margin-bottom: 35px; }
  .contact-us-wrapper .headquarters-info-wrap label {
    margin-bottom: 10px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.28571;
    letter-spacing: 0.5px; }
  .contact-us-wrapper .headquarters-info-wrap .link {
    font-weight: bold;
    display: block;
    font-size: 1.25rem;
    line-height: 1.3;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    margin-bottom: 30px; }
  .contact-us-wrapper .headquarters-info-wrap .social-links {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding: 0px; }
    @media only screen and (max-width: 500px) {
      .contact-us-wrapper .headquarters-info-wrap .social-links {
        width: 50%; } }
    .contact-us-wrapper .headquarters-info-wrap .social-links ul {
      list-style: none;
      margin-bottom: 0;
      display: flex; }
      .contact-us-wrapper .headquarters-info-wrap .social-links ul li {
        position: relative;
        list-style: none;
        height: 42px;
        width: 42px;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        background-color: #FFFFFF;
        margin-right: 14px;
        -webkit-transition: 0.3s all ease-in;
        -moz-transition: 0.3s all ease-in;
        -ms-transition: 0.3s all ease-in;
        -o-transition: 0.3s all ease-in;
        transition: 0.3s all ease-in;
        transition: 0.3s background-color ease-in; }
        @media only screen and (max-width: 767px) {
          .contact-us-wrapper .headquarters-info-wrap .social-links ul li {
            height: 36px;
            width: 36px; } }
        .contact-us-wrapper .headquarters-info-wrap .social-links ul li:hover {
          background-color: transparent;
          border: 1px solid #FFFFFF; }
          .contact-us-wrapper .headquarters-info-wrap .social-links ul li:hover .icomoon:before {
            color: #FFFFFF; }
        .contact-us-wrapper .headquarters-info-wrap .social-links ul li:last-child {
          margin-right: 0; }
        .contact-us-wrapper .headquarters-info-wrap .social-links ul li a {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0; }
          .contact-us-wrapper .headquarters-info-wrap .social-links ul li a .icomoon {
            position: absolute;
            font-size: 1.25rem;
            line-height: 1.25rem;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%); }
            .contact-us-wrapper .headquarters-info-wrap .social-links ul li a .icomoon:before {
              color: #373433;
              -webkit-transition: 0.3s all ease-in;
              -moz-transition: 0.3s all ease-in;
              -ms-transition: 0.3s all ease-in;
              -o-transition: 0.3s all ease-in;
              transition: 0.3s all ease-in; }
    .contact-us-wrapper .headquarters-info-wrap .social-links label {
      text-transform: uppercase;
      font-family: "Cairo", sans-serif;
      font-size: 1rem;
      line-height: 1.3;
      letter-spacing: 0.5px;
      color: #FFFFFF;
      opacity: 0.5;
      margin-bottom: 16px;
      font-weight: 700; }

.faqs-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  z-index: 0; }
  @media only screen and (max-width: 992px) {
    .faqs-wrapper {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .faqs-wrapper {
      padding-top: 60px;
      padding-bottom: 60px; } }

#faqs-accordion .panel,
#faqs-accordion .panel-heading,
#faqs-accordion .panel-title,
#faqs-accordion .panel-body {
  border: 0;
  border-radius: 0; }

#faqs-accordion .panel {
  box-shadow: none;
  padding-right: 80px;
  margin-top: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(55, 52, 51, 0.15); }
  #faqs-accordion .panel:first-child .panel-heading {
    padding-top: 0; }
  #faqs-accordion .panel .panel-heading {
    border-radius: 0;
    background-color: transparent;
    padding: 40px 0; }
    #faqs-accordion .panel .panel-heading .panel-title a {
      font-size: 2rem;
      line-height: 1.125;
      text-decoration: none;
      color: #373433;
      display: block;
      position: relative; }
      @media only screen and (min-width: 992px) {
        #faqs-accordion .panel .panel-heading .panel-title a {
          font-size: 2.625rem;
          line-height: 1.19048; } }
      #faqs-accordion .panel .panel-heading .panel-title a:after {
        content: "";
        background-image: url(../images/jada-close-icon.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        right: -80px;
        height: 57px;
        width: 57px; }
        @media only screen and (max-width: 767px) {
          #faqs-accordion .panel .panel-heading .panel-title a:after {
            height: 37px;
            width: 37px; } }
      #faqs-accordion .panel .panel-heading .panel-title a.collapsed:after {
        content: "";
        background-image: url(../images/jada-plus-icon.png); }
  #faqs-accordion .panel .panel-body {
    font-size: 1.375rem;
    line-height: 1.5;
    padding: 0;
    padding-bottom: 40px;
    color: #373433; }

body.arabic .contact-us-wrapper .headquarters-info-wrap {
  text-align: right; }
  body.arabic .contact-us-wrapper .headquarters-info-wrap .social-links ul li:last-child {
    margin-right: 14px; }
  body.arabic .contact-us-wrapper .headquarters-info-wrap .content .col-md-6:first-child {
    padding-right: 0px !important;
    padding-left: 15px !important; }
  body.arabic .contact-us-wrapper .headquarters-info-wrap .content .col-md-6:nth-child(2) {
    padding-left: 0px !important;
    padding-right: 15px !important; }
  @media only screen and (max-width: 767px) {
    body.arabic .contact-us-wrapper .headquarters-info-wrap .content .col-md-6:first-child {
      padding-right: 0px !important;
      padding-left: 0px !important; }
    body.arabic .contact-us-wrapper .headquarters-info-wrap .content .col-md-6:nth-child(2) {
      padding-left: 0px !important;
      padding-right: 0px !important; } }

body.arabic .contact-us-wrapper .enquiry-now-form {
  margin-left: -5px; }
  @media only screen and (min-width: 1200px) {
    body.arabic .contact-us-wrapper .enquiry-now-form {
      padding-left: 0px;
      padding-right: 0px; } }
  body.arabic .contact-us-wrapper .enquiry-now-form .buttons-container {
    text-align: right; }
  body.arabic .contact-us-wrapper .enquiry-now-form .input-box {
    padding: 0px !important; }
  body.arabic .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:first-child {
    padding-right: 0px !important;
    padding-left: 15px !important; }
  body.arabic .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:nth-child(2) {
    padding-right: 15px !important;
    padding-left: 0px !important; }
  @media only screen and (max-width: 1300px) {
    body.arabic .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6, body.arabic .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6, body.arabic .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6, body.arabic .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6, body.arabic .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6 {
      padding: 0px !important; }
      body.arabic .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:first-child {
        padding-right: 0px !important;
        padding-left: 0px !important; }
      body.arabic .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:nth-child(2) {
        padding-right: 0px !important;
        padding-left: 0px !important; } }
  @media only screen and (max-width: 991px) {
    body.arabic .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:first-child {
      padding-right: 0px !important;
      padding-left: 15px !important; }
    body.arabic .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:nth-child(2) {
      padding-right: 15px;
      padding-left: 0px; } }
  @media only screen and (max-width: 767px) {
    body.arabic .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6, body.arabic .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6, body.arabic .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6, body.arabic .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6, body.arabic .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6 {
      padding: 0px !important; }
      body.arabic .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:first-child, body.arabic .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:first-child {
        padding-right: 0px !important;
        padding-left: 0px !important; }
      body.arabic .contact-us-wrapper .enquiry-now-form .container-fluid .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-sm .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-md .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-lg .additional-info-wrap .additional-info .col-md-6:nth-child(2), body.arabic .contact-us-wrapper .enquiry-now-form .container-xl .additional-info-wrap .additional-info .col-md-6:nth-child(2) {
        padding-right: 0px !important;
        padding-left: 0px !important; } }

body.arabic .contact-us-wrapper .enquiry-now-info-wrap {
  padding: 75px 35px 85px 70px; }
  @media only screen and (max-width: 1400px) {
    body.arabic .contact-us-wrapper .enquiry-now-info-wrap {
      padding: 40px; } }
  @media only screen and (max-width: 767px) {
    body.arabic .contact-us-wrapper .enquiry-now-info-wrap {
      padding-right: 20px;
      padding-left: 20px; } }

body.arabic .contact-us-wrapper #faqs-accordion .panel {
  padding-right: 0;
  padding-left: 60px; }
  body.arabic .contact-us-wrapper #faqs-accordion .panel .panel-body {
    text-align: right; }
  body.arabic .contact-us-wrapper #faqs-accordion .panel .panel-heading .panel-title a:after {
    right: auto;
    left: -60px; }

.company-select .dropdown .dropdown-menu {
  border: 1px solid rgba(55, 52, 51, 0.15);
  box-shadow: none;
  padding: 10px 0px; }
  .company-select .dropdown .dropdown-menu li a {
    padding: 9px 19px 12px 19px;
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.25;
    font-weight: 400; }
  .company-select .dropdown .dropdown-menu li:after {
    display: none; }

.panel-group .panel.show .panel-collapse {
  display: block; }

.panel-group .panel.show .panel-heading .panel-title a:after {
  content: "";
  background-image: url(../images/jada-close-icon.png) !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  right: -80px;
  height: 57px;
  width: 57px; }
  @media only screen and (max-width: 767px) {
    .panel-group .panel.show .panel-heading .panel-title a:after {
      height: 37px;
      width: 37px; } }

.side-band {
  width: 25.2%;
  min-height: 170vh;
  height: 100%;
  background-image: url(../images/register-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 0px;
  top: 85px; }
  @media only screen and (max-width: 1090px) {
    .side-band {
      display: none; } }
  @media only screen and (max-width: 767px) {
    .side-band {
      display: none; } }

#apply_now.remove-transform {
  transform: none !important;
  width: 100% !important;
  height: auto !important;
  position: unset !important; }
  #apply_now.remove-transform .wrapper {
    padding-inline: 0px; }
    @media only screen and (max-width: 767px) {
      #apply_now.remove-transform .wrapper {
        overflow: auto; } }
    #apply_now.remove-transform .wrapper .content-wrapper {
      flex-direction: row;
      overflow-y: auto; }
      #apply_now.remove-transform .wrapper .content-wrapper h3.funding-title {
        font-weight: 700 !important;
        letter-spacing: -0.063px;
        margin-bottom: 55px !important; }
        @media only screen and (max-width: 1090px) {
          #apply_now.remove-transform .wrapper .content-wrapper h3.funding-title {
            font-size: 36px !important; } }
        @media only screen and (max-width: 767px) {
          #apply_now.remove-transform .wrapper .content-wrapper h3.funding-title {
            font-size: 36px !important;
            padding-top: 100px; } }
      #apply_now.remove-transform .wrapper .content-wrapper .login-form {
        position: relative;
        width: 70%; }
        @media only screen and (max-width: 1090px) {
          #apply_now.remove-transform .wrapper .content-wrapper .login-form {
            width: 100%; } }
      @media only screen and (max-width: 767px) {
        #apply_now.remove-transform .wrapper .content-wrapper {
          padding-inline: 0px; } }
  @media only screen and (max-width: 767px) {
    #apply_now.remove-transform {
      overflow: auto !important; } }

#apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .abs_label {
  color: #7F8184 !important;
  z-index: 2;
  font-size: clamp(11.5px, 1.1vw, 15px) !important;
  line-height: 1.4 !important; }

#apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form {
  z-index: unset;
  width: calc(100% - 30px); }
  @media only screen and (max-width: 1090px) {
    #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form {
      width: 100%; } }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form .first_name {
    width: calc(50% - 25px); }
    @media only screen and (max-width: 1090px) {
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form .first_name {
        width: 100%; } }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form .input-box.email {
    width: 47%; }
    @media only screen and (max-width: 1090px) {
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form .input-box.email {
        width: 100%; } }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form .input-box.phone {
    width: 47%; }
    #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form .input-box.phone .abs_label {
      z-index: 5; }
    @media only screen and (max-width: 1090px) {
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form .input-box.phone {
        width: 100%; } }

#apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select {
  width: calc(50% - 25px); }
  @media only screen and (max-width: 1090px) {
    #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select {
      width: 100%; } }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown {
    width: 100%  !important; }
    #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown .btn {
      min-height: 67px;
      padding: 20px;
      border: 1px solid rgba(127, 129, 132, 0.2);
      border-radius: 8px;
      z-index: 0;
      font-size: 1.25rem;
      line-height: 1.25;
      color: #373433;
      font-weight: 400;
      background-color: #fff; }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown .btn:focus, #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown .btn:focus-visible {
        box-shadow: none;
        border-color: #007473;
        outline: none !important; }
    #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown.multitag-select .filter-option-inner {
      max-height: 160px;
      overflow: auto; }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown.multitag-select .filter-option-inner .filter-option-inner-inner {
        display: flex;
        gap: 5px;
        flex-wrap: wrap; }
        #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown.multitag-select .filter-option-inner .filter-option-inner-inner .selected-tag {
          color: black;
          display: flex;
          border-radius: 6px;
          background: #F5F5F5;
          padding: 4px 34px 7px 11px;
          position: relative;
          transition: all 250ms linear; }
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown.multitag-select .filter-option-inner .filter-option-inner-inner .selected-tag svg {
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%); }
          #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown.multitag-select .filter-option-inner .filter-option-inner-inner .selected-tag:hover {
            background: transparent; }
    #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown.multitag-select > .dropdown-menu > .inner > .dropdown-menu li .check-mark {
      top: 10px; }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown.multitag-select > .dropdown-menu > .inner > .dropdown-menu li .check-mark::after {
        font-size: 15px; }
    #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown > .dropdown-menu {
      padding: 0;
      border: none;
      background: transparent; }
    #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown .dropdown-menu {
      padding: 0;
      min-height: unset !important; }
      #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.half-select .dropdown .dropdown-menu .inner {
        min-height: unset !important; }

#apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form1.upload-field {
  padding: 13%;
  border: 1px dashed #373433;
  border-radius: 15px;
  flex-direction: column; }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form1.upload-field .btn {
    width: 100%;
    border: none;
    margin-bottom: 10px;
    height: auto; }
    #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form1.upload-field .btn p {
      font-size: 13px;
      opacity: 0.5;
      text-transform: none;
      font-weight: 400;
      letter-spacing: 0.1px;
      white-space: normal; }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form1.upload-field .file-upload--info {
    display: flex;
    align-items: center;
    gap: 10px; }

#apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .inline-form2 {
  justify-content: flex-start; }

#apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .flex {
  margin-top: 35px;
  display: flex;
  gap: 10px;
  justify-content: flex-start; }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .flex p {
    width: 100%; }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .flex .in {
    position: relative; }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .flex input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    height: 24px;
    width: 24px;
    z-index: 1; }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .flex label {
    height: 24px;
    width: 24px;
    border-radius: 8px;
    border: 1px solid rgba(55, 52, 51, 0.2);
    position: relative;
    cursor: pointer; }
    #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .flex label:after {
      position: absolute;
      top: -6px;
      right: -4px;
      content: "\E906";
      font-family: "icomoon";
      font-size: 24px;
      color: #007473;
      opacity: 0; }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .flex input[type="checkbox"]:checked ~ label:after {
    opacity: 1; }

#apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.stage-fund .stage-of-fund-selector.disabled {
  cursor: not-allowed; }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.stage-fund .stage-of-fund-selector.disabled button {
    pointer-events: none; }

#apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.stage-fund .stage-of-fund-selector .dropdown-menu ul li {
  display: none; }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.stage-fund .stage-of-fund-selector .dropdown-menu ul li.selected.active {
    display: block; }
  #apply_now .wrapper .content-wrapper .login-form .personal-info-wrap .personal-info.apply-funding .company-select.stage-fund .stage-of-fund-selector .dropdown-menu ul li[data-fundslx="1"] {
    display: block; }

.form-precheck {
  position: absolute;
  top: 15%;
  opacity: 1;
  z-index: 4; }
  .form-precheck.unlock {
    opacity: 0;
    pointer-events: none; }
  .form-precheck--header h2 {
    font-size: 2.2rem;
    margin-bottom: 11px; }
  .form-precheck--header p {
    color: rgba(55, 52, 51, 0.7); }
  .form-precheck--cbox-container .disclaimer {
    margin-top: 20px; }

.submit-btn {
  transition: all 200ms linear;
  margin-top: 35px !important; }
  .submit-btn.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed; }

.form-funding-main {
  transition: all 300ms; }
  .form-funding-main.disabled {
    opacity: 0; }

body {
  overflow-y: scroll;
  overflow-x: hidden; }
  body.menu-active {
    overflow-y: hidden;
    transition: 0.5s all ease;
    -webkit-transition: 0.5s all ease;
    -moz-transition: 0.5s all ease;
    -ms-transition: 0.5s all ease;
    -o-transition: 0.5s all ease; }
  body.body-fixed {
    height: 100vh;
    overflow: hidden; }

.grecaptcha-badge {
  opacity: 0; }

main {
  overflow: hidden; }

a:hover {
  text-decoration: none !important; }

a:focus {
  text-decoration: none !important;
  outline: none; }

#cookie-popup {
  height: auto;
  width: 100vw;
  background-color: #373433;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  #cookie-popup.hidden {
    display: none; }
  #cookie-popup .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
    #cookie-popup .content-container .row {
      display: flex; }
      @media only screen and (max-width: 1200px) {
        #cookie-popup .content-container .row {
          flex-direction: column;
          justify-content: space-evenly;
          align-items: flex-start;
          height: 100%; } }
      #cookie-popup .content-container .row .p {
        font-family: "Cairo", sans-serif;
        margin-right: 15px;
        font-weight: normal;
        color: #FFFFFF;
        font-size: 1.1875rem;
        line-height: 1.375rem;
        max-width: 647px; }
        @media only screen and (max-width: 1600px) {
          #cookie-popup .content-container .row .p {
            font-size: 1.125rem;
            line-height: 1.3125rem;
            max-width: 620px; } }
        @media only screen and (max-width: 767px) {
          #cookie-popup .content-container .row .p {
            font-size: 15px;
            line-height: 18px;
            max-width: 320px; } }
        @media only screen and (max-width: 400px) {
          #cookie-popup .content-container .row .p {
            max-width: 255px; } }

.hidden {
  display: none; }

div[data-scroll-section] {
  will-change: transform;
  transform: translateZ(0); }

.ms-long {
  width: 100%; }

html.editmode .apply-jada-section {
  background: none !important; }
  html.editmode .apply-jada-section:after {
    display: none; }
  html.editmode .apply-jada-section:before {
    display: none; }

html.editmode .case-study-detail-wrapper .gallery-section.banner-spacer-green:before {
  display: none;
  height: 0; }

html.editmode .side-bar-hamburger {
  display: none; }

html.editmode .jada-cursor {
  display: none !important; }

html.editmode header {
  display: none; }

html.editmode .editmode-image {
  height: 50px;
  width: 50px; }

html.editmode .ms-rtestate-field img {
  height: 100px;
  width: 100px;
  cursor: pointer; }

html.editmode .editmode-container {
  background-color: #f2f8f7; }

html.editmode .editmode-homepage {
  max-height: none;
  max-width: 1090px;
  margin: auto;
  padding-top: 80px; }
  html.editmode .editmode-homepage h3 {
    margin-bottom: 50px;
    text-transform: uppercase; }
  html.editmode .editmode-homepage p {
    margin: 0; }
  html.editmode .editmode-homepage .slider_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px; }
    html.editmode .editmode-homepage .slider_box:last-child {
      margin-bottom: 0px; }
    html.editmode .editmode-homepage .slider_box h4 {
      margin-bottom: 20px;
      font-size: 25px;
      margin-left: 0;
      margin-top: 0;
      margin-right: 0; }
    html.editmode .editmode-homepage .slider_box .slider_image img {
      height: 180px;
      width: 280px; }
  html.editmode .editmode-homepage .edit_section {
    margin-bottom: 80px; }
    html.editmode .editmode-homepage .edit_section .description_box {
      display: flex;
      width: 75%;
      justify-content: space-between;
      margin-bottom: 30px; }
      html.editmode .editmode-homepage .edit_section .description_box img {
        height: 250px;
        width: 250px; }

.error-message {
  color: #D62525 !important;
  font-size: 16px;
  line-height: 1.3; }

.error input, .error textarea {
  border-color: #D62525 !important;
  margin-bottom: 14px; }

.error .error-message {
  position: absolute;
  left: 0;
  line-height: 1;
  bottom: -5px;
  color: #D62525 !important; }

.error .upload-error {
  position: relative;
  right: 0;
  line-height: 1.5;
  color: #D62525 !important;
  display: block;
  text-align: right; }

.error.textarea .error-message {
  bottom: 15px; }

#cookie-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #F2F8F7;
  border: 1px solid #007473;
  height: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  #cookie-popup.hidden {
    display: none; }
  #cookie-popup p {
    font-family: "Cairo", sans-serif;
    font-size: 1.5625rem;
    line-height: 1.36;
    font-weight: 400;
    color: #007473;
    margin: 0px; }
    @media only screen and (max-width: 991px) {
      #cookie-popup p {
        margin-bottom: 20px; } }
  #cookie-popup .button-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 991px) {
      #cookie-popup .button-container {
        justify-content: flex-start; } }
    #cookie-popup .button-container .jada-btn:first-child {
      margin-right: 20px; }
      @media only screen and (max-width: 500px) {
        #cookie-popup .button-container .jada-btn:first-child {
          margin-right: 10px; } }
